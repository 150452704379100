import { useEffect, useState } from 'react'

const useIsEqual = (initialState, currentState) => {
	const [isEqualState, setIsEqualState] = useState(true)

	useEffect(() => {
		const isEqual = (obj1, obj2) => {
			if (
				typeof obj1 !== 'object' ||
				typeof obj2 !== 'object' ||
				obj1 === null ||
				obj2 === null
			) {
				return obj1 === obj2
			}

			const keys1 = Object.keys(obj1)
			const keys2 = Object.keys(obj2)
			if (keys1.length !== keys2.length) {
				return false
			}

			for (const key of keys1) {
				if (!isEqual(obj1[key], obj2[key])) {
					return false
				}
			}

			return true
		}

		setIsEqualState(isEqual(initialState, currentState))
	}, [initialState, currentState])

	return isEqualState
}

export default useIsEqual
