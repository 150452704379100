import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IDigitizatedCowInfo } from 'models/ICattlesData'
import { IGroupApexChart } from '../models/IGroup'
import { INotification } from '../models/notifications'

interface WebSocketState {
	socket: WebSocket | null
	messageForFeedTableStatus: IGroupApexChart
	messageForNotifications: INotification
	messageForCattleInfoDigitization: IDigitizatedCowInfo
}

const initialState: WebSocketState = {
	socket: null,
	messageForFeedTableStatus: null,
	messageForNotifications: null,
	messageForCattleInfoDigitization: null,
}

const webSocketSlice = createSlice({
	name: 'websocket',
	initialState,
	reducers: {
		messageForFeedTableStatusGraph: (
			state,
			action: PayloadAction<IGroupApexChart>
		) => {
			state.messageForFeedTableStatus = action.payload
		},
		messageForNotification: (state, action: PayloadAction<any>) => {
			state.messageForNotifications = action.payload
		},
		messageForCattleInfoDigitization: (
			state,
			action: PayloadAction<IDigitizatedCowInfo>
		) => {
			state.messageForCattleInfoDigitization = action.payload
		},
		addImagesInDigitizatedCowInfo: (state, action: PayloadAction<any>) => {
			const newState = state.messageForCattleInfoDigitization
			if (newState) {
				newState.images.push(action.payload)
        
				state.messageForCattleInfoDigitization = newState
			}
		},
	},
})

export const {
	messageForFeedTableStatusGraph,
	messageForNotification,
	messageForCattleInfoDigitization,
	addImagesInDigitizatedCowInfo,
} = webSocketSlice.actions
export default webSocketSlice.reducer

export const receiveMessage = (messageData: any) => (dispatch: any) => {
	switch (messageData.type) {
		case 'foodtable_graph':
			dispatch(messageForFeedTableStatusGraph(messageData.payload))
			break
		case 'notification':
			dispatch(messageForNotification(messageData.payload))
			break
		case 'cow_milking_detected':
			dispatch(messageForCattleInfoDigitization(messageData.payload))
			break
		case 'cow_milking_empty':
			dispatch(messageForCattleInfoDigitization(messageData.payload))
			break
		case 'cow_milking_image':
			dispatch(addImagesInDigitizatedCowInfo(messageData.payload))
			break
		default:
			console.warn('Unknown message type:', messageData.type)
	}
}
