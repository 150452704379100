import { Box, Dialog } from '@mui/material'
import { COLORS } from 'styles/colors'
import { OutlinedButton } from 'ui/buttons/OutlinedButton'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'

const ErrorDialog = props => {
	return (
		<Dialog
			open={props.openErrorPopup}
			maxWidth='sm'
			scroll='paper'
			PaperProps={{
				style: {
					borderRadius: '12px',
					width: '1100px',
					padding: '24px',
				},
			}}
		>
			<TypographyHeader sx={{ textAlign: 'center' }}>
				{props.title}
			</TypographyHeader>
			<TypographyPrimary sx={{ textAlign: 'center', mt: '16px' }}>
				{props.description}
			</TypographyPrimary>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					gap: '24px',
				}}
			>
				{props.type === 'role_already_exists' && (
					<>
						<OutlinedButton
							disableRipple
							sx={{
								color: COLORS.deviationFont,
								border: `1px solid ${COLORS.deviationFont}`,
								'&:hover': {
									color: COLORS.deviationOpacity,
									border: `2px solid ${COLORS.deviationOpacity}`,
									backgroundColor: 'transparent',
									boxShadow: 'none',
								},
							}}
							onClick={props.returnAtEditCreateRoleForm}
						>
							Вернуться к редактированию
						</OutlinedButton>
						<OutlinedButton disableRipple onClick={props.changeCurrentRole}>
							Назначить существующую
						</OutlinedButton>
					</>
				)}
				{props.type === 'exit_without_changes' && (
					<>
						<OutlinedButton
							disableRipple
							onClick={props.returnAtEditEditUserForm}
						>
							Вернуться к редактированию
						</OutlinedButton>
						<OutlinedButton
							disableRipple
							sx={{
								color: COLORS.deviationFont,
								border: `1px solid ${COLORS.deviationFont}`,
								'&:hover': {
									color: COLORS.deviationOpacity,
									border: `2px solid ${COLORS.deviationOpacity}`,
									backgroundColor: 'transparent',
									boxShadow: 'none',
								},
							}}
							onClick={props.confirmExitWithoutChanges}
						>
							Выйти без сохранения
						</OutlinedButton>
					</>
				)}
			</Box>
		</Dialog>
	)
}

export default ErrorDialog
