import { Box } from '@mui/material'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import { TypographyPrimary12 } from 'ui/typography/TypographyPrimary12'
import { ReactComponent as InfoLogo } from '../../../../assets/barchart_info_logo.svg'

const RepeatIdentifiedCard = () => {
	return (
		<CardStandart
			sx={{
				padding: '24px',
				backgroundColor: COLORS.deviationOpacity,
				height: '100%',
			}}
		>
			<Box
				display={'flex'}
				flexDirection={'row'}
				alignItems={'center'}
				gap={'16px'}
			>
				<InfoLogo style={{ color: COLORS.error }} />
				<TypographyPrimary12>Проведите повторную оцифровку</TypographyPrimary12>
			</Box>
		</CardStandart>
	)
}

export default RepeatIdentifiedCard
