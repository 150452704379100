import { Box, Checkbox, Dialog, Divider } from '@mui/material'
import { UserEditField } from 'components/shared/UserEditField'
import { useState } from 'react'
import { COLORS } from 'styles/colors'
import SwitchiOS from 'ui/SwitchiOS'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyHeader14 } from 'ui/typography/TypographyHeader14'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

import { ReactComponent as CrossCloseLogo } from 'assets/cross_close_logo.svg'
import { ReactComponent as CheckBox } from 'assets/icons/checkBox.svg'
import CheckBoxActive from 'assets/icons/checkBoxActive'
import { ReactComponent as SettingLogo } from 'assets/setting_logo.svg'
import { ReactComponent as AnalyticsIcon } from 'assets/sidebar/analytics_icon.svg'
import { ReactComponent as EatingPlanIcon } from 'assets/sidebar/eating_plan_icon.svg'
import { ReactComponent as FeedTableIcon } from 'assets/sidebar/feed_table_icon.svg'
import { ReactComponent as GroupsIcon } from 'assets/sidebar/groups_icon.svg'
import AuthApiService from 'services/AuthApiService'
import { useAppDispatch } from 'store/hooks'
import { notifyUser } from 'store/notificationsSlice'
import { addNewRole } from 'store/userSlice'
import { ButtonUI } from 'ui/buttons/ButtonUI'
import { ReactComponent as RationsIcon } from 'assets/sidebar/rations_icon.svg'

const initialState = {
	can_edit_cattle_groups: false,
	can_edit_cattle_quantity_group: false,
	can_edit_cattle_quantity_main: false,
	can_edit_feedplans: false,
	can_export_feedplans: false,
	can_edit_rations: false,
	can_manage_permissions: false,
	can_manage_users: false,
	can_view_cattle_groups: false,
	can_view_feedplans: false,
	can_view_foodtable_main: false,
	can_view_rations: false,
	can_view_analytics: false,
}
const CreateRolePopup = props => {
	const [roleTitle, setRoleTitle] = useState('')
	const [roleDescription, setRoleDescription] = useState('')
	const [checked, setChecked] = useState(initialState)

	const hasTrueValue = Object.values(checked).some(value => value === true)
	const dispatch = useAppDispatch()
	const handleChange = (
		key: string,
		parent: string | null,
		child?: {} | null
	) => {
		setChecked(prevChecked => {
			if (parent && !prevChecked[parent]) {
				return {
					...prevChecked,
					[key]: !prevChecked[key],
					[parent]: !prevChecked[parent],
				}
			} else if (parent && prevChecked[parent]) {
				return {
					...prevChecked,
					[key]: !prevChecked[key],
				}
			}

			if (child && prevChecked[key]) {
				const updatedChild = Object.keys(child).reduce(
					(acc, childKey) => ({ ...acc, [childKey]: false }),
					{}
				)

				return {
					...prevChecked,
					[key]: !prevChecked[key],
					...updatedChild,
				}
			} else if (child && !prevChecked[key]) {
				return {
					...prevChecked,
					[key]: !prevChecked[key],
				}
			} else if (child && child[0] === null) {
				return {
					...prevChecked,
					[key]: !prevChecked[key],
				}
			}

			return prevChecked
		})
	}

	const [errorState, setErrorState] = useState({
		roleTitle: false,
		roleDescription: false,
	})
	const validators = {
		roleTitle: () => {
			const isError = roleTitle.length === 0
			if (errorState.roleTitle !== isError) {
				setErrorState({ ...errorState, roleTitle: isError })
			}
		},
		roleDescription: () => {
			const isError = roleDescription.length === 0
			if (errorState.roleDescription !== isError) {
				setErrorState({ ...errorState, roleDescription: isError })
			}
		},
	}
	const saveDataAtServer = () => {
		const perms = []
		for (const [key, value] of Object.entries(checked)) {
			if (value === true) {
				const newObj = { name: key }
				perms.push(newObj)
			}
		}
		const dataForFetch = {
			title: roleTitle,
			description: roleDescription,
			perms_list: perms,
		}
		AuthApiService.postNewRole(dataForFetch)
			.then(response => {
				dispatch(
					notifyUser({
						title: roleTitle,
						description: 'Роль успешно создана',
						isError: false,
					})
				)
				dispatch(addNewRole(response.data))
				setRoleTitle('')
				setRoleDescription('')
				setChecked(initialState)
				props.setIsCreateRolePopup(false)
			})
			.catch(error => {
				props.setErrorStateMessages(error.response.data)
				props.setIsCreateRolePopup(false)
				props.setOpenErrorPopup(true)
			})
	}

	return (
		<Dialog
			open={props.open}
			maxWidth='lg'
			scroll='paper'
			PaperProps={{
				style: {
					borderRadius: '12px',
					width: '1100px',
					padding: '24px',
				},
			}}
		>
			<Box
				display='flex'
				flexDirection='row'
				justifyContent='space-between'
				alignItems='center'
			>
				<TypographyHeader>Создание новой роли</TypographyHeader>
				<CrossCloseLogo
					onClick={() => {
						setRoleTitle('')
						setRoleDescription('')
						setChecked(initialState)
						props.setIsCreateRolePopup(false)
					}}
					style={{ cursor: 'pointer', stroke: COLORS.secondary }}
				/>
			</Box>

			<TypographySecondary14 mt='16px'>
				Создание новой роли позволяет самостоятельно настроить доступный
				функционал.
			</TypographySecondary14>
			<TypographySecondary14>
				После создания, новая роль появится в основном списке.
			</TypographySecondary14>
			<Box display='flex' flexDirection='column' gap='16px' mt='16px'>
				<UserEditField
					isErrorFocus={errorState.roleTitle}
					onBlur={() => validators.roleTitle()}
					onFocus={() => {
						setErrorState({ ...errorState, roleTitle: false })
					}}
					helperText='Укажите наименование новой роли'
					value={roleTitle}
					id={'roleTitle'}
					label='Наименование новой роли'
					onChange={e => {
						setRoleTitle(e.target.value)
					}}
				/>
				<UserEditField
					isErrorFocus={errorState.roleDescription}
					onBlur={() => validators.roleDescription()}
					onFocus={() => {
						setErrorState({ ...errorState, roleDescription: false })
					}}
					helperText='Укажите краткое описание новой роли'
					value={roleDescription}
					id={'roleDescription'}
					width={'100%'}
					label='Краткое описание'
					maxLength={109}
					onChange={e => {
						setRoleDescription(e.target.value)
					}}
				/>
			</Box>
			<TypographyPrimary16Bold>Доступы к разделам</TypographyPrimary16Bold>
			<Box display='flex' flexDirection='row' gap='16px' mt='24px'>
				<SwitchiOS
					background={COLORS.main}
					checked={checked.can_view_foodtable_main}
					onClick={() =>
						handleChange('can_view_foodtable_main', null, {
							can_edit_cattle_quantity_main:
								checked.can_edit_cattle_quantity_main,
						})
					}
				/>
				<Box display='flex' flexDirection='column' gap='16px'>
					<Box display='flex' flexDirection='row' alignItems='center' gap='8px'>
						<FeedTableIcon stroke='#7F7F84' />
						<TypographyHeader14>Кормовой стол</TypographyHeader14>
					</Box>
					<TypographySecondary14>
						Просмотр показателей за вчерашний день, плана кормления,
						обнаруженных отклонений, а также доступности корма.
					</TypographySecondary14>
					<TypographyPrimary>Настраиваемый функционал:</TypographyPrimary>
					<Box
						display='flex'
						flexDirection='row'
						alignItems='center'
						gap='16px'
					>
						<Checkbox
							sx={{
								color: COLORS.inputBorder,
								'&.MuiCheckbox-root': {
									margin: '0px',
									padding: '0px',
									width: '20px',
									height: '20px',
									'&.Mui-checked': {
										color: COLORS.main,
									},
								},
							}}
							disableRipple
							icon={<CheckBox />}
							checkedIcon={<CheckBoxActive />}
							checked={checked.can_edit_cattle_quantity_main}
							onClick={() => {
								handleChange(
									'can_edit_cattle_quantity_main',
									'can_view_foodtable_main'
								)
							}}
						/>
						<TypographyPrimary>
							Редактирование количества голов
						</TypographyPrimary>
					</Box>
				</Box>
			</Box>

			<Divider
				sx={{
					borderBottomWidth: 1,
					borderColor: COLORS.inputBorder,
					mt: '24px',
				}}
			/>

			<Box display='flex' flexDirection='row' gap='16px' mt='24px'>
				<SwitchiOS
					background={COLORS.main}
					checked={checked.can_view_cattle_groups}
					onClick={() =>
						handleChange('can_view_cattle_groups', null, {
							can_edit_cattle_groups: checked.can_edit_cattle_groups,
							can_edit_cattle_quantity_group:
								checked.can_edit_cattle_quantity_group,
						})
					}
				/>
				<Box display='flex' flexDirection='column' gap='16px'>
					<Box display='flex' flexDirection='row' alignItems='center' gap='8px'>
						<GroupsIcon stroke='#7F7F84' />
						<TypographyHeader14>Группы</TypographyHeader14>
					</Box>
					<TypographySecondary14>
						Просмотр списка всех групп, а также подробной информации по каждой
						группе.
					</TypographySecondary14>
					<TypographyPrimary>Настраиваемый функционал:</TypographyPrimary>
					<Box
						display='flex'
						flexDirection='row'
						alignItems='center'
						gap='16px'
					>
						<Checkbox
							sx={{
								color: COLORS.inputBorder,
								'&.MuiCheckbox-root': {
									margin: '0px',
									padding: '0px',
									width: '20px',
									height: '20px',
									'&.Mui-checked': {
										color: COLORS.main,
									},
								},
							}}
							disableRipple
							icon={<CheckBox />}
							checkedIcon={<CheckBoxActive />}
							checked={checked.can_edit_cattle_quantity_group}
							onClick={() => {
								handleChange(
									'can_edit_cattle_quantity_group',
									'can_view_cattle_groups'
								)
							}}
						/>
						<TypographyPrimary>
							Редактирование количества голов
						</TypographyPrimary>
					</Box>
					<Box
						display='flex'
						flexDirection='row'
						alignItems='center'
						gap='16px'
					>
						<Checkbox
							sx={{
								color: COLORS.inputBorder,
								'&.MuiCheckbox-root': {
									margin: '0px',
									padding: '0px',
									width: '20px',
									height: '20px',
									'&.Mui-checked': {
										color: COLORS.main,
									},
								},
							}}
							disableRipple
							icon={<CheckBox />}
							checkedIcon={<CheckBoxActive />}
							checked={checked.can_edit_cattle_groups}
							onClick={() => {
								handleChange('can_edit_cattle_groups', 'can_view_cattle_groups')
							}}
						/>
						<TypographyPrimary>Редактирование групп</TypographyPrimary>
					</Box>
				</Box>
			</Box>

			<Divider
				sx={{
					borderBottomWidth: 1,
					borderColor: COLORS.inputBorder,
					mt: '24px',
				}}
			/>

			<Box display='flex' flexDirection='row' gap='16px' mt='24px'>
				<SwitchiOS
					background={COLORS.main}
					checked={checked.can_view_rations}
					onClick={() =>
						handleChange('can_view_rations', null, {
							can_edit_rations: checked.can_edit_rations,
						})
					}
				/>
				<Box display='flex' flexDirection='column' gap='16px'>
					<Box display='flex' flexDirection='row' alignItems='center' gap='8px'>
						<RationsIcon stroke='#7F7F84' />
						<TypographyHeader14>Рационы</TypographyHeader14>
					</Box>
					<TypographySecondary14>
						Просмотр списка рационов и компонентов.
					</TypographySecondary14>
					<TypographyPrimary>Настраиваемый функционал:</TypographyPrimary>
					<Box
						display='flex'
						flexDirection='row'
						alignItems='center'
						gap='16px'
					>
						<Checkbox
							sx={{
								color: COLORS.inputBorder,
								'&.MuiCheckbox-root': {
									margin: '0px',
									padding: '0px',
									width: '20px',
									height: '20px',
									'&.Mui-checked': {
										color: COLORS.main,
									},
								},
							}}
							disableRipple
							icon={<CheckBox />}
							checkedIcon={<CheckBoxActive />}
							checked={checked.can_edit_rations}
							onClick={() => {
								handleChange('can_edit_rations', 'can_view_rations')
							}}
						/>
						<TypographyPrimary>
							Создание рационов, добавление и редактирование компонентов
						</TypographyPrimary>
					</Box>
				</Box>
			</Box>

			<Divider
				sx={{
					borderBottomWidth: 1,
					borderColor: COLORS.inputBorder,
					mt: '24px',
				}}
			/>

			<Box display='flex' flexDirection='row' gap='16px' mt='24px'>
				<SwitchiOS
					background={COLORS.main}
					checked={checked.can_view_feedplans}
					onClick={() =>
						handleChange('can_view_feedplans', null, {
							can_edit_feedplans: checked.can_edit_feedplans,
							can_export_feedplans: checked.can_export_feedplans,
						})
					}
				/>
				<Box display='flex' flexDirection='column' gap='16px'>
					<Box display='flex' flexDirection='row' alignItems='center' gap='8px'>
						<EatingPlanIcon stroke='#7F7F84' />
						<TypographyHeader14>План кормления</TypographyHeader14>
					</Box>
					<TypographySecondary14>
						Просмотр списка всех групп, а также подробной информации по каждой
						группе.
					</TypographySecondary14>
					<TypographyPrimary>Настраиваемый функционал:</TypographyPrimary>
					<Box
						display='flex'
						flexDirection='row'
						alignItems='center'
						gap='16px'
					>
						<Checkbox
							sx={{
								color: COLORS.inputBorder,
								'&.MuiCheckbox-root': {
									margin: '0px',
									padding: '0px',
									width: '20px',
									height: '20px',
									'&.Mui-checked': {
										color: COLORS.main,
									},
								},
							}}
							disableRipple
							icon={<CheckBox />}
							checkedIcon={<CheckBoxActive />}
							checked={checked.can_edit_feedplans}
							onClick={() => {
								handleChange('can_edit_feedplans', 'can_view_feedplans')
							}}
						/>
						<TypographyPrimary>
							Редактирование плана кормления
						</TypographyPrimary>
					</Box>
					<Box
						display='flex'
						flexDirection='row'
						alignItems='center'
						gap='16px'
					>
						<Checkbox
							sx={{
								color: COLORS.inputBorder,
								'&.MuiCheckbox-root': {
									margin: '0px',
									padding: '0px',
									width: '20px',
									height: '20px',
									'&.Mui-checked': {
										color: COLORS.main,
									},
								},
							}}
							disableRipple
							icon={<CheckBox />}
							checkedIcon={<CheckBoxActive />}
							checked={checked.can_export_feedplans}
							onClick={() => {
								handleChange('can_export_feedplans', 'can_view_feedplans')
							}}
						/>
						<TypographyPrimary>Экспорт</TypographyPrimary>
					</Box>
				</Box>
			</Box>

			<Divider
				sx={{
					borderBottomWidth: 1,
					borderColor: COLORS.inputBorder,
					mt: '24px',
				}}
			/>

			<Box display='flex' flexDirection='row' gap='16px' mt='24px'>
				<SwitchiOS
					background={COLORS.main}
					checked={checked.can_view_analytics}
					onClick={() =>
						handleChange('can_view_analytics', null, { empty: null })
					}
				/>
				<Box display='flex' flexDirection='column' gap='16px'>
					<Box display='flex' flexDirection='row' alignItems='center' gap='8px'>
						<AnalyticsIcon fill='#7F7F84' />
						<TypographyHeader14>Аналитика</TypographyHeader14>
					</Box>
					<TypographySecondary14>
						Просмотр и экспорт сводного отчета и аналитики кормления.
					</TypographySecondary14>
				</Box>
			</Box>

			<Divider
				sx={{
					borderBottomWidth: 1,
					borderColor: COLORS.inputBorder,
					mt: '24px',
				}}
			/>

			<Box display='flex' flexDirection='row' gap='16px' mt='24px'>
				<SwitchiOS
					background={COLORS.main}
					checked={checked.can_manage_users}
					onClick={() =>
						handleChange('can_manage_users', null, {
							can_manage_permissions: checked.can_manage_permissions,
						})
					}
				/>
				<Box display='flex' flexDirection='column' gap='16px'>
					<Box display='flex' flexDirection='row' alignItems='center' gap='8px'>
						<SettingLogo stroke='#7F7F84' />
						<TypographyHeader14>Настройки</TypographyHeader14>
					</Box>
					<TypographySecondary14>
						Просмотр страницы управления пользователями.
					</TypographySecondary14>
					<TypographyPrimary>Настраиваемый функционал:</TypographyPrimary>
					<Box
						display='flex'
						flexDirection='row'
						alignItems='center'
						gap='16px'
					>
						<Checkbox
							sx={{
								color: COLORS.inputBorder,
								'&.MuiCheckbox-root': {
									margin: '0px',
									padding: '0px',
									width: '20px',
									height: '20px',
									'&.Mui-checked': {
										color: COLORS.main,
									},
								},
							}}
							disableRipple
							icon={<CheckBox />}
							checkedIcon={<CheckBoxActive />}
							checked={checked.can_manage_permissions}
							onClick={() => {
								handleChange('can_manage_permissions', 'can_manage_users')
							}}
						/>
						<TypographyPrimary>
							Создание и редактирование доступов пользователей
						</TypographyPrimary>
					</Box>
				</Box>
			</Box>
			<Box sx={{ display: 'flex', alignSelf: 'center' }}>
				<ButtonUI
					sx={{ height: '56px', width: '400px', marginTop: '24px' }}
					onClick={() => saveDataAtServer()}
					disabled={
						roleTitle === '' ||
						roleTitle.trim() === '' ||
						roleDescription === '' ||
						roleDescription.trim() === '' ||
						!hasTrueValue
					}
				>
					{'Создать'}
				</ButtonUI>
			</Box>
		</Dialog>
	)
}

export default CreateRolePopup
