import { COLORS } from '../styles/colors'
import { OutlinedButton } from './buttons/OutlinedButton'

const CheckBoxFilter = ({ title, active, onClick }) => {
	return (
		<OutlinedButton
			disableRipple
			sx={{
				width: 'max-content',
				padding: '11px 16px',
				margin: '0px',
				height: '40px',
				color: active ? COLORS.main : COLORS.secondary,
				border: `1px solid ${active ? COLORS.main : COLORS.inputBorder}`,
				textTransform: 'none',
				fontWeight: 600,
				'&:hover': {
					backgroundColor: 'transparent',
					color: COLORS.main,
					borderColor: COLORS.main,
					boxShadow: 'none',
				},
				'&:active': {
					backgroundColor: COLORS.main,
					color: 'white',
					borderColor: COLORS.main,
					boxShadow: 'none',
				},
			}}
			onClick={onClick}
		>
			{title}
		</OutlinedButton>
	)
}

export default CheckBoxFilter
