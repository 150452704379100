import { ThemeProvider } from '@emotion/react'
import {
	Box,
	Checkbox,
	InputAdornment,
	OutlinedInput,
	useTheme,
} from '@mui/material'
import { ReactComponent as CheckBox } from 'assets/icons/checkBox.svg'
import CheckBoxActive from 'assets/icons/checkBoxActive'
import { roundingNumber } from 'components/shared/processes'
import React, { useEffect } from 'react'
import { COLORS } from 'styles/colors'
import { customTheme } from 'ui/buttons/ButtonStyle'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { LactationStatus } from '../models'
import { lactationStatuses } from '../processes'
const icon = <CheckBox />
const checkedIcon = <CheckBoxActive />
const LactationContentBlock = props => {
	const {
		lactation,
		handleDayChange,
		toggleFilter,
		turnOnFilters,
		setActiveFilters,
	} = props
	const outerTheme = useTheme()

	const isDisabledLactationStatus = lactation.statuses.includes(
		LactationStatus.NonLactating
	)
	useEffect(() => {
		const lactationStatuses = lactation.statuses
		lactationStatuses.includes(LactationStatus.Lactating) &&
			!isDisabledLactationStatus &&
			(!lactationStatuses.includes(LactationStatus.CommercialMilk) ||
				!lactationStatuses.includes(LactationStatus.DefectiveMilk)) &&
			turnOnFilters('lactation', [
				LactationStatus.CommercialMilk,
				LactationStatus.DefectiveMilk,
			])

		isDisabledLactationStatus &&
			(lactationStatuses.includes(LactationStatus.CommercialMilk) ||
				lactationStatuses.includes(LactationStatus.DefectiveMilk)) &&
			setActiveFilters(prev => {
				return {
					...prev,
					lactation: {
						...prev.lactation,
						statuses: prev.lactation.statuses.filter(
							el =>
								el !== LactationStatus.CommercialMilk &&
								el !== LactationStatus.DefectiveMilk
						),
					},
				}
			})
	}, [lactation.statuses])
	return (
		<>
			<TypographyPrimary>День лактации</TypographyPrimary>
			<ThemeProvider theme={customTheme(outerTheme)}>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'center'}
					gap={'12px'}
					marginTop={'16px'}
					flexWrap={'wrap'}
				>
					<OutlinedInput
						label='none'
						notched={false}
						type='number'
						error={
							lactation.startOf < 0 || lactation.startOf > lactation.endOf
								? true
								: false
						}
						placeholder='Дни'
						value={
							lactation.startOf ? roundingNumber(lactation.startOf, 0) : ''
						}
						endAdornment={
							<InputAdornment
								sx={{
									position: 'absolute',
									left:
										roundingNumber(lactation.startOf, 0)?.toString().length <= 2
											? '25px'
											: roundingNumber(lactation.startOf, 0)?.toString()
													.length <= 4
											? '15px'
											: '2px',
								}}
								position='end'
							>
								{lactation.startOf ? (
									<TypographySecondary>от</TypographySecondary>
								) : (
									''
								)}
							</InputAdornment>
						}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleDayChange(e, 'lactation', 'startOf')
						}
						sx={{
							position: 'relative',
							width: '120px',
							'& input': {
								textAlign: 'center',
							},
						}}
					/>

					<TypographySecondary>-</TypographySecondary>
					<OutlinedInput
						label='none'
						notched={false}
						type='number'
						error={
							lactation.endOf < 0 || lactation.endOf < lactation.startOf
								? true
								: false
						}
						placeholder='Дни'
						value={lactation.endOf ? roundingNumber(lactation.endOf, 0) : ''}
						endAdornment={
							<InputAdornment
								sx={{
									position: 'absolute',
									left:
										roundingNumber(lactation.endOf, 0)?.toString().length <= 2
											? '25px'
											: roundingNumber(lactation.endOf, 0)?.toString().length <=
											  4
											? '15px'
											: '2px',
								}}
								position='end'
							>
								{lactation.endOf ? (
									<TypographySecondary>до</TypographySecondary>
								) : (
									''
								)}
							</InputAdornment>
						}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleDayChange(e, 'lactation', 'endOf')
						}
						sx={{
							position: 'relative',
							width: '120px',
							'& input': {
								textAlign: 'center',
							},
						}}
					/>
				</Box>
			</ThemeProvider>
			<ul>
				{lactationStatuses.map((status, index) => (
					<React.Fragment key={status.id}>
						{index === 0 && (
							<TypographyPrimary sx={{ margin: '24px 0px 8px 0px' }}>
								Статус доения
							</TypographyPrimary>
						)}
						{index === 2 && (
							<TypographyPrimary
								sx={{
									color: isDisabledLactationStatus ? COLORS.secondary : 'none',
									margin: '16px 0px 8px 0px',
								}}
							>
								Молоко
							</TypographyPrimary>
						)}
						<li
							key={status.id}
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								padding: '6px 0px',
							}}
						>
							<Checkbox
								onClick={() => toggleFilter('lactation', status.key)}
								icon={icon}
								checkedIcon={checkedIcon}
								disableTouchRipple={isDisabledLactationStatus}
								disableRipple
								style={{
									padding: '0px 8px 0px 0px',
									margin: '0px',
									pointerEvents:
										isDisabledLactationStatus && (index === 2 || index === 3)
											? 'none'
											: 'auto',
								}}
								checked={lactation.statuses.includes(status.key)}
							/>

							<TypographyPrimary
								sx={{
									color:
										isDisabledLactationStatus && (index === 2 || index === 3)
											? COLORS.secondary
											: 'none',
								}}
							>
								{status.title}
							</TypographyPrimary>
						</li>
					</React.Fragment>
				))}
			</ul>
		</>
	)
}

export default LactationContentBlock
