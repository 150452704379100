import axAPI from '../http'
import { IGroupEntity } from '../models'

export default class GroupsApiService {
	static postGroupsQuantityChange = async (
		data: { groupId: number; quantity: number }[]
	) => {
		return axAPI({
			method: 'POST',
			url: `/groups/qty_change`,
			data: data,
		})
	}
	static postGroupChartData = async (data: {}) => {
		return axAPI({
			method: 'POST',
			url: `groups/group_psv_chart_data`,
			data: {
				data,
			},
		})
	}
	static patchGroupById = async (data, id: number) => {
		return axAPI({
			method: 'PATCH',
			url: `group/${id}`,
			data
		})
	}
	static putGroupsDataKey = async (groupData) => {
		return axAPI({
			method: 'PUT',
			url: `groups/${groupData.id}`,
			data: groupData,
		})
	}
	static postGroupKey = async (firstDate, lastDate, props: IGroupEntity) => {
		return axAPI({
			method: 'POST',
			url: `groups/count/${props.groupkey}`,
			data: {
				start_date: firstDate,
				end_date: lastDate,
			},
		})
	}
	static getGroupList = async () => {
		return axAPI.get('/groups/list')
	}
	static getMedianPsvByGroupId = async (group_id: number) => {
		return axAPI.get(`/groups/${group_id}/psv/median`)
	}
	static getPsvByDateAndGroupId = async (group_id: number, date: string) => {
		return axAPI.get(`/groups/${group_id}/psv/${date}`)
	}
	static getPsvByRangeDateAndGroupId = async (
		group_id: number,
		startDate: number,
		endDate: number
	) => {
		return axAPI.get(
			`/groups/${group_id}/psv?start=${startDate}&end=${endDate}`
		)
	}
	static getFoodTableReachableAndUnreachable = async (
		group_id: number,
		startDate: number,
		endDate: number
	) => {
		return axAPI.get(
			`cattle-group/${group_id}/foodtable/graph?start=${startDate}&end=${endDate}`
		)
	}
	static getGroupFeedplans = async (date: string) => {
		return axAPI.get(`feedplans/${date}`)
	}
	static getDataForHeadChartByOneDay = async (
		group_id: number,
		date: string
	) => {
		return axAPI.get(`groups/${group_id}/quantity/${date}`)
	}
	static getDataForHeadChartByRangeDate = async (
		group_id: number,
		startDate: number,
		endDate: number
	) => {
		return axAPI.get(
			`groups/${group_id}/quantity?start=${startDate}&end=${endDate}`
		)
	}
	static getDataForConsuptionChart = async (
		group_id: number,
		startDate: number,
		endDate: number
	) => {
		return axAPI.get(
			`analytics/food-intake/graph?group_id=${group_id}&start=${startDate}&end=${endDate}`
		)
	}
	static getHistoryForStateFoodChart = async (
		id: number,
		startDate: number,
		endDate: number
	) => {
		return axAPI.get(
			`cattle-group/${id}/foodtable/events?start=${startDate}&end=${endDate}`
		)
	}
}
