import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CattlesApiService from 'services/cattlesApiServices'
import CowMainInfo from './ui/CowMainInfo'
import CowPhotoCardViewer from './ui/CowPhotoCardViewer'
import WidjetsInPasportPage from './ui/WidjetsInPasportPage'
import YieldQtyCard from './ui/YieldQtyCard'

const CowPasportPage = () => {
	const urlParams = useParams()

	const [cowInfo, setCowInfo] = useState(undefined)
	useEffect(() => {
		CattlesApiService.getCowInfoByTagId(+urlParams.tagNumber).then(response => {
			setCowInfo(response.data)
		})
	}, [urlParams])
	return (
		<Box
			sx={{
				padding: '0 16px',
				display: 'flex',
				flexDirection: 'column',
				gap: '24px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					height: '228px',
					gap: '24px',
				}}
			>
				<Box width={'26%'}>
					<CowPhotoCardViewer images={cowInfo?.images} />
				</Box>
				<Box width={'74%'}>
					<CowMainInfo cattleInfo={cowInfo} />
				</Box>
			</Box>
			<WidjetsInPasportPage cattleInfo={cowInfo} />
			<YieldQtyCard cowId={cowInfo?.id} />
		</Box>
	)
}

export default CowPasportPage
