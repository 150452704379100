import { Box, Dialog, Divider } from '@mui/material'
import CustomTextField from 'components/shared/textFields/CustomTextField'
import React, { useState } from 'react'
import { COLORS } from 'styles/colors'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { ReactComponent as NextIcon } from '../../../assets/arrow_close_logo.svg'
import { ReactComponent as CrossCloseLogo } from '../../../assets/cross_close_logo.svg'

import RationsApiService from 'services/rationApiService'
import { useAppDispatch } from 'store/hooks'
import { notifyUser } from 'store/notificationsSlice'
import { changeComponentInfo } from 'store/rationSystemSlice'
import { OutlinedButton } from 'ui/buttons/OutlinedButton'

type IInputData = {
	title: string
	dry_matter_percent: number | string
	id: number
}

const PopupEditCompOfDryPercent = ({ openModal, setOpenModal, components }) => {
	const [caseWindow, setCaseWindow] = useState(1)
	const [hoverCompId, setHoverCompId] = useState(null)
	const [errorState, setErrorState] = useState(false)
	const [errorMessage, setErrorMessage] = useState(false)
	const [inputData, setInputData] = useState<IInputData>({
		title: '',
		dry_matter_percent: '',
		id: null,
	})
	const dispatch = useAppDispatch()
	function onSubmitChanges() {
		RationsApiService.patchComponent(inputData.id, inputData)
			.then(response => {
				if (response.status === 201 || response.status === 200) {
					dispatch(
						changeComponentInfo({
							id: response.data.id,
							title: response.data.title,
							dry_matter_percent: response.data.dry_matter_percent,
						})
					)
					setOpenModal(false)
					setCaseWindow(1)
					dispatch(
						notifyUser({
							title: 'Данные сохранены успешно',
							description: `В рационах, в которых использовался компонент «${inputData.title}», произведен перерасчет`,
							isError: false,
						})
					)
					setInputData({
						title: '',
						dry_matter_percent: '',
						id: null,
					})
				}
			})
			.catch(error => {
				setErrorState(true)
				setErrorMessage(true)
			})
	}
	const renderContent = () => {
		switch (caseWindow) {
			case 1:
				return (
					<Box display='flex' flexDirection='column'>
						<Box
							display='flex'
							flexDirection='row'
							justifyContent='space-between'
							alignItems='center'
							mb='32px'
						>
							<TypographyHeader>Шаг 1. Изменение компонента</TypographyHeader>
							<CrossCloseLogo
								style={{ cursor: 'pointer', height: '18px', stroke: COLORS.secondary}}
								onClick={() => {
									setCaseWindow(1)
									setOpenModal(false)
								}}
							/>
						</Box>
						<Box
							display='flex'
							flexDirection='row'
							justifyContent='space-between'
							width='100%'
							mb='8px'
						>
							<TypographySecondary width='75%'>Компоненты</TypographySecondary>
							<TypographySecondary width='25%'>СВ, %</TypographySecondary>
						</Box>
						<Divider sx={{ marginBottom: '22px' }} />
						{components?.map(el => (
							<React.Fragment key={el.id}>
								<Box
									display='flex'
									flexDirection='row'
									justifyContent='space-between'
									alignItems='center'
									width='100%'
									height='54px'
									onClick={() => {
										setInputData({
											title: el.title,
											dry_matter_percent: el.dry_matter_percent,
											id: el.id,
										})
										setCaseWindow(2)
									}}
									onMouseEnter={() => setHoverCompId(el.id)}
									onMouseLeave={() => setHoverCompId(null)}
									sx={{
										'&:hover': {
											backgroundColor: COLORS.backgroundHover,
											cursor: 'pointer',
										},
									}}
								>
									<TypographyPrimary width='75%'>{el.title}</TypographyPrimary>
									<TypographyPrimary width='20%'>
										{el.dry_matter_percent}
										{<span style={{ color: COLORS.icon }}>%</span>}
									</TypographyPrimary>

									<Box width='5%' display='flex' alignItems='center'>
										{hoverCompId === el.id && (
											<NextIcon
											stroke={COLORS.icon}
												style={{ transform: 'rotate(-90deg)', height: '18px' }}
											/>
										)}
									</Box>
								</Box>
							</React.Fragment>
						))}
					</Box>
				)
			case 2:
				return (
					<Box display='flex' flexDirection='column'>
						<Box
							display='flex'
							flexDirection='row'
							justifyContent='space-between'
							alignItems='center'
							mb='32px'
						>
							<TypographyHeader>
								Шаг 2. Редактирование компонента
							</TypographyHeader>
							<CrossCloseLogo
								style={{ cursor: 'pointer', height: '18px', stroke: COLORS.secondary}}
								onClick={() => {
									setCaseWindow(1)
									setOpenModal(false)
								}}
							/>
						</Box>
						<Box
							display='flex'
							flexDirection='column'
							justifyContent='space-between'
							width='100%'
						>
							<Box
								display='flex'
								flexDirection='row'
								justifyContent='space-between'
								gap='16px'
							>
								<CustomTextField
									key={`rationCompTitle${inputData.id}`}
									label='Название компонента'
									value={inputData.title}
									width={'100%'}
									withLabel={true}
									error={errorState}
									helperText={
										!inputData.title
											? 'Поле не может быть пустым'
											: errorMessage
											? 'Данное название уже используется'
											: ''
									}
									onClick={() => {
										setErrorState(false)
										setErrorMessage(false)
									}}
									onChange={e => {
										const newValue = e.target.value
										setInputData(prevData => ({
											...prevData,
											title: newValue,
										}))
									}}
									onBlur={e => {
										const newValue = e.target.value
										setInputData(prevData => ({
											...prevData,
											title: newValue,
										}))
									}}
								/>
								<CustomTextField
									key={`rationCompDry${inputData.id}`}
									label='СВ, %'
									type='text'
									value={inputData.dry_matter_percent}
									width='40%'
									withLabel={true}
									helperText={
										inputData.dry_matter_percent === ''
											? 'Поле не может быть пустым'
											: inputData.dry_matter_percent === 0
											? ''
											: ''
									}
									onChange={e => {
										let inputValue = e.target.value

										inputValue = inputValue.replace(/^0+/, '')

										inputValue = inputValue.replace(/\D/g, '')
										if (
											inputValue === '' ||
											inputValue < 0 ||
											inputValue > 100
										) {
											setInputData(prevData => ({
												...prevData,
												dry_matter_percent: '',
											}))
										} else {
											setInputData(prevData => ({
												...prevData,
												dry_matter_percent: inputValue,
											}))
										}
									}}
								/>
							</Box>
							<OutlinedButton
								disableRipple
								disabled={
									!inputData.title ||
									!inputData.title.trim() ||
									!inputData.dry_matter_percent
								}
								sx={{
									width: '60%',
									alignSelf: 'center',
									marginTop: '32px',
								}}
								onClick={onSubmitChanges}
							>
								Сохранить
							</OutlinedButton>
						</Box>
					</Box>
				)
		}
	}
	return (
		<Dialog
			scroll='body'
			open={openModal}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			PaperProps={{
				style: {
					borderRadius: '12px',
					padding: '24px 24px',
					boxShadow: 'none',
					width: '650px',
					minHeight: '246px',
				},
			}}
		>
			{renderContent()}
		</Dialog>
	)
}

export default PopupEditCompOfDryPercent
