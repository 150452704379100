import { Box } from '@mui/material'
import EditPlanPage from 'components/plan/EditPlanPage'
import { useParams } from 'react-router-dom'
import { TypographyHeaderExtra } from 'ui/typography/TypographyHeaderExtra'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import FarmFillStepIndex from '../shared/FarmFillStepIndex'
import FarmFillBack from './FarmFillBack'

const FarmFillFeedPlan = () => {
	const urlParams = useParams()
	return (
		<Box sx={{ pr: '150px' }}>
			<FarmFillBack prevStep='/groups' id={urlParams.id} />
			<FarmFillStepIndex stepNum='3' fullfied='3' />

			<Box sx={{ pt: '60px', pb: '40px' }}>
				<TypographyHeaderExtra>План кормления</TypographyHeaderExtra>
				<TypographyPrimary sx={{ pt: '24px' }}>
					Создайте план кормления для каждой группы.
				</TypographyPrimary>
			</Box>
			<EditPlanPage isFirstTry />
		</Box>
	)
}

export default FarmFillFeedPlan
