import Box from '@mui/material/Box/Box'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ErrorLogo } from '../assets/error_logo.svg'
import { ReactComponent as SmartFarmLogo } from '../assets/smart_farm_logo.svg'
import { useAppSelector } from '../store/hooks'
import { COLORS } from '../styles/colors'
import { OutlinedButton } from '../ui/buttons/OutlinedButton'
import { TypographyHeader } from '../ui/typography/TypographyHeader'
import { TypographyPrimary } from '../ui/typography/TypographyPrimary'
import { TypographySecondary14 } from '../ui/typography/TypographySecondary14'

const ErrorPerms = () => {
	const userData = useAppSelector(state => state.userReducer)
	const permissions = useAppSelector(state => state.authReducer.permissions)
	const navigate = useNavigate()

	const goBackMainPage = () => {
		if (permissions?.can_view_foodtable_main) {
			navigate('/feed')
		} else if (permissions?.can_view_feedplans) {
			navigate('/feed/plan')
		} else {
			navigate('/login')
		}
	}

	return (
		<Box
			sx={{
				padding: '24px 40px',
				width: '100vw',
				height: '100vh',
				background: '#f7f7f7',
				position: 'relative',
			}}
		>
			<SmartFarmLogo fill='#5222D0' />
			<Box
				sx={{
					position: 'absolute',
					top: '20%',
					left: '30%',
					width: '586px',
					height: '70%',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<ErrorLogo />
					<TypographyHeader sx={{ marginTop: '40px' }}>
						Доступ запрещен
					</TypographyHeader>
					<TypographyPrimary sx={{ marginTop: '16px', textAlign: 'center' }}>
						У вашего пользователя нет прав для просмотра страницы. Обратитесь к
						администратору организации ООО “{userData.userData.company.title}”
						для получения прав доступа.
					</TypographyPrimary>
					<OutlinedButton
						onClick={goBackMainPage}
						disableRipple
						sx={{ width: '400px' }}
					>
						Вернуться на главную
					</OutlinedButton>

					<TypographySecondary14 sx={{ marginTop: '40px' }}>
						Если ошибка сохраняется
						<a
							target='_blank'
							href='https://smartfarm.vision/help'
							style={{
								fontWeight: '400',
								fontSize: '14px',
								color: COLORS.main,
								textDecoration: 'underline',
								textUnderlineOffset: '3px',
							}}
						>
							свяжитесь с нами
						</a>
					</TypographySecondary14>
				</Box>
			</Box>
		</Box>
	)
}

export default ErrorPerms
