import { Box, ThemeProvider } from '@mui/material'

import { ReactComponent as PlusPopupIcon } from '../../assets/plus_popup_icon.svg'
import { fontTheme } from '../../styles/font'
import ManagementTable from './ManagementTable'

import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../store/hooks'

const theme = fontTheme

const ManagementPage = () => {
	const { userData } = useAppSelector(store => store.userReducer)
	const { permissions } = useAppSelector(state => state.authReducer)
	const navigate = useNavigate()
	return (
		<ThemeProvider theme={theme}>
			{' '}
			<Box sx={{ pr: '12px' }}>
				{permissions.can_manage_permissions && (
					<Box
						display='flex'
						flexDirection='row'
						justifyContent='flex-end'
						sx={{
							pl: '24px',
							pt: '24px',
							fontFamily: theme.typography.fontFamily,
						}}
					>
						<Box
							sx={{ pr: '24px', cursor: 'pointer' }}
							onClick={() => navigate('/settings/management/create-user')}
						>
							{userData.superuser ? <PlusPopupIcon /> : <></>}
						</Box>
					</Box>
				)}

				<Box>
					<ManagementTable />
				</Box>
			</Box>
		</ThemeProvider>
	)
}

export default ManagementPage
