import { Box } from '@mui/material'
import LamenessList from 'components/cattles/lamenessPage/ui/LamenessList'
import LamenessChart from './ui/LamenessCardChart'
import LamenessInfo from './ui/LamenessInfo'

const LamenessPage = () => {
	return (
		<Box
			sx={{
				padding: '0 16px',
				display: 'flex',
				flexDirection: 'column',
				gap: '24px',
			}}
		>
			<LamenessInfo />
			<LamenessChart />
			<LamenessList />
		</Box>
	)
}

export default LamenessPage
