import AutocompleteInput from 'components/shared/textFields/AutocompleteInput'
import { useEffect } from 'react'
import { fetchDiagnosesList } from 'store/cattlesSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'

type DiagnoseContentBlockProps = {
	setActiveFilters: React.Dispatch<React.SetStateAction<any>>
	diagnoses: { ids: number[] }
}

const DiagnoseContentBlock = (props: DiagnoseContentBlockProps) => {
	const { setActiveFilters, diagnoses } = props
	const dispatch = useAppDispatch()
	const { diagnosesList, diagnosesListPending, diagnosesListError } =
		useAppSelector(state => state.cattlesSlice)

	useEffect(() => {
		if (diagnosesList.length === 0) {
			dispatch(fetchDiagnosesList())
		}
	}, [])

	return (
		<>
			{diagnosesListError ? (
				<TypographyPrimary>{diagnosesListError}</TypographyPrimary>
			) : (
				<AutocompleteInput
					widthForInput={'100%'}
					onClick={id => {
						setActiveFilters(prev => {
							const ids = prev.diagnoses.ids
							const isPresent = ids.includes(id)
							const updatedIds = isPresent
								? ids.filter(item => item !== id)
								: [...ids, id]

							return {
								...prev,
								diagnoses: {
									...prev.diagnoses,
									ids: updatedIds,
								},
							}
						})
					}}
					isCheckbox
					availableList={diagnosesList}
					loading={diagnosesListPending}
					selectedValues={diagnoses.ids}
					label={'Диагнозы'}
					limitTags={2}
					needChip
				/>
			)}
		</>
	)
}

export default DiagnoseContentBlock
