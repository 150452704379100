import moment from 'moment'
import { COLORS } from '../../../styles/colors'
import { morphForWords, roundingNumber } from '../../shared/processes'

export const stateFoodTableTooltip = (
	densityList,
	dataPointIndex: number,
	series: {},
	daysBetweenDates: number,
	w
) => {
	const density = densityList[dataPointIndex]
	const trueDensity = density !== null
	const unReachableValue = series[0][dataPointIndex]
	const reachableValue = series[1][dataPointIndex]
	const totalValue = reachableValue + unReachableValue
	const totalWeightKg = +totalValue * density
	const isAvailable = reachableValue !== null && !isNaN(reachableValue)
	const isAvailableUnReachableData =
		unReachableValue !== null && !isNaN(unReachableValue)
	const timeValue =
		daysBetweenDates === 0
			? moment(w.config.xaxis.categories[dataPointIndex]).format('HH:mm')
			: moment(w.config.xaxis.categories[dataPointIndex]).format('DD.MM, HH:mm')
	const unReachableMass = Math.round(unReachableValue * density)
	const reachableMass = Math.round(reachableValue * density)

	return unReachableValue !== null && reachableValue !== null
		? `<div style='width: max-content; height: max-content; background-color: #fff; border-radius:12px; border: none;'>
	<div style='display: flex; flex-direction: column; padding: 4px 8px;'>
	<div style='display: flex; flex-direction: row;'>
	<p style='font-weight: 400; font-size: 12px; line-height: 14.6px; color: #7F7F84; margin-bottom: 8px'>Всего объём корма: ${(+totalValue.toFixed(
		1
	)).toLocaleString()} м³/${
				trueDensity ? (+totalWeightKg.toFixed(0)).toLocaleString() : '-'
		  } кг</p>
</div>
		<div style='display: flex; flex-direction: row; margin-bottom: 6.5px; align-items: center;'>
			<div style='width: 12px; height: 12px; border-radius: 50%; background-color: ${
				isAvailable ? '#65C466' : '#EB4D3D'
			}; margin-right: 4px;'></div>
			<p style='font-weight: 400; font-size: 12px; line-height: 14.6px; color: #7F7F84; margin: 0'>${
				isAvailable ? 'Доступный корм' : 'Корм недоступен'
			}</p>
			<p style='font-weight: 600; font-size: 16px; line-height: 19.5px; color: #000; margin: 0 0 0 8px'>${
				isAvailable ? +reachableValue.toFixed(1).toLocaleString() : '0'
			}</p>
			<p style='font-weight: 400; font-size: 12px; line-height: 14.6px; color: #7F7F84; margin: 0;'>\u00A0м³</p>
			<p style='font-weight: 600; font-size: 16px; line-height: 19.5px; color: #7F7F84; margin: 0;'>\u00A0/\u00A0</p>
			<p style='font-weight: 600; font-size: 16px; line-height: 19.5px; color: ${
				trueDensity ? '#000' : '#7F7F84'
			}; margin: 0'>${
				isAvailable && trueDensity ? reachableMass.toLocaleString() : '-'
		  }</p>
			<p style='font-weight: 400; font-size: 12px; line-height: 14.6px; color: #7F7F84; margin: 0'>\u00A0кг</p>
		</div>

		<div style='display: flex; flex-direction: row; margin-bottom: 6.5px; align-items: center;'>
		<div style='width: 12px; height: 12px; border-radius: 50%; background-color: ${
			COLORS.health
		}; margin-right: 4px;'></div>
		<p style='font-weight: 400; font-size: 12px; line-height: 14.6px; color: #7F7F84; margin: 0'>Недоступный корм</p>
		<p style='font-weight: 600; font-size: 16px; line-height: 19.5px; color: #000; margin: 0 0 0 8px'>${
			isAvailableUnReachableData
				? +unReachableValue.toFixed(1).toLocaleString()
				: '0'
		}</p>
		<p style='font-weight: 400; font-size: 12px; line-height: 14.6px; color: #7F7F84; margin: 0;'>\u00A0м³</p>
		<p style='font-weight: 600; font-size: 16px; line-height: 19.5px; color: #7F7F84; margin: 0;'>\u00A0/\u00A0</p>
		<p style='font-weight: 600; font-size: 16px; line-height: 19.5px; color: ${
			trueDensity ? '#000' : '#7F7F84'
		}; margin: 0'>${
				isAvailableUnReachableData && trueDensity
					? unReachableMass.toLocaleString()
					: '-'
		  }</p>
		<p style='font-weight: 400; font-size: 12px; line-height: 14.6px; color: #7F7F84; margin: 0'>\u00A0кг</p>
	</div>
			

		<div style='display: flex; flex-direction: row;'>
			<p style='font-weight: 400; font-size: 12px; line-height: 14.6px; color: #7F7F84; margin: 0'>${timeValue}</p>
		</div>
	</div>
</div>`
		: ''
}

export const tooltipForFoodDryIntake = (
	currentPSV,
	dataPointIndex,
	yearsPsv,
	mediumPSV,
	medianPsv,
	daysBetweenDates,
	timestamp,
	yearsTimestamp,
	weightPerHead
) => {
	const foodIntake =
		currentPSV[dataPointIndex] !== null
			? roundingNumber(currentPSV[dataPointIndex], 1)
			: 0
	const yearsFoodIntake = yearsPsv[dataPointIndex]
		? yearsPsv[dataPointIndex]
		: 0
	const mediumFoodIntake = mediumPSV[dataPointIndex]
		? mediumPSV[dataPointIndex]
		: 0
	const percentOfNorm = roundingNumber(
		(foodIntake / medianPsv.psv_norm) * 100,
		0
	)
	const timeValue =
		daysBetweenDates === 0
			? moment(timestamp[dataPointIndex]).format('HH:mm')
			: moment(timestamp[dataPointIndex]).format('DD.MM.YY')
	const timeValueForYearsRange = moment(yearsTimestamp[dataPointIndex]).format(
		'DD.MM.YY'
	)
	// 	const forMedianPsv = 		<div style='display:flex; flex-direction:row; align-items: center; gap:4px'>
	// 	<span style='width:12px; height:12px; border-radius: 50%; background-color: ${
	// 		COLORS.blue
	// 	};'></span>
	// 	<p style='font-weight: 600; font-size: 16px; color: #000; margin: 0px'>${mediumFoodIntake}</p>
	// 	<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>кг/голову</p>
	// </div>
	return `<div
style='width:max-content; height:max-content; background-color: #fff; border-radius:12px; border: hidden; box-shadow: 0px 0px 16px 0px rgba(4, 6, 6, 0.16); position: relative'>
<div style='padding:16px 8px; border: none;'>
${
	daysBetweenDates === 0
		? `<div style='display: flex; flex-direction: column; gap:4px'>
		<div style='display: flex; flex-direction: row; gap:16px'>
			<div style='display:flex; flex-direction:row; align-items: center; gap:4px'>
				<span style='width:12px; height:12px; border-radius: 50%; background-color: #5222D0;'></span>
				<p style='font-weight: 600; font-size: 16px; color: #000; margin: 0px'>${foodIntake}</p>
				<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>кг/голову</p>
			</div>
	
			</div>
			<p style='font-weight: 400; font-size: 12px; color: #000; margin: 0px'>${`Съедено ${percentOfNorm}% от нормы`}</p>
			<div>
				<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>${timeValue}</p>
			</div>
		</div>`
		: `<div style='display: flex; flex-direction: column; gap:4px'>
		<div style='display:flex; flex-direction:row; align-items: center; gap:4px'>
		<span style='width:12px; height:12px; border-radius: 50%; background-color: #5222D0;'></span>
		<p style='font-weight: 600; font-size: 16px; color: #000; margin: 0px'>${
			daysBetweenDates > 364 ? yearsFoodIntake : foodIntake
		}</p>
		<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>кг/голову</p>
	</div>
	<p style='font-weight: 400; font-size: 12px; color: #000; margin: 0px'>${`В среднем <span style='color:#000; font-weight: 600; font-size: 14px'>${roundingNumber(
		weightPerHead,
		1
	)}</span> кг/голову`}</p>
	<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>${
		daysBetweenDates > 364 ? timeValueForYearsRange : timeValue
	}</p>
		</div>`
}


</div>
</div>`
}

export const tooltipForConsuptionChart = (
	series,
	dataPointIndex: number,
	daysBetweenDates: number,
	distributedValuesForTooltip,
	yearsDistributedValues,
	cleanedValuesForTooltip,
	yearsCleanedValues,
	dataXasis
) => {
	const notAvailableData =
		series.length === 3 ? series[0][dataPointIndex] !== 0 : false
	const distributedFood =
		daysBetweenDates < 364
			? distributedValuesForTooltip[dataPointIndex]
			: yearsDistributedValues[dataPointIndex]
	const cleanedFood =
		daysBetweenDates < 364
			? cleanedValuesForTooltip[dataPointIndex]
			: yearsCleanedValues[dataPointIndex]
	const timeValue = moment(dataXasis[dataPointIndex]).format('DD.MM.YY')
	if (notAvailableData && daysBetweenDates < 364) {
		return `<div
					style='width:max-content; height:max-content; background-color: #fff; border-radius:12px; border: hidden; box-shadow: 0px 0px 16px 0px rgba(4, 6, 6, 0.16); position: relative; margin-top: 40px'>
					<div style='padding:16px 8px; border: none;'>
						<div style='display: flex; flex-direction: column; gap:4px'>
						
	
							<div style='display:flex; flex-direction:row; align-items: center; gap:4px'>
						<span style='width:12px; height:12px; border-radius: 50%; background-color: ${COLORS.inputBorder};'></span>
						<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>Нет данных</p>
					</div>
	
							<div>
								<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>${timeValue}</p>
							</div>
						</div>
					</div>
				</div>`
	} else {
		return `<div
				style='width:max-content; height:max-content; background-color: #fff; border-radius:12px; border: hidden; box-shadow: 0px 0px 16px 0px rgba(4, 6, 6, 0.16); position: relative'>
				<div style='padding:16px 8px; border: none;'>
					<div style='display: flex; flex-direction: column; gap:4px'>
						<div style='display:flex; flex-direction:row; align-items: center; gap:4px'>
							<span style='width:12px; height:12px; border-radius: 50%; background-color: ${
								COLORS.main
							};'></span>
							<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>Роздано корма</p>
							<p style='font-weight: 600; font-size: 16px; color: #000; margin: 0px'>${
								distributedFood === null
									? 0
									: Math.round(distributedFood).toLocaleString()
							}</p>
							<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>кг</p>
						</div>

						<div style='display:flex; flex-direction:row; align-items: center; gap:4px'>
					<span style='width:12px; height:12px; border-radius: 50%; background-color: ${
						COLORS.error
					};'></span>
					<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>Остаток</p>
					<p style='font-weight: 600; font-size: 16px; color: ${
						cleanedFood === null || cleanedFood === 0 ? '#7F7F84' : '#000'
					}; margin: 0px'>${
			cleanedFood === null ? 0 : Math.round(cleanedFood).toLocaleString()
		}</p>
					<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>кг</p>
					<p style='font-weight: 600; font-size: 16px; color: #7F7F84; margin: 0px'>${
						cleanedFood !== null && distributedFood !== null ? '/' : ''
					}</p>
					<p style='font-weight: 600; font-size: 16px; color: #000; margin: 0px;'>${
						cleanedFood === null || distributedFood === null
							? ''
							: roundingNumber((cleanedFood / distributedFood) * 100, 1)
					}</p>
					<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>${
						cleanedFood !== null && distributedFood !== null ? '%' : ''
					}</p>
				</div>

						<div>
							<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>${timeValue}</p>
						</div>
					</div>
				</div>
			</div>`
	}
}

export const tooltipForHeadChart = (
	series,
	dataPointIndex,
	daysBetweenDates,
	dataXasis,
	dataXYearsValues
) => {
	const headCount = Math.round(series[0][dataPointIndex])
	const timeValue =
		daysBetweenDates < 355
			? moment(dataXasis[dataPointIndex]).format('DD.MM.YY')
			: moment(dataXYearsValues[dataPointIndex]).format('DD.MM.YY')
	return `<div
				style='width:max-content; height:70px; background-color: #fff; border-radius:12px; border: hidden; box-shadow: 0px 0px 16px 0px rgba(4, 6, 6, 0.16); position: relative'>
				<div style='padding:16px 8px; border: none;'>
					<div style='display: flex; flex-direction: column; gap:4px'>
						<div style='display:flex; flex-direction:row; align-items: center; gap:4px'>
							<span style='width:12px; height:12px; border-radius: 50%; background-color: #5222D0;'></span>
							<p style='font-weight: 600; font-size: 16px; color: #000; margin: 0px'>${headCount}</p>
							<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>${morphForWords(
								headCount,
								['голова', 'головы', 'голов']
							)}</p>
						</div>
						<div>
							<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>${timeValue}</p>
						</div>
					</div>
				</div>
		
			</div>`
}

export const tooltipForDashboardChart = (
	labelData,
	dataPointIndex,
	reachableData,
	unreachableData,
	totalData,
	emptyData,
	cameraIssueData
) => {
	const groupName = labelData[dataPointIndex]
	const reachableCount = roundingNumber(reachableData[dataPointIndex], 0)
	const unreachableCount = roundingNumber(unreachableData[dataPointIndex], 0)
	const totalCount = roundingNumber(totalData[dataPointIndex], 0)
	const isEmptyTable = emptyData[dataPointIndex] === 100
	const isCameraIssue = cameraIssueData[dataPointIndex] === 100
	return `<div
				style='width:max-content; height:min-content; background-color: #fff; border-radius:8px; border: hidden; box-shadow: 0px 0px 16px 0px rgba(4, 6, 6, 0.16); position: relative'>
				<div style='padding:4px 8px; border: none;'>
					<div style='display: flex; flex-direction: column; gap:8px'>
					<p style='font-weight: 400; line-height: 12px; font-size: 12px; color: #7F7F84; margin: 0px'>${`${groupName}`}</p>
					${
						isCameraIssue
							? `<p style='font-weight: 400; line-height: 12px; font-size: 12px; color: #7F7F84; margin: 0px'>Нет данных</p>`
							: `	<p style='font-weight: 400; line-height: 12px; font-size: 12px; color: #7F7F84; margin: 0px'>${
									'Всего: ' + `${isEmptyTable ? 0 : totalCount}` + '%'
							  }</p>
					${
						isEmptyTable
							? `<div style='display:flex; flex-direction:row; justify-content: start; align-items: center; gap:4px'>
						<span style='width:12px; height:12px; border-radius: 50%; background-color: ${
							COLORS.error
						};'></span>
						<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>${'Пустой'}</p>
					</div>`
							: `<div style='display:flex; flex-direction:row; align-items: center; gap:4px'>
						<span style='width:12px; height:12px; border-radius: 50%; background-color: ${
							COLORS.success
						};'></span>
						<p style='font-weight: 600; line-height: 16px;font-size: 16px; color: #000; margin: 0px'>${reachableCount}</p>
						<p style='font-weight: 400; line-height: 12px;font-size: 12px; color: #7F7F84; margin: 0px'>${' % доступно'}</p>
					</div>

					<div style='display:flex; flex-direction:row; align-items: center; gap:4px'>
					<span style='width:12px; height:12px; border-radius: 50%; background-color: ${
						COLORS.health
					};'></span>
					<p style='font-weight: 600; line-height: 16px; font-size: 16px; color: #000; margin: 0px'>${unreachableCount}</p>
					<p style='font-weight: 400; line-height: 12px; font-size: 12px; color: #7F7F84; margin: 0px'>${' % недоступно'}</p>
				</div>`
					}`
					}
					
					
					</div>
				</div>
		
			</div>`
}
