import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../store/hooks'

import BreadCrumbsGroup from 'components/shared/breadCrumbs/BreadCrumbsGroup'
import { getDatesArray } from 'components/shared/processes'
import moment from 'moment'
import PlanApiService from 'services/planApiService'
import RangeDate from '../../ui/datePicker/RangeDate'
import HistoryTable from './HistoryTable'

const HistoryPage = () => {
	const weekDays = getDatesArray(6)
	const [dateRange, setDateRange] = useState({
		startDate: weekDays.startDate,
		endDate: weekDays.endDate,
	})
	const dateForReq = moment(dateRange.endDate).format('YYYY-MM-DD')
	const [historyData, setHistoryData] = useState([])
	const [activeFilter, setActiveFilter] = useState('all')
	const [fetchindData, setFetchindData] = useState(true)
	const handleActiveFilter = name => {
		setActiveFilter(name)
	}
	useEffect(() => {
		setFetchindData(true)
		if (activeFilter === 'all') {
			PlanApiService.getHistoryChangedForAllGroups(dateForReq)
				.then(response => {
					setHistoryData(response)
				})
				.finally(() => setFetchindData(false))
		} else {
			PlanApiService.getHistoryChangedByGroupkey(dateForReq, activeFilter)
				.then(response => {
					setHistoryData(response)
				})
				.finally(() => setFetchindData(false))
		}
	}, [activeFilter])

	const { groupList } = useAppSelector(store => store.groupsReducer)

	const onChangeCallback = value => {
		setDateRange({
			startDate: value[0],
			endDate: value[1],
		})
	}

	return (
		<Box
			sx={{ pl: '24px', pr: '24px', display: 'flex', flexDirection: 'column' }}
		>
			<Box
				display='flex'
				flexDirection='row'
				justifyContent='flex-end'
				alignItems='center'
				marginBottom='10px'
			>
				<RangeDate
					startDate={dateRange.startDate}
					endDate={dateRange.endDate}
					onChangeUpdateDate={onChangeCallback}
				/>
			</Box>
			<BreadCrumbsGroup
				handleActiveFilter={handleActiveFilter}
				array={groupList}
			/>
			<HistoryTable historyData={historyData} fetchindData={fetchindData} />
		</Box>
	)
}

export default HistoryPage
