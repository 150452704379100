import AutocompleteInput from 'components/shared/textFields/AutocompleteInput'
import { useEffect } from 'react'
import { fetchExaminersList } from 'store/cattlesSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'

type ExaminersContentBlockProps = {
	setActiveFilters: React.Dispatch<React.SetStateAction<any>>
	examiners: { ids: number[] }
}

const ExaminersContentBlock = (props: ExaminersContentBlockProps) => {
	const { setActiveFilters, examiners } = props
	const dispatch = useAppDispatch()
	const { examinersList, examinersListPending, examinersListError } =
		useAppSelector(state => state.cattlesSlice)

	useEffect(() => {
		if (examinersList.length === 0) {
			dispatch(fetchExaminersList())
		}
	}, [])

	return (
		<>
			{examinersListError ? (
				<TypographyPrimary>{examinersListError}</TypographyPrimary>
			) : (
				<AutocompleteInput
					widthForInput={'100%'}
					onClick={id => {
						setActiveFilters(prev => {
							const ids = prev.examiners.ids
							const isPresent = ids.includes(id)
							const updatedIds = isPresent
								? ids.filter(item => item !== id)
								: [...ids, id]

							return {
								...prev,
								examiners: {
									...prev.examiners,
									ids: updatedIds,
								},
							}
						})
					}}
					isCheckbox
					availableList={examinersList}
					loading={examinersListPending}
					selectedValues={examiners.ids}
					label={'Сотрудник'}
					limitTags={2}
					needChip
				/>
			)}
		</>
	)
}

export default ExaminersContentBlock
