import { Circle, StyleSheet, Svg, Text, View } from '@react-pdf/renderer'
import { roundingNumber } from 'components/shared/processes'
import moment from 'moment'
import React from 'react'
import { pdfColors } from './utils/colorPdf'
import registerFonts from './utils/registerFont'
import { tableListAdapter } from './utils/tableListAdapter'

type TableCell = {
	color: string
	value: string | number
}
type TableRow = TableCell[]
type TableBlock = TableRow[]
type TableData = TableBlock[]

const TableBodyForRangeDate = ({ distr_table }: { distr_table: TableData }) => {
	registerFonts()
	const filteredList = tableListAdapter(distr_table)
	return (
		<React.Fragment>
			<View style={[styles.flexColumn, styles.container]}>
				{filteredList.map((block: TableRow[], i) => (
					<View key={i} style={styles.flexColumn}>
						{(block as TableRow[]).map((row, idx) => (
							<View
								key={idx}
								style={{
									...styles.flexRow,
									height: '25px',
									alignItems: 'center',
								}}
							>
								<Text
									style={{
										...styles.firstCell,
										borderBottom:
											idx === block.length - 1 && i !== filteredList.length - 1
												? `1px solid ${pdfColors.grey}`
												: 'none',
									}}
								>
									{idx === 0 ? row[0].value : ''}
								</Text>
								<Text
									style={{
										...styles.lowerCell,
										backgroundColor: row[1].color,
										borderRight: `1px solid ${pdfColors.grey}`,
										borderBottom:
											i === filteredList.length - 1 && idx === block.length - 1
												? 'none'
												: `1px solid ${pdfColors.grey}`,
									}}
								>
									{row[1].value && row[1].color !== '' && row[1].value}
								</Text>
								<Text
									style={{
										...styles.lowerCell,
										backgroundColor: row[2].color,
										borderRight: `1px solid ${pdfColors.grey}`,
										borderBottom:
											i === filteredList.length - 1 && idx === block.length - 1
												? 'none'
												: `1px solid ${pdfColors.grey}`,
									}}
								>
									{row[2].color !== pdfColors.greyOpacity &&
										moment(row[2].value).format('HH:mm')}
								</Text>
								<Text
									style={{
										...styles.widtherCell,
										backgroundColor: row[3].color,
										borderRight: `1px solid ${pdfColors.grey}`,
										borderBottom:
											i === filteredList.length - 1 && idx === block.length - 1
												? 'none'
												: '1px solid #7f7f84',
									}}
								>
									{row[3].color !== pdfColors.greyOpacity &&
										moment(row[3].value).format('HH:mm')}
								</Text>
								<Text
									style={{
										...styles.lowerCell,
										backgroundColor: row[4].color,
										borderRight: `1px solid ${pdfColors.grey}`,
										borderBottom:
											i === filteredList.length - 1 && idx === block.length - 1
												? 'none'
												: `1px solid ${pdfColors.grey}`,
									}}
								>
									{row[4].color !== pdfColors.greyOpacity &&
										roundingNumber(row[4].value, 0)}
									<Text style={styles.colorDesc}>
										{row[4].color !== pdfColors.greyOpacity && ' кг'}
									</Text>
								</Text>
								<Text
									style={{
										...styles.widtherCell,
										backgroundColor: row[5].color,
										borderRight: `1px solid ${pdfColors.grey}`,
										borderBottom:
											i === filteredList.length - 1 && idx === block.length - 1
												? 'none'
												: `1px solid ${pdfColors.grey}`,
									}}
								>
									{row[5].color !== pdfColors.greyOpacity &&
										roundingNumber(row[5].value, 0)}
									<Text style={styles.colorDesc}>
										{row[5].color !== pdfColors.greyOpacity && ' кг'}
									</Text>
								</Text>
								<Text
									style={{
										...styles.lowerCell,
										backgroundColor: row[6].color,
										borderRight: `1px solid ${pdfColors.grey}`,
										borderBottom:
											idx === block.length - 1 && i !== filteredList.length - 1
												? `1px solid ${pdfColors.grey}`
												: 'none',
									}}
								>
									{idx === 0 &&
										row[6].color !== pdfColors.greyOpacity &&
										roundingNumber(row[6].value, 0)}
									{idx === 0 && row[6].color !== pdfColors.greyOpacity && (
										<Text style={styles.colorDesc}>
											{row[6].color !== pdfColors.greyOpacity && ' кг'}
										</Text>
									)}
								</Text>
								<Text
									style={{
										...styles.widtherCell,
										backgroundColor: row[7].color,
										borderRight: `1px solid ${pdfColors.grey}`,
										borderBottom:
											idx === block.length - 1 && i !== filteredList.length - 1
												? `1px solid ${pdfColors.grey}`
												: 'none',
									}}
								>
									{idx === 0 &&
										row[7].color !== pdfColors.greyOpacity &&
										roundingNumber(row[7].value, 0)}
								</Text>
								<Text
									style={{
										...styles.lowerCell,
										backgroundColor: row[8].color,
										borderBottom:
											idx === block.length - 1 && i !== filteredList.length - 1
												? `1px solid ${pdfColors.grey}`
												: 'none',
									}}
								>
									{idx === 0 &&
										row[8].color !== pdfColors.greyOpacity &&
										roundingNumber(row[8].value, 1)}
									{idx === 0 && row[8].color !== pdfColors.greyOpacity && (
										<Text style={styles.colorDesc}>
											{row[8].color !== pdfColors.greyOpacity && ' кг/гол'}
										</Text>
									)}
								</Text>
							</View>
						))}
					</View>
				))}
			</View>
			<View style={{ ...styles.flexRow, gap: '16px', marginTop: '13px' }}>
				<View style={{ ...styles.flexRow, gap: '8px' }}>
					<CircleLegend color={'rgba(250, 234, 232)'} />
					<Text style={styles.textOfLegend}>Отклонение</Text>
				</View>
				<View style={{ ...styles.flexRow, gap: '8px' }}>
					<CircleLegend color={'#DBDBDB'} />
					<Text style={styles.textOfLegend}>Нет данных</Text>
				</View>
			</View>
		</React.Fragment>
	)
}

export default TableBodyForRangeDate

const styles = StyleSheet.create({
	container: {
		width: '100%',
		fontFamily: 'Montserrat',
		border: `1px solid ${pdfColors.grey}`,
		borderBottomRightRadius: '8px',
		borderBottomLeftRadius: '8px',
	},
	flexColumn: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	flexRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	firstCell: {
		height: '25px',
		padding: '8px 0px 0px 4px',
		width: '9.53%',
		fontSize: '7px',
		fontWeight: 400,
		borderRight: `1px solid ${pdfColors.grey}`,
	},
	lowerCell: {
		flexDirection: 'row',
		height: '25px',
		width: '10.53%',
		padding: '8px 0px 0px 4px',
		fontSize: '7px',
		fontWeight: 400,
		alignItems: 'center',
	},
	widtherCell: {
		flexDirection: 'row',
		height: '25px',
		width: '15.79%',
		padding: '8px 0px 0px 4px',
		fontSize: '7px',
		fontWeight: 400,
	},
	colorDesc: {
		marginLeft: '6px',
		color: `${pdfColors.grey}`,
	},
	textOfLegend: {
		fontSize: '8px',
		fontWeight: 400,
		color: `${pdfColors.grey}`,
	},
})

const CircleLegend = ({ color }) => (
	<Svg width='12' height='13' viewBox='0 0 12 13'>
		<Circle cx='6' cy='6.5' r='6' fill={color} />
	</Svg>
)
