import { Box } from '@mui/material'
import DigitizationHeader from 'components/cattles/digitizationPage/ui/DigitizationHeader'
import PicturesViewer from 'components/cattles/digitizationPage/ui/PicturesViewer'
import CustomTextField from 'components/shared/textFields/CustomTextField'
import useDigitizationInfo from 'hook/useDigitizationInfo'
import CattlesApiService from 'services/cattlesApiServices'
import { useAppDispatch } from 'store/hooks'
import DropzoneAreaInput from 'ui/DropzoneAreaInput'
import { ButtonUI } from 'ui/buttons/ButtonUI'
import SameTagWarningCard from './shared/SameTagWarningCard'
import { submitCowFormInfo } from './utils'

const DigitizationPage = () => {
	const {
		currentInfoAboutCow,
		infoFromDigitizatedCow,
		dailyStatistics,
		errorMessages,
		setErrorMessages,
		handleFiles,
	} = useDigitizationInfo()
	const dispatch = useAppDispatch()

	return (
		<Box sx={{ padding: '0 16px' }}>
			<DigitizationHeader identifiedObj={dailyStatistics?.identified_count} />
			<Box mt={'24px'}>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						gap: '24px',
						'@media(max-width: 1200px)': {
							flexDirection: 'column-reverse',
						},
					}}
				>
					<Box
						sx={{
							width: '60%',
							'@media(max-width: 1200px)': {
								width: '100%',
							},
						}}
					>
						<PicturesViewer images={currentInfoAboutCow} />
					</Box>
					<Box
						sx={{
							width: '40%',
							'@media(max-width: 1200px)': {
								width: '100%',
							},
						}}
						display={'flex'}
						flexDirection={'column'}
						gap={'24px'}
					>
						<DropzoneAreaInput
							currentFile={currentInfoAboutCow.tagImage}
							onFileChange={handleFiles}
						/>
						<CustomTextField
							key={'tagNumber'}
							label='Номер ушной бирки'
							value={
								currentInfoAboutCow.tagNumber
									? currentInfoAboutCow.tagNumber
									: ''
							}
							width={'100%'}
							fontWeight={600}
							adorment={
								currentInfoAboutCow.images &&
								currentInfoAboutCow.images?.length !== 0
									? true
									: false
							}
							isIdentification={
								currentInfoAboutCow.tagNumber && currentInfoAboutCow.tagImage
									? true
									: false
							}
							withLabel={currentInfoAboutCow.tagNumber ? true : false}
							error={errorMessages !== '' ? true : false}
							helperText={errorMessages}
							onChange={e => {
								setErrorMessages('')
								handleFiles({ key: 'tagNumber', value: e.target.value })
							}}
						/>
						<ButtonUI
							onClick={() =>
								submitCowFormInfo(
									infoFromDigitizatedCow.cow.id,
									currentInfoAboutCow.tagImage,
									currentInfoAboutCow.tagNumber,
									infoFromDigitizatedCow,
									setErrorMessages,
									dispatch
								)
							}
							sx={{
								width: '100%',
								height: '56px',
								fontWeight: 600,
								fontSize: '14px',
								lineHeight: '17px',
								marginTop: '0px',
							}}
							disabled={
								!currentInfoAboutCow.images ||
								!currentInfoAboutCow.tagNumber ||
								!currentInfoAboutCow.tagImage
							}
						>
							Сохранить
						</ButtonUI>
						{errorMessages && currentInfoAboutCow.tagNumber && (
							<SameTagWarningCard
								description={
									'Вы уверены, что указанный номер совпадает с номером на бирке?'
								}
								onClick={() =>
									CattlesApiService.patchCowTagNumber(
										+currentInfoAboutCow.tagNumber,
										infoFromDigitizatedCow.cow.id
									).then(() => setErrorMessages(''))
								}
								descriptionButton={'Да'}
							/>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default DigitizationPage
