import useFatnessInfoForGraph from 'hook/useFatnessInfoForGraph'
import { CardStandart } from 'ui/CardStandart'
import RangeDate from 'ui/datePicker/RangeDate'
import FatnessChart from '../shared/FatnessChart'

const FatnessCard = props => {
	const { activeFilter } = props

	const {
		perfectCategories,
		goodCategories,
		criticalCategories,
		timestamp,
		onChangeCallback,
		dateRange,
	} = useFatnessInfoForGraph(activeFilter)

	return (
		<CardStandart
			sx={{
				padding: '24px',
				display: 'flex',
				flexDirection: 'column',
				gap: '24px',
			}}
		>
			<RangeDate
				onChangeUpdateDate={onChangeCallback}
				monthFilter={true}
				yearFilter={true}
				currentRange={'Месяц'}
				startDate={dateRange.startDate}
				endDate={dateRange.endDate}
			/>
			<FatnessChart
				perfectCategories={perfectCategories}
				goodCategories={goodCategories}
				criticalCategories={criticalCategories}
				timestamp={timestamp}
			/>
		</CardStandart>
	)
}

export default FatnessCard
