import { Box, Button } from '@mui/material'
import CustomTooltip from 'components/shared/CustomTooltip'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { ReactComponent as CameraLogo } from '../../../../assets/camera_group_logo.svg'
import { ReactComponent as ArrowNext } from '../../../../assets/fill_back_arrow.svg'
import { ReactComponent as MenuIconCattles } from '../../../../assets/icons/menu_cattles.svg'

const tooltipContent = 'Доступный пакет согласно вашей лицензии.'

const DigitizationWidjet = props => {
	const navigate = useNavigate()
	const { digitizated } = props

	return (
		<CardStandart sx={{ padding: '24px' }}>
			<Box
				display={'flex'}
				flexDirection={'row'}
				alignItems={'center'}
				justifyContent={'space-between'}
			>
				<Box>
					<Box
						display={'flex'}
						flexDirection={'row'}
						alignItems={'center'}
						gap={'4px'}
					>
						<MenuIconCattles fill={COLORS.secondary} />
						{digitizated ? (
							<Box
								display={'flex'}
								flexDirection={'column'}
								alignItems={'left'}
								gap={'4px'}
							>
								<Box
									display={'flex'}
									flexDirection={'row'}
									alignItems={'center'}
									gap={'4px'}
								>
									<TypographyPrimary16Bold>
										Оцифровано {digitizated.value}
									</TypographyPrimary16Bold>
									<TypographySecondary>
										{'/' + digitizated.out_of}
									</TypographySecondary>
									<CustomTooltip
										placement={'top'}
										width={'18px'}
										height={'18px'}
										info={true}
										title={tooltipContent}
									/>
								</Box>
								<TypographySecondary>
									Не опознано{' '}
									{digitizated?.unidentified && digitizated.unidentified}
								</TypographySecondary>
							</Box>
						) : (
							<Skeleton width={200} height={34} />
						)}
					</Box>
				</Box>

				<Button
					onClick={() => navigate('/cattles/digitization')}
					disableRipple={true}
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						gap: '6px',
						textTransform: 'none',
						'&:hover': {
							backgroundColor: 'transparent',
						},
					}}
				>
					<CameraLogo stroke={COLORS.main} />
					<TypographyPrimary16Bold sx={{ color: COLORS.main }}>
						Оцифровать
					</TypographyPrimary16Bold>
					<ArrowNext
						style={{ transform: 'rotate(180deg)' }}
						stroke={COLORS.main}
					/>
				</Button>
			</Box>
		</CardStandart>
	)
}

export default DigitizationWidjet
