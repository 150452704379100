import { roundingNumber } from 'components/shared/processes'
import moment from 'moment'
import ReactApexChart from 'react-apexcharts'
import { COLORS } from 'styles/colors'
import ChartLegend from 'ui/widjets/ChartLegend'
import { localizationApexCharts } from 'utils'
import '../../utils/module.chartStyle.css'

const YieldQtyChart = props => {
	const { categories, timestamp, dateBetweenDates } = props

	const series: any = [
		{
			name: 'Объём, л',
			data: categories,
		},
	]

	const options: any = {
		mask: false,
		colors: [COLORS.main],
		chart: {
			locales: localizationApexCharts(),
			defaultLocale: 'ru',
			zoom: {
				enabled: false,
			},
			height: 250,
			type: 'line',
			toolbar: {
				show: false,
			},
		},
		markers: {
			size: 0,
			colors: ['#fff'],
			strokeColors: COLORS.main,
			strokeWidth: 2,
			hover: {
				size: 5,
			},
		},
		stroke: {
			width: 2,
			curve: 'straight',
		},
		title: {
			style: {
				fontSize: '12px',
				fontFamily: 'Montserrat',
				fontStyle: 'normal',
				fontWeight: 400,
				lineHeight: '15px',
				color: '#7F7F84',
			},
			text: 'Объем, л',
		},

		dataLabels: {
			enabled: false,
		},
		grid: {
			strokeDashArray: [6, 3],
			show: true,
			xaxis: {
				lines: {
					show: true,
				},
			},
		},
		xaxis: {
			crosshairs: {
				show: true,
				opacity: 1,
				position: 'back',
				stroke: {
					width: 1,
					dashArray: 0,
				},
			},
			type: 'datetime',
			tooltip: {
				enabled: false,
			},
			labels: {
				datetimeUTC: false,
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
				datetimeFormatter: {
					year: 'yyyy',
					month: 'MMMM',
					day: 'd.MM.yy',
					hour: 'HH:mm',
				},
			},
			categories: timestamp,
		},
		yaxis: {
			tickAmount: 4,
			min: 0,
			labels: {
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
				formatter: function (value: number, index: number) {
					if (index % 2 !== 0) {
						return ''
					}
					return roundingNumber(value, 0)
				},
			},
		},
		tooltip: {
			fixed: true,
			custom: ({ series, seriesIndex, dataPointIndex, w }) => {
				const yieldQty = Math.round(series[0][dataPointIndex])
				const timeValue =
					dateBetweenDates === 0
						? moment(w.config.xaxis.categories[dataPointIndex]).format('HH:mm')
						: moment(w.config.xaxis.categories[dataPointIndex]).format(
								'DD.MM.YY'
						  )
				return `<div
				style='width:max-content; height:70px; background-color: #fff; border-radius:12px; border: hidden; box-shadow: 0px 0px 16px 0px rgba(4, 6, 6, 0.16); position: relative'>
				<div style='padding:16px 8px; border: none;'>
					<div style='display: flex; flex-direction: column; gap:4px'>
						<div style='display:flex; flex-direction:row; align-items: center; gap:4px'>
							<span style='width:12px; height:12px; border-radius: 50%; background-color: #5222D0;'></span>
							<p style='font-weight: 600; font-size: 16px; color: #000; margin: 0px'>${yieldQty}</p>
							<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>${'л'}</p>
						</div>
						<div>
							<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>${timeValue}</p>
						</div>
					</div>
				</div>
			</div>`
			},
		},
		legend: {
			show: false,
		},
	}
	const descriptionForLegend = [
		{ color: COLORS.main, description: 'Надои' },
		{ color: COLORS.secondary, description: 'Нет данных' },
	]
	return (
		<>
			<ReactApexChart
				id={'yieldQtyChart'}
				options={options}
				series={series}
				type='line'
				height={250}
			/>
			<ChartLegend initialLegends={descriptionForLegend} />
		</>
	)
}

export default YieldQtyChart
