import { COLORS } from 'styles/colors'

type PropsType = {
  lineColor?: string,
  bgColor?: string
}

const CheckBoxActive = (props: PropsType) => {
  const { lineColor, bgColor } = props;
  
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1"
        y="-1"
        width="18"
        height="18"
        rx="2"
        transform="matrix(1 0 0 -1 0 18)"
        stroke={lineColor ? lineColor : COLORS.main}
        strokeWidth="2"
      />
      <rect
        x="4"
        y="4"
        width="12"
        height="12"
        rx="2"
        fill={bgColor ? bgColor : COLORS.main}
      />
    </svg>
  );
};

export default CheckBoxActive;
