import { Box, keyframes, styled } from '@mui/material'
import { getColorByScore } from './processes'

interface ProgressBarSegmentProps {
	color: string
	width: number
}

const fadeIn = (props: ProgressBarSegmentProps) => keyframes`
  from {
    width: 0%;
  }
  to {
    width: calc(0% + ${props.width}%);
  }
`

const ProgressBar = styled(Box)({
	width: '100%',
	height: '12px',
	backgroundColor: 'transparent',
	borderRadius: '12px',
	overflow: 'hidden',
	display: 'flex',
})

const ProgressBarSegment = styled(Box)<ProgressBarSegmentProps>(
	{
		height: '100%',
		width: '0%',
	},
	props => ({
		backgroundColor: props.color,
		animation: `${fadeIn(props)} 0.5s ease-in-out forwards`,
		animationFillMode: 'forwards',
	})
)

const LinearChart = (props: { dotsInfo: any[]; totalCattle: number }) => {
	const { dotsInfo, totalCattle } = props

	const progressBarSegments = dotsInfo.map(el => {
		const categorySum = el.totalCattles
		const percent = (categorySum / totalCattle) * 100
		const color = getColorByScore(el.score)
		return <ProgressBarSegment key={el.score} color={color} width={percent} />
	})

	return <ProgressBar>{progressBarSegments}</ProgressBar>
}

export default LinearChart
