import { CircularProgress } from '@mui/material'
import { COLORS } from 'styles/colors'
type IPreloaderType = {
	color?: string
	size?: number
	mt?: number
}
export const Preloader = ({ color, size, mt }: IPreloaderType) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: mt ? `${mt}%` : 'none',
			}}
		>
			<CircularProgress
				size={size ? size : 38}
				sx={{
					'&.MuiCircularProgress-colorPrimary': {
						color: color ? color : COLORS.main,
					},
				}}
			/>
		</div>
	)
}

export default Preloader
