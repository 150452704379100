import { Box } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { COLORS } from 'styles/colors'
import { ReactComponent as LoadLogo } from '../assets/load_feed_logo.svg'
import SmallButton from './buttons/SmallButton'
import { TypographySecondary14 } from './typography/TypographySecondary14'
import { serverName } from 'variables'

const DropzoneAreaInput = ({ currentFile, onFileChange }) => {
	const [error, setError] = useState(undefined)

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			const selectedFile = acceptedFiles[0]
			if (selectedFile.type.startsWith('image/')) {
				// Если тип файла - изображение, сбрасываем ошибку и передаем файл дальше
				setError(null)
				onFileChange({ key: 'tagImage', value: selectedFile })
			} else {
				// Если тип файла не изображение, устанавливаем ошибку
				setError('Допустимы изображения формата .jpeg, .png')
			}
		},
		[onFileChange]
	)

	const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
		onDrop,
		noClick: true,
		accept: {
			'image/png': ['.png'],
			'image/jpeg': ['.jpg', '.jpeg'],
		},
	})

	const file = useMemo(() => currentFile, [currentFile])

	const handleDelete = () => {
		onFileChange({ key: 'tagImage', value: null })
	}
	// const handleDelete = () => {
	// 	onFileChange({ key: 'tagImage', value: null });
	// 	// Создаем input
	// 	const input = document.createElement('input');
	// 	input.setAttribute('type', 'file');
	// 	input.style.display = 'none';
	// 	// Добавляем input в DOM
	// 	document.body.appendChild(input);
	// 	// Фокусируем на input (необходимо для предотвращения открытия проводника)
	// 	input.focus();
	// 	document.body.removeChild(input);
	// };

	const handleChooseFile = () => {
		const input = document.createElement('input')
		input.setAttribute('type', 'file')
		input.addEventListener('change', event => {
			const selectedFile = (event.target as HTMLInputElement).files[0]
			if (selectedFile) {
				onFileChange({ key: 'tagImage', value: selectedFile })
			}
		})
		input.click()
	}
	return (
		<Box
			{...getRootProps()}
			sx={{
				height: '240px',
				border: `1px solid ${
					error ? COLORS.deviationFont : COLORS.inputBorder
				}`,
				backgroundColor: 'transparent',
				borderRadius: '12px',
				padding: '18px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<input {...getInputProps()} />
			{file ? (
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'center'}
					gap={'16px'}
				>
					<SmallButton
						setState={open}
						label={'Заменить'}
						background={COLORS.inputBorder}
					/>
					<Box
						sx={{
							width: 'auto',
							height: 'auto',
							position: 'relative',
						}}
					>
									<img
							src={
								file instanceof Blob && file.type.startsWith('image/')
									? URL.createObjectURL(file)
									: serverName + file
							}
							alt='PreviewTagImage'
							style={{
								maxWidth: '150px',
								maxHeight: '150px',
								position: 'relative',
								zIndex: 2,
							}}
						/>
						<span
							style={{
								backgroundColor: COLORS.inputBorder,
								width: '100%',
								height: '100%',
								borderRadius: '12px',
								transform: 'rotate(12deg)',
								position: 'absolute',
								top: '0px',
								left: '0px',
								zIndex: 1,
							}}
						></span>
			
					</Box>
					<Box
						onClick={event => {
							event.stopPropagation()
						}}
					>
						<SmallButton
							setState={handleDelete}
							label={'Удалить'}
							background={COLORS.lightPink}
						/>
					</Box>
				</Box>
			) : (
				<>
					<LoadLogo
						style={{ width: '70px', height: '70px', marginTop: '10%' }}
						stroke={COLORS.inputBorder}
					/>
					<TypographySecondary14 sx={{ textAlign: 'center' }}>
						Перетащите фото ушной бирки в эту область <br /> или{' '}
						<span
							onClick={open}
							style={{
								cursor: 'pointer',
								padding: '0px',
								textDecoration: 'underline',
								textUnderlineOffset: '4px',
								color: COLORS.main,
							}}
						>
							выберите файл на компьютере
						</span>
					</TypographySecondary14>
				</>
			)}
		</Box>
	)
}

export default DropzoneAreaInput
