import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Box from '@mui/material/Box/Box'
import 'antd/dist/antd.min.css'
import './dateRangeStyle.css'

import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import moment from 'moment'
import 'moment/locale/ru'
import { useEffect, useState } from 'react'
import { ReactComponent as CalendarMainLogo } from '../../assets/calendar_main_logo.svg'
import {
	firstDayHandler,
	getDatesArray,
	getOneMonthDate,
	getOneYearDate,
	getYesterdayDate,
} from '../../components/shared/processes'
import { COLORS } from '../../styles/colors'
import CheckBoxFilter from '../CheckBoxFilter'
import { TypographySecondary } from '../typography/TypographySecondary'

import { antdMonths } from './ruLocalization'

moment.locale('ru', antdMonths)
const { RangePicker } = DatePicker
const dateFormat = 'DD.MM.YY'

type RangeDateProps = {
	startDate?: any
	endDate?: any
	onChangeUpdateDate?: any
	disabledToday?: boolean
	todayFilter?: boolean
	yesterdayFilter?: boolean
	weekFilter?: boolean
	monthFilter?: boolean
	yearFilter?: boolean
	currentRange?: string
	dayBefore?: number
	disabledDates?: { firstItem: number; lastItem: number }
	disabled?: boolean
}
const RangeDate = (props: RangeDateProps) => {
	const initialStartDate =
		props.startDate === undefined ? undefined : moment(props.startDate)
	const initialEndDate =
		props.endDate === undefined ? undefined : moment(props.endDate)
	const [color, setColor] = useState(COLORS.icon)
	const [value, setValue] = useState<
		[moment.Moment | null, moment.Moment | null]
	>([initialStartDate, initialEndDate])
	const [activeButton, setActiveButton] = useState(props.currentRange)
	const [rangeInfo, setRangeInfo] = useState([])
	const beginValue = moment(value[0]).valueOf()
	const endValue = moment(value[1]).valueOf()
	const hasStart = rangeInfo.includes('start')
	const hasEnd = rangeInfo.includes('end')
	const oneDayInMilliseconds = 86400000

	const weekDays = getDatesArray(6)
	const yesterdayDate = getYesterdayDate()
	const oneMonthDays = getOneMonthDate()
	const oneYearDays = getOneYearDate()
	const primaryDateState = firstDayHandler()

	const disabledDate = current => {
		if (props.disabledToday) {
			return current > moment().add(-1, 'day').endOf('day')
		} else {
			return current && current > moment().add(1, 'day').endOf('day')
		}
	}
	const disabledDateWithRange = current => {
		const { firstItem, lastItem } = props.disabledDates || {}
		if (firstItem && lastItem) {
			const currentTimestamp = moment(current).valueOf()
			return (
				currentTimestamp < moment(firstItem).valueOf() ||
				currentTimestamp > moment(lastItem).valueOf()
			)
		}
		return false
	}

	useEffect(() => {
		if (
			activeButton === 'Сегодня' ||
			activeButton === 'Вчера' ||
			activeButton === 'Неделя' ||
			activeButton === 'Месяц' ||
			activeButton === 'Год'
		) {
			props.onChangeUpdateDate([beginValue, endValue])
		}
	}, [activeButton])

	useEffect(() => {
		if (hasStart === true && hasEnd === true) {
			props.onChangeUpdateDate([beginValue, endValue])
			setRangeInfo([])
		}
	}, [hasStart, hasEnd])

	const handleChangeDebut = range => {
		const beginValue = Date.parse(range[0])
		const endValue = Date.parse(range[1])
		setValue([moment(beginValue), moment(endValue)])
	}

	const handleCalendarChange = (dates, dateStrings, info) => {
		setRangeInfo(prevState => [...prevState, info.range])
	}

	const resetToDefaultRange = () => {
		if (props.currentRange === 'Сегодня') {
			setValue([moment(primaryDateState[0]), moment(primaryDateState[1])])
			props.onChangeUpdateDate([primaryDateState[0], primaryDateState[1]])
			setActiveButton('Сегодня')
		} else if (props.currentRange === 'Неделя') {
			setValue([moment(weekDays.startDate), moment(weekDays.endDate)])
			props.onChangeUpdateDate([
				moment(weekDays.startDate).valueOf(),
				moment(weekDays.endDate).valueOf(),
			])
			setActiveButton('Неделя')
		} else if (props.currentRange === 'Вчера') {
			setValue([moment(yesterdayDate.startDate), moment(yesterdayDate.endDate)])
			props.onChangeUpdateDate([
				moment(yesterdayDate.startDate).valueOf(),
				moment(yesterdayDate.endDate).valueOf(),
			])
			setActiveButton('Вчера')
		} else {
			setActiveButton('Сегодня')
		}
	}
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				gap: '8px',
			}}
		>
			{props.todayFilter && (
				<Box
					onClick={() => {
						setValue([moment(primaryDateState[0]), moment(primaryDateState[1])])
						handleChangeDebut([
							moment(primaryDateState[0]),
							moment(primaryDateState[1]),
						])
						setColor(COLORS.icon)
					}}
				>
					<CheckBoxFilter
						title={'Сегодня'}
						active={activeButton === 'Сегодня'}
						onClick={() => setActiveButton('Сегодня')}
					/>
				</Box>
			)}
			{props.yesterdayFilter && (
				<Box
					onClick={() => {
						setValue([
							moment(yesterdayDate.startDate),
							moment(yesterdayDate.endDate),
						])
						handleChangeDebut([
							moment(yesterdayDate.startDate),
							moment(yesterdayDate.endDate),
						])
						setColor(COLORS.icon)
					}}
				>
					<CheckBoxFilter
						title={'Вчера'}
						active={activeButton === 'Вчера'}
						onClick={() => setActiveButton('Вчера')}
					/>
				</Box>
			)}
			{props.weekFilter && (
				<Box
					onClick={() => {
						setValue([
							moment(
								props.dayBefore
									? weekDays.startDate - props.dayBefore * oneDayInMilliseconds
									: weekDays.startDate
							),
							moment(
								props.dayBefore
									? weekDays.endDate - props.dayBefore * oneDayInMilliseconds
									: weekDays.endDate
							),
						])
						handleChangeDebut([
							moment(
								props.dayBefore
									? weekDays.startDate - props.dayBefore * oneDayInMilliseconds
									: weekDays.startDate
							),
							moment(
								props.dayBefore
									? weekDays.endDate - props.dayBefore * oneDayInMilliseconds
									: weekDays.endDate
							),
						])
						setColor(COLORS.icon)
					}}
				>
					<CheckBoxFilter
						title={'Неделя'}
						active={activeButton === 'Неделя'}
						onClick={() => setActiveButton('Неделя')}
					/>
				</Box>
			)}
			{props.monthFilter && (
				<Box
					onClick={() => {
						setValue([
							moment(
								props.dayBefore
									? oneMonthDays.startDate -
											props.dayBefore * oneDayInMilliseconds
									: oneMonthDays.startDate
							),
							moment(
								props.dayBefore
									? oneMonthDays.endDate -
											props.dayBefore * oneDayInMilliseconds
									: oneMonthDays.endDate
							),
						])
						handleChangeDebut([
							moment(
								props.dayBefore
									? oneMonthDays.startDate -
											props.dayBefore * oneDayInMilliseconds
									: oneMonthDays.startDate
							),
							moment(
								props.dayBefore
									? oneMonthDays.endDate -
											props.dayBefore * oneDayInMilliseconds
									: oneMonthDays.endDate
							),
						])
						setColor(COLORS.icon)
					}}
				>
					<CheckBoxFilter
						title={'Месяц'}
						active={activeButton === 'Месяц'}
						onClick={() => setActiveButton('Месяц')}
					/>
				</Box>
			)}
			{props.yearFilter && (
				<Box
					onClick={() => {
						setValue([
							moment(
								props.dayBefore
									? oneYearDays.startDate -
											props.dayBefore * oneDayInMilliseconds
									: oneYearDays.startDate
							),
							moment(oneYearDays.endDate),
						])
						handleChangeDebut([
							moment(
								props.dayBefore
									? oneYearDays.startDate -
											props.dayBefore * oneDayInMilliseconds
									: oneYearDays.startDate
							),
							moment(
								props.dayBefore
									? oneYearDays.endDate - props.dayBefore * oneDayInMilliseconds
									: oneYearDays.endDate
							),
						])
						setColor(COLORS.icon)
					}}
				>
					<CheckBoxFilter
						title={'Год'}
						active={activeButton === 'Год'}
						onClick={() => {
							setActiveButton('Год')
						}}
					/>
				</Box>
			)}

			<RangePicker
				separator='—'
				onClick={() => {
					setActiveButton('Календарь')
				}}
				// onMouseEnter={() => !props.disabled && setColor(COLORS.main)}
				// onMouseLeave={() => setColor(COLORS.icon)}
				suffixIcon={
					<div style={{ position: 'relative' }}>
						<CalendarMainLogo
							stroke={activeButton === 'Календарь' ? COLORS.main : color}
						/>
						<KeyboardArrowDownIcon
							sx={{
								position: 'absolute',
								top: '2px',
								left: '180px',
								color: activeButton === 'Календарь' ? COLORS.main : color,
								transform:
									activeButton === 'Календарь' ? 'rotate(180deg)' : 'none',
							}}
						/>
					</div>
				}
				clearIcon={null}
				className={
					activeButton === 'Календарь' ? 'dataRangeActive' : 'dataRange'
				}
				value={value}
				placeholder={['__/__/__', '__/__/__']}
				locale={locale}
				disabled={props.disabled}
				disabledDate={
					props.disabledDates ? disabledDateWithRange : props.disabledDates === null ? null : disabledDate
				}
				format={dateFormat}
				onChange={handleChangeDebut}
				onCalendarChange={handleCalendarChange}
			/>
			{activeButton === 'Календарь' && !props.disabledDates && (
				<TypographySecondary
					sx={{ cursor: 'pointer' }}
					onClick={resetToDefaultRange}
				>
					Сбросить
				</TypographySecondary>
			)}
		</Box>
	)
}
export default RangeDate
