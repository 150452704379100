import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import moment from 'moment'
import { COLORS } from 'styles/colors'
import SwitchiOS from 'ui/SwitchiOS'
import { IFrameViewer } from '../../../models/IFrameInfo'
import { TypographyPrimary } from '../../../ui/typography/TypographyPrimary'
import { TypographySecondary } from '../../../ui/typography/TypographySecondary'
import { roundingNumber } from '../../shared/processes'

const InfoAboutFrame = (props: IFrameViewer) => {
	const totalWeight = `${roundingNumber(
		props.objectForCamera?.t_feed,
		1
	)} м³ / ${roundingNumber(
		props.objectForCamera?.t_feed * props.objectForCamera?.density,
		0
	)} кг`
	const availableWeight = `${roundingNumber(
		props.objectForCamera?.r_feed,
		1
	)} м³ / ${roundingNumber(
		props.objectForCamera?.r_feed * props.objectForCamera?.density,
		0
	)} кг`
	const unAvailableWeight = `${roundingNumber(
		props.objectForCamera?.ur_feed,
		1
	)} м³ / ${roundingNumber(
		props.objectForCamera?.ur_feed * props.objectForCamera?.density,
		0
	)} кг`

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '28%',
				gap: '10px',
				position: 'absolute',
				right: '0px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: '24px',
					mb: '14px',
				}}
			>
				<TypographyPrimary>Сетка на кормовом столе</TypographyPrimary>
				<SwitchiOS
					checked={props.visibleMask}
					background={COLORS.success}
					onClick={() => {
						props.setVisibleMask(prevState => !prevState)
						props.toggleSwitch()
					}}
				/>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<TypographySecondary>Начало замера</TypographySecondary>
				<TypographyPrimary>
					{moment(props.objectForCamera?.start_dt).format('HH:mm DD.MM.YY')}
				</TypographyPrimary>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<TypographySecondary>Конец замера</TypographySecondary>
				<TypographyPrimary>
					{moment(props.objectForCamera?.end_dt).format('HH:mm DD.MM.YY')}
				</TypographyPrimary>
			</Box>
			<Divider />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<TypographySecondary>Всего объём корма</TypographySecondary>
				<TypographyPrimary>{totalWeight}</TypographyPrimary>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<TypographySecondary>Доступный корм</TypographySecondary>
				<TypographyPrimary>{availableWeight}</TypographyPrimary>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<TypographySecondary>Недоступный корм</TypographySecondary>
				<TypographyPrimary>{unAvailableWeight}</TypographyPrimary>
			</Box>
		</Box>
	)
}

export default InfoAboutFrame
