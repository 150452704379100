import { Font } from '@react-pdf/renderer';
import FontMontSerratBold from '../../../../styles/fonts/Montserrat-Bold.ttf'
import FontMontSerratRegular from '../../../../styles/fonts/Montserrat-Regular.ttf'
const registerFonts = () => {
	Font.register({
		family: 'Montserrat',
		fonts: [
			{
				src: FontMontSerratRegular,
			},
			{
				src: FontMontSerratBold,
				fontWeight: 'bold',
			},
		],
	})
};

export default registerFonts;
