import {
	Box,
	Collapse,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	tableCellClasses,
} from '@mui/material'
import { ReactComponent as ArrowCloseDone } from 'assets/arrow_close_logo.svg'
import { morphForWords } from 'components/shared/processes'
import moment from 'moment'
import { useEffect, useState } from 'react'
import CattlesApiService from 'services/cattlesApiServices'
import { useAppSelector } from 'store/hooks'
import { COLORS } from 'styles/colors'
import CustomTableRow from 'ui/table/CustomTableRow'
import { TypographyHeaderSecondary } from 'ui/typography/TypographyHeaderSecondary'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import { getTranslationStatus } from '../processes'

type ViewHistoryBlockType = {
	cowId: number
}

const ViewHistoryBlock = (props: ViewHistoryBlockType) => {
	const { cowId } = props
	const [open, setOpen] = useState<boolean>(false)
	const [examinatorsInfo, setExaminatorsInfo] = useState([])
	const { diagnosesList, diagnosesListPending } = useAppSelector(
		state => state.cattlesSlice
	)
	const handleOpenTable = () => {
		setOpen(prev => !prev)
	}
	useEffect(() => {
		CattlesApiService.getExaminationCowInfoByCowId(cowId).then(data => {
			const examInfo = data.map((el, index) => {
				const obj = {
					rowKey: `examinatorRowId${el.id}`,
					height: '100%',
					cellItems: [
						{
							padding: '16px 8px 0px 0px',
							children: (
								<TypographyPrimary sx={{}}>
									{moment(el.created_dt).format('DD.MM.YY')}
								</TypographyPrimary>
							),
						},
						{
							padding: '16px 8px 0px 0px',
							children: (
								<TypographyPrimary key={`limpness-${index}`}>
									{el.limpness}{' '}
									<span style={{ color: COLORS.secondaryFont }}>
										{morphForWords(el.limpness, ['балл', 'балла', 'баллов'])}
									</span>
								</TypographyPrimary>
							),
						},
						{
							padding: '16px 8px 0px 0px',
							children: `${el.examiner.lastname}. ${el.examiner.firstname[0]}.`,
						},
						{
							padding: '16px 8px 0px 0px',
							children: (
								<Box
									display={'flex'}
									flexDirection={'column'}
									key={`diagnoses-${index}`}
									gap={'12px'}
								>
									{el.diagnosis.map((diagnose, diagIndex) => (
										<TypographyPrimary key={`diagnose-${index}-${diagIndex}`}>
											{diagnose.sickness
												.map(sickness => {
													const found = diagnosesList.find(
														el => el.id === sickness
													)
													return found ? found.title : 'Unknown'
												})
												.join(', ')}
										</TypographyPrimary>
									))}
									{el.dispose_recommended && (
										<span
											style={{
												width: '74px',
												padding: '4px 8px',
												borderRadius: '12px',
												fontSize: '12px',
												color: COLORS.error,
												backgroundColor: COLORS.deviationOpacity,
											}}
										>
											Выбытие
										</span>
									)}
								</Box>
							),
						},
						{
							padding: '16px 8px 0px 0px',
							children: (
								<Box
									display={'flex'}
									flexDirection={'column'}
									key={`diagnoses-${index}`}
									gap={'14px'}
								>
									{el.diagnosis.map((diagnose, posIndex) => (
										<TypographyPrimary key={`position-${index}-${posIndex}`}>
											{getTranslationStatus(diagnose.position)}
										</TypographyPrimary>
									))}
								</Box>
							),
						},
						{
							padding: '16px 8px 0px 0px',
							children: (
								<Box
									display={'flex'}
									flexDirection={'column'}
									key={`diagnoses-${index}`}
									gap={'14px'}
								>
									{el.diagnosis.map((diagnose, commentIndex) => (
										<TypographySecondary14
											key={`comment-${index}-${commentIndex}`}
										>
											{diagnose.comment}
										</TypographySecondary14>
									))}
								</Box>
							),
						},
					],
				}
				return obj
			})
			setExaminatorsInfo(examInfo)
		})
	}, [cowId, diagnosesListPending])

	return (
		<>
			<Box
				display={'flex'}
				flexDirection={'row'}
				justifyContent={'space-between'}
				alignItems={'center'}
			>
				<TypographyHeaderSecondary
					onClick={handleOpenTable}
					sx={{ cursor: 'pointer' }}
				>
					История осмотров
				</TypographyHeaderSecondary>
				<IconButton
					onClick={handleOpenTable}
					disableRipple
					sx={{
						p: 0,
						transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
						transition: '.3s ease-in-out',
					}}
				>
					<ArrowCloseDone stroke={COLORS.icon} />
				</IconButton>
			</Box>
			<Collapse in={open} unmountOnExit timeout='auto'>
				<TableContainer
					sx={{
						overflowY: 'auto',
						overflowX: 'hidden',
						height: '267px',
						'&::-webkit-scrollbar': {
							width: 4,
						},
						'&::-webkit-scrollbar-track': {
							backgroundColor: '#DBDBDB',
							borderRadius: 12,
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#5222D0',
							borderRadius: 12,
						},
					}}
				>
					<Table>
						<TableHead
							sx={{
								[`& .${tableCellClasses.root}`]: {
									backgroundColor: '#fff',
								},
							}}
						>
							<CustomTableRow
								rowKey={tableHead.rowKey}
								cellItems={tableHead.cellItems}
								rowBorderBottom={tableHead.rowBorderBottom}
								rowHeight={tableHead.height}
							/>
						</TableHead>
						<TableBody>
							{examinatorsInfo?.length === 0 ? (
								<TableRow
									sx={{
										width: '100%',
										[`& .${tableCellClasses.root}`]: {
											borderBottom: 'none',
											height: '200px',
										},
									}}
								>
									<TableCell colSpan={12} align={'center'} padding={'none'}>
										<TypographySecondary14>
											Признаков хромоты ранее не было обнаружено
										</TypographySecondary14>
									</TableCell>
								</TableRow>
							) : (
								examinatorsInfo.map(el => (
									<CustomTableRow
										key={el.rowKey}
										rowKey={el.rowKey}
										cellItems={el.cellItems}
										rowHeight={el.height}
									/>
								))
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TypographySecondary>
					Всего: {examinatorsInfo?.length}
				</TypographySecondary>
			</Collapse>
		</>
	)
}

export default ViewHistoryBlock

const tableHead = {
	rowKey: 'tableHistoryViewHead',
	rowBorderBottom: COLORS.secondaryFontOpacity,
	height: '40px',
	cellItems: [
		{
			width: '10%',
			children: (
				<TypographySecondary>
					Дата <br /> осмотра
				</TypographySecondary>
			),
		},
		{
			width: '10%',
			children: (
				<TypographySecondary>
					Балл
					<br />
					хромоты
				</TypographySecondary>
			),
		},
		{
			width: '10%',
			children: <TypographySecondary>Внес изменения</TypographySecondary>,
		},
		{
			width: '20%',
			children: <TypographySecondary>Диагноз</TypographySecondary>,
		},
		{
			width: '10%',
			children: (
				<TypographySecondary>
					Больное
					<br />
					копыто
				</TypographySecondary>
			),
		},
		{
			width: '20%',
			children: <TypographySecondary>Комментарий</TypographySecondary>,
		},
	],
}
