import { StyleSheet, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'
import { roundingNumber } from '../../shared/processes'
import { CircleLegend } from './utils/CircleLegend'
import { pdfColors } from './utils/colorPdf'
import registerFonts from './utils/registerFont'

const TableBody = props => {
	registerFonts()

	const rows = props.table?.map((item, index) => {
		return (
			<View style={styles.row} key={index}>
				<Text
					style={
						index === props.table.length - 1
							? styles.descriptionLowerWithoutBorderBottom
							: styles.descriptionLower
					}
				>
					{item[0].value ? item[0].value : '-'}
				</Text>
				<Text
					style={[
						index === props.table.length - 1
							? styles.descriptionWidtherWithoutBorderBottom
							: styles.descriptionWidther,
						{
							backgroundColor: item[1].color,
						},
					]}
				>
					{item[1].value &&
						item[1].color !== '' &&
						moment(item[1].value).format('HH:mm')}
				</Text>
				<Text
					style={[
						index === props.table.length - 1
							? styles.descriptionWidtherWithoutBorderBottom
							: styles.descriptionWidther,
						{
							backgroundColor: item[2].color,
						},
					]}
				>
					{item[2].value &&
						item[2].color !== pdfColors.greyOpacity &&
						moment(item[2].value).format('HH:mm')}
				</Text>
				<Text
					style={[
						index === props.table.length - 1
							? styles.descriptionWidtherWithoutBorderBottom
							: styles.descriptionWidther,
						{
							backgroundColor: item[3].color,
						},
					]}
				>
					{item[3].value &&
						item[3].color !== pdfColors.greyOpacity &&
						roundingNumber(item[3].value, 0)}
					<Text style={styles.colorDesc}>
						{item[3].color !== pdfColors.greyOpacity && ' кг'}
					</Text>
				</Text>
				<Text
					style={[
						index === props.table.length - 1
							? styles.descriptionWidtherWithoutBorderBottom
							: styles.descriptionWidther,
						{
							backgroundColor: item[4].color,
						},
					]}
				>
					{item[4].color !== pdfColors.greyOpacity &&
						roundingNumber(item[4].value, 0)}
					<Text style={styles.colorDesc}>
						{item[4].color !== pdfColors.greyOpacity && ' кг'}
					</Text>
				</Text>
			</View>
		)
	})

	return (
		<React.Fragment>
			<View style={styles.container}>
				<View style={styles.column}>{rows}</View>
				<View style={{ flexDirection: 'row', width: '34.25%' }}>
					<Text
						style={{
							height: '100%',
							padding: '8px 4px 0px 8px',
							width: '29.7%',
							fontSize: '7px',
							lineHeight: 'normal',
							fontWeight: 400,
							color: '#000',
							borderRight: '1px solid #7f7f84',
							borderBottom: '1px solid #7f7f84',
							backgroundColor: props.table[0][5].color,
						}}
					>
						{props.table[0][5].color !== pdfColors.greyOpacity &&
							roundingNumber(props.table[0][5].value, 0)}
						<Text style={styles.colorDesc}>
							{props.table[0][5].color !== pdfColors.greyOpacity && ' кг'}
						</Text>
					</Text>
					<Text
						style={{
							height: '100%',
							padding: '8px 4px 0px 8px',
							width: '40.55%',
							fontSize: '7px',
							lineHeight: 'normal',
							fontWeight: 400,
							color: '#000',
							borderRight: '1px solid #7f7f84',
							borderBottom: '1px solid #7f7f84',
							backgroundColor: props.table[0][6].color,
						}}
					>
						{props.table[0][6].color !== pdfColors.greyOpacity &&
							roundingNumber(props.table[0][6].value, 0)}
					</Text>
					<Text
						style={{
							height: '100%',
							padding: '8px 4px 0px 8px',
							width: '30%',
							fontSize: '7px',
							lineHeight: 'normal',
							fontWeight: 400,
							color: '#000',
							borderRight: '1px solid #7f7f84',
							borderBottom: '1px solid #7f7f84',
							borderBottomRightRadius: '16%',
							flexDirection: 'row',
							backgroundColor: props.table[0][7].color,
						}}
					>
						{props.table[0][7].color !== pdfColors.greyOpacity &&
							roundingNumber(props.table[0][7].value, 1)}
						<Text style={styles.colorDesc}>
							{props.table[0][7].color !== pdfColors.greyOpacity && ' кг/гол'}
						</Text>
					</Text>
				</View>
			</View>
			<View style={{ ...styles.row, gap: '8px', marginTop: '12px' }}>
				<CircleLegend color={'#DBDBDB'} />
				<Text style={styles.textOfLegend}>Нет данных</Text>
				<CircleLegend color={'rgba(250, 234, 232)'} />
				<Text style={styles.textOfLegend}>Отклонение</Text>
			</View>
		</React.Fragment>
	)
}

export default TableBody

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		width: '100%',
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		width: '65.65%',
		borderBottom: '1px solid #7f7f84',
		borderLeft: '1px solid #7f7f84',
		borderBottomLeftRadius: '8%',
	},
	row: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	descriptionLower: {
		height: '24px',
		padding: '8px 4px 0px 8px',
		width: '15.6%',
		fontSize: '7px',
		lineHeight: 'normal',
		fontWeight: 400,
		color: '#000',
		borderRight: '1px solid #7f7f84',
		borderBottom: '1px solid #7f7f84',
	},
	descriptionWidther: {
		height: '24px',
		padding: '8px 4px 0px 8px',
		width: '21.1%',
		fontSize: '7px',
		lineHeight: 'normal',
		fontWeight: 400,
		color: '#000',
		borderRight: '1px solid #7f7f84',
		borderBottom: '1px solid #7f7f84',
		flexDirection: 'row',
	},
	descriptionLowerWithoutBorderBottom: {
		height: '24px',
		padding: '8px 4px 0px 8px',
		width: '15.6%',
		fontSize: '7px',
		lineHeight: 'normal',
		fontWeight: 400,
		color: '#000',
		borderRight: '1px solid #7f7f84',
	},
	descriptionWidtherWithoutBorderBottom: {
		height: '24px',
		padding: '8px 4px 0px 8px',
		width: '21.1%',
		fontSize: '7px',
		lineHeight: 'normal',
		fontWeight: 400,
		color: '#000',
		borderRight: '1px solid #7f7f84',
		flexDirection: 'row',
	},
	colorDesc: {
		paddingLeft: '4px',
		color: '#7F7F84',
	},
	textOfLegend: {
		fontSize: '8px',
		fontWeight: 400,
		color: `${pdfColors.grey}`,
	},
})
