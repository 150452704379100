import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import moment from 'moment'
import { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { COLORS } from '../../styles/colors'
import { localizationApexCharts } from '../../utils'
import { tooltipForConsuptionChart } from './TooltipsForCharts/tooltipsForCharts'
import './styles/module.ApexChartTooltip.css'

const GroupApexConsuption = props => {
	const [cursorPosition, setCursorPosition] = useState(null)

	const handleMouseMove = e => {
		if (props.daysBetweenDates === 1) {
			const chartElement = document.getElementById('consuptionChart')
			if (chartElement) {
				const chartRect = chartElement.getBoundingClientRect()
				const mouseX = e.clientX - chartRect.left
				const chartWidth = chartRect.width

				if (mouseX <= chartWidth / 2) {
					setCursorPosition('left')
				} else {
					setCursorPosition('right')
				}
			}
		}
	}

	const distributedValuesForTooltip = props.fetchData.map(el => el.distributed_sum)
	const distributedValuesForRenderInChart = props.fetchData.map(el => {
		if (el.distributed_sum !== null && el.cleaned_sum !== null) {
			return el.distributed_sum > el.cleaned_sum
				? el.distributed_sum - el.cleaned_sum
				: el.distributed_sum
		} else {
			return el.distributed_sum
		}
	})

	const cleanedValuesForTooltip = props.fetchData.map(el => el.cleaned_sum)

	const cleanedValuesForRenderInChart = props.fetchData.map(el => {
		if (el.distributed_sum !== null && el.cleaned_sum !== null) {
			return el.cleaned_sum > el.distributed_sum
				? el.cleaned_sum - el.distributed_sum
				: el.cleaned_sum
		} else {
			return el.cleaned_sum
		}
	})
	const dataXasis = props.fetchData.map(el => el.date)
	let maxSeriesValue = -Infinity

	for (const data of props.fetchData) {
		const distributedSum =
			data.distributed_sum !== null ? data.distributed_sum : 0
		const cleanedSum = data.cleaned_sum !== null ? data.cleaned_sum : 0
		const sum =
			distributedSum > cleanedSum
				? Math.round(distributedSum)
				: Math.round(cleanedSum)

		if (sum > maxSeriesValue) {
			maxSeriesValue = sum
		}
	}

	const yearsValues = []

	for (let i = 0; i < 12; i++) {
		const startDate = moment().subtract(i, 'months').startOf('month')
		const endDate = moment().subtract(i, 'months').endOf('month')
		const filteredData = props.fetchData.filter(item =>
			moment(item.date).isBetween(startDate, endDate, null, '[]')
		)
		const distributed_sum = filteredData.reduce(
			(sum, item) => sum + (item.distributed_sum || 0),
			0
		)
		const cleaned_sum = filteredData.reduce(
			(sum, item) => sum + (item.cleaned_sum || 0),
			0
		)
		const dateInMilliseconds = startDate.valueOf()
		const monthData = { date: dateInMilliseconds, distributed_sum, cleaned_sum }
		yearsValues.push(monthData)
	}
	yearsValues.sort((a, b) => a.date - b.date)

	const yearsDistributedValues = yearsValues?.map(el => {
		if (el.distributed_sum !== null && el.cleaned_sum !== null) {
			return el.distributed_sum > el.cleaned_sum
				? el.distributed_sum - el.cleaned_sum
				: el.distributed_sum
		} else {
			return el.distributed_sum
		}
	})
	const yearsCleanedValues = yearsValues?.map(el => {
		if (el.distributed_sum !== null && el.cleaned_sum !== null) {
			return el.cleaned_sum > el.distributed_sum
				? el.cleaned_sum - el.distributed_sum
				: el.cleaned_sum
		} else {
			return el.cleaned_sum
		}
	})
	const dataXYearsValues = yearsValues?.map(el => new Date(el.date).getTime())

	let maxYearsSeriesValue = -Infinity

	for (const data of yearsValues) {
		const distributedSum =
			data.distributed_sum !== null ? data.distributed_sum : 0
		const cleanedSum = data.cleaned_sum !== null ? data.cleaned_sum : 0

		const sum =
			distributedSum > cleanedSum
				? Math.round(distributedSum)
				: Math.round(cleanedSum)

		if (sum > maxYearsSeriesValue) {
			maxYearsSeriesValue = sum
		}
	}
	const series = [
		{
			name: 'Остаток',
			color: COLORS.error,
			data: props.daysBetweenDates > 364 ? yearsCleanedValues : cleanedValuesForRenderInChart,
		},
		{
			name: 'Роздано корма',
			color: COLORS.main,
			data: props.daysBetweenDates > 364 ? yearsDistributedValues : distributedValuesForRenderInChart,
		},
	]

	if (
		distributedValuesForTooltip.some(value => value === null) &&
		cleanedValuesForTooltip.some(value => value === null) &&
		props.daysBetweenDates < 364
	) {
		series.unshift({
			name: 'Нет данных',
			color: COLORS.inputBorder,
			data: props.fetchData.map(el => {
				if (el.distributed_sum === null && el.cleaned_sum === null) {
					return maxSeriesValue
				}
				return 0
			}),
		})
	}

	const options: any = {
		colors: [COLORS.main, COLORS.inputBorder, COLORS.error],
		mask: false,
		chart: {
			zoom: {
				enabled: false,
			},
			stacked: true,
			locales: localizationApexCharts(),
			defaultLocale: 'ru',

			height: 250,
			type: 'bar',
			toolbar: {
				show: false,
			},
		},
		title: {
			style: {
				fontSize: '12px',
				fontFamily: 'Montserrat',
				fontStyle: 'normal',
				fontWeight: 400,
				lineHeight: '15px',
				color: '#7F7F84',
			},
			text: 'Вес, кг',
		},

		dataLabels: {
			enabled: false,
		},
		grid: {
			padding: {
				left: 0,
				right: 0,
			},
			strokeDashArray: [6, 3],
			show: true,
			position: 'back',
			xaxis: {
				lines: {
					show: true,
				},
			},
		},

		plotOptions: {
			bar: {
				horizontal: false,
				borderRadius:
					props.daysBetweenDates <= 1
						? 20
						: props.daysBetweenDates <= 60
						? 8
						: props.daysBetweenDates <= 91
						? 4
						: 8,
				borderRadiusApplication: 'end',
				borderRadiusWhenStacked: 'last',
				columnWidth:
					props.daysBetweenDates <= 1
						? '5%'
						: props.daysBetweenDates <= 2
						? '10%'
						: props.daysBetweenDates <= 4
						? '15%'
						: props.daysBetweenDates <= 10
						? '30%'
						: props.daysBetweenDates <= 20
						? '40%'
						: '60%',
				dataLabels: {
					hideOverflowingLabels: true,
					total: {
						offsetX: 200,
						enabled: false,
						style: {
							fontSize: '13px',
							fontWeight: 900,
						},
					},
				},
			},
		},
		fill: {
			opacity: 1,
		},
		xaxis: {
			crosshairs: {
				show: false,
			},
			type: 'category',
			tickPlacement: 'on',
			tickAmount: 31,
			tooltip: {
				enabled: false,
			},
			labels: {
				rotate: 0,
				trim: false,
				hideOverlappingLabels: false,
				formatter: function (value) {
					const momentValue = moment(value)
					if (momentValue.date() === 1 && props.daysBetweenDates <= 92) {
						return momentValue.format('DD.MM')
					} else {
						return props.daysBetweenDates <= 7
							? momentValue.format('DD.MM.YY')
							: props.daysBetweenDates <= 92
							? momentValue.format('D')
							: momentValue.format('MMMM')
					}
				},

				datetimeUTC: false,
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
			},
			categories:
				props.activeButton === 'Год' || props.daysBetweenDates > 364
					? dataXYearsValues
					: dataXasis,
		},
		yaxis: {
			crosshairs: {
				show: false,
			},
			tickAmount: 4,
			max:
				props.activeButton === 'Год' || props.daysBetweenDates > 364
					? maxYearsSeriesValue
					: maxSeriesValue,
			labels: {
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
				formatter: function (value: number, index: number) {
					if (index % 2 !== 0) {
						return ''
					}
					return Math.round(value).toLocaleString().toString()
				},
			},
		},
		states: {
			normal: {
				filter: {
					type: 'none',
					value: 0,
				},
			},
			hover: {
				filter: {
					type: 'none',
				},
			},
			active: {
				allowMultipleDataPointsSelection: false,
				filter: {
					type: 'none',
					value: 0,
				},
			},
		},
		tooltip: {
			custom: ({ series, dataPointIndex }) => {
				return tooltipForConsuptionChart(
					series,
					dataPointIndex,
					props.daysBetweenDates,
					distributedValuesForTooltip,
					yearsDistributedValues,
					cleanedValuesForTooltip,
					yearsCleanedValues,
					dataXasis
				)
			},
		},
		legend: {
			show: false,
		},
	}
	return (
		<div id={'#chart'}>
			{props.loading ? (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress
						sx={{
							'&.MuiCircularProgress-colorPrimary': {
								color: COLORS.secondaryFontOpacity,
							},
						}}
					/>
				</div>
			) : (
				<div
					style={{ marginLeft: '-8px' }}
					id={'consuptionChart'}
					onMouseMove={handleMouseMove}
				>
					<style>
						{`
      #consuptionChart .apexcharts-bar-series > g > path:first-child, #consuptionChart .apexcharts-xaxis-texts-g > text:first-child, #consuptionChart .apexcharts-gridlines-vertical > line:first-child {
        ${props.daysBetweenDates === 1 ? 'transform: translateX(30%);' : ''}
      }
      #consuptionChart .apexcharts-bar-series > g > path,
			#consuptionChart .apexcharts-xaxis-texts-g > text, 
			#consuptionChart .apexcharts-gridlines-vertical > line {
        ${props.daysBetweenDates === 1 ? 'transform: translateX(-30%);' : ''}
      }
			#consuptionChart .apexcharts-inner > line:nth-child(2) {
				${
					props.daysBetweenDates === 1
						? 'transform: translateX(30%) translateY(-176px);'
						: ''
				}
			 }
			 #consuptionChart .apexcharts-inner > line:nth-child(3) {
				${
					props.daysBetweenDates === 1
						? 'transform: translateX(-30%) translateY(-176px);'
						: ''
				}
			 }
			 ${
					props.daysBetweenDates === 1 &&
					`#consuptionChart .apexcharts-tooltip.apexcharts-active.apexcharts-theme-light {left: ${
						cursorPosition === 'left' ? '30%' : '60%'
					} !important;}`
				}
    `}
					</style>
					<ReactApexChart
						id={'apex-chart'}
						options={options}
						series={series}
						type='bar'
						height={250}
						className={'apex-chart'}
					/>
				</div>
			)}
		</div>
	)
}

export default GroupApexConsuption
