import { Box, Divider } from '@mui/material'
import TabsFilter from 'components/shared/breadCrumbs/TabsFilter'
import AutocompleteInput from 'components/shared/textFields/AutocompleteInput'
import useLamenessFilter from 'hook/useLamenessFilter'
import useCowsNextPage from 'hook/useNextPage'
import { useEffect, useState } from 'react'
import { CardStandart } from 'ui/CardStandart'
import CustomPagination from 'ui/CustomPagination'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { drawerWidth } from 'variables'
import LamenessTable from '../shared/LamenessTable'
import FilterContent from '../shared/filterElements/FilterContent'
import { InspectionStatus, TreatmentStatus } from '../shared/models'

const LamenessList = () => {
	const [page, setPage] = useState<number>(1)
	const initialPageSize = 36
	const {
		activeFilters,
		openFilter,
		toggleFilter,
		setActiveFilters,
		setOpenFilter,
		handleDayChange,
		returnInitialProps,
		turnOnFilters,
		handleTabs,
		isEqualFilter,
		isEqualLamenessFilter,
		isEqualTreatmentFilter,
		isEqualInspectionFilter,
		isEqualLactationFilter,
		isEqualCowAgeFilter,
		isEqualGroupsFilter,
		isEqualFatnessFilter,
		isEqualExaminersFilter,
		isEqualDiagnosisFilter,
	} = useLamenessFilter()

	const propForTable = {
		params: activeFilters,
		page,
		setPage,
		initialPageSize,
		isFetchCowsListEnabled: !openFilter,
	}

	const {
		cowsList,
		totalCountPages,
		fetchingCowsList,
		availableTabs,
		availableTags,
	} = useCowsNextPage(propForTable)

	useEffect(() => {
		setPage(1)
	}, [activeFilters])
	return (
		<CardStandart
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				padding: '24px',
				overflowX: 'auto',
				width: `calc(100vw - ${drawerWidth}px - 80px)`,
			}}
		>
			<Box
				display={'flex'}
				flexDirection={'row'}
				justifyContent={'space-between'}
				alignItems={'center'}
			>
				<TypographyHeader>Список голов</TypographyHeader>
				<AutocompleteInput
					label={'Поиск по номеру бирки'}
					widthForInput={'400px'}
					isCheckbox
					availableList={availableTags}
					onClick={id => {
						setActiveFilters(prev => {
							const tags = prev.tags
							const findTag = availableTags.find(el => el.id === id)
							const isPresent = tags.includes(findTag.id)
							const updateTags = isPresent
								? tags.filter(item => item !== findTag.id)
								: [...tags, findTag.id]

							return {
								...prev,
								tags: updateTags,
							}
						})
					}}
					selectedValues={activeFilters.tags}
				/>
			</Box>
			<Box
				display={'flex'}
				flexDirection={'row'}
				alignItems={'flex-end'}
				gap={'8px'}
			>
				<TabsFilter
					availableTabs={availableTabs}
					onClick={key => {
						if (
							key === InspectionStatus.Expired ||
							key === InspectionStatus.Required
						) {
							handleTabs('inspectionStatus', key)
						} else if (key === TreatmentStatus.DisposeRecommended) {
							handleTabs('treatmentStatus', key)
						} else {
							returnInitialProps('all')
						}
					}}
				/>
				<FilterContent
					activeFilters={activeFilters}
					openFilter={openFilter}
					toggleFilter={toggleFilter}
					setActiveFilters={setActiveFilters}
					setOpenFilter={setOpenFilter}
					handleDayChange={handleDayChange}
					returnInitialProps={returnInitialProps}
					turnOnFilters={turnOnFilters}
					isEqualFilter={isEqualFilter}
					isEqualLamenessFilter={isEqualLamenessFilter}
					isEqualTreatmentFilter={isEqualTreatmentFilter}
					isEqualInspectionFilter={isEqualInspectionFilter}
					isEqualLactationFilter={isEqualLactationFilter}
					isEqualCowAgeFilter={isEqualCowAgeFilter}
					isEqualGroupsFilter={isEqualGroupsFilter}
					isEqualFatnessFilter={isEqualFatnessFilter}
					isEqualExaminersFilter={isEqualExaminersFilter}
					isEqualDiagnosisFilter={isEqualDiagnosisFilter}
				/>
			</Box>
			<LamenessTable
				cattlesInfo={cowsList}
				fetchingCowsList={fetchingCowsList}
			/>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
				}}
			>
				<Divider />
				<CustomPagination
					onChangeFromServer={setPage}
					count={initialPageSize}
					totalCount={totalCountPages}
				/>
			</Box>
		</CardStandart>
	)
}

export default LamenessList
