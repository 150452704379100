import { IGroupEntity } from 'models'

export function validateGroupsArray(groupsArray: IGroupEntity[]) {
	if (!groupsArray || groupsArray.length === 0) {
		return false
	}
	for (const group of groupsArray) {
		if (
			!group.id ||
			!group.title ||
			group.title.trim() === '' ||
			group.rationtitle === '' ||
			!group.cattles_qty ||
			!group.weight_per_head
		)
			return false
	}
	return true
}

export function validateGroupArray(group) {
	if (
		!group.id ||
		!group.title ||
		group.title.trim() === '' ||
		group.rationtitle === '' ||
		!group.cattles_qty ||
		!group.weight_per_head
	)
		return false
	else return true
}
