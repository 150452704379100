import { Box, Divider } from '@mui/material'

import moment from 'moment'
import { ReactComponent as FarmTable } from '../../assets/farm_table.svg'
import { ReactComponent as FoodIcon } from '../../assets/food_icon.svg'
import { ReactComponent as GroupCowLogo } from '../../assets/group_cow_logo.svg'
import { COLORS } from '../../styles/colors'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'
import { roundingNumber } from '../shared/processes'
import LinearProgressBar from './utils/LinearProgressBar'

type GroupInfoProps = {
	reachable?: number
	unreachable?: number
	total_percent?: number
	barntitle: string
	rationtitle: string
	cattles_qty: number
	description?: string
	weight_per_head: number
	lastDistribution: number
	lastNudge: number
}

const GroupSpecificData = ({
	cattles_qty,
	barntitle,
	description,
	rationtitle,
	weight_per_head,
	reachable,
	unreachable,
	total_percent,
	lastDistribution,
	lastNudge,
}: GroupInfoProps) => {
	return (
		<Box
			width={'100%'}
			height={'100%'}
			display={'flex'}
			flexDirection={'column'}
			justifyContent={'space-between'}
			gap={'12px'}
			marginTop='24px'
		>
			<Box display={'flex'} flexDirection={'row'} alignItems='center'>
				<GroupCowLogo />{' '}
				<TypographyPrimary sx={{ pl: '4px' }}>
					Голов в группе: {cattles_qty}
				</TypographyPrimary>
			</Box>

			<TypographyPrimary>Коровник: {barntitle}</TypographyPrimary>

			<TypographyPrimary>
				Описание: {description ? description : 'Очень хорошие коровы'}
			</TypographyPrimary>

			<Divider sx={{ backgroundColor: COLORS.secondary }} />
			<Box
				sx={{
					display: ' flex',
					flexDirection: 'row',
					gap: '40px',
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
					<FoodIcon />
					<TypographyPrimary>Рацион: {rationtitle}</TypographyPrimary>
				</Box>
				<Box
					sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
				>
					<TypographyPrimary>Норма ПСВ: {weight_per_head}</TypographyPrimary>
					<TypographySecondary sx={{ pl: '4px' }}>
						кг/голову
					</TypographySecondary>
				</Box>
			</Box>
			<Box display={'flex'} flexDirection='row' alignItems='center'>
				<FarmTable />{' '}
				<TypographyPrimary sx={{ pl: '4px' }}>Кормовой стол</TypographyPrimary>
				<TypographyPrimary
					sx={{ pl: '4px', color: reachable > 2 ? COLORS.black : COLORS.error }}
				>
					{reachable > 2
						? `${roundingNumber(total_percent, 0)}% после раздачи`
						: 'пустой'}
				</TypographyPrimary>
			</Box>
			<LinearProgressBar reachable={reachable} unreachable={unreachable} />
			<Box display={'flex'} flexDirection='column' gap='4px'>
				<TypographySecondary>
					Последняя раздача:{' '}
					{lastDistribution !== null
						? moment(lastDistribution).format('HH:mm, DD.MM.YYYY')
						: '-'}
				</TypographySecondary>
				<TypographySecondary>
					Последнее подталкивание:{' '}
					{lastNudge !== null
						? moment(lastNudge).format('HH:mm, DD.MM.YYYY')
						: '-'}
				</TypographySecondary>
			</Box>
		</Box>
	)
}

export default GroupSpecificData
