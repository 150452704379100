import { Box } from '@mui/material'
import { ReactComponent as HealthLogo } from 'assets/icons/health-logo.svg'
import moment from 'moment'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import InfoChipItem from 'ui/InfoChipItem'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { TypographySecondary } from 'ui/typography/TypographySecondary'

const LamenessWidjet = props => {
	const { lameness, lastMilking, isError } = props
	return (
		<CardStandart
			sx={{
				width: '100%',
				padding: '24px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
			}}
		>
			<Box display={'flex'} flexDirection={'row'} gap={'8px'} mr={'4px'}>
				<span>
					<HealthLogo
						fill={isError ? COLORS.error : COLORS.secondary}
						stroke={isError ? COLORS.error : COLORS.secondary}
					/>
				</span>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						gap: '8px',
					}}
				>
					<TypographyPrimary16Bold
						sx={{ color: isError ? COLORS.error : COLORS.black }}
					>
						{lameness}{' '}
						<span
							style={{
								color: COLORS.secondary,
								fontWeight: 400,
								fontSize: '12px',
							}}
						>
							балла
						</span>
					</TypographyPrimary16Bold>
					<TypographySecondary>Хромота</TypographySecondary>
				</Box>
			</Box>
			<Box
				display={'flex'}
				flexDirection={'column'}
				justifyContent={isError ? 'space-between' : 'flex-end'}
				gap={'8px'}
			>
				{isError && (
					<InfoChipItem
						bgColor={COLORS.deviationOpacity}
						label={'Обратите внимание'}
						labelColor={COLORS.error}
					/>
				)}

				<TypographySecondary alignSelf={'flex-end'}>
					{`замер ${moment(lastMilking).format('D.MM.YY')}`}
				</TypographySecondary>
			</Box>
		</CardStandart>
	)
}

export default LamenessWidjet
