import React from 'react';
import InputMask from 'react-input-mask';

const TimeInput = ({ value, onChange, style, onBlur, onFocus }) => {
  let mask = '12:34';
  let formatChars = {
    '1': '[0-2]',
    '2': '[0-9]',
    '3': '[0-5]',
    '4': '[0-9]'
  };
	let beforeMaskedValueChange = (newState, oldState, userInput) => {
    let { value } = newState;
    if(value.startsWith('2'))
      formatChars['2'] = '[0-3]';
    else
      formatChars['2'] = '[0-9]'; 
    return {value, selection: newState.selection};
  }
  return (
    <InputMask
      mask={mask}
      placeholder="Время"
			formatChars={formatChars}
      beforeMaskedValueChange={beforeMaskedValueChange}
      value={value}
      onChange={onChange}
      style={style}
      onFocus={onFocus}
      onBlur={onBlur}
    ></InputMask>
  );
}
export default TimeInput