import React, { useEffect, useState } from 'react'
import { usePinInput } from 'react-pin-input-hook'
import { COLORS } from '../styles/colors'
import Box from '@mui/material/Box/Box'
import Timer from '../models/Timer'
import axAPI from '../http'
import { AlertText } from './AlertText'

const PinCodeInput = props => {
  const [values, setValues] = useState(Array(4).fill(''))
  const { fields } = usePinInput({
    values,
    autoFocus: true,
    placeholder: '',
  })

	useEffect(() => {
		if (!values.includes('')) {
			patchConfirmKey();
		}
		const handleKeyDown = e => {
			if (e.key === 'Backspace') {
				e.preventDefault(); 
				const newValues = [...values];
				const filledIndex = newValues.findIndex(value => value === '');

				if (filledIndex !== -1) {
					const lastIndex = filledIndex - 1;
					newValues[lastIndex] = '';
					setValues(newValues);
				} else {
					const lastIndex = newValues.length - 1;
					if (lastIndex >= 0) {
						newValues[lastIndex] = '';
						setValues(newValues);
					}
				}
			} 
			else if (/^[0-9]$/.test(e.key)) {
				const newValues = [...values];
				const emptyIndex = newValues.findIndex(value => value === '');
				if (emptyIndex !== -1) {
					newValues[emptyIndex] = e.key;
					setValues(newValues);
				}
			}
		};
	
		document.addEventListener('keydown', handleKeyDown);
	
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [values]);

	const patchConfirmKey = () => {
		axAPI({
			method: 'PATCH',
			url: 'auth/password/confirm_key',
			data: { key: values.join('') },
		})
			.then(response => {
				props.setResponseConfirmData({
					uid: response.data.uid,
					token: response.data.token,
				})
				props.handleStepChange()
			})
			.catch(error => {
				props.setSubmitError(true)
			})
	}
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        {values.map((value, index) => (
          <Box
            key={index}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              backgroundColor:
                value !== '' ? 'transparent' : COLORS.inputBorder,
              fontSize: '18px',
              fontWeight: 'bold',
            }}
          >
            <input
							readOnly
              style={{
                caretColor:'transparent',
                width: '100%',
                height: '100%',
                border: 'none',
                background: 'none',
                textAlign: 'center',
                color: props.submitError ? COLORS.error : COLORS.main,
                fontSize: '16px',
                fontWeight: '600',
                margin: 0,
              }}
              {...fields[index]}
            />
          </Box>
        ))}
      </Box>
      {props.submitError === true && (
        <Box
          sx={{
            marginTop: '8px',
            marginBottom: props.submitError ? '0px' : '32px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <AlertText sx={{ margin: '0px', color: COLORS.error }}>
            Неверный код подтверждения
          </AlertText>
        </Box>
      )}
      <Box sx={{ marginTop: '32px' }}>
        <Timer
          postResetPassword={props.postResetPassword}
          submitError={props.submitError}
          setSubmitError={props.setSubmitError}
          setValues={setValues}
        />
      </Box>
    </Box>
  )
}

export default PinCodeInput
