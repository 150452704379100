import { IRationComponents } from 'models/IRationSystem'
import axAPI from '../http'

export default class RationsApiService {
	static postNewRation = async (data: {
		title: string
		components: IRationComponents[]
	}) => {
		return axAPI({
			method: 'POST',
			url: `/ration/`,
			data: data,
		})
	}
	static patchCurrentRation = async (data: {
		title: string
		components: IRationComponents[]
		id: number
		food_humidity: number
	}) => {
		return axAPI({
			method: 'PATCH',
			url: `/ration/${data.id}`,
			data: { title: data.title, components: data.components, food_humidity: data.food_humidity },
		})
	}
	static deleteRationById = async id => {
		return axAPI({
			method: 'DELETE',
			url: `/ration/${id}`,
		})
	}
	static patchComponent = async (id, data) => {
		return axAPI({
			method: 'PATCH',
			url: `/ration/components/${id}`,
			data: data,
		})
	}
}
