import axios from 'axios'
import { serverName } from '../variables'

const axAPI = axios.create({
	withCredentials: true,
	baseURL: serverName,
})

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
	failedQueue.forEach(prom => {
		if (error) {
			prom.reject(error)
		} else {
			prom.resolve(token)
		}
	})

	failedQueue = []
}

const refreshToken = async () => {
	const refreshToken = localStorage.getItem('refreshToken')
	if (refreshToken) {
		try {
			const response = await axios.post(serverName + 'auth/jwt/refresh/', {
				refresh: refreshToken,
			})

			const { access, refresh } = response.data
			localStorage.setItem('apiToken', access)
			localStorage.setItem('refreshToken', refresh)
			return access
		} catch (e) {
			localStorage.removeItem('apiToken')
			localStorage.removeItem('refreshToken')
			throw e
		}
	} else {
		localStorage.removeItem('apiToken')
		localStorage.removeItem('refreshToken')
		throw new Error('No refresh token available')
	}
}

axAPI.interceptors.request.use(config => {
	const apiToken = localStorage.getItem('apiToken')
	if (apiToken) {
		config.headers.Authorization = 'Bearer ' + apiToken
	}
	return config
})

axAPI.interceptors.response.use(
	response => {
		return response
	},
	async error => {
		const originalRequest = error.config
		if (error.response.status === 401 && !originalRequest._retry) {
			if (isRefreshing) {
				return new Promise((resolve, reject) => {
					failedQueue.push({ resolve, reject })
				})
					.then(token => {
						originalRequest.headers['Authorization'] = 'Bearer ' + token
						return axAPI(originalRequest)
					})
					.catch(err => {
						return Promise.reject(err)
					})
			}

			originalRequest._retry = true
			isRefreshing = true

			try {
				const newToken = await refreshToken()
				processQueue(null, newToken)
				isRefreshing = false

				originalRequest.headers['Authorization'] = 'Bearer ' + newToken
				return axAPI(originalRequest)
			} catch (e) {
				processQueue(e, null)
				isRefreshing = false
				return Promise.reject(e)
			}
		}

		return Promise.reject(error)
	}
)

export default axAPI
