import { Box } from '@mui/material'
import moment from 'moment'
import { Dispatch, SetStateAction, useState } from 'react'
import GroupDate from 'ui/datePicker/GroupDate'
import { TypographyHeaderSecondary } from 'ui/typography/TypographyHeaderSecondary'
import { ICheckupList } from '../models'
type NextExaminationBlock = {
	date: number
	setCheckupInfo: Dispatch<SetStateAction<ICheckupList>>
}

const NextExaminationBlock = (props: NextExaminationBlock) => {
	const { date, setCheckupInfo } = props
	const [selectDate, setSelectedDate] = useState(date)
	const clickDatePicker = date => {
		setSelectedDate(date)
		setCheckupInfo(prev => {
			return {
				...prev,
				next_examination_date: moment(date).valueOf(),
			}
		})
	}
	return (
		<Box
			display={'flex'}
			flexDirection={'row'}
			width={'100%'}
			alignItems={'flex-start'}
			gap={'40px'}
		>
			<Box width={'150px'}>
				<TypographyHeaderSecondary>
					Дата повторного
					<br />
					осмотра
				</TypographyHeaderSecondary>
			</Box>
			<GroupDate
				value={selectDate}
				onChange={newDate => clickDatePicker(newDate)}
			/>
		</Box>
	)
}

export default NextExaminationBlock
