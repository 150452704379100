import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import { serverName } from 'variables'

const PicturesViewer = props => {
	const { images } = props
	const pictures = images.images
	const { tagNumber, tagImage } = images

	// Функция, которая принимает фотографию и возвращает JSX элемент для нее
	// const renderPicture = (picture, index) => (
	//   <img
	//     key={index}
	//     src={URL.createObjectURL(picture)}
	//     alt={`Preview ${index}`}
	//     style={{
	//       width: '90%',
	//       height: '90%',
	//       objectFit: 'contain',
	//       borderRadius: '12px',
	//     }}
	//   />
	// );

	//Не забыть вернуть на BLOB файл
	const renderPicture = (picture, index) => {
		return (
			<img
				key={index}
				src={serverName + picture}
				alt={`Cow Pictures ${index}`}
				style={{
					width: '100%',
					height: '32%',
					objectFit: 'cover',
					borderRadius: '12px',
				}}
			/>
		)
	}

	// Создаем массив JSX элементов для фотографий
	const pictureElements = pictures?.map((picture, index) =>
		renderPicture(picture.image, index)
	)

	// Если количество фотографий меньше 3, дополняем их пустыми блоками
	const emptyDataCount = Math.max(0, 3 - pictures?.length)
	const emptyDataElements = Array.from(
		{ length: emptyDataCount },
		(_, index) => (
			<span
				key={`empty-${index}`}
				style={{
					width: '100%',
					height: '33%',
					borderRadius: '12px',
					backgroundColor: COLORS.background,
				}}
			></span>
		)
	)

	return (
		<CardStandart
			sx={{
				padding: '24px 10%',
				height: 'calc(100vh - 200px)',
				'&:hover': { boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)' },
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: !pictures
					? 'white'
					: !tagNumber || !tagImage
					? COLORS.deviationOpacity
					: COLORS.successOpacity,
				gap: '20px',
			}}
		>
			{pictures ? pictureElements.concat(emptyDataElements) : <EmptyData />}
		</CardStandart>
	)
}

export default PicturesViewer

const EmptyData = () => (
	<>
		{Array.from({ length: 3 }, (_, index) => (
			<span
				key={`empty-${index}`}
				style={{
					width: '80%',
					height: '33%',
					borderRadius: '12px',
					backgroundColor: COLORS.background,
				}}
			></span>
		))}
	</>
)
