import { useEffect } from 'react'

import { Box, Typography } from '@mui/material'
import { ReactComponent as CowCounterLogo } from 'assets/cow_counter_logo.svg'
import { ReactComponent as CattlesEditLogo } from 'assets/ration_edit_logo.svg'
import GroupHeadEditPopup from 'components/group/GroupHeadEditPopup'
import { fetchCattlesQuantity } from 'store/counterSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'

const CowCounter = props => {
	const dispatch = useAppDispatch()
	const { cattles_qty } = useAppSelector(state => state.counterReducer)
	const { permissions } = useAppSelector(state => state.authReducer)
	useEffect(() => {
		dispatch(fetchCattlesQuantity())
	}, [props.next])

	return (
		<Box sx={{ display: 'flex', pl: '16px' }}>
			<CowCounterLogo />
			<Typography
				sx={{
					fontSize: 16,
					fontWeight: 600,
					paddingBottom: '8px',
					paddingLeft: '4px',
					paddingRight: '8px',
				}}
			>
				Всего голов {cattles_qty}
			</Typography>
			{permissions.can_edit_cattle_quantity_main && (
				<CattlesEditLogo
					style={{ width: '16px', cursor: 'pointer' }}
					onClick={() => props.setNext(true)}
				/>
			)}

			<GroupHeadEditPopup
				open={props.next}
				handleClose={() => props.setNext(false)}
			/>
		</Box>
	)
}

export default CowCounter
