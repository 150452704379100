import { styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import { COLORS } from '../../styles/colors'

export const TypographyHeader14 = styled(Typography)(theme => ({
	fontWeight: 600,
	fontFamily: 'Montserrat',
	fontSize: '14px',
	lineHeight: '17px',
	color: COLORS.black,
}))
