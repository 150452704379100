import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'

import { Box, CircularProgress, CssBaseline } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { changeAvatar, changeUserData, fetchUserData } from '../store/userSlice'

import { ReactComponent as CrossCloseLogo } from '../assets/cross_close_logo.svg'
import { ReactComponent as LogoutIcon } from '../assets/icons/logout_icon.svg'
import { ReactComponent as ProfileIcon } from '../assets/profile_icon.svg'
import { ReactComponent as EditLogo } from '../assets/ration_edit_logo.svg'

import { ThemeProvider } from '@mui/system'
import InputMask from 'react-input-mask'
import { useNavigate } from 'react-router-dom'
import AboutBlock from '../components/profile/AboutBlock'
import FeedbackBlock from '../components/profile/FeedbackBlock'
import SecurityBlock from '../components/profile/SecurityBlock'
import { ProfileItem } from '../components/profile/utils/ProfileItem'
import CustomTooltip from '../components/shared/CustomTooltip'
import { formatPhoneNumber } from '../components/shared/processes'
import CustomTextField from '../components/shared/textFields/CustomTextField'
import useAuth from '../hook/useAuth'
import axAPI from '../http'
import { clearPermissions } from '../store/authSlice'
import { notifyUser } from '../store/notificationsSlice'
import { COLORS } from '../styles/colors'
import { fontTheme } from '../styles/font'

import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { ButtonUI } from '../ui/buttons/ButtonUI'
import { TypographyHeader } from '../ui/typography/TypographyHeader'
import { TypographyHeaderSecondary } from '../ui/typography/TypographyHeaderSecondary'
import { TypographyPrimary500 } from '../ui/typography/TypographyPrimary500'

const theme = fontTheme

function ProfilePage() {
	const [editData, setEditData] = useState(false)
	const dispatch = useAppDispatch()

	const { userData, userDataError, userDataPending } = useAppSelector(
		state => state.userReducer
	)
	const authState = useAuth()
	const navigate = useNavigate()
	const [newUserData, setNewUserData] = useState(userData)

	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		dispatch(fetchUserData())
			.unwrap()
			.then(userData => {
				setNewUserData(userData)
				setLoaded(true)
			})
			.catch(error => {
				setLoaded(true)
			})
	}, [])
	const saveChangeAvatar = () => {
		if (userData.avatar !== newUserData.avatar) {
			if (newUserData.avatar === null) {
				axAPI({
					method: 'PUT',
					url: '/auth/users/me/change_avatar',
					data: { avatar: null },
				}).then(response => {
					if (response.status === 201 || response.status === 200) {
						dispatch(
							notifyUser({
								title: newUserData.firstname,
								description: 'Изменения аватара успешно сохранены',
								isError: false,
							})
						)
						dispatch(changeAvatar(newUserData.avatar))
						setEditData(false)
					}
				})
			} else {
				const formData = new FormData()
				formData.append('avatar', newUserData.avatar, 'avatar.jpg')
				axAPI({
					method: 'PUT',
					url: '/auth/users/me/change_avatar',
					data: formData,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
					.then(response => {
						if (response.status === 201 || response.status === 200) {
							dispatch(
								notifyUser({
									title: newUserData.firstname,
									description: 'Изменения аватара успешно сохранены',
									isError: false,
								})
							)
							dispatch(changeAvatar(newUserData.avatarPreview))
							setEditData(false)
						}
					})
					.catch(error => {
						dispatch(
							notifyUser({
								title: newUserData.firstname,
								description: error.response.data.avatar,
								isError: true,
							})
						)
					})
			}
		}
		if (
			userData.mobile_phone !== newUserData.mobile_phone ||
			userData.email !== newUserData.email
		) {
			axAPI({
				method: 'PUT',
				url: '/auth/users/me',
				data: newUserData,
			})
				.then(response => {
					if (response.status === 201 || response.status === 200) {
						dispatch(
							notifyUser({
								title: newUserData.firstname,
								description: 'Изменения данных успешно сохранены',
								isError: false,
							})
						)
						dispatch(changeUserData(newUserData))
						setEditData(false)
					}
				})
				.catch(error => {
					dispatch(
						notifyUser({
							title: newUserData.firstname,
							description: error.message,
							isError: true,
						})
					)
				})
		}
	}

	const DetailsData = (title: string, value: string) => {
		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					gap: '8px',
					marginBottom: '12px',
				}}
			>
				<TypographyPrimary
					sx={{
						color: '#7F7F84',
					}}
				>
					{title}:
				</TypographyPrimary>
				<TypographyPrimary>{value}</TypographyPrimary>
			</Box>
		)
	}

	if (userDataPending || !userData || !newUserData || !loaded) {
		return (
			<Box
				sx={{
					marginTop: '25%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<CircularProgress
					sx={{
						'&.MuiCircularProgress-colorPrimary': { color: COLORS.main },
					}}
				/>
			</Box>
		)
	} else if (userDataError) {
		;<>{userDataError}</>
	}

	const handleAvatarUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0]
		if (file) {
			setNewUserData(prevData => ({
				...prevData,
				avatar: file,
				avatarPreview: URL.createObjectURL(file),
			}))
		}
	}

	const handleRemoveAvatar = () => {
		setNewUserData(prevData => ({
			...prevData,
			avatar: null,
		}))
	}

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'start',
					gap: '24px',
					pl: '16px',
					pr: '36px',
					width: '100%',
					minHeigth: '100vh',
				}}
			>
				<ProfileItem
					sx={{
						width: '50%',
						minHeight: 'calc(100vh - 120px)',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<TypographyHeader>Личные данные</TypographyHeader>
						<Box onClick={() => setEditData(!editData)}>
							{editData ? (
								<CrossCloseLogo style={{ cursor: 'pointer', stroke: COLORS.secondary}} />
							) : (
								<EditLogo style={{ cursor: 'pointer' }} />
							)}
						</Box>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
							marginTop: '24px',
							gap: '16px',
						}}
					>
						{editData && (
							<Box
								sx={{
									width: '89px',
									height: '32px',
									padding: '8px',
									backgroundColor: COLORS.inputBorder,
									borderRadius: '8px',
									display: 'flex',
									alignItems: 'center',
									cursor: 'pointer',
									'&:active': { opacity: '0.9' },
								}}
							>
								<input
									type='file'
									id='avatar-upload'
									accept='image/*'
									style={{ display: 'none' }}
									onChange={handleAvatarUpload}
								/>
								<label htmlFor='avatar-upload'>
									<TypographyPrimary sx={{ cursor: 'pointer' }}>
										Загрузить
									</TypographyPrimary>
								</label>
							</Box>
						)}

						{newUserData.avatar === null ? (
							<ProfileIcon />
						) : (
							<img
								src={newUserData.avatarPreview || newUserData.avatar}
								alt='Avatar'
								style={{ width: '128px', height: '128px', borderRadius: '50%' }}
							/>
						)}
						{editData && (
							<Box
								sx={{
									width: '89px',
									height: '32px',
									padding: '8px',
									backgroundColor: COLORS.deviationOpacity,
									borderRadius: '8px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									cursor: 'pointer',
									'&:active': { opacity: '0.9' },
								}}
							>
								<TypographyPrimary
									sx={{ cursor: 'pointer' }}
									onClick={handleRemoveAvatar}
								>
									Удалить
								</TypographyPrimary>
							</Box>
						)}
					</Box>
					<TypographyPrimary500
						sx={{ fontSize: '16px', textAlign: 'center', marginTop: '24px' }}
					>
						{userData.firstname} {userData.lastname}
					</TypographyPrimary500>
					<Divider sx={{ marginTop: '16px', marginBottom: '16px' }} />
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignContent: 'center',
							gap: '8px',
						}}
					>
						{DetailsData('Роль', userData.role_title)}
						<CustomTooltip
							placement={'right'}
							width={'16px'}
							height={'16px'}
							info={true}
							title={
								'Роль назначает администратор системы. У каждой роли свои функциональные возможности.'
							}
						/>
					</Box>
					{DetailsData('Должность', userData.position)}
					{editData ? (
						<Box sx={{ marginTop: '16px' }}>
							<CustomTextField
								label={'Электронная почта'}
								value={newUserData.email}
								width={'100%'}
								withLabel={true}
								error={!newUserData.email}
								helperText={
									!newUserData.email ? 'Поле не может быть пустым' : ''
								}
								onChange={e => {
									setNewUserData(prevData => ({
										...prevData,
										email: e.target.value,
									}))
								}}
							/>

							<TextField
								sx={{
									mt: '14px',
									borderRadius: '12px',
									width: '100%',
									'& label': {
										fontSize: newUserData.mobile_phone ? '16px' : '14px',
									},
									'& label.Mui-focused': {
										color: `${COLORS.main}`,
										fontSize: newUserData.mobile_phone ? '16px' : '14px',
									},
									'& .MuiInputBase-input': {
										fontSize: '14px',
										paddingLeft: '20px',
									},
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px',
										height: '54px',
										'& fieldset': {
											border: `1px solid ${COLORS.inputBorder}`,
										},
										'&:hover fieldset': {
											border: `1px solid ${COLORS.hoverInputBorder}`,
										},
										'&.Mui-focused fieldset': {
											border: `1px solid ${COLORS.main}`,
										},
									},
								}}
								id='phone'
								label='Номер телефона'
								value={newUserData.mobile_phone}
								onChange={e => {
									setNewUserData(prevData => ({
										...prevData,
										mobile_phone: '+' + e.target.value.replace(/\D/g, ''),
										username: '+' + e.target.value.replace(/\D/g, ''),
									}))
								}}
								InputProps={{
									style: {
										fontSize: '14px',
										borderRadius: '8px',
										margin: '0px',
										padding: '0px',
									},
									inputComponent: InputMask as any,
									inputProps: {
										mask: '+7 (999) - 999 - 99 - 99',
										maskChar: '',
									},
								}}
							/>
							<ButtonUI
								onClick={() => saveChangeAvatar()}
								sx={{
									width: '100%',
									height: '56px',
									fontWeight: 600,
									fontSize: '14px',
									lineHeight: '17px',
								}}
								disabled={
									// userData === newUserData ||
									JSON.stringify(userData) === JSON.stringify(newUserData) ||
									!newUserData?.email?.trim() || // Проверка на пустое поле почты
									(newUserData?.mobile_phone &&
										newUserData?.mobile_phone?.length !== 12)
								}
							>
								Сохранить
							</ButtonUI>
						</Box>
					) : (
						<>
							{DetailsData('Электронная почта', userData.email)}
							{DetailsData(
								'Номер телефона',
								formatPhoneNumber(userData.mobile_phone)
							)}
						</>
					)}
				</ProfileItem>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '24px',
						width: '50%',
						flex: 1,
					}}
				>
					<SecurityBlock />
					<AboutBlock />
					<FeedbackBlock />
					<Box
						onClick={() => {
							dispatch(clearPermissions())
							sessionStorage.removeItem('permissions')
							authState.signOutAndContinue(() =>
								navigate('/login', { replace: true })
							)
						}}
						sx={{
							width: 'max-content',
							display: 'flex',
							flexDirection: 'row',
							gap: '8px',
							alignItems: 'center',
							cursor: 'pointer',
						}}
					>
						<LogoutIcon />
						<TypographyHeaderSecondary sx={{ fontWeight: '600' }}>
							Выйти из системы
						</TypographyHeaderSecondary>
					</Box>
				</Box>
			</Box>
		</ThemeProvider>
	)
}

export default ProfilePage
