import {
	Document,
	Page,
	Path,
	StyleSheet,
	Svg,
	Text,
	View,
} from '@react-pdf/renderer'
import React, { Fragment } from 'react'
import FoodTableStateChart from './FoodTableStateChart'
import Observations from './Observations'
import ObservationsForRange from './ObservationsForRange'
import PSVChart from './PSVChart'
import TableBody from './TableBody'
import TableBodyForRangeDate from './TableBodyForRangeDate'
import TableHeader from './TableHeader'
import TableHeaderForRangeDate from './TableHeaderForRangeDate'
import WarningCardInfoAboutAnalytics from './WarningCardInfoAboutAnalytics'
import registerFonts from './utils/registerFont'

registerFonts()

const DailyAnalytic = blobFiles => {
	return (
		<Document>
			{blobFiles.map((el, index) => (
				<Fragment key={index}>
					<Page size='A4' style={styles.container}>
						<SmartFarmLogo />
						<View style={{ ...styles.flexRow, marginTop: '20px' }}>
							<Text style={styles.textBold14}>
								Аналитика кормления за{' '}
								{(el.type === 'day' && 'день') ||
									(el.type === 'week' && 'неделю') ||
									(el.type === 'month' && 'месяц') ||
									(el.type === 'year' && 'год')}
							</Text>
							<Text style={styles.textBold14}>
								{el.type === 'day'
									? el.startDate
									: `${el.startDate} - ${el.endDate}`}
							</Text>
						</View>
						<Text style={{ ...styles.textBold14, marginTop: '8px' }}>
							{el.groupTitle}
						</Text>
						{el.availabilityText && (
							<WarningCardInfoAboutAnalytics
								availabilityText={el.availabilityText}
							/>
						)}
						<Text style={{ ...styles.textBold14, marginTop: '32px' }}>
							{el.type === 'day'
								? 'Состояние кормового стола'
								: 'Результаты кормления'}
						</Text>
						{el.type === 'day'
							? el.dailyAnalytic.food_table.table.length > 0 && (
									<>
										<TableHeader />
										<TableBody table={el.dailyAnalytic.food_table.table} />
									</>
							  )
							: el.distr_table.length > 0 && (
									<>
										<TableHeaderForRangeDate />
										<TableBodyForRangeDate distr_table={el.distr_table} />
									</>
							  )}
						{el.type === 'day' && (
							<React.Fragment>
								<Observations
									observations={el.dailyAnalytic.food_table.observations}
								/>
								<View
									style={{
										borderBottom: `1px solid #DBDBDB`,
										width: '100%',
										marginTop: '20px',
									}}
								></View>
							</React.Fragment>
						)}
						<Text
							style={styles.pageNumber}
							render={({ pageNumber, totalPages }) =>
								`${pageNumber}/${totalPages}`
							}
						/>
					</Page>
					<Page size='A4' style={styles.container}>
						{el.type === 'day' ? (
							<React.Fragment>
								<Text style={styles.textBold14}>Качество подталкивания</Text>
								<Observations
									observations={el.dailyAnalytic.nudges.observations}
								/>
								<FoodTableStateChart imgURI={el.imgURIFoodTable} />
								<View
									style={{
										borderBottom: `1px solid #DBDBDB`,
										width: '100%',
										marginTop: '20px',
									}}
								></View>
								<Text style={{ ...styles.textBold14, marginTop: '30px' }}>
									Динамика ПСВ
								</Text>
								<Observations
									observations={el.dailyAnalytic.psv.observations}
								/>
								<PSVChart imgURIPSV={el.imgURIPSV} />
								<View
									style={{
										borderBottom: `1px solid #DBDBDB`,
										width: '100%',
										marginTop: '20px',
									}}
								></View>
							</React.Fragment>
						) : (
							<ObservationsForRange observations={el.observations} />
						)}

						<Text
							style={styles.pageNumber}
							render={({ pageNumber, totalPages }) =>
								`${pageNumber}/${totalPages}`
							}
						/>
					</Page>
				</Fragment>
			))}
		</Document>
	)
}

export default DailyAnalytic

const styles = StyleSheet.create({
	container: {
		padding: '36px 40px',
		fontFamily: 'Montserrat',
	},
	image: {
		width: '60px',
		height: '29px',
	},
	flexColumn: {
		display: 'flex',
		flexDirection: 'column',
	},
	flexRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	textBold14: {
		fontFamily: 'Montserrat',
		fontSize: '14px',
		lineHeight: 'normal',
		fontWeight: 600,
	},
	pageNumber: {
		position: 'absolute',
		fontSize: '8px',
		fontWeight: 400,
		color: '#7F7F84',
		bottom: 40,
		right: 40,
	},
})

const SmartFarmLogo = () => (
	<Svg style={{ width: 60, height: 29 }} viewBox='0 0 112 60'>
		<Path
			d='M59.8615 10.161L60.0116 9.45978H60.0622L60.2123 10.161L63.0343 17.2504H57.0405L59.8615 10.161ZM55.2946 21.6372H64.7802L66.2475 25.3237H71.8472L62.5193 2.62122H57.5546L48.2266 25.3237H53.8273L55.2946 21.6372Z'
			fill='#5222D0'
		/>
		<Path
			d='M100.382 25.3237H105.649V7.34221H112V2.63807H94.0129V7.34221H100.382V25.3237Z'
			fill='#5222D0'
		/>
		<Path
			d='M79.5542 7.34221H84.017C84.5855 7.34221 85.109 7.47608 85.5884 7.74194C86.0687 8.00874 86.4459 8.36729 86.7254 8.81851C87.0041 9.2688 87.1429 9.7556 87.1429 10.278C87.1429 10.8668 87.0059 11.3901 86.7329 11.846C86.4609 12.301 86.0847 12.6605 85.6043 12.9217C85.125 13.1828 84.5977 13.313 84.017 13.313H79.5542V7.34221ZM79.5542 17.7344H81.9099C82.0778 17.7344 82.2448 17.7344 82.4128 17.7344C82.5798 17.7344 82.7514 17.7344 82.9306 17.7344L86.9093 25.3237H92.9772L88.1289 16.9003C89.0108 16.5333 89.7725 16.025 90.4198 15.3734C91.0662 14.7237 91.5644 13.9636 91.9153 13.0976C92.2661 12.2289 92.4416 11.2899 92.4416 10.278C92.4416 8.77639 92.0907 7.44612 91.389 6.29091C90.6863 5.13571 89.7172 4.23607 88.4798 3.59668C87.2433 2.95729 85.8276 2.63807 84.2337 2.63807H74.2546V25.3237H79.5542V17.7344Z'
			fill='#5222D0'
		/>
		<Path
			d='M62.0183 35.3148V58.0005H67.317V43.6887L73.0847 51.8959H74.4216L80.2559 43.7383V58.0005H85.5386V35.3148H80.9248L73.7527 45.0564V45.0732L66.6818 35.3148H62.0183Z'
			fill='#5222D0'
		/>
		<Path
			d='M23.3399 49.9272L26.1613 42.8377L26.3111 42.1375H26.3618L26.5123 42.8377L29.3341 49.9272H23.3399ZM23.8544 35.2989L14.5264 58.0005H20.1267L21.594 54.3149H31.0796L32.5465 58.0005H38.1469L28.8192 35.2989H23.8544Z'
			fill='#5222D0'
		/>
		<Path
			d='M53.0329 44.5228C52.7608 44.9777 52.3846 45.3363 51.9043 45.5984C51.4249 45.8605 50.8967 45.9907 50.3169 45.9907H45.8532V40.018H50.3169C50.8855 40.018 51.408 40.1519 51.8874 40.4196C52.3677 40.6864 52.7458 41.045 53.0244 41.4953C53.304 41.9456 53.4429 42.4333 53.4429 42.9547C53.4429 43.5436 53.3059 44.0678 53.0329 44.5228ZM58.2152 45.7744C58.5661 44.9066 58.7424 43.9667 58.7424 42.9547C58.7424 41.4541 58.3906 40.1238 57.6889 38.9677C56.9862 37.8115 56.0162 36.9128 54.7797 36.2744C53.5432 35.6359 52.1266 35.3148 50.5337 35.3148H40.5542V58.0005H45.8532V50.4111H48.2108C48.3778 50.4111 48.5448 50.4111 48.7118 50.4111C48.8788 50.4111 49.0514 50.4111 49.2296 50.4111L53.2092 58.0005H59.2762L54.4288 49.577C55.3098 49.2101 56.0715 48.7017 56.7189 48.0502C57.3662 47.4005 57.8643 46.6413 58.2152 45.7744Z'
			fill='#5222D0'
		/>
		<Path
			d='M5.299 49.6435H14.5101V45.224H5.299V40.018H16.0312V35.3148H0.000244141V58.0005H5.299V49.6435Z'
			fill='#5222D0'
		/>
		<Path
			d='M27.599 11.011L33.366 19.2191H34.7029L40.5373 11.0615V25.3237H45.8204V2.63807H41.2065L34.0349 12.3796V12.3955L26.9639 2.63807H22.2996V25.3237H27.599V11.011Z'
			fill='#5222D0'
		/>
		<Path
			d='M11.9104 21.1706C11.3036 21.3597 10.5099 21.4542 9.52811 21.4542C8.7259 21.4542 8.02708 21.3466 7.43013 21.1294C6.83309 20.9122 6.35708 20.6089 6.00049 20.2204C5.6454 19.831 5.42118 19.3695 5.33243 18.8358C5.31995 18.7469 5.31226 18.6571 5.30757 18.5681C5.30241 18.4792 5.29875 18.3902 5.29875 18.3013H0C0 19.6147 0.325816 20.8448 0.977359 21.9972C1.62975 23.1468 2.67156 24.0848 4.10374 24.8066C5.5362 25.5293 7.34982 25.8916 9.545 25.8916C11.4392 25.8916 13.1008 25.6192 14.5263 25.0743C15.9523 24.5276 17.0623 23.731 17.8527 22.6806C18.6439 21.6303 19.0401 20.3646 19.0401 18.8855C19.0401 17.4728 18.6945 16.2802 18.003 15.3075C17.3126 14.3349 16.3733 13.5597 15.187 12.9793C14.0001 12.4017 12.5877 11.9514 10.9496 11.6285C9.72381 11.4075 8.74607 11.1707 8.01572 10.9207C7.285 10.6708 6.74481 10.374 6.39413 10.0277C6.04308 9.68315 5.86717 9.27217 5.86717 8.79379C5.86717 8.39312 5.96277 8.03928 6.15153 7.73409C6.34019 7.42891 6.68618 7.1752 7.1879 6.97487C7.68878 6.77547 8.38657 6.6753 9.27706 6.6753C10.1584 6.6753 10.8708 6.79139 11.4176 7.02449C11.9636 7.25852 12.3572 7.56744 12.5954 7.95126C12.8343 8.33508 12.9549 8.75543 12.9549 9.2104H18.254C18.254 7.74252 17.9234 6.47778 17.2594 5.41525C16.5957 4.35366 15.5997 3.53078 14.2675 2.94756C12.9347 2.3634 11.2956 2.07134 9.34452 2.07134C7.4169 2.07134 5.79709 2.34937 4.4881 2.90544C3.1791 3.46057 2.19771 4.24038 1.54616 5.24018C0.894244 6.24092 0.568048 7.41393 0.568048 8.76011C0.568048 10.2495 0.985803 11.474 1.82169 12.4307C2.65796 13.3856 3.67425 14.1195 4.8733 14.6316C6.07085 15.1437 7.4836 15.594 9.11035 15.9834C10.1683 16.2277 11.024 16.4702 11.6759 16.7089C12.3283 16.9467 12.8245 17.2416 13.1646 17.5926C13.5035 17.9428 13.6735 18.3697 13.6735 18.8696C13.6735 19.4481 13.5318 19.9274 13.2477 20.3122C12.9637 20.696 12.517 20.9825 11.9104 21.1706Z'
			fill='#5222D0'
		/>
	</Svg>
)
