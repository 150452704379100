import Box from '@mui/material/Box/Box'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'

import InputFormField from '../shared/textFields/InputFormField'
import EditPlanTableDistribution from './EditPlanTableDistribution'

const EditPlanPageDistribution = ({
	feedPlanDistribution,
	setAdapterFeedplan,
	setAdapterNudgePlan,
	setFeedPlanDistribution,
	setDeviationArray,
	isFirstTry,
}) => {
	const deviationValueForDistribution = feedPlanDistribution[0]?.deviation

	return (
		<Box
			sx={{
				width: 'border-box',
				padding: '24px',
				boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
				backgroundColor: 'white',
				borderRadius: '12px',
				overflowX: 'hidden',
			}}
		>
			<Box sx={{ mb: '24px' }}>
				<TypographyHeader> Раздача корма </TypographyHeader>
			</Box>
			<TypographyPrimary>
				Время раздачи корма задается отдельно для каждой группы в назначенное
				время.
			</TypographyPrimary>
			<TypographyPrimary sx={{ marginBottom: '30px' }}>
				Сумма % веса раздач всех компонентов должна составлять 100%.
			</TypographyPrimary>
			<Box
				display='flex'
				flexDirection='row'
				alignItems='center'
				sx={{ gap: '15px' }}
			>
				<TypographyPrimary>
					Допустимое отклонение во времени выполнения
				</TypographyPrimary>
				<InputFormField
					type='distr'
					value={deviationValueForDistribution}
					setAdapterNudgePlan={setAdapterNudgePlan}
					setFeedPlanDistribution={setFeedPlanDistribution}
					setDeviationArray={setDeviationArray}
				/>
			</Box>
			<Box sx={{ overflowX: 'auto' }}>
				<EditPlanTableDistribution
					feedPlanDistribution={feedPlanDistribution}
					setAdapterFeedplan={setAdapterFeedplan}
				/>
			</Box>
		</Box>
	)
}

export default EditPlanPageDistribution
