import Box from '@mui/material/Box/Box'
import Dialog from '@mui/material/Dialog/Dialog'
import { useNavigate } from 'react-router-dom'
import RationsApiService from 'services/rationApiService'
import { useAppDispatch } from '../../../store/hooks'
import { notifyUser } from '../../../store/notificationsSlice'
import { OutlinedButton } from '../../../ui/buttons/OutlinedButton'
import { TypographyHeader } from '../../../ui/typography/TypographyHeader'
import { TypographyPrimary } from '../../../ui/typography/TypographyPrimary'

const PopupSaveNewRation = props => {
	const dataForPostServer = {
		...props.rationData,
		food_humidity: 100 - props.finallyCV,
		components: props.rationData.components?.map(component => {
			return {
				dry_per_head: +component.dry_matter_kg,
				component_id: component.id,
			}
		}),
	}

	const navigate = useNavigate()

	const dispatch = useAppDispatch()
	async function onSubmitChanges() {
		RationsApiService.postNewRation(dataForPostServer)
			.then(response => {
				if (response.status === 201 || response.status === 200) {
					props.setOpenPopup(false)
					dispatch(
						notifyUser({
							title: props.rationData.title,
							description: 'Рацион успешно создан',
							isError: false,
						})
					)
					navigate('/feed/ration')
				}
			})
			.catch(error => {
				props.setOpenPopup(false)
				props.setErrorResponse(true)
			})
	}

	return (
		<Dialog
			scroll='body'
			open={props.openPopup}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			PaperProps={{
				style: {
					borderRadius: '12px',
					padding: '32px 24px',
					boxShadow: 'none',
					width: '650px',
					height: 'max-content',
				},
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					textAlign: 'center',
				}}
			>
				<TypographyHeader>Сохранение нового рациона</TypographyHeader>
				<TypographyPrimary sx={{ marginTop: '16px' }}>
					Новый рацион будет сохранен в архив.
				</TypographyPrimary>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: '24px',
					}}
				>
					<OutlinedButton
						disableRipple
						sx={{
							width: '100%',
						}}
						onClick={() => props.setOpenPopup(false)}
					>
						Вернуться к редактированию
					</OutlinedButton>
					<OutlinedButton
						disableRipple
						sx={{
							width: '100%',
						}}
						onClick={onSubmitChanges}
					>
						Сохранить
					</OutlinedButton>
				</Box>
			</Box>
		</Dialog>
	)
}

export default PopupSaveNewRation
