import { Box } from '@mui/material'
import { morphForWords } from 'components/shared/processes'
import useLamenessInfoForChart from 'hook/useLamenessInfoForChart'
import moment from 'moment'
import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { CardStandart } from 'ui/CardStandart'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import LinearChart from '../shared/LinearChart'
import LinearChartLegend from '../shared/LinearChartLegend'

const LamenessInfo = () => {
	const [dateRange, setDateRange] = useState({
		startDate: moment().startOf('day').valueOf(),
		endDate: moment().add(1, 'days').endOf('day').valueOf(),
	})
	const { dotsInfo, isLoading } = useLamenessInfoForChart(dateRange)

	const totalCattle = dotsInfo.reduce((acc, el) => {
		return acc + el.totalCattles
	}, 0)
	const onePointScore = dotsInfo.find(el => el.score === 1).totalCattles
	const totalCattlesWithoutOneScore = totalCattle - onePointScore
	const morpWord = morphForWords(totalCattlesWithoutOneScore, ['голова', 'головы', 'голов'])

	return (
		<CardStandart sx={{ padding: '24px' }}>
			{isLoading ? (
				<Skeleton height={'24px'} />
			) : (
				<>
					<TypographySecondary
						sx={{ textAlign: 'end', marginBottom: '8px' }}
					>{`Всего хромых ${(
						totalCattlesWithoutOneScore
					).toLocaleString()} ${morpWord}`}</TypographySecondary>
					<LinearChart dotsInfo={dotsInfo} totalCattle={totalCattle} />
				</>
			)}
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginTop: '24px',
					flexWrap: 'wrap',
				}}
			>
				{isLoading
					? Array.from(Array(5)).map((_, index) => (
							<Skeleton key={index} height={'47px'} width={'150px'} />
					  ))
					: dotsInfo.map(el => (
							<LinearChartLegend
								key={el.score}
								score={el.score}
								label={el.label}
								totalCattlesByCategory={el.totalCattles}
								totalCattles={totalCattle}
							/>
					  ))}
			</Box>
		</CardStandart>
	)
}

export default LamenessInfo
