import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

type FarmFillBackProps = {
	prevStep: '/' | '/ration' | '/groups' | '/fill'
	id?: string
}

const FarmFillBack = (props: FarmFillBackProps) => {
	const navigate = useNavigate()
	return (
		<Box display='flex' flexDirection='row' width='100%' gap='10px'>
			<Box
				onClick={() =>
					props.prevStep === '/fill'
						? navigate('/fill')
						: navigate(`/fill/${props.id}${props.prevStep}`)
				}
				sx={{ cursor: 'pointer' }}
			>
				<ArrowBackIosIcon color='action' />
			</Box>
			<TypographySecondary14
				onClick={() =>
					props.prevStep === '/fill'
						? navigate('/fill')
						: navigate(`/fill/${props.id}${props.prevStep}`)
				}
				sx={{ pt: '4px', cursor: 'pointer' }}
			>
				Вернуться к предыдущему шагу
			</TypographySecondary14>
		</Box>
	)
}

export default FarmFillBack
