import Box from '@mui/material/Box/Box'
import moment from 'moment'
import { CardStandart } from '../../ui/CardStandart'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import { TypographySecondary14 } from '../../ui/typography/TypographySecondary14'

const RationMainInfo = ({ rationInfo }) => {
	const groupLabelArray = rationInfo?.groups.map(el => {
		return (
			<Box
				key={el.id}
				style={{
					fontSize: '14px',
					fontWeight: '400',
					padding: '8px 16px',
					background: '#f7f7f7',
					borderRadius: '12px',
					whiteSpace: 'nowrap',
				}}
			>
				{el.title}
			</Box>
		)
	})
	return (
		<CardStandart>
			<Box sx={{ padding: '24px', display: 'flex', flexDirection: 'column' }}>
				<TypographyHeader>Основная информация</TypographyHeader>
				<Box
					sx={{
						marginTop: '16px',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						gap: '8px',
					}}
				>
					<TypographySecondary14>Дата создания:</TypographySecondary14>
					<TypographyPrimary>
						{moment(rationInfo.created_dt).format('DD.MM.YYYY')}
					</TypographyPrimary>
				</Box>
				<Box
					sx={{
						marginTop: '16px',
						display: 'flex',
						flexDirection: 'row',
						alignContent: 'center',
						gap: '8px',
					}}
				>
					{rationInfo?.groups?.length !== 0 && (
						<>
							<TypographySecondary14
								sx={{ whiteSpace: 'nowrap', paddingTop: '8px' }}
							>
								Используется в группах:
							</TypographySecondary14>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									gap: '8px',
									alignItems: 'center',
									flexWrap: 'wrap',
								}}
							>
								{groupLabelArray}
							</Box>
						</>
					)}
				</Box>
			</Box>
		</CardStandart>
	)
}

export default RationMainInfo
