import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Card, CardContent } from '@mui/material'
import { Box } from '@mui/system'
import { NavLink } from 'react-router-dom'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'

const SettingsManagmentList = () => {
	return (
		<NavLink to='management'>
			<Card
				sx={{
					boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
					backgroundColor: 'white',
					borderRadius: '12px',
					'&:hover': { boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.15)' },
				}}
			>
				<CardContent>
					<Box
						display='flex'
						flexDirection='row'
						justifyContent='space-between'
						sx={{ pt: '12px', pl: '12px' }}
					>
						<TypographyHeader>Управление пользователями</TypographyHeader>
						<ArrowForwardIosIcon sx={{ color: '#7F7F84' }} />
					</Box>
					<Box sx={{ pt: '24px', pl: '12px' }}>
						<TypographySecondary>
							Создавайте и редактируйте пользователей
						</TypographySecondary>
					</Box>
				</CardContent>
			</Card>
		</NavLink>
	)
}

export default SettingsManagmentList
