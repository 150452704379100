import {
	IFiltersLamenessTable,
	InspectionStatus,
	TreatmentStatus,
} from 'components/cattles/lamenessPage/shared/models'
import { Dispatch, useEffect, useState } from 'react'
import CattlesApiService from 'services/cattlesApiServices'
interface ICowsNextPageProps {
	page: number
	setPage?: Dispatch<number>
	activeFilter?: any
	initialPageSize: number
	fatnessPoint?: number[]
	params?: IFiltersLamenessTable
	isFetchCowsListEnabled?: boolean
}

const useCowsNextPage = (props: ICowsNextPageProps) => {
	const {
		page,
		activeFilter,
		initialPageSize,
		fatnessPoint,
		params,
		isFetchCowsListEnabled,
	} = props

	const [cowsList, setCowsList] = useState([])
	const [totalCountPages, setTotalCountPages] = useState<number>(1)
	const [fetchingCowsList, setFetchingCowsList] = useState(false)
	const [availableTags, setAvailableTags] = useState([])

	const [availableTabs, setAvailableTabs] = useState(initialTabs)

	const updateTabsDescription = (
		disposeRecommended,
		examinationOverdue,
		examinationRequired
	) => {
		setAvailableTabs(tabs =>
			tabs.map(tab => {
				switch (tab.key) {
					case InspectionStatus.Required:
						return {
							...tab,
							description:
								examinationRequired > 0 ? examinationRequired : tab.description,
						}
					case InspectionStatus.Expired:
						return {
							...tab,
							description:
								examinationOverdue > 0 ? examinationOverdue : tab.description,
						}
					case TreatmentStatus.DisposeRecommended:
						return {
							...tab,
							description:
								disposeRecommended > 0 ? disposeRecommended : tab.description,
						}
					default:
						return tab
				}
			})
		)
	}

	const fetchCowsList = (page: number) => {
		setFetchingCowsList(true)
		CattlesApiService.getCowsList(
			activeFilter === 'all' || !activeFilter ? null : activeFilter,
			fatnessPoint,
			page,
			initialPageSize,
			params
		)
			.then(response => {
				const {
					count,
					results,
					available_tags,
					dispose_recommended,
					examination_overdue,
					examination_required,
				} = response.data
				setTotalCountPages(count)
				setCowsList(results)
				const tagsWithTitleAndIndex = available_tags.map(el => {
					if (el) {
						return { title: el.toString(), id: el }
					}
				})
				setAvailableTags(tagsWithTitleAndIndex)
				updateTabsDescription(
					dispose_recommended,
					examination_overdue,
					examination_required
				)
				setFetchingCowsList(false)
			})
			.catch(error => {
				console.error('Error fetching cows list:', error)
				setFetchingCowsList(false)
			})
	}

	useEffect(() => {
		if (isFetchCowsListEnabled) {
			fetchCowsList(page)
		}
	}, [page, activeFilter, fatnessPoint, params, isFetchCowsListEnabled])

	return {
		cowsList,
		totalCountPages,
		fetchingCowsList,
		availableTags,
		availableTabs,
	}
}

export default useCowsNextPage

const initialTabs = [
	{
		key: 'all',
		title: 'Все',
		description: null,
	},
	{
		key: InspectionStatus.Required,
		title: 'Требуется осмотр',
		description: null,
	},
	{
		key: InspectionStatus.Expired,
		title: 'Осмотр просрочен',
		description: null,
	},
	{
		key: TreatmentStatus.DisposeRecommended,
		title: 'Рекомендованы на выбытие',
		description: null,
	},
]
