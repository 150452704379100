import { Box, Divider } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import { COLORS } from 'styles/colors'

type TabsFilterType = {
	onClick: (id) => void
	availableTabs: AvailableArrayType[]
	isAllFilter?: boolean
	selectedTab?: number
}
type AvailableArrayType = {
	id?: number
	title: string
	description?: string | number
	key?: string
}

interface StyledTabsProps {
	children?: React.ReactNode
	value: number
	onChange: (event: React.SyntheticEvent, newValue: number) => void
}

const StyledTabs = styled((props: StyledTabsProps) => (
	<Tabs
		{...props}
		TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
	/>
))({
	'& .MuiTabs-indicator': {
		windth: '100%',
		height: '2px',
		borderRadius: '4px',
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
	},
	'& .MuiTabs-indicatorSpan': {
		borderRadius: '4px',
		width: '100%',
		backgroundColor: COLORS.main,
	},
})

interface StyledTabProps {
	label: string
}

const StyledTab = styled((props: StyledTabProps) => (
	<Tab disableRipple {...props} />
))(({ theme }) => ({
	textTransform: 'none',
	padding: 0,
	margin: 0,
	marginRight: '16px',
	fontWeight: 600,
	fontSize: 14,
	color: COLORS.secondaryFont,
	'&.MuiTab-root': {
		minWidth: 0,
		'&.Mui-selected': {
			color: COLORS.main,
		},
		'&.Mui-focusVisible': {
			backgroundColor: 'transparent',
		},
	},
}))
const TabsFilter = (props: TabsFilterType) => {
	const { availableTabs, onClick, isAllFilter, selectedTab } = props
	const [value, setValue] = useState(0)

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue)
		const selectedId =
			availableTabs[newValue]?.id && availableTabs[newValue]?.id
		const selectedKey =
			availableTabs[newValue]?.key && availableTabs[newValue]?.key
		onClick(selectedId ? selectedId : selectedKey ? selectedKey : null)
	}

	return (
		<Box sx={{ width: '100%', position: 'relative' }}>
			<StyledTabs value={value} onChange={handleChange}>
				{/* {isAllFilter && <StyledTab label={'Все'} />} */}
				{availableTabs.map((el, index) => (
					<StyledTab
						key={index}
						label={`${el.title} ${el.description ? el.description : ''}`}
					/>
				))}
			</StyledTabs>

			<Divider
				sx={{
					position: 'absolute',
					top: '46px',
					width: '100%',
					borderWidth: '1px',
					borderRadius: '4px',
				}}
			/>
		</Box>
	)
}

export default TabsFilter
