import {
	Box,
	InputAdornment,
	OutlinedInput,
	ThemeProvider,
	useTheme,
} from '@mui/material'
import { morphForWords } from 'components/shared/processes'
import React from 'react'
import { customTheme } from 'ui/buttons/ButtonStyle'
import { TypographySecondary } from 'ui/typography/TypographySecondary'

const CowAgeContentBlock = props => {
	const { handleDayChange, cowAge } = props
	const outerTheme = useTheme()
	return (
		<>
			<ThemeProvider theme={customTheme(outerTheme)}>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'center'}
					gap={'12px'}
					marginTop={'16px'}
					flexWrap={'wrap'}
				>
					<OutlinedInput
						label='none'
						notched={false}
						type='number'
						placeholder='Год'
						value={cowAge.startOf ? cowAge.startOf : ''}
						endAdornment={
							<InputAdornment position='end'>
								{cowAge.startOf ? (
									<TypographySecondary>
										{morphForWords(cowAge.startOf, ['года', 'лет', 'лет'])}
									</TypographySecondary>
								) : (
									''
								)}
							</InputAdornment>
						}
						startAdornment={
							<InputAdornment position='start'>
								{cowAge.startOf ? (
									<TypographySecondary>от</TypographySecondary>
								) : (
									''
								)}
							</InputAdornment>
						}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleDayChange(e, 'cowAge', 'startOf')
						}
						sx={{
							position: 'relative',
							width: '120px',
							'& input': {
								textAlign: 'center',
							},
						}}
					/>

					<TypographySecondary>-</TypographySecondary>
					<OutlinedInput
						label='none'
						notched={false}
						type='number'
						placeholder='Год'
						value={cowAge.endOf ? cowAge.endOf : ''}
						endAdornment={
							<InputAdornment position='end'>
								{cowAge.endOf ? (
									<TypographySecondary>
										{morphForWords(cowAge.endOf, ['года', 'лет', 'лет'])}
									</TypographySecondary>
								) : (
									''
								)}
							</InputAdornment>
						}
						startAdornment={
							<InputAdornment position='start'>
								{cowAge.endOf ? (
									<TypographySecondary>до</TypographySecondary>
								) : (
									''
								)}
							</InputAdornment>
						}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleDayChange(e, 'cowAge', 'endOf')
						}
						sx={{
							position: 'relative',
							width: '120px',
							'& input': {
								textAlign: 'center',
							},
						}}
					/>
				</Box>
			</ThemeProvider>
		</>
	)
}

export default CowAgeContentBlock
