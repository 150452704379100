import { Box, Divider } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
const TableSkeleton = props => {
	const {
		widthForHeader,
		heightForHeader,
		widthForBody,
		heightForBody,
		rowCount,
		totalHeight,
	} = props
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '16px',
				height: totalHeight,
			}}
		>
			{(heightForHeader || widthForHeader) && (
				<>
					<Skeleton height={heightForHeader} width={widthForHeader} />
					<Divider />
				</>
			)}
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
				{Array.from({ length: rowCount }, (_, index) => (
					<Skeleton key={index} width={widthForBody} height={heightForBody} />
				))}
			</Box>
		</Box>
	)
}

export default TableSkeleton
