import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { COLORS } from '../../styles/colors'
import Search from '../../ui/Search'

import { EDeviationState } from 'models/EDeviation'
import axAPI from '../../http'
import GroupsApiService from '../../services/groupsApiService'
import RangeDate from '../../ui/datePicker/RangeDate'
import { firstDayHandler } from '../shared/processes'
import GroupList from './shared/GroupList'
import CardDeviations from './ui/CardDeviations'
import DeviationsList from './ui/DeviationsList'

const DeviationPage = () => {
	const primaryDateState = firstDayHandler()
	const [dateRange, setDateRange] = React.useState({
		startDate: primaryDateState[0],
		endDate: primaryDateState[1],
	})

	const [deviationsList, setDeviationList] = useState([])
	const [controlValue, setControlValue] = useState('')
	const [select, setSelect] = useState('')

	const [groupList, setGroupList] = useState([])

	const [currentGroupId, setCurrentGroupId] = useState('0')
	const [activeCard, setActiveCard] = useState(false)

	const onChangeCallback = value => {
		setDateRange({
			startDate: value[0],
			endDate: value[1],
		})
	}
	useEffect(() => {
		GroupsApiService.getGroupList().then(response => {
			setGroupList(response.data)
		})
	}, [])

	useEffect(() => {
		axAPI
			.get(
				`captured-deviations?start=${dateRange.startDate}&end=${
					dateRange.endDate + 86400000
				}&group=${currentGroupId === '0' ? '' : currentGroupId}`
			)
			.then(response => {
				response.data.length !== 0
					? setDeviationList(response.data)
					: setDeviationList([])
			})
	}, [dateRange, currentGroupId])

	const startDate = new Date(dateRange.startDate)
	const endDate = new Date(dateRange.endDate + 86400000)

	const daysInRange = Math.floor(
		(endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)
	)

	const filteredArrays = new Array(daysInRange).fill(null).map(() => [])
	if (deviationsList !== undefined) {
		deviationsList?.forEach(obj => {
			const createdDate = new Date(obj.created_dt)
			const daysDiff = Math.floor(
				(createdDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)
			)
			filteredArrays[daysDiff]?.push(obj)
		})
	}

	const filteredSearchList = filteredArrays
		?.reverse()
		.map(el =>
			el.filter(
				el =>
					el.title.includes(controlValue) ||
					el.description.includes(controlValue) ||
					el.group.title.includes(controlValue)
			)
		)

	const rationNumber = deviationsList?.filter(value => {
		return value.type === EDeviationState.Ration
	}).length
	const planNumber = deviationsList?.filter(value => {
		return (
			value.type === EDeviationState.Feedplan ||
			value.type === EDeviationState.FeedplanWeight
		)
	}).length

	const cameraNumber = deviationsList?.filter(value => {
		return (
			value.type === EDeviationState.Camera ||
			value.type === EDeviationState.CameraIssue
		)
	}).length

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				paddingLeft: '24px',
				paddingRight: '40px',
			}}
		>
			<Box
				display='flex'
				flexDirection='row'
				sx={{ pt: '40px', marginBottom: '24px' }}
				justifyContent='space-between'
				alignItems='center'
			>
				<Search controlValue={controlValue} setControlValue={setControlValue} />
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}
				>
					<RangeDate
						onChangeUpdateDate={onChangeCallback}
						startDate={dateRange.startDate}
						endDate={dateRange.endDate}
						currentRange={'Сегодня'}
					/>
				</Box>
			</Box>

			<GroupList
				groupList={groupList}
				currentGroupId={currentGroupId}
				setCurrentGroupId={setCurrentGroupId}
			/>

			<Typography
				sx={{
					fontSize: '12px',
					color: `${COLORS.secondaryFont}`,
					marginBottom: '16px',
				}}
			>
				{`Всего отклонений за указанный период: ${deviationsList?.length}`}
			</Typography>

			<CardDeviations
				activeCard={activeCard}
				setActiveCard={setActiveCard}
				select={select}
				setSelect={setSelect}
				rationNumber={rationNumber}
				planNumber={planNumber}
				cameraNumber={cameraNumber}
			/>

			<DeviationsList
				deviationsList={filteredSearchList}
				controlValue={controlValue}
				select={select}
				activeCard={activeCard}
			/>
		</Box>
	)
}

export default DeviationPage
