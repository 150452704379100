import { Box, CircularProgress } from '@mui/material'
import { useEffect } from 'react'

import { ReactComponent as FillRationPlusLogo } from 'assets/fill_ration_plus_logo.svg'

import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
	addNewRation,
	fetchRationComponents,
	fetchRations,
} from 'store/rationSystemSlice'
import { COLORS } from 'styles/colors'
import { ButtonUI } from 'ui/buttons/ButtonUI'
import { OutlinedButton } from 'ui/buttons/OutlinedButton'
import { TypographyHeaderExtra } from 'ui/typography/TypographyHeaderExtra'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import FarmFillRationCard from '../shared/FarmFillRationCard'
import FarmFillStepIndex from '../shared/FarmFillStepIndex'
import { validateRationArray } from '../utils/validateRationArray'
import FarmFillBack from './FarmFillBack'

const FarmFillRation = () => {
	const { rationArray, loading, rationComponents } = useAppSelector(
		state => state.rationSystemReducer
	)
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(fetchRationComponents()).then(() => dispatch(fetchRations()))
	}, [])
	const urlParams = useParams()
	const navigate = useNavigate()
	const isValid = validateRationArray(rationArray)
	return (
		<Box sx={{ pr: '100px' }}>
			<FarmFillBack prevStep='/' id={urlParams.id} />
			<FarmFillStepIndex stepNum='1' fullfied='1' />

			<Box sx={{ pt: '60px' }}>
				<TypographyHeaderExtra>Рационы</TypographyHeaderExtra>
				<TypographyPrimary sx={{ pt: '24px', pb: '32px' }}>
					Внесите рационы, которые используются на ферме.
				</TypographyPrimary>

				{loading ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<CircularProgress
							sx={{
								'&.MuiCircularProgress-colorPrimary': { color: COLORS.main },
							}}
						/>
					</div>
				) : (
					<Box display='flex' flexDirection='column' gap='24px'>
						{rationArray?.map((el, index) => (
							<FarmFillRationCard
								key={index}
								ration={el}
								rationComponents={rationComponents}
								index={index}
							/>
						))}
					</Box>
				)}

				<OutlinedButton
					onClick={() => {
						dispatch(addNewRation())
					}}
					sx={{
						width: '100%',
						height: '48px',
						mt: '24px',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Box></Box>
					Добавить рацион
					<Box sx={{ pt: '4px', pr: '16px' }}>
						<FillRationPlusLogo />
					</Box>
				</OutlinedButton>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<ButtonUI
					sx={{
						width: '600px',
						height: '55px',
						textTransform: 'none',
					}}
					onClick={() => navigate(`/fill/${urlParams.id}/groups`)}
					disabled={!isValid}
				>
					Сохранить и перейти к следующему шагу
				</ButtonUI>
			</Box>
		</Box>
	)
}

export default FarmFillRation
