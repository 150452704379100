import { styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import { COLORS } from '../../styles/colors'

export const TypographySecondaryBold16 = styled(Typography)(theme => ({
	fontWeight: 600,
	fontSize: '16px',
	lineHeight: '17px',
	color: COLORS.secondaryFont,
}))
