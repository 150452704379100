import { styled } from '@mui/material'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { COLORS } from '../../styles/colors'

export const OutlinedButton = styled(Button)(() => ({
	marginTop: '32px',
	backgroundColor: 'transparent',
	color: COLORS.main,
	height: '56px',
	width: '27.7vw',
	fontSize: '16px',
	fontWeight: '600',
	textTransform: 'none',
	border: `1px solid ${COLORS.main}`,
	borderRadius: '12px',
	'&:hover': {
		backgroundColor: COLORS.main,
		color: 'white',
		boxShadow: '0px 4px 16px rgba(82, 34, 208, 0.4)',
	},
	'&:disabled': {
		color: 'white',
		backgroundColor: COLORS.secondaryFontOpacity,
		border: `1px solid ${COLORS.secondaryFontOpacity}`,
	},
}))

export const ArrowButton = styled(IconButton)(() => ({
	position: 'absolute',
	right: '8px',
	top: '50%',
	transform: 'translateY(-50%)',
}))
