import Box from '@mui/material/Box/Box'
import Table from '@mui/material/Table/Table'
import TableBody from '@mui/material/TableBody/TableBody'
import TableCell from '@mui/material/TableCell/TableCell'
import tableCellClasses from '@mui/material/TableCell/tableCellClasses'
import TableContainer from '@mui/material/TableContainer/TableContainer'
import TableHead from '@mui/material/TableHead/TableHead'
import TableRow from '@mui/material/TableRow/TableRow'
import { ReactComponent as NudgeDeleteIcon } from '../../assets/ration_cross_logo.svg'
import { COLORS } from '../../styles/colors'
import { PlanTableCell } from '../../ui/PlanTableCell'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'
import CreateButtonTimeNudge from '../shared/CreateButtonTimeNudge'
import TimeInput from '../shared/textFields/InputTimeField'

const EditPlanTableNudge = ({ adapterNudgePlan, setAdapterNudgePlan }) => {
	const sxBorder = {
		borderBottom: `1px solid ${COLORS.secondaryFontOpacity} !important`,
	}

	const addColumn = i => {
		setAdapterNudgePlan(prevData => {
			const newArray = [...prevData]
			newArray[i].timeNudge.push('')
			return newArray
		})
	}
	const removeColumn = (index, idx) => {
		setAdapterNudgePlan(prevData => {
			const newData = [...prevData]
			newData[index].timeNudge.splice(idx, 1)
			return newData
		})
	}
	return (
		<TableContainer
			sx={{
				overflowY: 'hidden',
				'&::-webkit-scrollbar': {
					height: 4,
				},
				'&::-webkit-scrollbar-track': {
					backgroundColor: '#DBDBDB',
					borderRadius: 12,
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: '#5222D0',
					borderRadius: 12,
				},
			}}
		>
			<Table
				sx={{
					width: '100%',
					[`& .${tableCellClasses.root}`]: {
						whiteSpace: 'nowrap',
						borderBottom: 'none',
					},
				}}
				aria-label='simple table'
			>
				<TableHead sx={sxBorder}>
					<TableRow>
						<PlanTableCell>Группа</PlanTableCell>
						<PlanTableCell>Действия</PlanTableCell>

						<TableCell sx={{ position: 'relative' }}>
							<Box>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										whiteSpace: 'nowrap',
									}}
								>
									<TypographySecondary>Подталкивание</TypographySecondary>
								</Box>
								<Box
									sx={{
										position: 'absolute',
										top: '55px',
										width: '225px',
									}}
								>
									<TypographySecondary>Время</TypographySecondary>
								</Box>
							</Box>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell sx={{ padding: '8px' }}></TableCell>
					</TableRow>
					{adapterNudgePlan?.map((group, i) => (
						<TableRow key={i}>
							<TableCell
								component='th'
								scope='row'
								align='left'
								sx={{ width: '200px' }}
							>
								<Box
									display='flex'
									flexDirection='column'
									justifyContent='center'
									sx={{
										borderLeft: `2px solid ${COLORS.icon}`,
										pl: '12px',
										height: '54px',
									}}
								>
									<Box
										display='flex'
										flexDirection='row'
										sx={{ pb: '12px', alignItems: 'center' }}
									>
										<TypographyPrimary sx={{ whiteSpace: 'nowrap' }}>
											{group.groupName}
										</TypographyPrimary>
										<TypographySecondary
											sx={{ ml: '3px', whiteSpace: 'nowrap' }}
										>
											{group.barn}
										</TypographySecondary>
									</Box>
									<TypographyPrimary sx={{ color: COLORS.secondary }}>
										{`Голов: ${group.cattles_qty}`}
									</TypographyPrimary>
								</Box>
							</TableCell>
							<TableCell sx={{ width: '150px' }}>
								<Box display='flex' flexDirection='row' alignItems='center'>
									<Box
										display='flex'
										flexDirection='column'
										justifyContent='center'
										sx={{ pt: '12px' }}
									>
										{group.time?.map((plan, index) => (
											<Box
												display='flex'
												flexDirection='row'
												alignItems='center'
												sx={{ pb: '2px', pl: '4px' }}
												key={`plan_time${index}`}
											>
												<TypographyPrimary
													sx={{
														color: COLORS.secondaryFont,
														whiteSpace: 'nowrap',
														pr: '4px',
													}}
												>
													{plan === '' ? plan : 'P' + (index + 1) + ':'}
												</TypographyPrimary>
												<TypographyPrimary sx={{ whiteSpace: 'nowrap' }}>
													{plan === '' ? <></> : plan}
												</TypographyPrimary>
											</Box>
										))}
										{group.timeCleaning?.map((plan, index) => (
											<Box
												display='flex'
												flexDirection='row'
												alignItems='center'
												sx={{ pb: '2px', pl: '4px' }}
												key={`plan_time${index}`}
											>
												<TypographyPrimary
													sx={{
														color: COLORS.secondaryFont,
														whiteSpace: 'nowrap',
														pr: '4px',
													}}
												>
													{plan === '' ? <></> : 'У' + (index + 1) + ':'}
												</TypographyPrimary>
												<TypographyPrimary sx={{ whiteSpace: 'nowrap' }}>
													{plan === '' ? <></> : plan}
												</TypographyPrimary>
											</Box>
										))}
									</Box>
								</Box>
							</TableCell>
							{group.timeNudge?.map((time, idx) => (
								<TableCell align='justify' key={idx} sx={{ width: '150px' }}>
									<Box sx={{ position: 'relative' }}>
										<TimeInput
											value={time}
											onChange={e => {
												const newValue = e.target.value
												setAdapterNudgePlan(prevData => {
													const newData = [...prevData]
													newData[i].timeNudge[idx] = newValue
													return newData
												})
											}}
											onFocus={e => (e.target.style.borderColor = 'black')}
											onBlur={e => (e.target.style.borderColor = '#DBDBDB')}
											style={{
												width: '120px',
												height: '50px',
												border: '1px solid',
												borderColor: '#DBDBDB',
												paddingLeft: '5px',
												borderRadius: '12px',
												transition: 'border-color 0.1s ease-in-out',
												textAlign: 'center',
											}}
										/>
										<NudgeDeleteIcon
											onClick={() => removeColumn(i, idx)}
											style={{
												position: 'absolute',
												top: '8px',
												right: '8px',
												width: '16px',
												height: '16px',
												cursor: 'pointer',
											}}
										/>
									</Box>
								</TableCell>
							))}
							<TableCell>
								<CreateButtonTimeNudge onClick={() => addColumn(i)} />
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default EditPlanTableNudge
