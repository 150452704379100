import { useState } from 'react'
import { COLORS } from 'styles/colors'
import {
	ColumnTypes,
	IInspection,
	ILactation,
	ILameness,
	ITreatment,
	InspectionStatus,
	LactationStatus,
	LamenessStatus,
	LamenessTypeChart,
	PositionHoof,
	TreatmentStatus,
} from '../models'

export const initialActiveColumn: ColumnTypes[] = [
	{
		title: 'День лактации',
		isShowColumn: true,
	},
	{
		title: 'Диагноз',
		isShowColumn: true,
	},
	{
		title: 'Статус лечения',
		isShowColumn: true,
	},
	{
		title: 'Статус осмотра',
		isShowColumn: true,
	},
	{
		title: 'Возраст коровы',
		isShowColumn: false,
	},
	{
		title: 'Группа',
		isShowColumn: false,
	},
	{
		title: 'Статус по лактации',
		isShowColumn: false,
	},
	{
		title: 'Упитанность, балл',
		isShowColumn: false,
	},
	{
		title: 'Сотрудник',
		isShowColumn: false,
	},
]

export const titleOfMainFilters = [
	{ key: 'lameness', title: 'Хромота', id: 0 },
	{ key: 'treatmentStatus', title: 'Статус лечения', id: 1 },
	{ key: 'inspectionStatus', title: 'Статус осмотра', id: 2 },
	{ key: 'diagnosis', title: 'Диагноз', id: 3 },
	{ key: 'cowAge', title: 'Возраст коровы', id: 4 },
	{ key: 'groups', title: 'Группа', id: 5 },
	{ key: 'lactation', title: 'Лактация', id: 6 },
	{ key: 'fatness', title: 'Упитанность, балл', id: 7 },
	{ key: 'examiners', title: 'Сотрудник', id: 8 },
]

export const lamenessStatuses: ILameness[] = [
	{ key: LamenessStatus.One, title: '1 балл', id: 0 },
	{ key: LamenessStatus.Two, title: '2 балла', id: 1 },
	{ key: LamenessStatus.Three, title: '3 балла', id: 2 },
	{ key: LamenessStatus.Four, title: '4 балла', id: 3 },
	{ key: LamenessStatus.Five, title: '5 баллов', id: 4 },
]

export const treatmentsStatuses: ITreatment[] = [
	{ key: TreatmentStatus.NotPrescribed, title: 'Не назначено', id: 0 },
	{ key: TreatmentStatus.Suspended, title: 'Приостановлено', id: 1 },
	{ key: TreatmentStatus.Treated, title: 'Лечится', id: 2 },
	{ key: TreatmentStatus.Completed, title: 'Завершено', id: 3 },
	{ key: TreatmentStatus.NotRequired, title: 'Не требуется', id: 4 },
	{
		key: TreatmentStatus.DisposeRecommended,
		title: 'Рекомендована на выбытие',
		id: 5,
	},
]

export const inspectionStatuses: IInspection[] = [
	{ key: InspectionStatus.Required, title: 'Требуется осмотр', id: 0 },
	{ key: InspectionStatus.Planned, title: 'Планируется', id: 1 },
	{ key: InspectionStatus.Expired, title: 'Просрочен', id: 2 },
	{ key: InspectionStatus.Completed, title: 'Проведен', id: 3 },
	{ key: InspectionStatus.NotPlanned, title: 'Не планируется', id: 4 },
]

export const lactationStatuses: ILactation[] = [
	{ key: LactationStatus.Lactating, title: 'Дойная', id: 0 },
	{ key: LactationStatus.NonLactating, title: 'Сухостойная', id: 1 },
	{ key: LactationStatus.DefectiveMilk, title: 'Бракованное', id: 2 },
	{ key: LactationStatus.CommercialMilk, title: 'Товарное', id: 3 },
]

const treatmentStatusTranslations: { [key in TreatmentStatus]: string } = {
	[TreatmentStatus.NotPrescribed]: 'Не назначено',
	[TreatmentStatus.Suspended]: 'Приостановлено',
	[TreatmentStatus.Treated]: 'Лечится',
	[TreatmentStatus.Completed]: 'Завершено',
	[TreatmentStatus.NotRequired]: 'Не требуется',
	[TreatmentStatus.DisposeRecommended]: 'Рекомендована на выбытие',
}

const inspectionStatusTranslations: { [key in InspectionStatus]: string } = {
	[InspectionStatus.Required]: 'Требуется осмотр',
	[InspectionStatus.Planned]: 'Планируется',
	[InspectionStatus.Expired]: 'Просрочен',
	[InspectionStatus.Completed]: 'Проведён',
	[InspectionStatus.NotPlanned]: 'Не планируется',
}

const positionHoofTranslations: { [key in PositionHoof]: string } = {
	[PositionHoof.Front_Left]: 'Переднее левое',
	[PositionHoof.Front_Right]: 'Переднее правое',
	[PositionHoof.Rear_Left]: 'Заднее левое',
	[PositionHoof.Rear_Right]: 'Заднее правое',
	[PositionHoof.All]: 'Все',
}
export const initialChartInfo = [
	{
		label: '1 балл',
		color: COLORS.black,
		score: 1,
		category: [],
		timestamp: [],
		totalCattles: 0,
	},
	{
		label: '2 балла',
		color: COLORS.health,
		score: 2,
		category: [],
		timestamp: [],
		totalCattles: 0,
	},
	{
		label: '3 балла',
		color: COLORS.icon,
		score: 3,
		category: [],
		timestamp: [],
		totalCattles: 0,
	},
	{
		label: '4 балла',
		color: COLORS.error30,
		score: 4,
		category: [],
		timestamp: [],
		totalCattles: 0,
	},
	{
		label: '5 баллов',
		color: COLORS.error,
		score: 5,
		category: [],
		timestamp: [],
		totalCattles: 0,
	},
]
export const getTranslationStatus = (
	enumValue: TreatmentStatus | InspectionStatus | PositionHoof
): string => {
	if (treatmentStatusTranslations.hasOwnProperty(enumValue)) {
		return treatmentStatusTranslations[enumValue as TreatmentStatus]
	}
	if (inspectionStatusTranslations.hasOwnProperty(enumValue)) {
		return inspectionStatusTranslations[enumValue as InspectionStatus]
	}
	if (positionHoofTranslations.hasOwnProperty(enumValue)) {
		return positionHoofTranslations[enumValue as PositionHoof]
	}
	return 'Неизвестный статус'
}

export const getSeriesData = (dotsInfo, visibleSeries, selectTypeOfChart) => {
	if (selectTypeOfChart === LamenessTypeChart.PointType) {
		return dotsInfo
			.filter((dots, index) => dots.score !== 1 )
			.map(el => ({
				name: el.label,
				data: el.category,
			}))
	} else {
		const totalDays = dotsInfo[0].category.length
		const summedData = Array(totalDays).fill(0)

		dotsInfo.forEach(el => {
			el.category.forEach((value, index) => {
				summedData[index] += value
			})
		})

		return [
			{
				name: 'Общее количество голов',
				data: summedData,
			},
		]
	}
}

export const updateSeriesVisibilityBySelector = (visibleSeries) => {
	document.querySelectorAll('.apexcharts-line-series').forEach(el => {
		Array.from(el.children).forEach((child, childIndex) => {
			const childElement = child as HTMLElement
			childElement.style.display = visibleSeries[childIndex + 1]
				? 'block'
				: 'none'
		})
	})
}

export const useVisibleSeries = dotsInfo => {
	const [visibleSeries, setVisibleSeries] = useState(
		dotsInfo.map(dots => dots.score !== 1)
	)

	const handleCheckboxChange = index => {
		setVisibleSeries(prevVisibleSeries =>
			prevVisibleSeries.map((visible, i) => (i === index ? !visible : visible))
		)
	}

	return [visibleSeries, handleCheckboxChange]
}

export const getColorByScoreForCrytical = (score: number, percent: number) => {
	switch (score) {
		case 1:
			return percent < 75 ? COLORS.error : COLORS.black
		case 2:
			return percent > 15 ? COLORS.error : COLORS.black
		case 3:
			return percent > 9 ? COLORS.error : COLORS.black
		case 4:
		case 5:
			return percent > 0.5 ? COLORS.error : COLORS.black
		default:
			return COLORS.black
	}
}

export const getColorByScore = score => {
	switch (score) {
		case 1:
			return COLORS.main
		case 2:
			return COLORS.health
		case 3:
			return COLORS.secondary
		case 4:
			return COLORS.error30
		case 5:
			return COLORS.error
		default:
			return COLORS.secondary
	}
}
