import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { COLORS } from '../../styles/colors'
import { ReactComponent as CrossCloseLogo } from '../../assets/cross_close_logo.svg'

const DeleteButton = ({ children }) => {
	const [hovered, setHovered] = useState(false)

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: hovered ? '95px' : '32px',
				height: '32px',
				backgroundColor: `${COLORS.background}`,
				borderRadius: hovered ? '12px' : '50%',
				cursor: 'pointer',
				transition: 'ease-in-out .1s',
			}}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			{hovered ? (
				<Typography
					sx={{
						padding: '8px 16px',
						fontWeight: '400',
						fontSize: '14px',
						lineHeight: '16.8px',
					}}
				>
					{children}
				</Typography>
			) : (
				<CrossCloseLogo style={{ width: '14px', height: '14px', stroke: COLORS.secondary}} />
			)}
		</Box>
	)
}

export default DeleteButton
