import Box from '@mui/material/Box/Box'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import Table from '@mui/material/Table/Table'
import TableBody from '@mui/material/TableBody/TableBody'
import TableCell from '@mui/material/TableCell/TableCell'
import tableCellClasses from '@mui/material/TableCell/tableCellClasses'
import TableContainer from '@mui/material/TableContainer/TableContainer'
import TableHead from '@mui/material/TableHead/TableHead'
import TableRow from '@mui/material/TableRow/TableRow'
import { pdf } from '@react-pdf/renderer'
import { ReactComponent as FilterArray } from 'assets/filter_array.svg'
import { ReactComponent as CheckBox } from 'assets/icons/checkBox.svg'
import CheckBoxActive from 'assets/icons/checkBoxActive'
import { ReactComponent as ClipBoard } from 'assets/icons/clipboard-logo.svg'
import moment from 'moment'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import AnalyticApiServices from 'services/analyticApiService'
import GroupsApiService from 'services/groupsApiService'
import { useAppDispatch } from 'store/hooks'
import { notifyUser } from 'store/notificationsSlice'
import { COLORS } from 'styles/colors'
import Preloader from 'ui/Preloader'
import SelectSmall from 'ui/menu/SelectForm'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondaryBold14 } from 'ui/typography/TypographySecondaryBold14'
import axAPI from '../../http'
import DailyAnalytic from './pdf-generate/DailyAnalytic'
import { getDataUri } from './pdf-generate/utils/getDataUri'
import { renderGraph } from './pdf-generate/utils/renderTwoCharts'

const TableOfFeeding = props => {
	const [isLoadingGenerate, setIsLoadingGenerate] = useState<number>(undefined)
	const dispatch = useAppDispatch()
	const generatePDFAndRenderGraph = async (
		type,
		index,
		groupId,
		startDate,
		endDate,
		groupTitle,
		addInStateForGeneratePDF
	) => {
		const formatterDate = moment(startDate).format('YYYY-MM-DD')
		try {
			if (type === 'day') {
				const dailyAnalitycs = await AnalyticApiServices.getDailyAnalytic(
					groupId,
					formatterDate
				)
				const graphData = await axAPI
					.get(dailyAnalitycs.data.nudges.graph_url)
					.then(response => {
						const data = response.data
						const firstItem = data[0]
						const firstObject = {
							...firstItem,
							start_dt: startDate,
						}
						const updatedData = data.map((item, index) => {
							if (index === 0) {
								return firstObject
							}
							return item
						})

						const lastTime = moment(startDate).endOf('day')
						const newObject = {
							start_dt: lastTime,
							t_feed: null,
							r_feed: null,
							ur_feed: null,
							density: 0,
						}
						updatedData.push(newObject)
						return updatedData
					})

				const psvData = await axAPI.get(dailyAnalitycs.data.psv.graph_url)

				const medianPsv = await GroupsApiService.getMedianPsvByGroupId(groupId)
				const chartContainer = document.getElementById('analyticChart')
				ReactDOM.unmountComponentAtNode(chartContainer)
				ReactDOM.render(
					renderGraph(
						graphData,
						dailyAnalitycs.data.nudges.layered_graph,
						psvData.data,
						medianPsv.data,
						startDate
					),
					chartContainer,
					() => {
						getDataUri('foodStateAnalytic').then(resultFood => {
							getDataUri('psvAnalytic').then(resultPsv => {
								if (!addInStateForGeneratePDF) {
									const objWithData = [
										{
											dailyAnalytic: dailyAnalitycs.data,
											availabilityText: dailyAnalitycs.data.availability_text,
											startDate: moment(startDate).format('DD.MM.YY'),
											endDate: moment(endDate).format('DD.MM.YY'),
											groupTitle,
											imgURIFoodTable: resultFood,
											imgURIPSV: resultPsv,
											type,
										},
									]
									const pdfBlob = pdf(DailyAnalytic(objWithData)).toBlob()
									pdfBlob.then(blob => {
										const pdfUrl = URL.createObjectURL(blob)
										window.open(pdfUrl, '_blank')
									})
								} else {
									const objWithDataForPDF = {
										type,
										index,
										dailyAnalytic: dailyAnalitycs.data,
										availabilityText: dailyAnalitycs.data.availability_text,
										startDate: moment(startDate).format('DD.MM.YY'),
										endDate: moment(endDate).format('DD.MM.YY'),
										groupTitle,
										imgURIFoodTable: resultFood,
										imgURIPSV: resultPsv,
									}

									props.setBlobFiles(prevState => {
										return [...prevState, objWithDataForPDF]
									})
								}
							})
						})
					}
				)
			} else {
				const rangeAnalytics = await AnalyticApiServices.getRangeDateAnalytic(
					groupId,
					startDate,
					endDate
				)

				if (!addInStateForGeneratePDF) {
					const objWithData = [
						{
							index,
							startDate: moment(startDate).format('DD.MM.YY'),
							endDate: moment(endDate).format('DD.MM.YY'),
							observations: rangeAnalytics.data.observations,
							distr_table: rangeAnalytics.data.distributions_table,
							availabilityText: rangeAnalytics.data.availability_text,
							groupTitle,
							type,
						},
					]
					const pdfBlob = pdf(DailyAnalytic(objWithData)).toBlob()
					pdfBlob.then(blob => {
						const pdfUrl = URL.createObjectURL(blob)
						window.open(pdfUrl, '_blank')
					})
				} else {
					const objWithData = {
						index,
						startDate: moment(startDate).format('DD.MM.YY'),
						endDate: moment(endDate).format('DD.MM.YY'),
						observations: rangeAnalytics.data.observations,
						distr_table: rangeAnalytics.data.distributions_table,
						availabilityText: rangeAnalytics.data.availability_text,
						groupTitle,
						type,
					}
					props.setBlobFiles(prevState => {
						return [...prevState, objWithData]
					})
				}
			}
		} catch (error) {
			dispatch(
				notifyUser({
					title: `Генерация PDF файла - ${groupTitle}`,
					description: error.message,
					isError: true,
				})
			)
		}
	}

	const toggleAllCheckboxes = () => {
		const allIndexes = props.analyticForFeeding?.reduce((acc, el, index) => {
			if (el.available === true) {
				acc.push(index)
			}
			return acc
		}, [])
		if (props.selectedCheckbox.length === allIndexes.length) {
			props.setSelectedCheckbox([])
			props.setBlobFiles([])
		} else {
			props.setSelectedCheckbox(allIndexes)
			for (let i = 0; i <= allIndexes.length - 1; i++) {
				props.setBlobFiles([])
				generatePDFAndRenderGraph(
					props.analyticForFeeding[allIndexes[i]].type,
					i,
					props.analyticForFeeding[allIndexes[i]].group_id,
					props.analyticForFeeding[allIndexes[i]].startDate,
					props.analyticForFeeding[allIndexes[i]].endDate,
					props.analyticForFeeding[allIndexes[i]].group_title,
					true
				)
			}
		}
	}

	const toggleCheckbox = index => {
		if (props.selectedCheckbox.includes(index)) {
			props.setSelectedCheckbox(
				props.selectedCheckbox.filter(item => item !== index)
			)
			const filteredBlobFiles = props.blobFiles.filter(
				item => item.index !== index
			)
			props.setBlobFiles(filteredBlobFiles)
		} else {
			props.setSelectedCheckbox([...props.selectedCheckbox, index])
			generatePDFAndRenderGraph(
				props.analyticForFeeding[index].type,
				index,
				props.analyticForFeeding[index].group_id,
				props.analyticForFeeding[index].startDate,
				props.analyticForFeeding[index].endDate,
				props.analyticForFeeding[index].group_title,
				true
			)
		}
	}
	const handleReverseList = () => {
		const reversedArray = [...props.analyticForFeeding].reverse()
		props.setVisibleAnalytics(reversedArray)
	}
	const handleClickPDF = (
		available,
		type,
		index,
		groupId,
		startDate,
		endDate,
		groupTitle,
		isAddInStateForGeneratePDF
	) => {
		if (available) {
			setIsLoadingGenerate(index)
			generatePDFAndRenderGraph(
				type,
				index,
				groupId,
				startDate,
				endDate,
				groupTitle,
				isAddInStateForGeneratePDF
			)
				.then(() => {
					setIsLoadingGenerate(undefined)
				})
				.catch(error => {
					console.log('Ошибка при генерации данных:', error.message)
				})
				.finally(() => {
					setIsLoadingGenerate(undefined)
				})
		} else {
			dispatch(
				notifyUser({
					title: 'Нет данных',
					description:
						type === 'day'
							? `В течение всего дня (${moment(startDate).format(
									'DD.MM.YY'
							  )}) отсутствовала связь 
					с камерой и данные не фиксировались.`
							: `В период (${moment(startDate).format('DD.MM.YY')}-${moment(
									endDate
							  ).format(
									'DD.MM.YY'
							  )}) отсутствовала связь с камерой и данные не фиксировались.`,
					isWarning: true,
				})
			)
		}
	}
	const typeForFilter = [
		{ type: 'year', value: 'Год' },
		{ type: 'month', value: 'Месяц' },
		{ type: 'week', value: 'Неделя' },
		{ type: 'day', value: 'День' },
	]

	return (
		<TableContainer
			sx={{
				overflowY: 'auto',
				overflowX: 'hidden',
				height: 'calc(100vh - 350px)',
				'&::-webkit-scrollbar': {
					width: 4,
				},
				'&::-webkit-scrollbar-track': {
					backgroundColor: '#DBDBDB',
					borderRadius: 12,
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: '#5222D0',
					borderRadius: 12,
				},
			}}
		>
			<Table stickyHeader>
				<TableHead
					sx={{
						[`& .${tableCellClasses.root}`]: {
							backgroundColor: '#fff',
						},
					}}
				>
					<TableRow>
						<TableCell sx={{ width: '1%' }}>
							<Checkbox
								onClick={() => toggleAllCheckboxes()}
								sx={{
									color: COLORS.inputBorder,
									'&.MuiCheckbox-root': {
										margin: '0px',
										padding: '0px',
										width: '20px',
										height: '20px',
										'&.Mui-checked': {
											color: COLORS.main,
										},
									},
								}}
								disableRipple
								icon={<CheckBox />}
								checkedIcon={<CheckBoxActive />}
								checked={
									props.selectedCheckbox.length ===
										props.analyticForFeeding?.length &&
									props.selectedCheckbox.length !== 0
								}
							/>
						</TableCell>
						<TableCell sx={{ width: '20%' }}>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									gap: '8px',
									alignItems: 'center',
								}}
							>
								<TypographySecondary>Дата/период</TypographySecondary>
								<FilterArray
									onClick={handleReverseList}
									style={{ cursor: 'pointer' }}
								/>
							</Box>
						</TableCell>
						<TableCell sx={{ width: '40%' }}>
							<TypographySecondary>Название файла</TypographySecondary>
						</TableCell>
						<TableCell sx={{ width: '15%' }}>
							<TypographySecondary>Группа</TypographySecondary>
						</TableCell>
						<TableCell sx={{ width: '20%' }}>
							<SelectSmall
								typeArray={typeForFilter}
								state={props.setActiveFilterTypeSelect}
								isCheckbox
								isMultiplaySelect
								isClearIcon
							/>
						</TableCell>
						<TableCell sx={{ width: '15%' }}>
							<TypographySecondary>Действия</TypographySecondary>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.isLoading ? (
						<TableRow
							sx={{
								height: '100px',
								[`& .${tableCellClasses.root}`]: {
									borderBottom: 'none',
								},
							}}
						>
							<TableCell colSpan={6}>
								<Preloader />
							</TableCell>
						</TableRow>
					) : props.analyticForFeeding?.length !== 0 ? (
						<>
							{props.analyticForFeeding?.map((el, index) => (
								<TableRow
									key={index}
									sx={{
										height: '34px',
										'&:hover': {
											background: COLORS.mainSecondaryOpacity,
										},
										[`& .${tableCellClasses.root}`]: {
											borderBottom: 'none',
										},
									}}
								>
									<TableCell>
										{el.available && (
											<Checkbox
												onClick={() => toggleCheckbox(index)}
												sx={{
													color: COLORS.inputBorder,
													'&.MuiCheckbox-root': {
														margin: '0px',
														padding: '0px',
														width: '20px',
														height: '20px',
														'&.Mui-checked': {
															color: COLORS.main,
														},
													},
												}}
												disableRipple
												icon={<CheckBox />}
												checkedIcon={<CheckBoxActive />}
												checked={props.selectedCheckbox.includes(index)}
											/>
										)}
									</TableCell>
									<TableCell>
										<TypographyPrimary>{el.date_col}</TypographyPrimary>
									</TableCell>
									<TableCell>
										<TypographyPrimary>{el.filename}</TypographyPrimary>
									</TableCell>
									<TableCell>
										<TypographyPrimary>{el.group_title}</TypographyPrimary>
									</TableCell>
									<TableCell>
										<Box
											sx={{
												width: 'max-content',
												borderRadius: '12px',
												padding: '6px 16px',
												backgroundColor:
													el.available === false
														? COLORS.inputBorder
														: el.type === 'year'
														? COLORS.orange
														: el.type === 'month'
														? COLORS.blueLight
														: el.type === 'week'
														? COLORS.pink
														: el.type === 'day'
														? COLORS.greenLight
														: '',
											}}
										>
											<TypographyPrimary sx={{ color: '#fff' }}>
												{el.type === 'year'
													? 'Год'
													: el.type === 'month'
													? 'Месяц'
													: el.type === 'week'
													? 'Неделя'
													: el.type === 'day'
													? 'День'
													: ''}
											</TypographyPrimary>
										</Box>
									</TableCell>
									<TableCell
										onClick={() =>
											handleClickPDF(
												el.available,
												el.type,
												index,
												el.group_id,
												el.startDate,
												el.endDate,
												el.group_title,
												false
											)
										}
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											gap: '4px',
											cursor: 'pointer',
										}}
									>
										{isLoadingGenerate === index ? (
											<Preloader size={24} />
										) : (
											<ClipBoard style={{ opacity: el.available ? 1 : 0.2 }} />
										)}

										<TypographyPrimary
											sx={{
												opacity: el.available ? 1 : 0.2,
											}}
										>
											PDF
										</TypographyPrimary>
									</TableCell>
								</TableRow>
							))}
						</>
					) : (
						<TableRow>
							<TableCell colSpan={6} sx={{ textAlign: 'center' }}>
								<TypographySecondaryBold14 style={{ verticalAlign: 'middle' }}>
									По заданным параметрам аналитики не найдено
								</TypographySecondaryBold14>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default TableOfFeeding
