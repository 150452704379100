import Box from '@mui/material/Box/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton/IconButton'
import { useState } from 'react'
import { ReactComponent as ArrowCloseDone } from '../../assets/arrow_close_logo.svg'
import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg'
import { ReactComponent as LockIcon } from '../../assets/icons/lock_icon.svg'
import useAuth from '../../hook/useAuth'
import { resetInputError } from '../../store/authSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import PasswordInput from '../../ui/PasswordInput'
import { OutlinedButton } from '../../ui/buttons/OutlinedButton'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { TypographySecondary14 } from '../../ui/typography/TypographySecondary14'
import { ProfileItem } from './utils/ProfileItem'
import { COLORS } from 'styles/colors'

const SecurityBlock = () => {
	const authState = useAuth()
	const [newPassword, setNewPassword] = useState('')
	const [confirmNewPassword, setConfirmNewPassword] = useState('')
	const [open, setOpen] = useState(false)
	const { passwordError } = useAppSelector(state => state.authReducer)
	const dispatch = useAppDispatch()
	const setPasswordHandler = () => {
		authState.setPasswordAndContinue(confirmNewPassword, newPassword)
	}
	const handleClick = () => {
		setOpen(!open)
	}
	return (
		<Box sx={{ width: '100%', minHeight: 'auto' }}>
			<ProfileItem>
				<Box
					onClick={handleClick}
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						cursor: 'pointer',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '8px',
						}}
					>
						<LockIcon />
						<TypographyHeader>Безопасность</TypographyHeader>
					</Box>
					<IconButton
						disableRipple
						disableTouchRipple
						sx={{
							padding: '0px',
							'&:hover': { backgroundColor: 'transparent' },
						}}
					>
						{open ? <ArrowOpenDone /> : <ArrowCloseDone stroke={COLORS.icon}/>}
					</IconButton>
				</Box>
				<TypographySecondary14 sx={{ marginTop: '16px' }}>
					Сменить пароль
				</TypographySecondary14>
				<Collapse in={open} timeout='auto' unmountOnExit>
					<PasswordInput
						id={'newPassword'}
						key={'oldPassword'}
						error={passwordError !== '' && passwordError !== undefined}
						setNewPassword={setConfirmNewPassword}
						onClick={() => dispatch(resetInputError())}
						label={'Старый пароль'}
						confirmNewPassword={confirmNewPassword}
					/>
					<PasswordInput
						id={'newPassword'}
						key={'newPassword'}
						setNewPassword={setNewPassword}
						label={'Новый пароль'}
						needTooltip
					/>
					<OutlinedButton
						onClick={setPasswordHandler}
						disabled={
							!newPassword ||
							!confirmNewPassword ||
							newPassword.length <= 7 ||
							!/(?=.*?[A-Z])(?=.*[a-z])/.test(newPassword) ||
							!/^[A-Za-z0-9!-@#$%.^,&*()_{}[\];:"/|^]+$/.test(newPassword)
						}
						disableRipple
						type='submit'
						sx={{
							width: '100%',
							textTransform: 'none',
							marginBottom: '16px',
						}}
					>
						Сохранить изменения
					</OutlinedButton>
				</Collapse>
			</ProfileItem>
		</Box>
	)
}

export default SecurityBlock
