import Box from '@mui/material/Box'
import { ApexOptions } from 'apexcharts'
import ReactApexChart from 'react-apexcharts'

interface IDonutProps {
	colors: string[]
	categories: number[]
	labels?: string[]
}

const DonutChart = (props: IDonutProps) => {
	const { categories, colors } = props

	const options: ApexOptions = {
		chart: {
			type: 'donut',
		},
		dataLabels: { enabled: false },
		plotOptions: {
			pie: {
				expandOnClick: false,
				donut: {
					labels: {
						show: false,
						name: { show: false },
						value: { show: false },
						total: { show: false, showAlways: false },
					},
					size: '60%',
					background: 'transparent',
				},
			},
		},
		states: {
			hover: {
				filter: {
					type: 'none',
				},
			},
			active: {
				allowMultipleDataPointsSelection: false,
				filter: {
					type: 'none',
				},
			},
		},
		stroke: {
			show: false,
		},
		colors: colors,
		legend: {
			show: false,
		},
		tooltip: {
			enabled: false,
		},
	}

	return (
		<Box
			width={'40%'}
			sx={{
				'@media (max-width: 1200px)': {
					width: '75%',
				},
			}}
		>
			<ReactApexChart
				height={'100%'}
				width={'100%'}
				type='donut'
				options={options}
				series={categories}
			/>
		</Box>
	)
}

export default DonutChart
