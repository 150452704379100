import { initialChartInfo } from 'components/cattles/lamenessPage/shared/processes'
import { getDaysBetweenDates } from 'components/shared/processes'
import { IDot, ILamenessGraphResponse } from 'models/ICattlesData'
import moment from 'moment'
import { useEffect, useState } from 'react'
import CattlesApiService from 'services/cattlesApiServices'
import { fetchLightGroupList } from 'store/groupsSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'

type DateRangeType = {
	startDate: number
	endDate: number
}

const useLamenessInfoForChart = (
	dateRange: DateRangeType,
	activeFilter?: string
) => {
	const [dotsInfo, setDotsInfo] = useState(initialChartInfo)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const daysBetweenDates = getDaysBetweenDates(dateRange)
	const dispatch = useAppDispatch()

	const processServerData = (
		res: ILamenessGraphResponse,
		daysBetweenDates: number
	) => {
		if (daysBetweenDates > 180) {
			const aggregatedLines = res.lines.map(line => {
				const aggregatedDots = aggregateMonthly(line.dots)
				return {
					...line,
					dots: aggregatedDots,
				}
			})
			setDotsInfo(prevDots => {
				return prevDots.map(el => {
					const line = aggregatedLines.find(l => l.score === el.score)
					if (line) {
						return {
							...el,
							category: line.dots.map(dot => dot.value),
							timestamp: line.dots.map(dot => dot.date_ms),
							totalCattles: line.dots.reduce((sum, num) => sum + num.value, 0)
						}
					}
					return el
				})
			})
		} else {
			setDotsInfo(prevDots => {
				return prevDots.map(el => {
					const line = res.lines.find(l => l.score === el.score)
					if (line) {
						return {
							...el,
							category: line.dots.map(dot => dot.value),
							timestamp: line.dots.map(dot => dot.date_ms),
							totalCattles: line.dots.reduce((sum, num) => sum + num.value, 0)
						}
					}
					return el
				})
			})
		}
	}

	const aggregateMonthly = (dots: IDot[]): IDot[] => {
		const monthlyData: { [key: string]: { value: number; date_ms: number } } =
			{}

		dots.forEach(dot => {
			const date = moment(dot.date_ms)
			const monthKey = date.format('YYYY-MM')

			if (!monthlyData[monthKey]) {
				monthlyData[monthKey] = {
					value: 0,
					date_ms: date.startOf('month').valueOf(),
				}
			}

			monthlyData[monthKey].value += dot.value
		})

		return Object.values(monthlyData)
	}
	const { lightGroupList } = useAppSelector(state => state.groupsReducer)

	useEffect(() => {
		setIsLoading(true)
		!lightGroupList && dispatch(fetchLightGroupList())
		CattlesApiService.getLamenessGraph(
			dateRange.startDate,
			dateRange.endDate,
			activeFilter === 'all' ? null : +activeFilter
		)
			.then(res => {
				processServerData(res, daysBetweenDates)
				setIsLoading(false)
			})
			.catch(err => {
				console.log(err)
				setIsLoading(false)
			})
	}, [activeFilter, dateRange])

	return {
		dotsInfo,
		isLoading,
		lightGroupList,
		daysBetweenDates,
	}
}

export default useLamenessInfoForChart
