import { styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import { COLORS } from '../../styles/colors'

export const TypographyPrimary = styled(Typography)(theme => ({
	fontWeight: 400,
	fontSize: '14px',
	lineHeight: '17px',
	color: COLORS.black,
}))
