import { Box, Card, CardContent } from '@mui/material'
import { COLORS } from 'styles/colors'
import { TypographyHeaderSecondary } from 'ui/typography/TypographyHeaderSecondary'
import { ReactComponent as PlanEditPlusLogo } from '../../../assets/plan_edit_plus_logo.svg'

const AddPlanCard = props => {
	return (
		<Card
			onClick={props.onClick}
			sx={{
				p: '0px',
				boxShadow: 'none',
				borderRadius: '8px',
				cursor: 'pointer',
				border: `1px solid ${COLORS.icon}`,
				height: '48px',
			}}
		>
			<CardContent>
				<Box display='flex' flexDirection='row' justifyContent='space-between'>
					<Box></Box>
					<TypographyHeaderSecondary sx={{ color: COLORS.icon }}>
						{props.title}
					</TypographyHeaderSecondary>
					<PlanEditPlusLogo />
				</Box>
			</CardContent>
		</Card>
	)
}

export default AddPlanCard
