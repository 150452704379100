import axAPI from '../http'

export default class AuthApiService {
	static getUserInfoById = async userId => {
		return axAPI.get(`auth/users/${userId}/`)
	}
	static putChangeUserData = async (updateData, currentUserId) => {
		return axAPI({
			method: 'PUT',
			url: `auth/users/${currentUserId}/`,
			data: updateData,
		})
	}
	static postNewUser = async updateData => {
		return axAPI({
			method: 'POST',
			url: 'auth/users/',
			data: updateData,
		})
	}
	static postForcePassword = async updateData => {
		return axAPI({
			method: 'POST',
			url: 'auth/force_password',
			data: updateData,
		})
	}
	static postNewRole = async data => {
		return axAPI({
			method: 'POST',
			url: 'auth/roles',
			data: data,
		})
	}
}
