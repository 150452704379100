import { Box, Collapse } from '@mui/material'
import CattlesList from 'components/cattles/cattlesPage/ui/CattlesList'
import DigitizationWidjet from 'components/cattles/cattlesPage/ui/DigitizationWidjet'
import WidjetsHeader from 'components/cattles/cattlesPage/ui/WidjetsHeader'
import BreadCrumbsGroup from 'components/shared/breadCrumbs/BreadCrumbsGroup'
import { useEffect, useState } from 'react'
import { fetchDailyStatistics } from 'store/cattlesSlice'
import { fetchLightGroupList } from 'store/groupsSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import RepeatIdentifiedCard from './ui/RepeatIdentifiedCard'

const CattlesPage = () => {
	const [activeFilter, setActiveFilter] = useState('all')
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(fetchDailyStatistics(activeFilter))
		!lightGroupList && dispatch(fetchLightGroupList())
	}, [activeFilter])

	const { dailyStatistics, loadingStatistics } = useAppSelector(
		state => state.cattlesSlice
	)
	const { lightGroupList } = useAppSelector(state => state.groupsReducer)

	const handleActiveFilter = name => {
		setActiveFilter(name)
	}
	return (
		<Box
			sx={{
				padding: '0 16px',
				display: 'flex',
				flexDirection: 'column',
				gap: '24px',
			}}
		>
			<Collapse
				in={dailyStatistics?.issues?.repeat_identification}
				timeout='auto'
				unmountOnExit
			>
				<RepeatIdentifiedCard />
			</Collapse>
			<DigitizationWidjet
				loadingStatistics={loadingStatistics}
				digitizated={dailyStatistics?.identified_count}
			/>
			<BreadCrumbsGroup
				handleActiveFilter={handleActiveFilter}
				array={lightGroupList}
			/>
			<WidjetsHeader
				loadingStatistics={loadingStatistics}
				dailyStatistics={dailyStatistics}
			/>
			<CattlesList activeFilter={activeFilter} />
		</Box>
	)
}

export default CattlesPage
