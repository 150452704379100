import { Box, Dialog, Divider } from '@mui/material'
import useIsEqual from 'hook/useIsEqual'
import { ICowsList } from 'models/ICattlesData'
import moment from 'moment'
import { useState } from 'react'
import CattlesApiService from 'services/cattlesApiServices'
import { useAppDispatch } from 'store/hooks'
import { notifyUser } from 'store/notificationsSlice'
import { COLORS } from 'styles/colors'
import { ButtonUI } from 'ui/buttons/ButtonUI'
import { OutlinedButton } from 'ui/buttons/OutlinedButton'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { ReactComponent as CrossCloseLogo } from '../../../../../assets/cross_close_logo.svg'
import { ICheckupList } from '../models'
import DefectiveMilkBlock from './DefectiveMilkBlock'
import DiagnosticBlock from './DiagnosticBlock'
import DisposeRecommendedBlock from './DisposeRecommendedBlock'
import MainInfoBlock from './MainInfoBlock'
import NextExaminationBlock from './NextExaminationBlock'
import ViewHistoryBlock from './ViewHistoryBlock'
type ModalProps = {
	openModal: boolean
	cowInfo: ICowsList
	onClose: () => void
}

const initialCheckupBlock: ICheckupList = {
	diagnosis: [],
	next_examination_date: moment().add(3, 'days').valueOf(),
	milk_reject_start: moment().valueOf(),
	milk_reject_end: moment().add(7, 'days').valueOf(),
	dispose_recommended: false,
}

const CheckupCowModal = (props: ModalProps) => {
	const { openModal, cowInfo, onClose } = props
	const [openConfirmationExitWindow, setOpenConfirmationExitWindow] =
		useState<boolean>(false)
	const [checkupInfo, setCheckupInfo] =
		useState<ICheckupList>(initialCheckupBlock)
	const dispatch = useAppDispatch()
	const isEqualState = useIsEqual(initialCheckupBlock, checkupInfo)

	const submitForm = () => {
		CattlesApiService.postCheckupCowInfoByCowId(cowInfo.id, checkupInfo)
			.then(() => {
				onClose()
				setCheckupInfo(initialCheckupBlock)
				dispatch(
					notifyUser({
						title: `Осмотр коровы ${cowInfo.tag_id} завершён.`,
						description: 'Данные успешно сохранены.',
						isError: false,
					})
				)
			})
			.catch(error => {
				dispatch(
					notifyUser({
						title: `Осмотр коровы ${cowInfo.tag_id} завершён с ошибкой.`,
						description: error.message,
						isError: true,
					})
				)
			})
	}

	const closeDialogWindow = () => {
		if (isEqualState) {
			onClose()
			setCheckupInfo(initialCheckupBlock)
		} else {
			setOpenConfirmationExitWindow(true)
		}
	}
	return (
		<>
			{openConfirmationExitWindow ? (
				<Dialog
					scroll='paper'
					fullWidth={true}
					maxWidth={'sm'}
					onClose={closeDialogWindow}
					open={openConfirmationExitWindow}
					aria-labelledby='checkup-dialog-confirmation-form'
					PaperProps={{
						style: {
							borderRadius: '12px',
							padding: '32px 24px',
							boxShadow: 'none',
							height: 'max-content',
						},
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '24px',
						}}
					>
						<TypographyHeader>Выйти без сохранения</TypographyHeader>
						<TypographyPrimary>
							Внесенные изменения будут утеряны.{' '}
						</TypographyPrimary>
						<Box
							display={'flex'}
							flexDirection={'row'}
							width={'100%'}
							gap={'24px'}
						>
							<OutlinedButton
								disableRipple
								sx={{ m: 0, width: '100%' }}
								onClick={() => setOpenConfirmationExitWindow(false)}
							>
								Вернуться к <br />
								редактированию
							</OutlinedButton>
							<OutlinedButton
								disableRipple
								sx={{
									m: 0,
									width: '100%',
									color: COLORS.deviationFont,
									border: `1px solid ${COLORS.deviationFont}`,
									'&:hover': {
										color: COLORS.error,
										opacity: 0.8,
										backgroundColor: 'transparent',
										boxShadow: 'none',
									},
								}}
								onClick={() => {
									onClose()
									setCheckupInfo(initialCheckupBlock)
								}}
							>
								Выйти без сохранения
							</OutlinedButton>
						</Box>
					</Box>
				</Dialog>
			) : (
				<Dialog
					scroll='paper'
					fullWidth={true}
					maxWidth={'lg'}
					onClose={closeDialogWindow}
					open={openModal}
					aria-labelledby='checkup-dialog-form'
					sx={{ padding: '24px', margin: '0px' }}
					PaperProps={{
						style: {
							borderRadius: '12px',
							padding: '32px 24px',
							boxShadow: 'none',
							height: 'max-content',
						},
					}}
				>
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
						<Box
							display={'flex'}
							flexDirection={'row'}
							alignItems={'center'}
							justifyContent={'space-between'}
						>
							<TypographyHeader>
								{'Осмотр коровы ' + cowInfo.tag_id}
							</TypographyHeader>
							<CrossCloseLogo
								style={{ cursor: 'pointer', stroke: COLORS.icon }}
								onClick={closeDialogWindow}
							/>
						</Box>
						<MainInfoBlock cowInfo={cowInfo} />
						<Divider />
						<DiagnosticBlock
							diagnosisInfo={checkupInfo.diagnosis}
							setCheckupInfo={setCheckupInfo}
							disposeRecommended={checkupInfo.dispose_recommended}
						/>
						<Divider />
						<NextExaminationBlock
							date={checkupInfo.next_examination_date}
							setCheckupInfo={setCheckupInfo}
						/>
						<Divider />
						<DefectiveMilkBlock
							startOfReject={checkupInfo.milk_reject_start}
							endOfReject={checkupInfo.milk_reject_end}
							setCheckupInfo={setCheckupInfo}
						/>
						<Divider />
						<DisposeRecommendedBlock
							disposeRecommended={checkupInfo.dispose_recommended}
							setCheckupInfo={setCheckupInfo}
							diagnosisInfo={checkupInfo.diagnosis}
						/>
						<Divider />
						<ViewHistoryBlock cowId={cowInfo.id} />
						<ButtonUI
							sx={{
								marginTop: '0px',
								maxWidth: '400px',
								height: '56px',
								fontWeight: 600,
								fontSize: '14px',
								lineHeight: '17px',
								alignSelf: 'center',
							}}
							onClick={submitForm}
						>
							Сохранить
						</ButtonUI>
					</Box>
				</Dialog>
			)}
		</>
	)
}

export default CheckupCowModal
