import { Box, Divider } from '@mui/material'
import { getYearsOld } from 'components/shared/processes'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'

const CowMainInfo = props => {
	const { cattleInfo } = props
	return (
		<CardStandart sx={{ padding: '24px', height: '100%' }}>
			<Box display={'flex'} flexDirection={'column'} gap={'16px'}>
				<TypographyHeader sx={{ marginBottom: '8px' }}>
					Общая информация
				</TypographyHeader>
				<TypographyPrimary>
					<span style={{ color: COLORS.secondary, marginRight: '8px' }}>
						Ушная бирка:
					</span>
					{cattleInfo?.tag_id}
				</TypographyPrimary>
				<TypographyPrimary>
					<span style={{ color: COLORS.secondary, marginRight: '8px' }}>
						Кличка:
					</span>
					{cattleInfo?.name}
				</TypographyPrimary>
				<TypographyPrimary>
					<span style={{ color: COLORS.secondary, marginRight: '8px' }}>
						Возраст:
					</span>
					{cattleInfo && getYearsOld(cattleInfo.birth_date)}
				</TypographyPrimary>
				<Divider />
				<TypographyPrimary>
					<span style={{ color: COLORS.secondary, marginRight: '8px' }}>
						Группа:
					</span>
					{cattleInfo?.group.title}
				</TypographyPrimary>
			</Box>
		</CardStandart>
	)
}

export default CowMainInfo
