import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axAPI from '../http'
import { IGroupEntity, IGroups, ILightGroupList } from '../models/IGroup'

export const fetchGroupList = createAsyncThunk(
	'groups/fetchList',
	async function (_, { rejectWithValue }) {
		try {
			const response = await axAPI({
				method: 'GET',
				url: 'groups/list',
			})

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data
		} catch (error) {
			let errorMessage = 'Failed to retrive groups!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)

export const fetchLightGroupList = createAsyncThunk(
	'groups',
	async function (_, { rejectWithValue }) {
		try {
			const response = await axAPI({
				method: 'GET',
				url: 'groups',
			})

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data
		} catch (error) {
			let errorMessage = 'Failed to retrive groups!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)

export const fetchGroupData = createAsyncThunk(
	'groups/fetchGroupData',
	async (groupKey: string, { rejectWithValue }) => {
		try {
			const response = await axAPI({
				method: 'GET',
				url: `groups/${groupKey}`,
			})

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data
		} catch (error) {
			let errorMessage = 'Failed to retrive group data!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)

const initialState: IGroups = {
	groupList: [],
	lightGroupList: null,
	loading: false,
}

export const groupsSlice = createSlice({
	name: 'groups',
	initialState,
	reducers: {
		updateGroupsData: (state, action) => {
			state.groupList = state.groupList.map(group =>
				group.id === action.payload.id ? { ...group, ...action.payload } : group
			)
		},
		updateCattlesQty: (state, action) => {
			const { index, quantity } = action.payload
			const updatedGroupList = state.groupList.map((group, i) =>
				i === index ? { ...group, cattles_qty: quantity } : group
			)
			return {
				...state,
				groupList: updatedGroupList,
			}
		},
		updateCorrectionPercent: (state, action) => {
			const { index, correction_percent } = action.payload
			const updatedGroupList = state.groupList.map((group, i) =>
				i === index
					? { ...group, correction_percent: correction_percent }
					: group
			)
			return {
				...state,
				groupList: updatedGroupList,
			}
		},
		updateHumidityPercent: (state, action) => {
			const { index, food_humidity } = action.payload
			const updatedGroupList = state.groupList.map((group, i) =>
				i === index ? { ...group, food_humidity: food_humidity } : group
			)
			return {
				...state,
				groupList: updatedGroupList,
			}
		},
	},
	extraReducers: {
		[fetchGroupList.fulfilled.type]: (
			state,
			action: PayloadAction<IGroupEntity[]>
		) => {
			state.loading = false
			state.error = ''
			state.groupList = action.payload
		},
		[fetchGroupList.pending.type]: state => {
			state.loading = true
		},
		[fetchGroupList.rejected.type]: (state, action: PayloadAction<string>) => {
			state.loading = false
			state.error = action.payload
		},
		[fetchLightGroupList.fulfilled.type]: (
			state,
			action: PayloadAction<ILightGroupList[]>
		) => {
			state.loading = false
			state.error = ''
			state.lightGroupList = action.payload
		},
		[fetchLightGroupList.pending.type]: state => {
			state.loading = true
		},
		[fetchLightGroupList.rejected.type]: (
			state,
			action: PayloadAction<string>
		) => {
			state.loading = false
			state.error = action.payload
		},
		[fetchGroupData.fulfilled.type]: (
			state,
			action: PayloadAction<IGroupEntity>
		) => {
			state.loading = false
			state.error = ''
			state.currentGroup = action.payload
		},
		[fetchGroupData.pending.type]: state => {
			state.loading = true
		},
		[fetchGroupData.rejected.type]: (state, action: PayloadAction<string>) => {
			state.loading = false
			state.error = action.payload
		},
	},
})
export const {
	updateGroupsData,
	updateCattlesQty,
	updateCorrectionPercent,
	updateHumidityPercent,
} = groupsSlice.actions
export default groupsSlice.reducer
