import { styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import { COLORS } from '../../styles/colors'

export const TypographyPrimary500 = styled(Typography)(theme => ({
	fontWeight: 500,
	fontSize: '14px',
	lineHeight: '17px',
	color: COLORS.black,
}))
