import AutocompleteInput from 'components/shared/textFields/AutocompleteInput'
import { useAppSelector } from 'store/hooks'

const GroupsContentBlock = props => {
	const { setActiveFilters, groups } = props
	const { lightGroupList } = useAppSelector(state => state.groupsReducer)
	const availableGroupsArray = lightGroupList?.map(el => ({
		id: el.id,
		title: el.title,
	}))
	return (
		<AutocompleteInput
			widthForInput={'100%'}
			onClick={id => {
				setActiveFilters(prev => {
					const ids = prev.groups.ids
					const isPresent = ids.includes(id)
					const updatedIds = isPresent
						? ids.filter(item => item !== id)
						: [...ids, id]

					return {
						...prev,
						groups: {
							...prev.groups,
							ids: updatedIds,
						},
					}
				})
			}}
			isCheckbox
			availableList={availableGroupsArray}
			selectedValues={groups.ids}
			label={'Группа'}
			limitTags={2}
			needChip
		/>
	)
}

export default GroupsContentBlock
