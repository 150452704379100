import Box from '@mui/material/Box/Box'
import Collapse from '@mui/material/Collapse/Collapse'
import IconButton from '@mui/material/IconButton/IconButton'
import { useState } from 'react'
import { COLORS } from 'styles/colors'
import { ReactComponent as ArrowCloseDone } from '../../assets/arrow_close_logo.svg'
import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg'
import { ReactComponent as BarchartInfoLogo } from '../../assets/barchart_info_logo.svg'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import { TypographySecondary14 } from '../../ui/typography/TypographySecondary14'
import { ProfileItem } from './utils/ProfileItem'

const AboutBlock = () => {
	const [open, setOpen] = useState(false)
	const handleClick = () => {
		setOpen(!open)
	}
	return (
		<Box sx={{ width: '100%', minHeight: 'auto' }}>
			<ProfileItem>
				<Box
					onClick={handleClick}
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						cursor: 'pointer',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '8px',
						}}
					>
						<BarchartInfoLogo style={{ color: COLORS.secondaryFont }} />
						<TypographyHeader>О приложении</TypographyHeader>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '8px',
						}}
					>
						<TypographySecondary14>версия: 1.0</TypographySecondary14>
						<IconButton
							disableRipple
							disableTouchRipple
							sx={{
								padding: '0px',
								'&:hover': { backgroundColor: 'transparent' },
							}}
						>
							{open ? <ArrowOpenDone /> : <ArrowCloseDone stroke={COLORS.icon}/>}
						</IconButton>
					</Box>
				</Box>
				<Collapse in={open} timeout='auto' unmountOnExit>
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								marginTop: '16px',
							}}
						>
							<TypographyPrimary>Номер лицензии</TypographyPrimary>
							<TypographySecondary14 sx={{ fontWeight: '500' }}>
								SF02122023PRO
							</TypographySecondary14>
						</Box>
						{/* <Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								backgroundColor: COLORS.background,
								borderRadius: '8px',
								marginLeft: '-8px',
								cursor:'pointer',
							}}
						>
							<TypographyPrimary sx={{ padding: '8px' }}>
								Руководство пользователя
							</TypographyPrimary>
						</Box> */}
					</Box>
				</Collapse>
			</ProfileItem>
		</Box>
	)
}

export default AboutBlock
