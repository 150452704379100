import { calculateChartFoodState } from 'components/group/utils/calculateChartFoodState'
import moment from 'moment'
import ReactApexChart from 'react-apexcharts'
import { COLORS } from '../../../../styles/colors'
import { localizationApexCharts } from '../../../../utils'
import {
	getMaxOfArray,
	roundingNumber,
	timezoneOffsetInMilliseconds,
} from '../../../shared/processes'

export const renderGraph = (data, layerd_graph, psv, medianPsv, date) => {
	const timezoneOffset = timezoneOffsetInMilliseconds()
	const {
		dataAvailable,
		dataUnavailable,
		dataCameraIssue,
		dataXasis,
		layeredValues,
	} = calculateChartFoodState({
		eatenChartData: data,
		daysBetweenDates: 0,
		layerd_graph,
	})

	const totalValuesOfAvailableAndUnavailable = dataAvailable.map(
		(value, index) => value + (dataUnavailable[index] || 0)
	)

	const currentPSV = psv.map(el =>
		el.psv === null ? null : roundingNumber(el.psv, 1)
	)
	let timestampForPsv = []
	let foundNegative = false
	const oneDayInMilleseconds = 86400000
	timestampForPsv = psv.map((el, index) => {
		let timestamp = date + el.timestamp
		if (el.timestamp < moment(date).endOf('day')) {
			if (
				index > 1 &&
				(el.timestamp < 0 || psv[index - 1].timestamp > psv[index].timestamp)
			) {
				foundNegative = true
			}
			if (foundNegative) {
				timestamp += oneDayInMilleseconds
			}
		}
		return timestamp
	})
	const maxCurrentPsv = Math.max(
		getMaxOfArray(currentPSV),
		medianPsv?.psv_norm || 0
	)

	const roundingMaxPsvNumber = roundingNumber(
		maxCurrentPsv + maxCurrentPsv * 0.25,
		0
	)
	let maxSeriesValue = getMaxOfArray(totalValuesOfAvailableAndUnavailable) * 1.2

	const seriesForFoodTableState: any = [
		{
			name: 'Недоступный корм',
			data: dataUnavailable,
			color: COLORS.health,
		},
		{
			name: 'нет подталкивания',
			data: layeredValues,
			color: '#ff0000',
		},
		{
			name: 'Доступный корм',
			data: dataAvailable,
			color: COLORS.success,
		},
		{
			name: 'Проблемы с камерой',
			data: dataCameraIssue,
			color: COLORS.icon,
		},
	]

	const seriesForPsv: any = [
		{
			name: 'ПСВ',
			data: currentPSV,
		},
	]

	const optionsForPsv: any = {
		mask: false,
		colors: [COLORS.main, COLORS.blue],
		chart: {
			id: 'psvAnalytic',
			animations: {
				enabled: false,
			},
			locales: localizationApexCharts(),
			defaultLocale: 'ru',
			zoom: {
				enabled: false,
			},
			height: 250,
			type: 'line',
			toolbar: {
				show: false,
			},
		},
		markers: {
			size: [0, 0],
			colors: ['#fff'],
			strokeColors: [COLORS.main, COLORS.blue],
			strokeWidth: 2,
			hover: {
				size: 5,
			},
		},
		stroke: {
			width: 2,
			curve: 'straight',
		},
		dataLabels: {
			enabled: false,
		},
		grid: {
			strokeDashArray: [6, 3],
			show: true,
			borderColor: '#e4e4e4',
			xaxis: {
				lines: {
					show: true,
					style: {
						colors: ['#DBDBDB'],
						width: 1,
					},
				},
			},
		},
		annotations: {
			yaxis: [
				{
					y: medianPsv.psv_norm,
					borderColor: COLORS.success,
					strokeDashArray: 6,
				},
			],
		},
		xaxis: {
			tickPlacement: 'on',
			crosshairs: {
				show: true,
				position: 'front',
				stroke: {
					color: '#7F7F84',
					width: 1,
					dashArray: 0,
				},
			},
			type: 'datetime',
			tooltip: {
				enabled: false,
			},
			labels: {
				rotate: 0,
				trim: false,
				hideOverlappingLabels: false,

				datetimeUTC: false,
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
				datetimeFormatter: {
					year: 'yyyy',
					month: 'dd.MM.yy',
					day: 'dd.MM.yy',
					hour: 'HH:mm',
				},
			},
			categories: timestampForPsv,
		},
		yaxis: {
			tickAmount: 4,
			min: 0,
			max: roundingMaxPsvNumber,
			labels: {
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
				formatter: function (value: number, index: number) {
					if (index % 2 !== 0) {
						return ''
					}
					return roundingNumber(value, 0)
				},
			},
		},
		tooltip: {
			enabled: false,
		},
		legend: {
			show: false,
		},
	}

	const optionsForFoodTableState: any = {
		mask: false,
		chart: {
			id: 'foodStateAnalytic',
			locales: localizationApexCharts(),
			defaultLocale: 'ru',
			animations: {
				enabled: false,
			},
			zoom: {
				enabled: false,
			},
			height: 250,
			type: 'area',
			stacked: true,
			toolbar: {
				show: false,
			},
		},
		fill: {
			type: ['gradient', 'pattern', 'gradient', 'gradient'],
			gradient: {
				opacityFrom: 0,
				opacityTo: 0.82,
				stops: [0, 0],
			},
			pattern: {
				style: 'slantedLines',
				width: 10,
				height: 6,
				strokeWidth: 1,
			},
		},
		dataLabels: {
			enabled: false,
		},
		grid: {
			strokeDashArray: [6, 3],
			show: true,
			borderColor: '#e4e4e4',
			xaxis: {
				lines: {
					show: true,
					style: {
						colors: ['#DBDBDB'],
						width: 1,
					},
				},
			},
		},
		stroke: {
			width: 2,
			curve: 'straight',
		},
		xaxis: {
			tickPlacement: 'on',
			crosshairs: {
				show: true,
				position: 'front',
				stroke: {
					color: '#b6b6b6',
					width: 1,
					dashArray: 0,
				},
			},
			type: 'datetime',
			tooltip: {
				enabled: false,
			},
			labels: {
				datetimeUTC: false,
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
				datetimeFormatter: {
					year: 'yyyy',
					month: 'dd.MM.yy',
					day: 'dd.MM.yy',
					hour: 'HH:mm',
				},
			},
			categories: dataXasis,
		},
		yaxis: {
			tickAmount: 2,
			max: maxSeriesValue,
			labels: {
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
				formatter: function (value: number) {
					return Math.round(value).toString()
				},
			},
		},
		legend: {
			show: false,
		},
		tooltip: {
			enabled: false,
		},
	}

	return (
		<>
			<ReactApexChart
				options={optionsForFoodTableState}
				series={seriesForFoodTableState}
				type='area'
				height={250}
			/>
			<ReactApexChart
				options={optionsForPsv}
				series={seriesForPsv}
				type='line'
				height={250}
			/>
		</>
	)
}
