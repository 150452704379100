import { Circle, Path, StyleSheet, Svg, Text, View } from '@react-pdf/renderer'
import { pdfColors } from './utils/colorPdf'

const WarningCardInfoAboutAnalytics = ({ availabilityText }) => {
	return (
		<View style={styles.container}>
			<WarningLogo />
			<Text style={styles.typography}>{availabilityText}</Text>
		</View>
	)
}

export default WarningCardInfoAboutAnalytics

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		width: '100%',
		marginTop: '16px',
		wordWrap: 'break-word',
		whiteSpace: 'wrap',
		gap: '8px',
		alignItems: 'center',
		borderRadius: '8px',
		padding: '8px 26px 8px 12px',
		backgroundColor: pdfColors.greyOpacity,
	},
	typography: {
		wordWrap: 'break-word',
		whiteSpace: 'wrap',
		color: pdfColors.grey,
		fontSize: '8px',
		fontWeight: 400,
	},
})
const WarningLogo = () => (
	<Svg width='12' height='12' viewBox='0 0 24 24'>
		<Circle
			cx='12'
			cy='12'
			r='9'
			stroke='#EB4D3D'
			stroke-width='5'
			fill='none'
		/>
		<Path
			d='M12 17V11'
			stroke='#EB4D3D'
			stroke-width='5'
			stroke-linecap='round'
			fill='none'
		/>
		<Circle cx='12' cy='7' r='1' fill='#EB4D3D' />
	</Svg>
)
