import Box from '@mui/material/Box'
import React, { useState } from 'react'
import { InputAdornment, TextField } from '@mui/material'
import { COLORS } from '../styles/colors'
import { ReactComponent as SearchIcon } from '../assets/search.svg'

const Search = props => {
	const [isFocused, setIsFocused] = useState(false)

	return (
		<Box
			component='form'
			noValidate
			autoComplete='off'
			onSubmit={event => event.preventDefault()}
		>
			<TextField
				sx={{
					width: '348px',
					height: '40px',
					'& .MuiOutlinedInput-root': {
						'&.Mui-focused fieldset': {
							borderColor: COLORS.main,
						},
					},
					'& label.Mui-focused': {
						color: COLORS.main,
					},
				}}
				onChange={event => props.setControlValue(event.target.value)}
				label='Поиск'
				value={props.controlValue}
				autoFocus={false}
				InputProps={{
					style: {
						height: '40px',
						fontSize: '14px',
						borderRadius: '12px',
						background: '#fff',
					},
					startAdornment: (
						<InputAdornment position='start'>
							<SearchIcon stroke={COLORS.icon}/>
						</InputAdornment>
					),
				}}
				InputLabelProps={{
					style: {
						height: '40px',
						fontSize: '14px',
						display: 'flex',
						alignItems: 'center',
						paddingBottom: '30px',
						paddingLeft: isFocused || props.controlValue ? '0px' : '30px',
					},
					shrink: isFocused || props.controlValue !== '',
					id: 'search',
				}}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
			/>
		</Box>
	)
}

export default Search
