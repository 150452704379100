import { Box } from '@mui/material'
import { EDeviationState } from 'models/EDeviation'
import moment from 'moment'
import { useState } from 'react'
import { ChipItem } from '../../ui/ChipItem'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'
import { TypographySecondary14 } from '../../ui/typography/TypographySecondary14'
import DeviationItemGroup from './DeviationItemGroup'

const groupData = [
	{ gtitle: 'Все', key: 'all' },
	{ gtitle: 'Рацион', key: 'ration' },
	{ gtitle: 'План кормления', key: 'feedplan' },
	{ gtitle: 'Камеры и освещение', key: 'camera' },
]

const GroupSpecificDeviationContent = ({ id, deviationsList }) => {
	const [currentGroup, setCurrentGroup] = useState('all')

	const filteredDeviationList = deviationsList?.filter(el => {
		if (currentGroup === EDeviationState.Feedplan) {
			return (
				el.type === EDeviationState.Feedplan ||
				el.type === EDeviationState.FeedplanWeight
			)
		} else if (currentGroup === EDeviationState.Camera) {
			return (
				el.type === EDeviationState.Camera ||
				el.type === EDeviationState.CameraIssue
			)
		} else {
			return el.type === currentGroup
		}
	})

	const hasDateChanged = (prevItem, nextItem) => {
		const prevDate = moment(prevItem.created_dt).format('DD MMMM')
		const nextDate = moment(nextItem.created_dt).format('DD MMMM')
		return prevDate !== nextDate
	}

	const chipItemStyle = { mr: '8px' }

	return (
		<Box display='flex' flexDirection='column'>
			<Box display='flex' flexDirection='row' marginBottom={'27px'}>
				{groupData.map(group => {
					const deviationCount =
						group.key === 'all'
							? deviationsList.length
							: deviationsList.filter(el => {
									if (group.key === EDeviationState.Feedplan) {
										return (
											el.type === EDeviationState.Feedplan ||
											el.type === EDeviationState.FeedplanWeight
										)
									} else if (group.key === EDeviationState.Camera) {
										return (
											el.type === EDeviationState.Camera ||
											el.type === EDeviationState.CameraIssue
										)
									} else {
										return el.type === group.key
									}
							  }).length

					return (
						<ChipItem
							key={group.key}
							onClick={() => setCurrentGroup(group.key)}
							label={`${group.gtitle} ${deviationCount}`}
							sx={
								group.key === currentGroup
									? {
											...chipItemStyle,
											backgroundColor: '#5222D0',
											color: '#fff',
									  }
									: chipItemStyle
							}
						/>
					)
				})}
			</Box>
			<Box
				sx={{
					height: '312px',
					marginBottom: '8px',
					overflow: 'auto',
					'&::-webkit-scrollbar': {
						width: 4,
					},
					'&::-webkit-scrollbar-track': {
						backgroundColor: '#DBDBDB',
						borderRadius: 12,
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#5222D0',
						borderRadius: 12,
					},
				}}
			>
				{(currentGroup === 'all' ? deviationsList : filteredDeviationList)
					.length === 0 ? (
					<TypographySecondary14 sx={{ textAlign: 'center', marginTop: '10%' }}>
						Отклонений не обнаружено
					</TypographySecondary14>
				) : currentGroup === 'all' ? (
					deviationsList.map((item, index) => (
						<DeviationItemGroup
							item={item}
							showDate={
								index === 0 || hasDateChanged(deviationsList[index - 1], item)
							}
							key={item.id}
						/>
					))
				) : (
					filteredDeviationList.map((item, index) => (
						<DeviationItemGroup
							item={item}
							showDate={
								index === 0 ||
								hasDateChanged(filteredDeviationList[index - 1], item)
							}
							key={item.id}
						/>
					))
				)}
			</Box>
			<TypographySecondary sx={{ marginBottom: '16px' }}>
				Всего:{' '}
				{currentGroup === 'all'
					? deviationsList?.length
					: filteredDeviationList?.length}
			</TypographySecondary>
		</Box>
	)
}

export default GroupSpecificDeviationContent
