import { Box } from '@mui/material'
import { useState } from 'react'
import { COLORS } from 'styles/colors'
import { ChipItem } from 'ui/ChipItem'

interface BreadCrumbsProps {
	handleActiveFilter: (id: string | number) => void
	array: { title: string; id: number }[]
}

const BreadCrumbsGroup = (props: BreadCrumbsProps) => {
	const { handleActiveFilter, array } = props
	const [activeFilter, setActiveFilter] = useState<string | number>('all')

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				gap: '8px',
				flexWrap: 'wrap',
			}}
		>
			<ChipItem
				onClick={() => {
					handleActiveFilter('all')
					setActiveFilter('all')
				}}
				sx={{
					cursor: 'pointer',
					backgroundColor: activeFilter === 'all' && COLORS.main,
					color: activeFilter === 'all' && '#fff',
				}}
				label={'Все'}
			/>
			{array?.map((el, index) => (
				<ChipItem
					key={index}
					onClick={() => {
						handleActiveFilter(el.id)
						setActiveFilter(el.id)
					}}
					sx={{
						cursor: 'pointer',
						backgroundColor: activeFilter === el.id && COLORS.main,
						color: activeFilter === el.id && '#fff',
					}}
					label={el.title}
				/>
			))}
		</Box>
	)
}

export default BreadCrumbsGroup
