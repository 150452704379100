import moment from 'moment'
import CattlesApiService from 'services/cattlesApiServices'
import { changeTagNumber } from 'store/cattlesSlice'
import { notifyUser } from 'store/notificationsSlice'

export const submitCowFormInfo = async (
	cowId: number,
	tagImage: File | string,
	tagNumber: number,
	infoFromDigitizatedCow: any,
	setErrorMessages: any,
	dispatch: any
) => {
	const objForSend = {
		cow_id: cowId,
		tag_id: tagNumber,
		timestamp: moment().valueOf(),
		angle: 'tag',
		image: tagImage,
	}

	try {
		if (String(tagNumber) !== String(infoFromDigitizatedCow.cow.tag_id)) {
			await CattlesApiService.patchCowTagInfo(objForSend)
				.then(response => {
					dispatch(changeTagNumber(response.data))
				})
				.catch(e => {
					dispatch(
						notifyUser({
							title: 'Ошибка',
							description: 'Не удалось изменить номер ушной бирки',
							isError: true,
						})
					)
				})
		}

		const findTagImage = infoFromDigitizatedCow.cow.images.find(
			el => el.angle === 'tag'
		)?.image

		if (tagImage !== findTagImage) {
			await CattlesApiService.postCowTagInfo(objForSend)
		}
	} catch (e) {
		if (e.response.data.tag_id) {
			setErrorMessages(e.response.data.tag_id)
		} else {
			dispatch(
				notifyUser({
					title: 'Ошибка',
					description: 'Не удалось сохранить данные о корове',
					isError: true,
				})
			)
		}
	}
}
