import Box from '@mui/material/Box/Box'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import { TypographySecondaryBold16 } from 'ui/typography/TypographySecondaryBold16'

import { IGroupApexChart } from '../../models/IGroup'
import GroupsApiService from '../../services/groupsApiService'
import { useAppSelector } from '../../store/hooks'
import { COLORS } from '../../styles/colors'

import { calculateChartFoodState } from 'components/group/utils/calculateChartFoodState'
import Preloader from 'ui/Preloader'
import { TypographyPrimary12 } from 'ui/typography/TypographyPrimary12'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondaryBold14 } from 'ui/typography/TypographySecondaryBold14'
import { localizationApexCharts } from '../../utils'
import { getMaxOfArray, roundingNumber } from '../shared/processes'
import GroupEatenLegend from './GroupEatenLegend'
import { stateFoodTableTooltip } from './TooltipsForCharts/tooltipsForCharts'
import FrameViwer from './utils/FrameViewer'

const GroupApexChart = (props: {
	id: number
	startDate: number
	endDate: number
	daysBetweenDates: number
	group_title: string
}) => {
	const [eatenChartData, setEatenChartData] = useState<IGroupApexChart[]>([])
	const [objectForCamera, setObjectForCamera] = useState(null)
	const [openFrameViewer, setOpenFrameViewer] = useState(false)
	const chartRef = useRef(null)
	const [currentReachableValue, setCurrentFoodValue] = useState({
		volumeValue: 0,
		weightValue: 0,
	})
	const [loading, setLoading] = useState<boolean>(false)
	const messages = useAppSelector(
		state => state.websocketReducer.messageForFeedTableStatus
	)

	useEffect(() => {
		if (
			messages !== null &&
			moment(props.endDate).date() === moment().date() &&
			eatenChartData.length !== 0
		) {
			setCurrentFoodValue({
				volumeValue: roundingNumber(messages.r_feed + messages.ur_feed, 1),
				weightValue: roundingNumber(
					(messages.r_feed + messages.ur_feed) * messages.density
						? messages.density
						: 0,
					0
				),
			})
		}
	}, [messages])

	useEffect(() => {
		if (props.startDate && props.endDate) {
			setLoading(true)
			GroupsApiService.getFoodTableReachableAndUnreachable(
				props.id,
				props.startDate,
				props.endDate
			)
				.then(response => {
					const data = response.data
					const lastItem = data[data.length - 1]
					const firstItem = data[0]
					if (data.length > 1 && lastItem.status === 'camera_issue') {
						data[data.length - 1].start_dt = props.endDate
					}
					if (
						(props.daysBetweenDates === 1 || props.daysBetweenDates === 0) &&
						data.length > 1
					) {
						const firstObject = {
							...firstItem,
							start_dt: props.startDate,
						}
						const updatedData = data.map((item, index) => {
							if (index === 0) {
								return firstObject
							}
							return item
						})

						if (
							moment(props.startDate).format('DD.MM') ===
							moment().format('DD.MM')
						) {
							for (let i = 1; i <= 2; i++) {
								const currentTime = moment()
								const lastTime = moment(props.startDate).endOf('day')
								const newObject = {
									start_dt: i === 1 ? currentTime : lastTime,
									t_feed: null,
									r_feed: null,
									ur_feed: null,
									density: 0,
								}
								updatedData.push(newObject)
							}
						}
						setEatenChartData(updatedData)
					} else {
						setEatenChartData(data)
					}
					if (
						lastItem &&
						lastItem.r_feed !== null &&
						lastItem.ur_feed !== null
					) {
						setCurrentFoodValue(prevValue => ({
							...prevValue,
							volumeValue: roundingNumber(
								lastItem.r_feed + lastItem.ur_feed,
								1
							),
						}))
						if (lastItem.density !== null) {
							setCurrentFoodValue(prevValue => ({
								...prevValue,
								weightValue: roundingNumber(
									(lastItem.r_feed + lastItem.ur_feed) * lastItem.density,
									0
								),
							}))
						} else {
							setCurrentFoodValue(prevValue => ({
								...prevValue,
								weightValue: null,
							}))
						}
					}
				})
				.catch(error => {
					console.log(error)
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}, [props.startDate, props.endDate])

	const {
		dataAvailable,
		dataUnavailable,
		dataCameraIssue,
		dataXasis,
		densityList,
	} = calculateChartFoodState({
		eatenChartData,
		daysBetweenDates: props.daysBetweenDates,
	})

	const totalValuesOfAvailableAndUnavailable = dataAvailable.map(
		(value, index) => value + (dataUnavailable[index] || 0)
	)

	let maxSeriesValue = getMaxOfArray(totalValuesOfAvailableAndUnavailable) * 1.2

	const series: any = [
		{
			name: 'Недоступный корм',
			data: dataUnavailable,
			color: COLORS.health,
		},
		{
			name: 'Доступный корм',
			data: dataAvailable,
			color: COLORS.success,
		},
		{
			name: 'Проблемы с камерой',
			data: dataCameraIssue,
			color: COLORS.icon,
		},
	]
	function clickHandler(dataPointIndex) {
		const objectForCamera = eatenChartData[dataPointIndex]
		setObjectForCamera(objectForCamera)
		setOpenFrameViewer(true)
	}
	const addAnnotation = dataPointIndex => {
		const frameData = eatenChartData[dataPointIndex]
		if (
			chartRef.current &&
			dataPointIndex !== null &&
			frameData.id &&
			frameData.status === 'success'
		) {
			const chart = chartRef.current.chart
			chart.clearAnnotations()
			chart.addPointAnnotation({
				x: frameData.start_dt,
				y: frameData.r_feed + frameData.ur_feed,
				marker: {
					size: 0,
				},
				click: () => {
					clickHandler(dataPointIndex)
				},
				image: {
					width: 25,
					height: 25,
					path: 'https://api.cowvision.ru/static/camera-logo.svg',
				},
			})
		}
	}
	const clearAnnotations = () => {
		if (chartRef.current) {
			const chart = chartRef.current.chart
			chart.clearAnnotations()
		}
	}

	const handleMouseMove = (event, chartContext, config) => {
		if (config.dataPointIndex !== -1) {
			addAnnotation(config.dataPointIndex)
		}
	}

	const handleMouseLeave = () => {
		clearAnnotations()
	}
	const options: any = {
		mask: false,
		chart: {
			events: {
				mouseMove: handleMouseMove,
				mouseLeave: handleMouseLeave,
			},
			locales: localizationApexCharts(),
			defaultLocale: 'ru',
			animations: {
				enabled: true,
				easing: 'easein',
				speed: 300,
				animateGradually: {
					enabled: false,
					delay: 0,
				},
				dynamicAnimation: {
					enabled: true,
					speed: 300,
				},
			},
			zoom: {
				enabled: false,
			},
			height: 250,
			type: 'area',
			stacked: true,
			toolbar: {
				show: false,
			},
		},
		fill: {
			type: 'gradient',
			gradient: {
				opacityFrom: 0,
				opacityTo: 0.82,
				stops: [0, 0],
			},
		},
		dataLabels: {
			enabled: false,
		},
		grid: {
			strokeDashArray: [6, 3],
			show: true,
			borderColor: '#e4e4e4',
			xaxis: {
				lines: {
					show: true,
					style: {
						colors: ['#DBDBDB'],
						width: 1,
					},
				},
			},
		},
		markers: {
			showNullDataPoints: false,
			size: [0, 0],
			hover: {
				size: 0,
			},
		},
		stroke: {
			width: 2,
			curve: 'straight',
		},
		xaxis: {
			tickPlacement: 'on',
			crosshairs: {
				show: true,
				position: 'front',
				stroke: {
					color: '#b6b6b6',
					width: 1,
					dashArray: 0,
				},
			},
			type: 'datetime',
			tooltip: {
				enabled: false,
			},
			labels: {
				datetimeUTC: false,
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
				datetimeFormatter: {
					year: 'yyyy',
					month: 'dd.MM.yy',
					day: 'dd.MM.yy',
					hour: 'HH:mm',
				},
			},
			categories: dataXasis,
		},
		yaxis: {
			tickAmount: 2,
			max: maxSeriesValue,
			labels: {
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
				formatter: function (value: number) {
					return Math.round(value).toString()
				},
			},
		},
		legend: {
			show: false,
		},
		tooltip: {
			custom: ({ series, dataPointIndex, w }) => {
				return stateFoodTableTooltip(
					densityList,
					dataPointIndex,
					series,
					props.daysBetweenDates,
					w
				)
			},
		},
	}

	if (!eatenChartData) return <></>
	return (
		<Box id='foodtable'>
			{loading ? (
				<Preloader />
			) : (eatenChartData.length === 1 &&
					eatenChartData[0].status === 'camera_issue') ||
			  eatenChartData.length === 0 ||
			  eatenChartData.every(el => el.status === 'camera_issue') ? (
				<Box
					sx={{
						height: '260px',
						mt: 'auto',
						mb: 'auto',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<TypographySecondaryBold16>Нет данных</TypographySecondaryBold16>
					<TypographySecondary14 sx={{ marginTop: '12px' }}>
						Нет данных, соответствующих заданным условиям.
					</TypographySecondary14>
				</Box>
			) : (
				<Box sx={{ marginLeft: '-12px' }}>
					<Box sx={{ height: '60px', marginBottom: '24px' }}>
						{moment(props.startDate).format('DD-MM') ===
							moment().format('DD-MM') && (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: '4px',
									marginLeft: '12px',
								}}
							>
								<TypographySecondary>Сейчас</TypographySecondary>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										gap: '4px',
									}}
								>
									<TypographyPrimary16Bold>
										{currentReachableValue.volumeValue}
									</TypographyPrimary16Bold>
									<TypographyPrimary12>м³</TypographyPrimary12>
									<TypographySecondaryBold14>/</TypographySecondaryBold14>
									<TypographyPrimary16Bold>
										{currentReachableValue.weightValue === null
											? '-'
											: currentReachableValue.weightValue}
									</TypographyPrimary16Bold>
									<TypographyPrimary12>кг</TypographyPrimary12>
								</Box>
								<TypographySecondary>
									Корма на кормовом столе
								</TypographySecondary>
							</Box>
						)}
					</Box>
					<TypographySecondary
						sx={{ marginBottom: '-10px', marginLeft: '12px' }}
					>
						Объём корма, м³
					</TypographySecondary>
					<Box sx={{ position: 'relative' }}>
						<style>
							{props.daysBetweenDates === 1 || props.daysBetweenDates === 0
								? ' #foodtable .apexcharts-xaxis-texts-g > text:nth-child(3n - 2) {fill: #7F7F84 !important}'
								: ' #foodtable .apexcharts-xaxis-texts-g > text {fill: #7F7F84 !important}'}
						</style>
						<ReactApexChart
							ref={chartRef}
							options={options}
							series={series}
							type='area'
							height={250}
						/>
						<FrameViwer
							openFrameViewer={openFrameViewer}
							setOpenFrameViewer={setOpenFrameViewer}
							objectForCamera={objectForCamera}
							group_title={props.group_title}
						/>
					</Box>
				</Box>
			)}
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					marginBottom: '24px',
				}}
			>
				<span
					style={{
						width: '16px',
						height: '16px',
						borderRadius: '50%',
						backgroundColor: COLORS.success,
					}}
				></span>
				<TypographySecondary sx={{ marginLeft: '8px' }}>
					Доступный корм
				</TypographySecondary>
				<span
					style={{
						width: '16px',
						height: '16px',
						borderRadius: '50%',
						backgroundColor: COLORS.health,
						marginLeft: '16px',
					}}
				></span>
				<TypographySecondary sx={{ marginLeft: '8px' }}>
					Недоступный корм
				</TypographySecondary>
				<span
					style={{
						width: '16px',
						height: '16px',
						borderRadius: '50%',
						backgroundColor: COLORS.icon,
						marginLeft: '16px',
					}}
				></span>
				<TypographySecondary sx={{ marginLeft: '8px' }}>
					Нет данных
				</TypographySecondary>
			</Box>
			<GroupEatenLegend
				id={props.id}
				startDate={props.startDate}
				endDate={props.endDate}
				daysBetweenDates={props.daysBetweenDates}
			/>
		</Box>
	)
}

export default GroupApexChart
