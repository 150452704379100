import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { Outlet } from 'react-router-dom'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { isMobile } from 'variables'
import { ReactComponent as SmartFarmLogo } from '../assets/smart_farm_logo.svg'
import useAuth from '../hook/useAuth'
import { useAppSelector } from '../store/hooks'
import { COLORS } from '../styles/colors'
import MainPage from './MainPage'
import LoginPage from './LoginPage'

const Layout = () => {
	const authState = useAuth()
	const permissions = useAppSelector(state => state.authReducer)
	if (isMobile) {
		return (
			<Box p={'40px 16px'}>
				<SmartFarmLogo fill='#5222D0' />
				<TypographyHeader mt={'40px'}>
					Вы пытаетесь войти через телефон,
				</TypographyHeader>
				<TypographyPrimary mt={'16px'}>
					система доступна только на планшете, ноутбуке или компьютере.
				</TypographyPrimary>
			</Box>
		)
	}
	if (!authState.token || !permissions) {
		return (
			<Box sx={{ display: 'flex', fontFamily: 'Montserrat' }}>
				<CssBaseline />
				<Box
					component='main'
					sx={{
						background: COLORS.background,
						width: '100vw',
						height: '100vh',
						p: 3,
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							height: 56,
						}}
					>
						<SmartFarmLogo fill='#5222D0' />
						<Button
							sx={{
								color: 'rgb(127, 127, 132)',
								fontSize: 14,
								lineHeight: 17,
								fontWeight: 400,
								textTransform: 'none',
								'&:hover': {
									backgroundColor: 'transparent',
									color: `${COLORS.main}`,
								},
							}}
							href='https://smartfarm.vision'
						>
							<ArrowBackIosNewOutlinedIcon />{' '}
							Вернуться на главную
						</Button>
					</Box>
					<LoginPage />
					{/* <Outlet /> */}
				</Box>
			</Box>
		)
	} else {
		return <MainPage />
	}
}

export default Layout
