import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { COLORS } from '../../styles/colors'
import { OutlinedButton } from '../../ui/buttons/OutlinedButton'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'

import { useAppDispatch } from '../../store/hooks'
import { notifyUser } from '../../store/notificationsSlice'

import { Box } from '@mui/material'
import axAPI from '../../http'
import { TypographyPrimary14Bold } from '../../ui/typography/TypographyPrimary14Bold'

type ManagementPopupProps = {
	userId?: string
	active: boolean
	open: boolean
	userName?: string
	onClose?: any
	handleClose?: () => void
	title: string
	status: string
}

const ManagementPopup = (props: ManagementPopupProps) => {
	const dispatch = useAppDispatch()

	const setActivityFlagAtServer = () => {
		axAPI({
			method: 'POST',
			url: 'auth/set_activity',
			data: { id: props.userId, active: !props.active },
		}).then(response => {
			if (response.status === 202) {
				dispatch(
					notifyUser({
						title: !props.active
							? 'Восстановление пользователя'
							: 'Удаление пользователя',
						description: `Пользователь ${props.userName} ${
							!props.active ? 'восстановлен' : 'удален'
						}`,
						isError: false,
					})
				)
				props.handleClose()
			}
		})
	}

	if (!props.open) return <></>

	return (
		<Box>
			<Dialog open={props.open} onClose={props.onClose} scroll='body'>
				<DialogTitle>
					<TypographyHeader align='center' sx={{ pt: '16px' }}>
						{props.title} пользователя
					</TypographyHeader>
				</DialogTitle>
				<DialogContent
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: '4px',
						justifyContent: 'center',
					}}
				>
					<TypographyPrimary>
						{'Вы уверены, что хотите удалить пользователя'}
					</TypographyPrimary>
					<TypographyPrimary14Bold>{props.userName}</TypographyPrimary14Bold>
					<TypographyPrimary align='center'>{'?'}</TypographyPrimary>
				</DialogContent>
				<DialogActions sx={{ pb: '32px' }}>
					<OutlinedButton
						disableRipple
						sx={{
							margin: 0,
							ml: '16px',
							color: COLORS.deviationFont,
							border: `2px solid ${COLORS.deviationFont}`,
							'&:hover': {
								color: COLORS.deviationOpacity,
								border: `2px solid ${COLORS.deviationOpacity}`,
								backgroundColor: 'transparent',
								boxShadow: 'none',
							},
						}}
						onClick={props.onClose}
					>
						Отмена
					</OutlinedButton>
					<OutlinedButton
						sx={{ margin: 0, mr: '16px', pl: '8px' }}
						onClick={setActivityFlagAtServer}
					>
						{props.status}
					</OutlinedButton>
				</DialogActions>
			</Dialog>
		</Box>
	)
}

export default ManagementPopup
