import { IHistoryTableRequest } from 'models/feedplan'
import axAPI from '../http'

export default class PlanApiService {
	static postChangedPlans = async data => {
		return axAPI({
			method: 'POST',
			url: `/feedplans/`,
			data: { plans: data },
		})
	}
	static getFeedplans = async date => {
		return axAPI.get(`feedplans/${date}`)
	}
	static getHistoryChangedForAllGroups = async (
		endDate: string
	): Promise<IHistoryTableRequest[]> => {
		try {
			const response = await axAPI.get<IHistoryTableRequest[]>(
				`/feedplan/history/${endDate}`
			)
			return response.data
		} catch (error) {
			console.error(error)
			throw error
		}
	}
	static getHistoryChangedByGroupkey = async (
		endDate: string,
		groupkey: string
	): Promise<IHistoryTableRequest[]> => {
		try {
			const response = await axAPI.get<IHistoryTableRequest[]>(
				`/feedplan/history/${endDate}/${groupkey}`
			)
			return response.data
		} catch (error) {
			console.error(error)
			throw error
		}
	}
}
