import { createTheme } from '@mui/material';
import MontserratTtf from './fonts/Montserrat-Medium.ttf';
import { COLORS } from './colors';

export const fontTheme = createTheme({
  palette: {
    background: {
      default: '#F7F7F7',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        ::selection {
          background-color: ${COLORS.mainSecondaryOpacity};
          color: inherit;
        }
      `,
    },
  },
});
