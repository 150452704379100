import { Box } from '@mui/material'
import { roundingNumber } from 'components/shared/processes'
import useMilkingsInfoForGraph from 'hook/useMilkingsInfoForGraph'
import { memo } from 'react'
import { CardStandart } from 'ui/CardStandart'
import RangeDate from 'ui/datePicker/RangeDate'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import YieldQtyChart from '../shared/YieldQtyChart'
import YieldQtyHistory from '../shared/YieldQtyHistory'

interface YieldQtyCardProps {
	cowId: number
}

const YieldQtyCard: React.FC<YieldQtyCardProps> = memo(props => {
	const { cowId } = props

	const {
		milkingInfo,
		categories,
		timestamp,
		onChangeCallback,
		daysDifference,
		dateRange,
	} = useMilkingsInfoForGraph(cowId)
	return (
		<CardStandart
			sx={{
				padding: '24px',
				display: 'flex',
				flexDirection: 'column',
				gap: '24px',
			}}
		>
			<TypographyHeader>Суточный надой</TypographyHeader>
			<RangeDate
				onChangeUpdateDate={onChangeCallback}
				startDate={dateRange.startDate}
				endDate={dateRange.endDate}
				weekFilter={true}
				monthFilter={true}
				yearFilter={true}
				currentRange={'Неделя'}
			/>
			<Box display={'flex'} flexDirection={'column'} gap={'4px'}>
				<TypographySecondary>
					{daysDifference === 0 ? 'Итого за день' : 'Итого за период'}
				</TypographySecondary>
				{milkingInfo && (
					<TypographyPrimary16Bold>
						{roundingNumber(milkingInfo.total_milk_sum, 1)}
						<span style={{ fontWeight: 400, fontSize: '12px' }}>{' л'}</span>
					</TypographyPrimary16Bold>
				)}
			</Box>
			{categories && timestamp && (
				<>
					<YieldQtyChart
						categories={categories}
						timestamp={timestamp}
						dateBetweenDates={daysDifference}
					/>
					<YieldQtyHistory milkingInfo={milkingInfo?.graph} />
				</>
			)}
		</CardStandart>
	)
})

export default YieldQtyCard
