import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { ReactElement, forwardRef } from 'react'
import { COLORS } from 'styles/colors'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: COLORS.secondaryFont,
		boxShadow: '0px 4px 16px 0px #00000014',
		fontSize: 12,
		borderRadius: '8px',
		padding: '8px',
	},
}))

interface ITooltip {
	children: ReactElement
	title: string
	placement:
		| 'bottom'
		| 'left'
		| 'right'
		| 'top'
		| 'bottom-end'
		| 'bottom-start'
		| 'left-end'
		| 'left-start'
		| 'right-end'
		| 'right-start'
		| 'top-end'
		| 'top-start'
}

const TooltipHover = forwardRef<HTMLDivElement, ITooltip>((props, ref) => {
	const { children, title, placement } = props
	return (
		<LightTooltip placement={placement} title={title}>
			<div ref={ref}>{children}</div>
		</LightTooltip>
	)
})

export default TooltipHover
