export function validateRationArray(rationArray) {
	if (!rationArray || rationArray.length === 0) {
			return false;
	}
	for (const ration of rationArray) {
			if (!ration.id) return false
			if (!ration.title || ration.title.trim() === '') {
					return false;
			}
			if (!ration.components || ration.components.length === 0) {
					return false;
			}
			for (const component of ration.components) {
					if (
							!component.weight_per_head ||
							component.weight_per_head === '' ||
							component.weight_per_head === null ||
							component.weight_per_head === undefined
					) {
							return false;
					}
			}
	}
	return true;
}
