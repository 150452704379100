import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
	IDiagnosesRes,
	IDiagnosesResponse,
	IExaminersResponse,
} from 'components/cattles/lamenessPage/shared/models'
import { IDailyStatisticsState, IDigitizatedCowInfo } from 'models/ICattlesData'
import CattlesApiService from 'services/cattlesApiServices'

export const fetchDailyStatistics = createAsyncThunk(
	'cows/statistics',
	async function (groupId: string | number, { rejectWithValue }) {
		try {
			const response = await CattlesApiService.getDailyStatistics(groupId)

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data as IDailyStatisticsState
		} catch (error) {
			let errorMessage = 'Failed to fetch daily statistics!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)

export const fetchDigitizatedCowInfo = createAsyncThunk(
	'cows/camera/milking',
	async function (cameraId: number, { rejectWithValue }) {
		try {
			const response = await CattlesApiService.getInfoFromDigitizatedFrame(
				cameraId
			)

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data as IDigitizatedCowInfo
		} catch (error) {
			let errorMessage = 'Failed to fetch daily statistics!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)

export const fetchExaminersList = createAsyncThunk(
	'cows/examiners',
	async function (_, { rejectWithValue }) {
		try {
			const response = await CattlesApiService.getExaminersList()

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data as IExaminersResponse[]
		} catch (error) {
			let errorMessage = 'Failed to fetch daily statistics!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)

export const fetchDiagnosesList = createAsyncThunk(
	'cows/sicknesses',
	async function (_, { rejectWithValue }) {
		try {
			const response = await CattlesApiService.getDiagnosisList()

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data as IDiagnosesResponse[]
		} catch (error) {
			let errorMessage = 'Failed to fetch daily statistics!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)
type ExaminersListProp = {
	id: number
	title: string
	description: string
}
interface ICattlesState {
	loadingStatistics: boolean
	error: string
	dailyStatistics: IDailyStatisticsState
	loadingDigitizatedInfo: boolean
	infoFromDigitizatedCow: IDigitizatedCowInfo
	errorDigitizated: string | undefined | boolean
	examinersList?: ExaminersListProp[]
	examinersListPending?: boolean
	examinersListError?: string
	diagnosesList?: IDiagnosesResponse[]
	diagnosesListPending?: boolean
	diagnosesListError?: string
}

const initialState: ICattlesState = {
	loadingStatistics: true,
	error: '',
	dailyStatistics: undefined,
	loadingDigitizatedInfo: true,
	infoFromDigitizatedCow: undefined,
	errorDigitizated: undefined,
	examinersList: [],
	examinersListPending: false,
	examinersListError: '',
	diagnosesList: [],
	diagnosesListPending: false,
	diagnosesListError: '',
}

export const cattlesSlice = createSlice({
	name: 'cows',
	initialState,
	reducers: {
		cowChangeInFrame: (state, action: PayloadAction<IDigitizatedCowInfo>) => {
			return {
				...state,
				infoFromDigitizatedCow: action.payload,
			}
		},
		changeTagNumber: (state, action) => {
			if (state.infoFromDigitizatedCow) {
				const newTagNumber = action.payload.tag_id
				const newStateDigitInfo = {
					...state.infoFromDigitizatedCow,
					cow: {
						...state.infoFromDigitizatedCow.cow,
						tag_id: newTagNumber,
					},
				}
				return {
					...state,
					infoFromDigitizatedCow: newStateDigitInfo,
				}
			}
			return state
		},
	},
	extraReducers: {
		[fetchDailyStatistics.fulfilled.type]: (
			state,
			action: PayloadAction<IDailyStatisticsState>
		) => {
			state.loadingStatistics = false
			state.error = ''
			state.dailyStatistics = action.payload
		},
		[fetchDailyStatistics.pending.type]: state => {
			state.loadingStatistics = true
			state.dailyStatistics = undefined
		},
		[fetchDailyStatistics.rejected.type]: (
			state,
			action: PayloadAction<string>
		) => {
			state.loadingStatistics = false
			state.error = action.payload
		},

		[fetchDigitizatedCowInfo.fulfilled.type]: (
			state,
			action: PayloadAction<IDigitizatedCowInfo>
		) => {
			state.loadingDigitizatedInfo = false
			state.errorDigitizated = false
			state.infoFromDigitizatedCow = action.payload
		},
		[fetchDigitizatedCowInfo.pending.type]: state => {
			state.loadingDigitizatedInfo = true
			state.infoFromDigitizatedCow = undefined
		},
		[fetchDigitizatedCowInfo.rejected.type]: (
			state,
			action: PayloadAction<string>
		) => {
			state.loadingDigitizatedInfo = false
			state.errorDigitizated = action.payload
		},
		[fetchExaminersList.fulfilled.type]: (
			state,
			action: PayloadAction<IExaminersResponse[]>
		) => {
			state.examinersListPending = false
			state.examinersList = action.payload
				.filter(
					el => el.id !== null && el.name !== null && el.position !== null
				)
				.map(el => ({
					id: el.id,
					title: el.name,
					description: el.position,
				}))
		},
		[fetchExaminersList.pending.type]: state => {
			state.examinersListPending = true
			state.examinersListError = ''
		},
		[fetchExaminersList.rejected.type]: (
			state,
			action: PayloadAction<string>
		) => {
			state.examinersListPending = false
			state.examinersListError = action.payload
		},

		[fetchDiagnosesList.fulfilled.type]: (
			state,
			action: PayloadAction<IDiagnosesRes>
		) => {
			state.diagnosesListPending = false
			state.diagnosesList = action.payload.items
		},
		[fetchDiagnosesList.pending.type]: state => {
			state.diagnosesListPending = true
			state.diagnosesListError = ''
		},
		[fetchDiagnosesList.rejected.type]: (
			state,
			action: PayloadAction<string>
		) => {
			state.diagnosesListPending = false
			state.diagnosesListError = action.payload
		},
	},
})
export const { cowChangeInFrame, changeTagNumber } = cattlesSlice.actions
export default cattlesSlice.reducer
