import Box from '@mui/material/Box/Box'
import Card from '@mui/material/Card/Card'
import Divider from '@mui/material/Divider/Divider'
import { pdf } from '@react-pdf/renderer'
import { useEffect, useState } from 'react'
import Preloader from 'ui/Preloader'
import { ReactComponent as AnalyticsDownloadLogo } from '../../assets/analytics_download_logo.svg'
import { fetchLightGroupList } from '../../store/groupsSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { COLORS } from '../../styles/colors'
import { ChipItem } from '../../ui/ChipItem'
import CustomPagination from '../../ui/CustomPagination'
import { OutlinedButton } from '../../ui/buttons/OutlinedButton'
import RangeDate from '../../ui/datePicker/RangeDate'
import { getYesterdayDate } from '../shared/processes'
import TableOfFeeding from './TableOfFeeding'
import DailyAnalytic from './pdf-generate/DailyAnalytic'
import { createAnalyticTypeListByDate } from './utils/createAnalyticTypeListByDate'

type AnalyticsType = {
	date_col: string
	filename: string
	group_title: string
	group_id: number
	type: string
	startDate: number
	endDate: number
	available: boolean
}

const FeedingPaginationList = () => {
	const [activeFilterGroupButton, setActiveFilterGroupButton] =
		useState<number>(-1)
	const [activeFilterTypeSelect, setActiveFilterTypeSelect] = useState<
		number[]
	>([])
	const [selectedCheckbox, setSelectedCheckbox] = useState([])
	const [analyticForFeeding, setAnalyticForFeeding] = useState<AnalyticsType[]>(
		[]
	)
	const [blobFiles, setBlobFiles] = useState([])
	const [visibleAnalytics, setVisibleAnalytics] = useState<AnalyticsType[]>([])
	const [activeExportColorButton, setActiveExportColorButton] = useState(
		COLORS.inputBorder
	)
	const [isLoading, setIsLoading] = useState(false)

	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(fetchLightGroupList())
	}, [])
	const { lightGroupList } = useAppSelector(store => store.groupsReducer)
	const yesterdayDate = getYesterdayDate()

	const [dateRange, setDateRange] = useState({
		startDate: yesterdayDate.startDate,
		endDate: yesterdayDate.endDate,
	})

	useEffect(() => {
		const fetchData = async () => {
			if (lightGroupList) {
				try {
					setIsLoading(true)

					const array = await createAnalyticTypeListByDate(
						dateRange,
						lightGroupList,
						activeFilterGroupButton,
						activeFilterTypeSelect
					)

					setAnalyticForFeeding(array)
				} catch (error) {
					console.error('Error fetching analytics:', error)
				} finally {
					setIsLoading(false)
				}
			}
		}

		fetchData()
	}, [
		dateRange,
		activeFilterGroupButton,
		lightGroupList,
		activeFilterTypeSelect,
	])

	const allGeneratePdfFile = async () => {
		const pdfBlob = await pdf(DailyAnalytic(blobFiles)).toBlob()
		const pdfUrl = URL.createObjectURL(pdfBlob)
		const a = document.createElement('a')
		a.href = pdfUrl
		a.download = 'AnalyticsList.pdf'
		a.click()
	}

	useEffect(() => {
		setSelectedCheckbox([])
		setBlobFiles([])
	}, [visibleAnalytics])

	useEffect(() => {
		if (selectedCheckbox.length > 0) {
			setActiveExportColorButton(COLORS.main)
		} else {
			setActiveExportColorButton(COLORS.inputBorder)
		}
	}, [selectedCheckbox])

	const onChangeCallback = value => {
		setDateRange({
			startDate: value[0],
			endDate: value[1],
		})
	}
	return (
		<Box sx={{ ml: '16px', mr: '32px', position: 'relative' }}>
			<ChipItem
				onClick={() => setActiveFilterGroupButton(-1)}
				key={'selectAllGroups'}
				label='Все'
				sx={{
					backgroundColor: activeFilterGroupButton === -1 && COLORS.main,
					color: activeFilterGroupButton === -1 && '#fff',
					margin: '0px 8px 8px 0px',
				}}
			/>
			{lightGroupList?.map(el => (
				<ChipItem
					onClick={() => setActiveFilterGroupButton(el.id)}
					key={el.id}
					label={el.title}
					sx={{
						backgroundColor: activeFilterGroupButton === el.id && COLORS.main,
						color: activeFilterGroupButton === el.id && '#fff',
						margin: '0px 8px 8px 0px',
					}}
				/>
			))}
			<Card
				sx={{
					marginTop: '16px',
					boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
					backgroundColor: 'white',
					borderRadius: '12px',
					p: '24px',
				}}
			>
				<Box display='flex' flexDirection='row' justifyContent='space-between'>
					<RangeDate
						onChangeUpdateDate={onChangeCallback}
						startDate={dateRange.startDate}
						endDate={dateRange.endDate}
						yesterdayFilter={true}
						weekFilter={true}
						monthFilter={true}
						yearFilter={true}
						currentRange={'Вчера'}
						dayBefore={1}
					/>
					<OutlinedButton
						onClick={allGeneratePdfFile}
						disabled={
							activeExportColorButton === COLORS.inputBorder
								? true
								: false || blobFiles.length !== selectedCheckbox.length
						}
						sx={{
							width: '128px',
							height: '40px',
							margin: '0px',
							color: COLORS.main,
							textTransform: 'none',
							fontWeight: 600,
							cursor: 'pointer',
							'&:hover': {
								backgroundColor: 'transparent',
								color: COLORS.main,
								borderColor: COLORS.main,
								boxShadow: 'none',
							},
							'&:disabled': {
								backgroundColor: 'transparent',
								color: COLORS.inputBorder,
								borderColor: COLORS.inputBorder,
								boxShadow: 'none',
							},
						}}
						startIcon={
							blobFiles.length === selectedCheckbox.length ? (
								<AnalyticsDownloadLogo stroke={activeExportColorButton} />
							) : (
								<Preloader size={24} />
							)
						}
					>
						Экспорт
					</OutlinedButton>
				</Box>
				<TableOfFeeding
					blobFiles={blobFiles}
					analyticForFeeding={visibleAnalytics}
					selectedCheckbox={selectedCheckbox}
					setSelectedCheckbox={setSelectedCheckbox}
					setActiveFilterTypeSelect={setActiveFilterTypeSelect}
					setVisibleAnalytics={setVisibleAnalytics}
					setActiveExportColorButton={setActiveExportColorButton}
					setBlobFiles={setBlobFiles}
					isLoading={isLoading}
				/>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '16px',
					}}
				>
					<Divider />
					<CustomPagination
						initialArray={analyticForFeeding}
						setVisibleState={setVisibleAnalytics}
						count={36}
					/>
				</Box>
			</Card>
			<Box
				sx={{
					position: 'absolute',
					top: '0',
					left: '0',
					width: '100%',
					zIndex: '-5',
				}}
				id={'analyticChart'}
			></Box>
		</Box>
	)
}

export default FeedingPaginationList
