import { Box } from '@mui/material'
import { roundingNumber } from 'components/shared/processes'
import { COLORS } from 'styles/colors'
import { TypographyPrimary12 } from 'ui/typography/TypographyPrimary12'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { TypographySecondary } from 'ui/typography/TypographySecondary'

const DailyYieldCard = props => {
	const {
		today_average,
		today_total,
		yesterday_at_this_time,
		yesterday_average,
		yesterday_total,
	} = props?.milking_data
	if (!props.milking_data) return <></>
	const diffAverageYield = roundingNumber(today_average - yesterday_average, 1)
	return (
		<Box display={'flex'} flexDirection={'column'} gap={'24px'}>
			<Box
				display={'flex'}
				flexDirection={'row'}
				justifyContent={'space-between'}
			>
				<Box
					display={'flex'}
					flexDirection={'column'}
					justifyContent={'flex-start'}
					gap={'4px'}
				>
					<TypographySecondary>Сегодня</TypographySecondary>
					{today_total ? (
						<TypographyPrimary16Bold
							sx={{
								color:
									today_total >= yesterday_at_this_time
										? COLORS.success
										: COLORS.error,
							}}
						>
							{roundingNumber(today_total, 1).toLocaleString()}{' '}
							<span
								style={{
									fontWeight: 400,
									fontSize: '12px',
									color: COLORS.secondary,
								}}
							>
								Л
							</span>{' '}
							<TriangleSpan
								width={20}
								height={10}
								top={today_total > yesterday_at_this_time ? false : true}
								color={
									today_total > yesterday_at_this_time
										? COLORS.success
										: COLORS.error
								}
							/>
						</TypographyPrimary16Bold>
					) : (
						<TypographySecondary>Нет данных</TypographySecondary>
					)}
				</Box>

				<Box
					display={'flex'}
					flexDirection={'column'}
					gap={'4px'}
					textAlign={'right'}
				>
					<TypographySecondary>Средний на голову</TypographySecondary>
					{diffAverageYield ? (
						<TypographySecondary sx={{ color: COLORS.black }}>
							{today_average} <span style={{ color: COLORS.secondary }}>л</span>{' '}
							<span
								style={{
									color: diffAverageYield > 0 ? COLORS.success : COLORS.error,
								}}
							>
								{diffAverageYield > 0
									? `+${diffAverageYield}`
									: diffAverageYield}{' '}
								л
							</span>
						</TypographySecondary>
					) : (
						<TypographySecondary>Нет данных</TypographySecondary>
					)}
				</Box>
			</Box>

			<Box
				display={'flex'}
				flexDirection={'row'}
				justifyContent={'space-between'}
			>
				<Box
					display={'flex'}
					flexDirection={'column'}
					justifyContent={'flex-start'}
					gap={'4px'}
				>
					<TypographySecondary>Вчера на это время</TypographySecondary>
					{yesterday_at_this_time ? (
						<TypographyPrimary12>
							{roundingNumber(yesterday_at_this_time, 1).toLocaleString()}{' '}
							<span
								style={{
									fontWeight: 400,
									fontSize: '12px',
									color: COLORS.secondary,
								}}
							>
								л
							</span>
						</TypographyPrimary12>
					) : (
						<TypographySecondary>Нет данных</TypographySecondary>
					)}
				</Box>

				<Box
					display={'flex'}
					flexDirection={'column'}
					gap={'4px'}
					textAlign={'right'}
				>
					<TypographySecondary>Средний на голову</TypographySecondary>
					{yesterday_average ? (
						<TypographyPrimary12>
							{roundingNumber(yesterday_average, 1)}{' '}
							<span style={{ color: COLORS.secondary }}>л</span>
						</TypographyPrimary12>
					) : (
						<TypographySecondary>Нет данных</TypographySecondary>
					)}
				</Box>
			</Box>

			<TypographySecondary>{`Итого за вчера: ${
				yesterday_total
					? roundingNumber(yesterday_total, 1) + ' л'
					: 'Нет данных'
			}`}</TypographySecondary>
		</Box>
	)
}

export default DailyYieldCard

const TriangleSpan = props => {
	const { width, height, color, top } = props
	return (
		<span
			style={{
				width: '0px',
				height: '0px',
				borderLeft: `${width / 2}px solid transparent`,
				borderRight: `${width / 2}px solid transparent`,
				borderTop: top ? `${height}px solid ${color}` : 'none',
				borderBottom: !top ? `${height}px solid ${color}` : 'none',
				display: 'inline-block',
			}}
		></span>
	)
}
