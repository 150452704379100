import axAPI from '../http'

export default class AnalyticApiServices {
	static getDailyAnalytic = async (groupId: number, date: string) => {
		return axAPI.get(`analytics/groups/${groupId}/${date}`)
	}
	static getAvailableAnalitycs = async (
		groupId: number,
		startDate: number,
		endDate: number
	) => {
		return axAPI.get(
			`cattle-group/${groupId}/foodtable/graph/availability?start=${startDate}&end=${endDate}`
		)
	}
	static getRangeDateAnalytic = async (
		groupId: number,
		startDate: number,
		endDate: number
	) => {
		return axAPI.get(
			`analytics/groups/${groupId}?start=${startDate}&end=${endDate}`
		)
	}
}
