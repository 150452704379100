import { configureStore } from '@reduxjs/toolkit'
import authReducer from './authSlice'
import barnReducer from './barnSlice'
import cattlesSlice from './cattlesSlice'
import counterReducer from './counterSlice'
import feedPlanReducer from './feedPlanSlice'
import groupAnalyticsReducer from './groupAnalyticsSlice'
import groupsReducer from './groupsSlice'
import newFeedPlanReducer from './newFeedPlanSlice'
import notificationsSlice from './notificationsSlice'
import rationSystemReducer from './rationSystemSlice'
import userReducer from './userSlice'
import websocketReducer from './websocketSlice'

const store = configureStore({
	reducer: {
		groupsReducer,
		groupAnalyticsReducer,
		authReducer,
		userReducer,
		counterReducer,
		barnReducer,
		rationSystemReducer,
		feedPlanReducer,
		newFeedPlanReducer,
		notificationsSlice,
		cattlesSlice,
		websocketReducer,
	},
	middleware: getDefaultMiddleware => getDefaultMiddleware(),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
