import { StyleSheet, Text, View } from '@react-pdf/renderer'

const TableHeaderForRangeDate = () => {
	return (
		<View style={styles.row}>
			<Text style={styles.firstElement}>Дата</Text>
			<Text style={styles.descriptionLower}>Номер раздачи</Text>
			<Text style={styles.descriptionLower}>Плановое время раздачи</Text>
			<Text style={styles.descriptionWidther}>Фактическое время раздачи</Text>
			<Text style={{ ...styles.descriptionLower, paddingRight: '8px' }}>
				Вес раздачи
			</Text>
			<Text style={styles.descriptionWidther}>Фактический вес раздачи</Text>
			<Text style={styles.descriptionLower}>Остатки</Text>
			<Text style={styles.descriptionWidther}>Количество подталкиваний</Text>
			<Text style={{ ...styles.descriptionLower, border: 'none' }}>ПСВ</Text>
		</View>
	)
}

export default TableHeaderForRangeDate

const styles = StyleSheet.create({
	row: {
		marginTop: '16px',
		width: '100%',
		flexDirection: 'row',
		alignItems: 'center',
		borderTop: '1px solid #7f7f84',
		borderLeft: '1px solid #7f7f84',
		borderRight: '1px solid #7f7f84',
		borderTopLeftRadius: '16%',
		borderTopRightRadius: '16%',
	},
	firstElement: {
		height: '36px',
		padding: '7px 0px 0px 4px',
		width: '9.53%',
		borderRight: '1px solid #7f7f84',
		fontSize: '7px',
		lineHeight: 'normal',
		fontWeight: 400,
		color: '#7F7F84',
	},
	descriptionLower: {
		height: '36px',
		padding: '7px 0px 0px 4px',
		width: '10.53%',
		borderRight: '1px solid #7f7f84',
		fontSize: '7px',
		lineHeight: 'normal',
		fontWeight: 400,
		color: '#7F7F84',
		wordWrap: 'break-word',
	},
	descriptionWidther: {
		height: '36px',
		padding: '7px 0px 0px 4px',
		width: '15.79%',
		borderRight: '1px solid #7f7f84',
		fontSize: '7px',
		lineHeight: 'normal',
		fontWeight: 400,
		color: '#7F7F84',
	},
})
