import { Box } from '@mui/material'
import { TypographyPrimary } from './typography/TypographyPrimary'

interface IInfoChipItemProps {
	bgColor: string
	label: string
	labelColor: string
}

const InfoChipItem = (props: IInfoChipItemProps) => {
	const { bgColor, label, labelColor } = props
	return (
		<Box
			sx={{
				width: 'auto',
				borderRadius: '12px',
				height: 'auto',
				backgroundColor: bgColor,
				padding: '4px 8px',
			}}
		>
			<TypographyPrimary
				sx={{
					textAlign: 'center',
					color: labelColor,
				}}
			>
				{label}
			</TypographyPrimary>
		</Box>
	)
}

export default InfoChipItem
