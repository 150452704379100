import Box from '@mui/material/Box/Box'
import IconButton from '@mui/material/IconButton/IconButton'
import Table from '@mui/material/Table/Table'
import TableBody from '@mui/material/TableBody/TableBody'
import TableCell from '@mui/material/TableCell/TableCell'
import tableCellClasses from '@mui/material/TableCell/tableCellClasses'
import TableContainer from '@mui/material/TableContainer/TableContainer'
import TableHead from '@mui/material/TableHead/TableHead'
import TableRow from '@mui/material/TableRow/TableRow'
import { useEffect } from 'react'
import { ReactComponent as GroupDeleteIcon } from '../../assets/group_delete_icon.svg'
import { ReactComponent as PlusPopupIcon } from '../../assets/plus_popup_icon.svg'
import { COLORS } from '../../styles/colors'
import { PlanTableCell } from '../../ui/PlanTableCell'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'
import TimeInput from '../shared/textFields/InputTimeField'

const EditPlanTableCleaning = ({
	adapterCleaningTable,
	adapterNudgePlan,
	setAdapterNudgePlan,
}) => {
	useEffect(() => {
		if (adapterCleaningTable) {
			const timeArr = adapterCleaningTable.map(obj => obj.timeCleaning)
			const maxTimeLength = Math.max(...timeArr.map(arr => arr.length))
			const timeValueArr = adapterCleaningTable.map(obj => {
				const lengthDiff = maxTimeLength - obj.timeCleaning.length
				if (lengthDiff > 0) {
					for (let i = 0; i < lengthDiff; i++) {
						obj.timeCleaning.push('')
					}
				}
				return obj
			})
			setAdapterNudgePlan(timeValueArr)
		}
	}, [adapterCleaningTable])

	const addColumn = () => {
		setAdapterNudgePlan(prevData => {
			const newArray = [...prevData]
			newArray.forEach(obj => {
				obj.timeCleaning.push('')
			})
			return newArray
		})
	}

	const removeColumn = index => {
		setAdapterNudgePlan(prevData => {
			const newData = [...prevData]
			newData.forEach(obj => {
				obj.timeCleaning.splice(index, 1)
			})
			return newData
		})
	}

	const sxBorder = {
		borderBottom: `1px solid ${COLORS.secondaryFontOpacity} !important`,
	}
	if (!adapterNudgePlan) return <></>
	return (
		<TableContainer
			sx={{
				overflowY: 'hidden',
				width: '100%',
				'&::-webkit-scrollbar': {
					height: 4,
				},
				'&::-webkit-scrollbar-track': {
					backgroundColor: '#DBDBDB',
					borderRadius: 12,
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: '#5222D0',
					borderRadius: 12,
				},
			}}
		>
			<Table
				sx={{
					width: '100%',
					[`& .${tableCellClasses.root}`]: {
						whiteSpace: 'nowrap',
						borderBottom: 'none',
					},
				}}
				aria-label='simple table'
			>
				<TableHead sx={sxBorder}>
					<TableRow>
						<PlanTableCell>Группа</PlanTableCell>
						<PlanTableCell>Действия</PlanTableCell>
						{adapterNudgePlan?.length > 0 &&
							Array.from(
								{ length: adapterNudgePlan[0].timeCleaning?.length },
								(_, i) => (
									<TableCell key={i} sx={{ position: 'relative' }}>
										<Box>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
												}}
											>
												<TypographySecondary>
													Уборка {i + 1}
												</TypographySecondary>
												<IconButton onClick={() => removeColumn(i)}>
													<GroupDeleteIcon />
												</IconButton>
											</Box>
											<Box
												sx={{
													position: 'absolute',
													top: '70px',
													width: '225px',
												}}
											>
												<TypographySecondary>Время</TypographySecondary>
											</Box>
										</Box>
									</TableCell>
								)
							)}
						<TableCell>
							<PlusPopupIcon
								style={{ cursor: 'pointer' }}
								onClick={addColumn}
							/>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell sx={{ padding: '8px' }}></TableCell>
					</TableRow>
					{adapterNudgePlan?.map((group, i) => (
						<TableRow key={i}>
							<TableCell component='td' sx={{ width: '' }}>
								<Box
									display='flex'
									flexDirection='column'
									justifyContent='center'
									sx={{
										borderLeft: `2px solid ${COLORS.icon}`,
										pl: '12px',
										height: '54px',
									}}
								>
									<Box
										display='flex'
										flexDirection='row'
										sx={{ pb: '12px', alignItems: 'center' }}
									>
										<TypographyPrimary sx={{ whiteSpace: 'nowrap' }}>
											{group.groupName}
										</TypographyPrimary>
										<TypographySecondary
											sx={{ ml: '3px', whiteSpace: 'nowrap' }}
										>
											{group.barn}
										</TypographySecondary>
									</Box>
									<TypographyPrimary sx={{ color: COLORS.secondary }}>
										{`Голов: ${group.cattles_qty}`}
									</TypographyPrimary>
								</Box>
							</TableCell>
							<TableCell sx={{ width: '150px' }}>
								<Box display='flex' flexDirection='row' alignItems='center'>
									<Box
										display='flex'
										flexDirection='column'
										justifyContent='center'
										sx={{ pt: '12px' }}
									>
										{group.time?.map((plan, index) => (
											<Box
												display='flex'
												flexDirection='row'
												alignItems='center'
												sx={{ pb: '2px', pl: '4px' }}
												key={`plan_time${index}`}
											>
												<TypographyPrimary
													sx={{
														color: COLORS.secondaryFont,
														whiteSpace: 'nowrap',
														pr: '4px',
													}}
												>
													{plan === '' ? <></> : 'P' + (index + 1) + ':'}
												</TypographyPrimary>
												<TypographyPrimary sx={{ whiteSpace: 'nowrap' }}>
													{plan === '' ? <></> : plan}
												</TypographyPrimary>
											</Box>
										))}
										{group.timeNudge?.map((plan, index) => (
											<Box
												display='flex'
												flexDirection='row'
												alignItems='center'
												sx={{ pb: '2px', pl: '4px' }}
												key={`plan_time${index}`}
											>
												<TypographyPrimary
													sx={{
														color: COLORS.secondaryFont,
														whiteSpace: 'nowrap',
														pr: '4px',
													}}
												>
													{plan === '' ? <></> : 'П' + (index + 1) + ':'}
												</TypographyPrimary>
												<TypographyPrimary sx={{ whiteSpace: 'nowrap' }}>
													{plan === '' ? <></> : plan}
												</TypographyPrimary>
											</Box>
										))}
									</Box>
								</Box>
							</TableCell>
							{group.timeCleaning?.map((time, idx) => (
								<TableCell key={idx} sx={{ width: '200px' }}>
									<TimeInput
										value={time}
										onChange={e => {
											const newValue = e.target.value
											setAdapterNudgePlan(prevData => {
												const newData = [...prevData]
												newData[i].timeCleaning[idx] = newValue
												return newData
											})
										}}
										onBlur={e => (e.target.style.borderColor = '#DBDBDB')}
										onFocus={e => (e.target.style.borderColor = 'black')}
										style={{
											width: '120px',
											height: '50px',
											border: '1px solid',
											borderColor: '#DBDBDB',
											paddingLeft: '5px',
											borderRadius: '12px',
											transition: 'border-color 0.1s ease-in-out',
											textAlign: 'center',
										}}
									/>
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default EditPlanTableCleaning
