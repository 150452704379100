import { createContext } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';

import { authLogin, authLogout, authSetPassword } from '../store/authSlice'
import { notifyUser } from '../store/notificationsSlice'

export const AuthContext = createContext<any>(null);

export const AuthProvider = ({ children }) => {
    const dispatch = useAppDispatch()
    const authState = useAppSelector(state => state.authReducer)

    const signInAndContinue = (username: string, password: string) => {
        sessionStorage.removeItem('permissions')
        dispatch(authLogin({ username, password }))
    }
    const signOutAndContinue = (cb: any) => {
        dispatch(authLogout());
        cb();
        sessionStorage.removeItem('permissions')
        
    }

    const setPasswordAndContinue = (current_password: string, new_password: string) => {
        dispatch(authSetPassword({current_password, new_password}))
        .unwrap() 
        .then(() => {
            dispatch(notifyUser({
                title: 'Изменение пароля',
                description: 'Пароль успешно изменен!',
                isError: false,
            }));
        })
        .catch(error => {
            dispatch(notifyUser({
                title: 'Изменение пароля',
                description: `Ошибка при изменении пароля: ${error}`,
                isError: true,
            }));
        });
    }
    
    const value = {
        token: authState.apiToken,
        permissions: authState.permissions,
        signInAndContinue: signInAndContinue,
        signOutAndContinue: signOutAndContinue,
        setPasswordAndContinue: setPasswordAndContinue
    }
    
    return <AuthContext.Provider value={value}>
        { children }
    </AuthContext.Provider>
}