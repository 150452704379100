import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'

import { ReactComponent as ArrowCloseDone } from '../../assets/arrow_close_logo.svg'
import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg'

import { Box, Divider, Grid, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { COLORS } from 'styles/colors'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import {
	IRationArray,
	IRationTableComponents,
} from '../../models/IRationSystem'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import { TypographyPrimary14Bold } from '../../ui/typography/TypographyPrimary14Bold'
import { sxList } from '../group/utils/headChartStyles'
import CustomTooltip from '../shared/CustomTooltip'
import { roundingNumber } from '../shared/processes'

export default function RationCompositionComp({
	rationInfo,
	rationCompById,
}: {
	rationInfo: IRationArray
	rationCompById: IRationTableComponents[]
}) {
	const [open, setOpen] = useState(false)
	const sx = sxList(open)
	const handleClick = () => {
		setOpen(!open)
	}
	let fullCVWeightPerHeadArray = []
	let fullWeightPerHeadArray = []

	const waterWeight =
		(100 * +rationInfo.finallyDryMatterKg -
			+rationInfo.finallyCV * +rationInfo.finallyWeightMatterKg) /
		+rationInfo.finallyCV

	return (
		<List sx={sx}>
			<Box display='flex' flexDirection='row' alignItems='center'>
				<ListItemButton
					disableRipple
					disableTouchRipple
					sx={{
						padding: '14px',
						'&:hover': { backgroundColor: 'transparent' },
					}}
					onClick={handleClick}
				>
					<TypographyHeader>Состав</TypographyHeader>
				</ListItemButton>
				<IconButton
					disableRipple
					disableTouchRipple
					onClick={handleClick}
					sx={{
						pr: '16px',
						pt: '8px',
						'&:hover': { backgroundColor: 'transparent' },
					}}
				>
					{open ? <ArrowOpenDone /> : <ArrowCloseDone stroke={COLORS.icon}/>}
				</IconButton>
			</Box>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					<Box
						sx={{
							paddingLeft: '14px',
							display: 'flex',
							flexDirection: 'row',
							gap: '8px',
						}}
					>
						<TypographySecondary14>Влажность рациона:</TypographySecondary14>
						<TypographyPrimary>
							{roundingNumber(rationInfo.food_humidity, 0)}{' '}
							<span style={{ color: COLORS.secondaryFont }}>%</span>
						</TypographyPrimary>
					</Box>
					<ListItemButton
						sx={{ '&:hover': { backgroundColor: 'transparent' } }}
						disableRipple
						disableTouchRipple
					>
						<Box
							sx={{
								width: '100%',
								height: 'auto',
								backgroundColor: '#fff',
								borderRadius: '12px',
								marginTop: '16px',
							}}
						>
							<Grid
								container
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<Grid item xs={8} sm={4} md={4} lg={4} xl={6}>
									<TypographySecondary>Компоненты</TypographySecondary>
								</Grid>
								<Grid
									item
									xs={1}
									sm={2}
									md={1}
									lg={0.8}
									xl={0.6}
									sx={{ textAlign: 'center' }}
								>
									<TypographySecondary>СВ, %</TypographySecondary>
								</Grid>
								<Grid
									item
									xs={1}
									sm={3}
									md={2}
									lg={1.8}
									xl={1.3}
									sx={{ textAlign: 'center' }}
								>
									<TypographySecondary>СВ, кг/голову</TypographySecondary>
								</Grid>
								<Grid
									item
									xs={1}
									sm={3}
									md={2}
									lg={1.8}
									xl={1.3}
									sx={{ textAlign: 'center' }}
								>
									<TypographySecondary>Вес, кг/голову</TypographySecondary>
								</Grid>
								<Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
							</Grid>
							<Divider sx={{ marginTop: '8px' }} />
							<Grid
								container
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<>
									<Grid
										item
										xs={8}
										sm={4}
										md={4}
										lg={4}
										xl={6}
										sx={{ marginTop: '20px' }}
									>
										<TypographyPrimary>Вода</TypographyPrimary>
									</Grid>
									<Grid
										item
										xs={1}
										sm={2}
										md={1}
										lg={0.8}
										xl={0.6}
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<TypographySecondary>-</TypographySecondary>
									</Grid>
									<Grid
										item
										xs={1}
										sm={3}
										md={2}
										lg={1.8}
										xl={1.3}
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<TypographySecondary>-</TypographySecondary>
									</Grid>
									<Grid
										item
										xs={1}
										sm={3}
										md={2}
										lg={1.8}
										xl={1.3}
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<TypographyPrimary>
											{roundingNumber(waterWeight, 1)}
										</TypographyPrimary>
										<TypographySecondary>кг/гол</TypographySecondary>
									</Grid>
									<Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
								</>
								{rationInfo.components.map(info => {
									const component = rationCompById.find(
										comp => comp.id === info.component_id
									)

									if (!component) {
										return null
									}
									const weightPerHead =
										(+info.dry_per_head * 100) / component.dry_matter_percent
									fullCVWeightPerHeadArray.push(info.dry_per_head)
									fullWeightPerHeadArray.push(weightPerHead)
									return (
										<React.Fragment key={info.component_id}>
											<Grid
												item
												xs={8}
												sm={4}
												md={4}
												lg={4}
												xl={6}
												sx={{ marginTop: '20px' }}
											>
												<TypographyPrimary>{component.title}</TypographyPrimary>
											</Grid>
											<Grid
												item
												xs={1}
												sm={2}
												md={1}
												lg={0.8}
												xl={0.6}
												sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<TypographyPrimary>
													{roundingNumber(component.dry_matter_percent, 0)}
												</TypographyPrimary>
												<TypographySecondary>%</TypographySecondary>
											</Grid>
											<Grid
												item
												xs={1}
												sm={3}
												md={2}
												lg={1.8}
												xl={1.3}
												sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<TypographyPrimary>
													{roundingNumber(info.dry_per_head, 1)}
												</TypographyPrimary>
												<TypographySecondary>кг/гол</TypographySecondary>
											</Grid>
											<Grid
												item
												xs={1}
												sm={3}
												md={2}
												lg={1.8}
												xl={1.3}
												sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<TypographyPrimary>
													{roundingNumber(info.head_weight, 1)}
												</TypographyPrimary>
												<TypographySecondary>кг/гол</TypographySecondary>
											</Grid>
											<Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
										</React.Fragment>
									)
								})}
							</Grid>

							<Divider sx={{ marginTop: '8px' }} />
							<Grid
								container
								sx={{
									marginTop: '16px',
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<Grid
									item
									xs={8}
									sm={4}
									md={4}
									lg={4}
									xl={6}
									sx={{ textAlign: 'right', paddingRight: '30px' }}
								>
									<TypographyPrimary14Bold>
										Итого в рационе:
									</TypographyPrimary14Bold>
								</Grid>
								<Grid
									item
									xs={1}
									sm={2}
									md={1}
									lg={1}
									xl={0.8}
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<TypographyPrimary14Bold>
										{rationInfo.finallyCV
											? roundingNumber(rationInfo.finallyCV, 0)
											: '-'}
									</TypographyPrimary14Bold>
									<TypographySecondary sx={{ marginRight: '4px' }}>
										%
									</TypographySecondary>
									<CustomTooltip
										width={'16px'}
										height={'16px'}
										info={true}
										placement={'top'}
										title={
											'СВ,% рациона расчитывается исходя из долей компонентов в рационе.'
										}
									/>
								</Grid>
								<Grid
									item
									xs={1}
									sm={3}
									md={2}
									lg={1.5}
									xl={1.1}
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<TypographyPrimary14Bold>
										{roundingNumber(rationInfo.finallyDryMatterKg, 1)}
									</TypographyPrimary14Bold>
									<TypographySecondary>кг/гол</TypographySecondary>
								</Grid>
								<Grid
									item
									xs={1}
									sm={3}
									md={2}
									lg={2}
									xl={1.3}
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<TypographyPrimary14Bold>
										{roundingNumber(
											+rationInfo.finallyWeightMatterKg + waterWeight,
											1
										)}
									</TypographyPrimary14Bold>
									<TypographySecondary>кг/гол</TypographySecondary>
								</Grid>
								<Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
							</Grid>
						</Box>
					</ListItemButton>
				</List>
			</Collapse>
		</List>
	)
}
