import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import mask from '../../../assets/icons/legend-for-chart.png'
import { COLORS } from '../../../styles/colors'

const itemLegends = [
	{
		color: COLORS.success,
		title: 'Доступный корм',
	},
	{
		color: COLORS.health,
		title: 'Недоступный корм',
	},
	{
		title: 'Период высокой недоступности',
	},
	{
		color: COLORS.icon,
		title: 'Нет данных',
	},
]
const FoodTableStateChart = ({ imgURI }) => {
	return (
		<View style={styles.container}>
			<Image src={imgURI} />
			<View style={{ ...styles.row, gap: '8px', marginTop: '26px' }}>
				{itemLegends.map((el, index) => {
					return el.color ? (
						<View style={{ ...styles.row, gap: '4px' }} key={index}>
							<View
								style={{ ...styles.circle, backgroundColor: el.color }}
							></View>
							<Text style={styles.text}>{el.title}</Text>
						</View>
					) : (
						<View style={{ ...styles.row, gap: '4px' }} key={index}>
							<Image style={{ width: '12px', height: '8px' }} src={mask} />
							<Text style={styles.text}>{el.title}</Text>
						</View>
					)
				})}
			</View>
		</View>
	)
}

export default FoodTableStateChart

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	row: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	circle: {
		borderRadius: '50%',
		width: '8px',
		height: '8px',
	},

	text: {
		fontWeight: 400,
		fontSize: '7px',
		color: '#7f7f84',
	},
})
