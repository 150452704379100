import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import { COLORS } from '../../../styles/colors'

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	row: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	circle: {
		borderRadius: '50%',
		width: '8px',
		height: '8px',
	},
	text: {
		fontWeight: 400,
		fontSize: '7px',
		color: '#7f7f84',
	},
	annotation: {
		fontWeight: 400,
		fontSize: '7px',
		color: COLORS.success,
	},
})

const itemLegends = [
	{
		color: COLORS.main,
		title: 'ПСВ',
	},
]
const PSVChart = ({ imgURIPSV }) => {
	return (
		<View style={styles.container}>
			<Image src={imgURIPSV} />
			<View style={{ ...styles.row, gap: '8px', marginTop: '26px' }}>
				<View style={{ ...styles.row, gap: '4px' }}>
					<Text style={styles.annotation}>- – -</Text>
					<Text style={styles.text}>Норма ПСВ</Text>
				</View>
				{itemLegends.map((el, index) => (
					<View style={{ ...styles.row, gap: '4px' }} key={index}>
						<View
							style={{ ...styles.circle, backgroundColor: el.color }}
						></View>
						<Text style={styles.text}>{el.title}</Text>
					</View>
				))}
			</View>
		</View>
	)
}

export default PSVChart
