import { Box, Checkbox, ClickAwayListener, Collapse } from '@mui/material'
import { ReactComponent as NextIcon } from 'assets/arrow_close_logo.svg'
import { ReactComponent as CrossCloseLogo } from 'assets/cross_close_logo.svg'
import { ReactComponent as CheckBox } from 'assets/icons/checkBox.svg'
import CheckBoxActive from 'assets/icons/checkBoxActive'
import { useEffect, useState } from 'react'
import { COLORS } from 'styles/colors'
import { TypographyPrimary12 } from 'ui/typography/TypographyPrimary12'
import { TypographySecondary } from 'ui/typography/TypographySecondary'

interface SelectedType {
	type: number
	value: number
}
interface SelectSmallProps {
	typeArray: any
	selectedArray?: any
	state?: any
	placeholder?: string
	isCheckbox?: boolean
	height?: string
	width?: string
	value?: string[] | number[]
	isMultiplaySelect?: boolean
	isClearIcon?: boolean
	startAdornmentDesc?: string
	onClick?: (type: number) => void
}

export default function SelectSmall(props: SelectSmallProps) {
	const {
		typeArray,
		state,
		placeholder,
		isCheckbox,
		height,
		isMultiplaySelect,
		isClearIcon,
		value,
		startAdornmentDesc,
		width,
		onClick,
	} = props
	const [openSelectedList, setOpenSelectedList] = useState<boolean>(false)

	const [selected, setSelected] = useState<SelectedType[]>([])
	const [selectedCheckbox, setSelectedCheckbox] = useState<number[]>([])

	const toggleCheckbox = (type, value) => {
		if (selectedCheckbox.includes(type)) {
			setSelectedCheckbox(selectedCheckbox.filter(item => item !== type))
			setSelected(selected.filter(item => item.type !== type))
			if (state) {
				state(prevState => {
					const newState = prevState.filter(item => item !== type)
					return newState
				})
			}
		} else {
			setSelectedCheckbox(
				isMultiplaySelect ? [...selectedCheckbox, type] : [type]
			)
			setSelected(
				isMultiplaySelect ? [...selected, { type, value }] : [{ type, value }]
			)
			if (state) {
				state(prevState => {
					const newState = isMultiplaySelect ? [...prevState, type] : [type]
					return newState
				})
			}
			if (onClick) {
				onClick(type)
			}
		}
	}
	useEffect(() => {
		if (value) {
			const matchEl = typeArray.find(el => el.type === value)
			if (matchEl) {
				setSelected([matchEl])
				setSelectedCheckbox([matchEl.type])
			}
		}
	}, [value])

	const clearSelectedItem = () => {
		setSelectedCheckbox([])
		setSelected([])
		if (state) {
			state([])
		}
	}
	const selectedValuesString = selected.map(item => item.value).join(', ')

	return (
		<ClickAwayListener onClickAway={() => setOpenSelectedList(false)}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: '8px',
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<Box
						onClick={() => setOpenSelectedList(prevState => !prevState)}
						sx={{
							minWidth: width ? width : '100px',
							maxWidth: '150px',
							height: height ? height : '30px',
							borderRadius: '8px',
							border: `1px solid ${
								openSelectedList
									? COLORS.main
									: // : selectedCheckbox.length > 0
									  // ? COLORS.icon
									  COLORS.inputBorder
							}`,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: '4px',
							cursor: 'pointer',
							padding: '4px 8px',
							whiteSpace: 'nowrap',
							'&:hover': {
								border: `1px solid ${
									openSelectedList ? COLORS.main : COLORS.black
								}`,
								boxShadow: 'none',
							},
						}}
					>
						{startAdornmentDesc && selectedCheckbox.length !== 0 && (
							<TypographySecondary>{startAdornmentDesc}</TypographySecondary>
						)}
						<>
							{selectedCheckbox.length === 0 ? (
								<TypographySecondary>
									{placeholder ? placeholder : 'Тип'}
								</TypographySecondary>
							) : selectedCheckbox.length !== typeArray.length ? (
								<TypographyPrimary12
									sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
								>
									{selectedValuesString}
								</TypographyPrimary12>
							) : (
								<TypographyPrimary12>Все</TypographyPrimary12>
							)}
						</>
						<NextIcon
							stroke={COLORS.icon}
							style={{
								transform: `rotate(${openSelectedList ? '180' : '0'}deg)`,
								stroke: openSelectedList && COLORS.main,
								width: '16px',
								height: '16px',
							}}
						/>
					</Box>
					<Collapse in={openSelectedList} timeout='auto'>
						<Box sx={{ position: 'relative' }}>
							<Box
								sx={{
									position: 'absolute',
									overflowX: 'auto',
									top: '8px',
									minWidth: '100%',
									maxHeight: '180px',
									display: 'flex',
									flexDirection: 'column',
									gap: '6px',
									background: '#fff',
									borderRadius: '8px',
									boxShadow:
										openSelectedList && '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
									'&::-webkit-scrollbar': {
										width: 4,
									},
									'&::-webkit-scrollbar-track': {
										backgroundColor: '#DBDBDB',
										borderRadius: 12,
									},
									'&::-webkit-scrollbar-thumb': {
										backgroundColor: '#5222D0',
										borderRadius: 12,
									},
								}}
							>
								{typeArray.map((el, index) => (
									<Box
										onClick={() => toggleCheckbox(el.type, el.value)}
										key={index}
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: isCheckbox ? 'start' : 'center',
											padding: '3px 4px',
											gap: '4px',
											cursor: 'pointer',
											'&:hover': {
												backgroundColor: COLORS.mainSecondaryOpacity,
											},
										}}
									>
										{isCheckbox && (
											<Checkbox
												sx={{
													color: COLORS.inputBorder,
													'&.MuiCheckbox-root': {
														margin: '0px',
														padding: '0px',
														width: '20px',
														height: '20px',
														'&.Mui-checked': {
															color: COLORS.main,
														},
													},
												}}
												disableRipple
												icon={<CheckBox />}
												checkedIcon={<CheckBoxActive />}
												checked={selectedCheckbox.includes(el.type)}
											/>
										)}

										<TypographySecondary sx={{ textAlign: 'center' }}>
											{el.value}
										</TypographySecondary>
									</Box>
								))}
							</Box>
						</Box>
					</Collapse>
				</Box>
				{selectedCheckbox.length > 0 && isClearIcon && (
					<CrossCloseLogo
						onClick={() => clearSelectedItem()}
						style={{
							width: '16px',
							height: '16px',
							cursor: 'pointer',
							stroke: COLORS.secondary,
						}}
					/>
				)}
			</Box>
		</ClickAwayListener>
	)
}
