import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import { Suspense, lazy, useState } from 'react'

// import RationsFullCards from '../components/rationSystem/RationsFullCards'
// import RationsFullComponents from '../components/rationSystem/RationsFullComponents'
import { COLORS } from '../styles/colors'
import { fontTheme } from '../styles/font'
import { TypographySecondaryBold14 } from '../ui/typography/TypographySecondaryBold14'
import Preloader from 'ui/Preloader'

const theme = fontTheme
const RationsFullCards = lazy(() => import('../components/rationSystem/RationsFullCards'))
const RationsFullComponents = lazy(() => import('../components/rationSystem/RationsFullComponents'))
const RationPageEntry = () => {
	const [activeList, setActiveList] = useState('ration')

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Box
				sx={{ fontFamily: theme.typography.fontFamily, pl: '16px', pr: '40px' }}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: '16px',
						position: 'relative',
					}}
				>
					<TypographySecondaryBold14
						onClick={() => setActiveList('ration')}
						sx={{
							cursor: 'pointer',
							zIndex: '1',
							color: activeList === 'ration' && COLORS.main,
							textDecoration: activeList === 'ration' && 'underline',
							textUnderlineOffset: activeList === 'ration' && '8px',
							textDecorationThickness: activeList === 'ration' && '2px',
							'&:hover': {
								color: COLORS.main,
							},
						}}
					>
						Рационы
					</TypographySecondaryBold14>
					<TypographySecondaryBold14
						onClick={() => setActiveList('components')}
						sx={{
							cursor: 'pointer',
							zIndex: '1',
							color: activeList === 'components' && COLORS.main,
							textDecoration: activeList === 'components' && 'underline',
							textUnderlineOffset: activeList === 'components' && '8px',
							textDecorationThickness: activeList === 'components' && '2px',
							'&:hover': {
								color: COLORS.main,
							},
						}}
					>
						Компоненты
					</TypographySecondaryBold14>
					<Box
						sx={{
							position: 'absolute',
							top: '21px',
							width: '100%',
							height: '2px',
							backgroundColor: COLORS.inputBorder,
							zIndex: '0',
						}}
					></Box>
				</Box>
				<Box sx={{ marginTop: '22px' }}>
					{activeList === 'ration' ? (
						<Suspense fallback={<Preloader mt={25}/>}>
						<RationsFullCards />
						</Suspense>
					) : (
						<Suspense fallback={<Preloader mt={25}/>}>
						<RationsFullComponents />
						</Suspense>
					)}
				</Box>
			</Box>
		</ThemeProvider>
	)
}

export { RationPageEntry }
