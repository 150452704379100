export const hasPermission = (userPermissions, requiredPermissions) => {
	if (userPermissions === null) return
	return requiredPermissions.every(permission => userPermissions[permission])
}

export const getFirstWordsFromName = (firstname: string, lastname: string) => {
	const firstNameInitial = firstname ? firstname[0] : '';
	const lastNameInitial = lastname ? lastname[0] : '';
	const sirnameAndFirstname = firstNameInitial + lastNameInitial;
	return sirnameAndFirstname.toUpperCase()
}

export const localizationApexCharts = () => {
	return [
		{
			name: 'ru',
			options: {
				months: [
					'Январь',
					'Февраль',
					'Март',
					'Апрель',
					'Май',
					'Июнь',
					'Июль',
					'Август',
					'Сентябрь',
					'Октябрь',
					'Ноябрь',
					'Декабрь',
				],
				shortMonths: [
					'Янв',
					'Фев',
					'Март',
					'Апр',
					'Май',
					'Июнь',
					'Июль',
					'Авг',
					'Сен',
					'Окт',
					'Ноя',
					'Дек',
				],
				days: [
					'Понедельник',
					'Вторник',
					'Среда',
					'Четверг',
					'Пятница',
					'Суббота',
					'Воскресенье',
				],
				shortDays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вск'],
			},
		},
	]
}
