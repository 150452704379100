import { TableCell, TableCellProps, TableRow } from '@mui/material'
import { ReactNode } from 'react'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'

type CustomTableRowProps = {
	rowHeight?: string
	rowBgColor?: string
	rowHoverBgColor?: string
	rowBorderBottom?: string
	rowKey: number | string
	cellItems: CellType[]
}

type CellType = {
	align?: TableCellProps['align']
	padding?: string
	colSpan?: number
	width?: string
	children: ReactNode | string
}

const CustomTableRow = (props: CustomTableRowProps) => {
	const {
		rowKey,
		rowHeight,
		rowBgColor,
		rowHoverBgColor,
		rowBorderBottom,
		cellItems,
	} = props

	return (
		<TableRow
			key={rowKey}
			sx={{
				width: '100%',
				height: rowHeight ? rowHeight : '50px',
				backgroundColor: rowBgColor ? rowBgColor : 'transparent',
				'&:hover': {
					background: rowHoverBgColor ? rowHoverBgColor : 'none',
				},
			}}
		>
			{cellItems.map((el, index) => (
				<TableCell
					key={index}
					colSpan={el.colSpan ? el.colSpan : 1}
					align={el.align ? el.align : 'left'}
					padding={'none'}
					width={el.width ? el.width : '5%'}
					sx={{
						padding: el.padding ? el.padding : '0px',
						verticalAlign: 'top',
						borderBottom: rowBorderBottom
							? `1px solid ${rowBorderBottom}`
							: 'none',
					}}
				>
					{typeof el.children === 'string' ? (
						<TypographyPrimary>{el.children}</TypographyPrimary>
					) : (
						el.children
					)}
				</TableCell>
			))}
		</TableRow>
	)
}

export default CustomTableRow
