import * as React from 'react'

import Snackbar from '@mui/material/Snackbar'
import { ReactComponent as WarningLogo } from '../../assets/barchart_info_logo.svg'
import { ReactComponent as CloseIcon } from '../../assets/cross_close_logo.svg'

import { Box } from '@mui/material'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'

import { ReactComponent as SuccessLogo } from '../../assets/vector_ok_logo.svg'
import { ReactComponent as ErrorLogo } from '../../assets/warning_notification.svg'

import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { closeNotification } from '../../store/notificationsSlice'

export default function Notification() {
	const { open, title, description, isError, isWarning } = useAppSelector(
		store => store.notificationsSlice
	)
	const dispatch = useAppDispatch()

	const handleClose = (
		event: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === 'clickaway') {
			return
		}

		dispatch(closeNotification())
	}

	return (
		<Snackbar
			anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
			open={open}
			autoHideDuration={3000}
			onClose={handleClose}
			sx={{ marginRight: '28px' }}
		>
			<Box
				sx={{
					backgroundColor: 'white',
					borderRadius: '8px',
					p: '16px',
					boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
					maxWidth: '490px',
				}}
				display='flex'
				flexDirection='row'
				justifyContent='space-between'
				gap='8px'
			>
				<Box sx={{ width: '24px' }}>
					{isWarning ? (
						<WarningLogo style={{ color: '#F09A37' }} />
					) : isError ? (
						<ErrorLogo />
					) : (
						<SuccessLogo />
					)}
				</Box>
				<Box display='flex' flexDirection='column' gap='10px'>
					<TypographyPrimary16Bold mt={'4px'}>{title}</TypographyPrimary16Bold>
					<TypographyPrimary>{description}</TypographyPrimary>
				</Box>
				<Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
					<CloseIcon style={{ width: '18px' }} />
				</Box>
			</Box>
		</Snackbar>
	)
}
