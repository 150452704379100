import Box from '@mui/material/Box/Box'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Collapse from '@mui/material/Collapse/Collapse'
import { ReactComponent as NextIcon } from 'assets/arrow_close_logo.svg'
import { ReactComponent as CheckBox } from 'assets/icons/checkBox.svg'
import CheckBoxActive from 'assets/icons/checkBoxActive'
import { ReactComponent as PlusIcon } from 'assets/plan_edit_plus_logo.svg'
import { ReactComponent as SearchIcon } from 'assets/search.svg'
import { IRationTableComponents } from 'models/IRationSystem'
import { Dispatch, SetStateAction, useState } from 'react'
import { useAppDispatch } from 'store/hooks'
import { addComponentToRation } from 'store/rationSystemSlice'
import { COLORS } from 'styles/colors'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import { IInputData } from '../AddNewRation'
import PopupAddNewComponents from './PopupAddNewComponents'

interface PopupListComponentsProps {
	rationComponents: IRationTableComponents[]
	setActiveComponentIds: any
	activeComponentIds: number[]
	setRationData?: Dispatch<SetStateAction<IInputData>>
	editComp?: boolean
	indexForRationArray?: number
}

const PopupListComponents = ({
	rationComponents,
	setActiveComponentIds,
	activeComponentIds,
	setRationData,
	editComp,
	indexForRationArray,
}: PopupListComponentsProps) => {
	const [open, setOpen] = useState(false)
	const [openPopupAddComp, setOpenPopupAddComp] = useState(false)
	const dispatch = useAppDispatch()
	return (
		<ClickAwayListener onClickAway={() => setOpen(false)}>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Box
					onClick={() => setOpen(!open)}
					sx={{
						width: '400px',
						height: '54px',
						backgroundColor: '#fff',
						borderRadius: '12px',
						border: `1px solid ${COLORS.inputBorder}`,
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						gap: '8px',
						padding: '16px',
						marginBottom: '4px',
						boxShadow: open && '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
						cursor: 'pointer',
					}}
				>
					<SearchIcon stroke={COLORS.icon} />
					<TypographySecondary14>
						Добавить 1 или несколько компонентов
					</TypographySecondary14>
					<Box onClick={() => setOpen(!open)}>
						<NextIcon
							stroke={COLORS.icon}
							style={{ marginTop: '8px', transform: open && 'rotate(-180deg)' }}
						/>
					</Box>
				</Box>
				<Collapse in={open} timeout='auto'>
					<Box sx={{ position: 'relative' }}>
						<Box
							sx={{
								zIndex: '10',
								height: '210px',
								width: '400px',
								position: 'absolute',
								backgroundColor: '#fff',
								borderRadius: '12px',
								border: `1px solid ${COLORS.inputBorder}`,
								padding: '16px',
								boxShadow: open && '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
								overflow: 'auto',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								'&::-webkit-scrollbar': {
									width: 4,
									marginLeft: '20px',
								},
								'&::-webkit-scrollbar-track': {
									backgroundColor: '#DBDBDB',
									borderRadius: 12,
									margin: 1.2,
								},
								'&::-webkit-scrollbar-thumb': {
									backgroundColor: '#7f7f84',
									borderRadius: 12,
								},
							}}
						>
							{rationComponents?.map(el => (
								<Box
									key={el.id}
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										gap: '8px',
										marginBottom: '8px',
									}}
								>
									<Checkbox
										sx={{
											color: COLORS.inputBorder,
											'&.MuiCheckbox-root': {
												margin: '0px',
												padding: '0px',
												width: '20px',
												height: '20px',
												'&.Mui-checked': {
													color: COLORS.main,
												},
											},
										}}
										disableRipple
										icon={<CheckBox />}
										checkedIcon={<CheckBoxActive />}
										checked={activeComponentIds.includes(el.id)}
										onClick={() => {
											if (editComp === true) {
												if (activeComponentIds.includes(el.id)) {
													setActiveComponentIds(prevIds =>
														prevIds.filter(id => id !== el.id)
													)
												} else {
													setActiveComponentIds(prevIds => [...prevIds, el.id])
												}
												dispatch(
													addComponentToRation({
														id: el.id,
														index: indexForRationArray,
													})
												)
											} else {
												setActiveComponentIds(prevActiveIds => {
													if (prevActiveIds.includes(el.id)) {
														// Remove the component ID from the active list
														return prevActiveIds.filter(id => id !== el.id)
													} else {
														// Add the component ID to the active list
														return [...prevActiveIds, el.id]
													}
												})

												setRationData(prevData => {
													const existingComponents = prevData.components || []
													const isComponentSelected = existingComponents.some(
														component => component.id === el.id
													)

													if (isComponentSelected) {
														const updatedComponents = existingComponents.filter(
															component => component.id !== el.id
														)
														return {
															...prevData,
															components: updatedComponents,
														}
													} else {
														const newComponent = {
															...el,
															weight_per_head: '',
															dry_matter_kg: '',
														}

														return {
															...prevData,
															components: [...existingComponents, newComponent],
														}
													}
												})
											}
										}}
									/>
									<TypographyPrimary>{el.title}</TypographyPrimary>
									<TypographySecondary sx={{ marginTop: '2px' }}>
										СВ {el.dry_matter_percent} %
									</TypographySecondary>
								</Box>
							))}
						</Box>
						<Box
							onClick={() => setOpenPopupAddComp(true)}
							sx={{
								zIndex: '11',
								position: 'absolute',
								top: '183px',
								left: '16px',
								width: '370px',
								height: '24px',
								backdropFilter: 'blur(2px)',
								borderRadius: '8px',
								background: 'rgba(255, 255, 255, 0.70)',
								display: 'flex',
								flexDirection: 'row',
								gap: '8px',
								alignItems: 'center',
								cursor: 'pointer',
							}}
						>
							<PlusIcon height='24px' width='24px' />
							<TypographySecondary14>
								Добавить новый компонент
							</TypographySecondary14>
						</Box>
					</Box>
				</Collapse>
				<PopupAddNewComponents
					openPopupAddComp={openPopupAddComp}
					setOpenPopupAddComp={setOpenPopupAddComp}
				/>
			</Box>
		</ClickAwayListener>
	)
}

export default PopupListComponents
