import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import axAPI from '../../http'
import { INotification } from '../../models/notifications'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import NotificationsCurrent from './NotificationsCurrent'
import NotificationsPrev from './NotificationsPrev'

const NotificationsContent = ({ open, countNotif, setCountNotif }) => {
	const [notifications, setNotifications] = useState<INotification[]>([])
	const [currentNotifications, setCurrentNotifications] = useState<
		INotification[]
	>([])
	const [prevNotifications, setPrevNotifications] = useState<INotification[]>(
		[]
	)
	const [nextPage, setNextPage] = useState('')
	const [countNotifications, setCountNotifications] = useState(30)
	const [fetching, setFetching] = useState(true)
	const { ref, inView } = useInView({
		threshold: 0.1,
	})
	const currentDay = new Date().setHours(0, 0, 0, 0)

	useEffect(() => {
		if (fetching && notifications.length < countNotif) {
			axAPI
				.get(`notifications?cursor=${nextPage}&page_size=${countNotifications}`)

				.then(response => {
					setNotifications([...notifications, ...response.data.results])

					if (response.data.next !== null) {
						const startString = 'cursor='
						const endString = '&'
						const startIndex = response.data.next.indexOf(startString)
						const endIndex = response.data.next.indexOf(
							endString,
							startIndex + startString.length
						)
						const cursorValue = response.data.next.substring(
							startIndex + startString.length,
							endIndex
						)
						setNextPage(cursorValue)
					}
					setFetching(false)
				})
		}
	}, [fetching, open])

	useEffect(() => {
		setCurrentNotifications(
			notifications.filter(el => el.created_dt >= currentDay)
		)
		setPrevNotifications(notifications.filter(el => el.created_dt < currentDay))
	}, [notifications])

	useEffect(() => {
		if (inView && notifications.length < countNotif) {
			setFetching(true)
		}
	}, [inView])

	const removeNotification = notification => {
		setPrevNotifications(prevNotifications.filter(el => el.id !== notification))
		setCurrentNotifications(
			currentNotifications.filter(el => el.id !== notification)
		)
		setCountNotif(prevState => prevState - 1)
		axAPI({
			method: 'PATCH',
			url: `notifications/bulk`,
			data: { id: [notification], close_today: false, close_old: false },
		})
	}
	return (
		<Box>
			<TypographyHeader sx={{ color: '#ffffff', marginBottom: '16px' }}>
				Уведомления
			</TypographyHeader>
			<Typography
				sx={{ color: '#ffffff', fontSize: '14px', marginBottom: '32px' }}
			>
				{`Всего: ${!countNotif ? '0' : countNotif}`}
			</Typography>

			{countNotif === 0 ? (
				<Typography sx={{ color: '#ffffff', fontSize: '14px' }}>
					Нет недавних уведомлений
				</Typography>
			) : (
				<>
					{currentNotifications.length !== 0 && (
						<>
							<NotificationsCurrent
								setCurrentNotifications={setCurrentNotifications}
								currentNotif={currentNotifications}
								fetching={fetching}
								remove={removeNotification}
								setCountNotif={setCountNotif}
							/>
							<Box
								ref={ref}
								sx={{ height: '20px', background: 'transparent' }}
							></Box>
						</>
					)}
					{prevNotifications.length !== 0 && (
						<>
							<NotificationsPrev
								setCountNotif={setCountNotif}
								prevNotif={prevNotifications}
								fetching={fetching}
								remove={removeNotification}
								setPrevNotifications={setPrevNotifications}
							/>
							<Box
								ref={ref}
								sx={{ height: '20px', background: 'transparent' }}
							></Box>
						</>
					)}
				</>
			)}
		</Box>
	)
}

export default NotificationsContent
