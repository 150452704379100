import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import { Box } from '@mui/material'
import { COLORS } from 'styles/colors'
import { OutlinedButton } from 'ui/buttons/OutlinedButton'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'

const DaleteRationModal = props => {
	return (
		<Box sx={{ borderRadius: '8px' }}>
			<Dialog open={props.open} onClose={props.onClose} scroll='body'>
				<DialogTitle>
					<TypographyHeader align='center' sx={{ pt: '2px' }}>
						Удаление рациона
					</TypographyHeader>
				</DialogTitle>
				<DialogContent
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: '4px',
						justifyContent: 'center',
					}}
				>
					<TypographyPrimary>
						{`Вы уверены, что хотите удалить рацион"${
							props.rationTitle && props.rationTitle
						}"`}
					</TypographyPrimary>
				</DialogContent>
				<DialogActions sx={{ p: '0px 24px 24px 24px' }}>
					<OutlinedButton
						sx={{ margin: 0, mr: '16px', pl: '8px' }}
						onClick={props.onClose}
					>
						Отмена
					</OutlinedButton>
					<OutlinedButton
						disableRipple
						onClick={props.deleteRation}
						sx={{
							margin: 0,
							ml: '16px',
							color: COLORS.deviationFont,
							border: `2px solid ${COLORS.deviationFont}`,
							'&:hover': {
								color: COLORS.deviationOpacity,
								border: `2px solid ${COLORS.deviationOpacity}`,
								backgroundColor: 'transparent',
								boxShadow: 'none',
							},
						}}
					>
						Удалить
					</OutlinedButton>
				</DialogActions>
			</Dialog>
		</Box>
	)
}

export default DaleteRationModal
