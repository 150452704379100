import { Theme, createTheme, outlinedInputClasses } from '@mui/material'
import { COLORS } from 'styles/colors'

export const customTheme = (outerTheme: Theme) =>
	createTheme({
		palette: {
			mode: outerTheme.palette.mode,
		},
		components: {
			MuiTypography: {
				styleOverrides: {
					root: {
						textAlign: 'center',
						fontFamily: 'Montserrat',
						fontSize: '14px',
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					notchedOutline: {
						borderColor: COLORS.inputBorder,
						borderRadius: '8px',
					},
					root: {
						borderRadius: '8px',
						background: 'white',
						textAlign: 'center',
						fontFamily: 'Montserrat',
						fontSize: '14px',
						[`&:hover .${outlinedInputClasses.notchedOutline}`]: {
							borderColor: 'var(--TextField-brandBorderHoverColor)',
						},
						[`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
							border: `1px solid ${COLORS.main}`,
						},
					},
				},
			},
			MuiFilledInput: {
				styleOverrides: {
					root: {
						textAlign: 'center',
						'&:before, &:after': {
							borderBottom: '1px solid var(--TextField-brandBorderColor)',
						},
						'&:hover:not(.Mui-disabled, .Mui-error):before': {
							borderBottom: `1px solid ${COLORS.hoverInputBorder}`,
						},
						'&.Mui-focused:after': {
							borderBottom:
								'1px solid var(--TextField-brandBorderFocusedColor)',
						},
					},
				},
			},
			MuiInput: {
				styleOverrides: {
					root: {
						textAlign: 'center',
						'&:before': {
							border: '1px solid var(--TextField-brandBorderColor)',
						},
						'&:hover:not(.Mui-disabled, .Mui-error):before': {
							border: '1px solid var(--TextField-brandBorderHoverColor)',
						},
						'&.Mui-focused:after': {
							border: `1px solid ${COLORS.inputBorder}`,
						},
					},
				},
			},
		},
	})
