import { Box, Collapse, IconButton, List, ListItemButton } from '@mui/material'
import { useEffect, useState } from 'react'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'

import { ReactComponent as ArrowCloseDone } from '../../assets/arrow_close_logo.svg'
import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg'

import moment from 'moment'
import GroupsApiService from '../../services/groupsApiService'
import { COLORS } from '../../styles/colors'
import RangeDate from '../../ui/datePicker/RangeDate'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { TypographyPrimary12 } from '../../ui/typography/TypographyPrimary12'
import { TypographyPrimary16Bold } from '../../ui/typography/TypographyPrimary16Bold'
import {
	getDatesArray,
	getDaysBetweenDates,
	morphForWords,
} from '../shared/processes'
import GroupApexHeadQty from './GroupApexHeadQty'
import GroupApexLineChartHeadQty from './GroupApexLineChartHeadQty'
import GroupHeadQtyLegend from './GroupHeadQtyLegend'
import { sxList } from './utils/headChartStyles'

const GroupSpecificHeadChart = (props: { groupkey; group_id }) => {
	const [open, setOpen] = useState(false)
	const [fetchData, setFetchData] = useState([])
	const [fetchDayData, setFetchDayData] = useState([])
	const [headCount, setHeadCount] = useState(0)
	const [loading, setLoading] = useState<boolean>(false)

	const weekDays = getDatesArray(6)

	const [dateRange, setDateRange] = useState({
		startDate: weekDays.startDate,
		endDate: weekDays.endDate,
	})
	const daysBetweenDates = getDaysBetweenDates(dateRange)

	const onChangeCallback = value => {
		setDateRange({
			startDate: value[0],
			endDate: value[1],
		})
	}
	const startCurrentDate = moment(dateRange.startDate).startOf('day').valueOf()
	const endCurrentDate =
		moment(dateRange.startDate).startOf('day').add(1, 'day').valueOf() - 1

	useEffect(() => {
		if (open === true && daysBetweenDates === 0) {
			GroupsApiService.getDataForHeadChartByOneDay(
				props.group_id,
				moment(dateRange.startDate).format('YYYY-MM-DD')
			)
				.then(response => {
					const updatedArray = response.data.map((item, index, array) => {
						if (index === 0) {
							return { ...item, changed_dt: startCurrentDate }
						}
						const prevQuantity =
							index > 0
								? array[index - 1].quantity
								: response.data[index].quantity
						return { ...item, prevQuantity }
					})

					const lastItem = response.data[response.data.length - 1]
					updatedArray.push({
						...lastItem,
						changed_dt: endCurrentDate,
						quantity: updatedArray[updatedArray.length - 1].quantity,
						prevQuantity: updatedArray[updatedArray.length - 1].quantity,
					})

					setFetchDayData(updatedArray)
				})
				.catch(error => {
					console.log(error)
				})
		}
	}, [dateRange, open])

	useEffect(() => {
		if (open === true && daysBetweenDates !== 0) {
			setLoading(true)
			GroupsApiService.getDataForHeadChartByRangeDate(
				props.group_id,
				dateRange.startDate,
				dateRange.endDate
			)
				.then(response => {
					const dataWithoutFirstObject = response.data.slice(1)

					const updatedArray = dataWithoutFirstObject.map(
						(item, index, array) => {
							const prevQuantity =
								index > 0
									? array[index - 1].quantity
									: response.data[0].quantity
							return {
								...item,
								prevQuantity,
							}
						}
					)

					setFetchData(updatedArray)
					setHeadCount(
						dataWithoutFirstObject.reduce(
							(total, obj) => total + obj.quantity / (daysBetweenDates + 1),
							0
						)
					)
				})

				.catch(error => {
					console.log(error)
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}, [dateRange])
	const sx = sxList(open)

	const handleClick = () => {
		setOpen(!open)
	}
	return (
		<List sx={sx}>
			<Box
				display='flex'
				flexDirection='row'
				alignItems='center'
				sx={{ pt: '12px', pb: '12px' }}
			>
				<ListItemButton
					disableRipple
					disableTouchRipple
					sx={{ '&:hover': { backgroundColor: 'transparent' } }}
					onClick={handleClick}
				>
					<TypographyHeader>Поголовье</TypographyHeader>
				</ListItemButton>
				<IconButton
					disableRipple
					disableTouchRipple
					onClick={handleClick}
					sx={{
						pr: '16px',
						pt: '8px',
						'&:hover': { backgroundColor: 'transparent' },
					}}
				>
					{open ? <ArrowOpenDone /> : <ArrowCloseDone stroke={COLORS.icon}/>}
				</IconButton>
			</Box>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					<Box
						sx={{
							pl: 2,
							marginBottom: '24px',
						}}
					>
						<RangeDate
							onChangeUpdateDate={onChangeCallback}
							startDate={dateRange.startDate}
							endDate={dateRange.endDate}
							weekFilter={true}
							monthFilter={true}
							yearFilter={true}
							currentRange={'Неделя'}
						/>
					</Box>
					<Box
						sx={{ display: 'flex', flexDirection: 'column', gap: '4px', pl: 2 }}
					>
						{daysBetweenDates !== 0 ? (
							<TypographySecondary>В среднем</TypographySecondary>
						) : (
							<TypographySecondary>Сейчас</TypographySecondary>
						)}

						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								gap: '4px',
							}}
						>
							<TypographyPrimary16Bold>
								{Math.round(headCount)}
							</TypographyPrimary16Bold>
							<TypographyPrimary12>
								{morphForWords(Math.round(headCount), [
									'голова',
									'головы',
									'голов',
								])}
							</TypographyPrimary12>
						</Box>
						<TypographySecondary>В группе</TypographySecondary>
					</Box>
					<ListItemButton
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							paddingLeft: '12px',
							'&:hover': { backgroundColor: 'transparent' },
						}}
						disableRipple
						disableTouchRipple
					>
						<Box
							sx={{
								height: '250px',
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								marginTop: '24px',
							}}
						>
							{daysBetweenDates !== 0 ? (
								<GroupApexHeadQty
									fetchData={fetchData}
									loading={loading}
									daysBetweenDates={daysBetweenDates}
								/>
							) : (
								<GroupApexLineChartHeadQty
									group_id={props.group_id}
									fetchDayData={fetchDayData}
									dateRange={dateRange}
									loading={loading}
								/>
							)}
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								marginTop: '24px',
							}}
						>
							<span
								style={{
									width: '16px',
									height: '16px',
									borderRadius: '50%',
									backgroundColor: COLORS.main,
								}}
							></span>
							<TypographySecondary sx={{ marginLeft: '8px' }}>
								Головы
							</TypographySecondary>
						</Box>
						{daysBetweenDates !== 0 ? (
							<GroupHeadQtyLegend fetchData={fetchData} dateRange={dateRange} />
						) : (
							<GroupHeadQtyLegend
								fetchData={fetchDayData}
								dateRange={dateRange}
								daysBetweenDates={daysBetweenDates}
							/>
						)}
					</ListItemButton>
				</List>
			</Collapse>
		</List>
	)
}

export default GroupSpecificHeadChart
