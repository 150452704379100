import { Box, Collapse, Divider, Grid } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg'
import { ReactComponent as FilterArray } from '../../assets/filter_array.svg'
import GroupsApiService from '../../services/groupsApiService'
import { TypographyHeader14 } from '../../ui/typography/TypographyHeader14'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'
import { roundingNumber } from '../shared/processes'

const GroupEatenLegend = (props: {
	id
	startDate
	endDate
	daysBetweenDates
}) => {
	const [eatenLegendData, setEatenLegendData] = useState([])
	const [openHistory, setOpenHistory] = useState(true)

	const handleClick = () => {
		setOpenHistory(prevState => !prevState)
	}
	const handleReverseArray = () => {
		const reversedArray = [...eatenLegendData].reverse()
		setEatenLegendData(reversedArray)
	}
	useEffect(() => {
		if (props.startDate && props.endDate) {
			GroupsApiService.getHistoryForStateFoodChart(
				props.id,
				props.startDate,
				props.endDate
			)
				.then(response => {
					setEatenLegendData(response.data?.reverse())
				})
				.catch(error => {
					console.log(error)
				})
				.finally(() => {})
		}
		if (props.daysBetweenDates > 1) {
			setOpenHistory(false)
		} else {
			setOpenHistory(true)
		}
	}, [props.id, props.startDate, props.endDate])

	if (!eatenLegendData) return <></>

	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: '8px',
				}}
			>
				<TypographyHeader14>История изменений</TypographyHeader14>
				<ArrowOpenDone
					onClick={handleClick}
					style={{
						cursor: 'pointer',
						width: '14px',
						transform: openHistory ? 'rotate(0deg)' : 'rotate(180deg)',
					}}
				/>
			</Box>
			<Collapse in={openHistory} timeout='auto'>
				<Box sx={{ marginTop: '16px' }}>
					<Grid
						container
						sx={{ display: 'flex', flexDirection: 'row', marginTop: '16px' }}
					>
						<Grid item xs={3} sx={{ display: 'flex', flexDirection: 'row' }}>
							<TypographySecondary>Дата и время</TypographySecondary>
							<FilterArray
								onClick={handleReverseArray}
								style={{ marginLeft: '10px', cursor: 'pointer' }}
							/>
						</Grid>
						<Grid item xs={2}>
							<TypographySecondary>Действие</TypographySecondary>
						</Grid>
						<Grid item xs={2}>
							<TypographySecondary>Объем корма / Вес</TypographySecondary>
						</Grid>
						<Grid item xs={4}></Grid>
					</Grid>

					<Divider sx={{ marginTop: '8px' }} />
					<Box
						sx={{
							height: '245px',
							overflow: 'auto',
							'&::-webkit-scrollbar': {
								width: 4,
							},
							'&::-webkit-scrollbar-track': {
								backgroundColor: '#DBDBDB',
								borderRadius: 12,
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: '#5222D0',
								borderRadius: 12,
							},
						}}
					>
						{eatenLegendData.map(data => (
							<Grid
								key={data.id}
								container
								sx={{
									display: 'flex',
									flexDirection: 'row',
									marginTop: '16px',
								}}
							>
								<Grid item xs={3}>
									<TypographyPrimary>
										{props.daysBetweenDates === 0 ||
										props.daysBetweenDates === 1
											? moment(data.created_dt).format('HH:mm')
											: moment(data.created_dt).format('DD MMMM YYYY, HH:mm')}
									</TypographyPrimary>
								</Grid>
								<Grid item xs={2}>
									<TypographyPrimary>
										{data.type === 'cleaning' ? (
											'Уборка корма'
										) : data.type === 'distribution' ? (
											'Раздача корма'
										) : data.type === 'nudge' ? (
											'Подталкивание'
										) : (
											<></>
										)}
									</TypographyPrimary>
								</Grid>
								<Grid item xs={2}>
									<TypographyPrimary>
										{data.type === 'distribution' ? (
											`${
												data.weight === null
													? '-'
													: roundingNumber(data.weight, 1)
											}  м³ / ${
												data.weight_kg === null
													? '-'
													: data.weight_kg.toFixed(0)
											} кг`
										) : data.type === 'cleaning' ? (
											`${
												data.weight === null
													? '-'
													: roundingNumber(data.weight, 1)
											} м³ / ${
												data.weight_kg === null
													? '-'
													: roundingNumber(data.weight_kg, 0)
											} кг`
										) : (
											<></>
										)}
									</TypographyPrimary>
								</Grid>
								<Grid item xs={4}></Grid>
							</Grid>
						))}
					</Box>
					<TypographySecondary sx={{ marginTop: '24px' }}>
						Всего: {eatenLegendData.length}
					</TypographySecondary>
				</Box>
			</Collapse>
		</Box>
	)
}

export default GroupEatenLegend
