import { styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import { COLORS } from '../../styles/colors'

export const TypographyHeaderExtra = styled(Typography)(theme => ({
	fontWeight: 700,
	fontSize: '28px',
	lineHeight: '34px',
	color: COLORS.black,
}))
