import { Box } from '@mui/material'
import { COLORS } from 'styles/colors'

const LinearProgressBar = ({ reachable, unreachable }) => {
	return (
		<Box
			sx={{
				width: '100%',
				height: '4px',
				backgroundColor: reachable <= 2 ? COLORS.error : COLORS.inputBorder,
				position: 'relative',
				borderRadius: '4px',
			}}
		>
			{reachable > 2 && (
				<>
					<Box
						sx={{
							zIndex: '5',
							height: '100%',
							borderRadius: '4px',
							width: `${unreachable}%`,
							backgroundColor: COLORS.health,
							position: 'absolute',
						}}
					/>
					<Box
						sx={{
							height: '100%',
							left: `${unreachable === 0 ? 0 : unreachable - 1}%`,
							width: `${unreachable === 100 ? 0 : reachable}%`,
							backgroundColor: COLORS.success,
							borderRadius: '4px',
							position: 'absolute',
						}}
					/>
				</>
			)}
		</Box>
	)
}

export default LinearProgressBar
