import { Box } from '@mui/material'
import { ReactComponent as LactationLogo } from 'assets/lactation-logo.svg'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { TypographySecondary } from 'ui/typography/TypographySecondary'

const LactationWidjet = props => {
	const { dailyYield, lactationDay, lactationNumber } = props
	return (
		<CardStandart
			sx={{
				width: '100%',
				padding: '24px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
			}}
		>
			<Box display={'flex'} flexDirection={'row'} gap={'8px'} mr={'4px'}>
				<span>
					<LactationLogo fill={COLORS.secondary} />
				</span>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						gap: '8px',
					}}
				>
					<TypographyPrimary16Bold>
						{dailyYield?.toLocaleString()}{' '}
						<span
							style={{
								color: COLORS.secondary,
								fontWeight: 400,
								fontSize: '12px',
							}}
						>
							л
						</span>
					</TypographyPrimary16Bold>
					<TypographySecondary>Надой</TypographySecondary>
				</Box>
			</Box>

			<TypographySecondary alignSelf={'flex-end'}>
				{lactationDay +
					' день лактации' +
					` (№${lactationNumber && lactationNumber})`}
			</TypographySecondary>
		</CardStandart>
	)
}

export default LactationWidjet
