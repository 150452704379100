import { useEffect, useState } from 'react';
import { useAppDispatch } from '../store/hooks';
import { receiveMessage } from '../store/websocketSlice';
import { serverName } from '../variables'

const useWebSocket = (userData, apiToken) => {
  const [socket, setSocket] = useState(null);
  const dispatch = useAppDispatch();
  const serverNameForWs = "wss" + serverName.substring(serverName.indexOf(':'))
  
  useEffect(() => {
    if (userData.id && apiToken && !socket) {
      const ws = new WebSocket(`${serverNameForWs}ws/${userData.id}?token=${apiToken}`);

      ws.addEventListener('open', () => {
        
      });

      ws.addEventListener('message', (event) => {
        const messageData = JSON.parse(event.data);
        dispatch(receiveMessage(messageData));
      });

      ws.addEventListener('close', () => {
        
      });

      setSocket(ws);
    }

    return () => {
      if (socket) {
        socket.close();
        setSocket(null);
      }
    };
  }, [dispatch, userData?.id, apiToken, socket]);

  return socket;
};

export default useWebSocket;
