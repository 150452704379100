import { styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import { COLORS } from '../../styles/colors'

export const TypographyHeader = styled(Typography)(theme => ({
	fontWeight: 700,
	fontSize: '20px',
	lineHeight: '24px',
	color: COLORS.black,
}))
