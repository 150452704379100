import { IDescription } from './models'

export const generateColorsAndValuesArray = (array: IDescription[]) => {
	const categories = []
	const colors = []

	for (let i = 0; i < array?.length; i++) {
		categories.push(array[i].value)
		colors.push(array[i].color)
	}
	return { categories, colors }
}
