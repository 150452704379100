import { Box, Typography } from '@mui/material'

import { ReactComponent as ClockIconError } from 'assets/clock_error_group_logo.svg'
import { ReactComponent as RationIconError } from 'assets/ration_group_error_logo.svg'
import { ReactComponent as CameraIconError } from 'assets/translation_error_logo.svg'

import { EDeviationState } from 'models/EDeviation'
import moment from 'moment'

const DeviationItem = props => {
	return (
		<Box>
			<Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '24px' }}>
				<Box sx={{ marginRight: '15px' }}>
					{props.item.type === EDeviationState.Ration ? (
						<RationIconError />
					) : (
						<></>
					)}
					{props.item.type === EDeviationState.Feedplan ||
					EDeviationState.FeedplanWeight ? (
						<ClockIconError />
					) : (
						<></>
					)}
					{props.item.type === EDeviationState.Camera ? (
						<CameraIconError />
					) : (
						<></>
					)}
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: '8px',
						}}
					>
						<Typography
							sx={{ fontSize: '16px', fontWeight: '600', lineHeight: '19.5px' }}
						>
							{props.item.title}
						</Typography>
						<Typography
							sx={{
								fontSize: '14px',
								fontWeight: '400',
								lineHeight: '16.8px',
								color: '#7f7f84',
							}}
						>
							{moment(props.item.created_dt).format('HH:mm')}
						</Typography>
					</Box>
					<Box
						sx={{
							width: 'min-content',
							fontSize: '14px',
							padding: '8px 16px',
							background: '#f7f7f7',
							borderRadius: '12px',
							whiteSpace: 'nowrap',
							marginBottom: '8px',
						}}
					>
						{props.item.group.title}
					</Box>
					<Typography
						sx={{ fontSize: '14px', fontWeight: '400', lineHeight: '16.8px' }}
					>
						{props.item.description}
					</Typography>
				</Box>
			</Box>
		</Box>
	)
}

export default DeviationItem
