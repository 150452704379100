import { Box } from '@mui/material'
import SelectSmall from 'ui/menu/SelectForm'
import { TypographySecondary } from 'ui/typography/TypographySecondary'

const FatnessContentBlock = props => {
	const { fatness, setActiveFilters } = props
	return (
		<Box
			display={'flex'}
			flexDirection={'row'}
			alignItems={'center'}
			gap={'16px'}
			flexWrap={'wrap'}
		>
			<SelectSmall
				typeArray={typeForFilter}
				isCheckbox={false}
				width={'120px'}
				isMultiplaySelect={false}
				value={fatness.startOf}
				height={'54px'}
				placeholder={'Балл'}
				startAdornmentDesc={'от'}
				onClick={type => {
					setActiveFilters(prev => {
						return {
							...prev,
							fatness: {
								...prev.fatness,
								startOf: type,
							},
						}
					})
				}}
			/>
			<TypographySecondary>-</TypographySecondary>
			<SelectSmall
				typeArray={typeForFilter}
				value={fatness.endOf}
				width={'120px'}
				isCheckbox={false}
				isMultiplaySelect={false}
				height={'54px'}
				placeholder={'Балл'}
				startAdornmentDesc={'до'}
				onClick={type => {
					setActiveFilters(prev => {
						return {
							...prev,
							fatness: {
								...prev.fatness,
								endOf: type,
							},
						}
					})
				}}
			/>
		</Box>
	)
}

export default FatnessContentBlock

const typeForFilter = [
	{ type: 1, value: '1' },
	{ type: 1.25, value: '1,25' },
	{ type: 1.5, value: '1,5' },
	{ type: 1.75, value: '1,75' },
	{ type: 2, value: '2' },
	{ type: 2.25, value: '2,25' },
	{ type: 2.5, value: '2,5' },
	{ type: 2.75, value: '2,75' },
	{ type: 3, value: '3' },
	{ type: 3.25, value: '3,25' },
	{ type: 3.5, value: '3,5' },
	{ type: 3.75, value: '3,75' },
	{ type: 4, value: '4' },
	{ type: 4.25, value: '4,25' },
	{ type: 4.5, value: '4,5' },
	{ type: 4.75, value: '4,75' },
	{ type: 5, value: '5' },
]
