import { getOneMonthDate } from 'components/shared/processes'
import { ILineGraph } from 'models/ICattlesData'
import { useEffect, useState } from 'react'
import CattlesApiService from 'services/cattlesApiServices'

const useFatnessInfoForGraph = activeFilter => {
	const monthDates = getOneMonthDate()
	const [dateRange, setDateRange] = useState({
		startDate: monthDates.startDate,
		endDate: monthDates.endDate,
	})
	const [filteredFatnessArray, setFilteredFatnessArray] = useState<
		ILineGraph[]
	>([])

	useEffect(() => {
		CattlesApiService.getFatnessGraph(
			dateRange.startDate,
			dateRange.endDate,
			activeFilter === 'all' ? null : activeFilter
		).then(response => setFilteredFatnessArray(response.lines))
	}, [activeFilter, dateRange])

	const onChangeCallback = value => {
		setDateRange({
			startDate: value[0],
			endDate: value[1],
		})
	}
	const { perfectCategories, goodCategories, criticalCategories, timestamp } =
		filteredFatnessArray.reduce(
			(acc, category) => {
				const categoryData = category.dots.map(el => el.value)
				const categoryTimestamps = category.dots.map(el => el.date)

				if (category.label === 'Идеальная') {
					acc.perfectCategories.push(...categoryData)
					acc.timestamp.push(...categoryTimestamps)
				} else if (category.label === 'В норме') {
					acc.goodCategories.push(...categoryData)
				} else if (category.label === 'Критическая') {
					acc.criticalCategories.push(...categoryData)
				}

				return acc
			},
			{
				perfectCategories: [],
				goodCategories: [],
				criticalCategories: [],
				timestamp: [],
			}
		)

	return {
		perfectCategories,
		goodCategories,
		criticalCategories,
		timestamp,
		onChangeCallback,
		dateRange,
	}
}

export default useFatnessInfoForGraph
