import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axAPI from '../http'

import {
	IDataOnChangeHandlerParameters,
} from '../models'
import { IFeed, IFeedFact, IFeedReducer } from '../models/IFeed'

export const fetchNewFeedPlanData = createAsyncThunk(
	'feedplans/data',
	async function (params: { date: string }, { rejectWithValue }) {
		try {
			const response = await axAPI({
				method: 'GET',
				url: `feedplans/${params.date}`,
			})

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data
		} catch (error) {
			let errorMessage = 'Failed to retrive feed plan!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)

export const feedNewPlanFactDataOnChange = createAsyncThunk(
	'feedplans/create_update_fact',
	async function (data: IDataOnChangeHandlerParameters, { rejectWithValue }) {
		const requestData = {
			weight_input: data.weight,
		}

		try {
			const url = `feedplans/${data.feedPlanDate}/${data.feedPlanId}/groups/${data.feedGroupId}`
			const response = await axAPI({
				method: 'PATCH',
				url: url,
				data: requestData,
			})

			if (response.status !== 200 && response.status !== 201) {
				throw new Error('HTTP request error!')
			}
			return {
				index: data.factIndex,
				callBack: data.callBack,
				...response.data,
			}
		} catch (error) {
			let errorMessage = 'Failed to update feed plan fact data!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)

const initialState: IFeedReducer = {
	loading: false,
	error: '',
	feedPlanData: [],
}

export const feedNewPlanDataSlice = createSlice({
	name: 'feedplans',
	initialState,
	reducers: {
		setNewWeightValue(state, action) {
			const { feedPlanId, feedGroupId, weight } = action.payload
			const foundIndex = state.feedPlanData.findIndex(
				item => item.id === feedPlanId
			)
			if (foundIndex !== -1) {
				const feedPlanDataCopy = [...state.feedPlanData]
				const feedFacts = feedPlanDataCopy[foundIndex].feed_facts
				const updatedFeedFacts = feedFacts.map(feedFact => {
					if (feedFact.group.id === feedGroupId) {
						return {
							...feedFact,
							weight: weight,
							has_fact_data_demand: false,
						}
					}
					return feedFact
				})
				feedPlanDataCopy[foundIndex].feed_facts = updatedFeedFacts

				state.feedPlanData = feedPlanDataCopy
			}
		},
	},
	extraReducers: {
		[fetchNewFeedPlanData.fulfilled.type]: (
			state,
			action: PayloadAction<IFeed[]>
		) => {
			state.loading = false
			state.error = ''
			state.feedPlanData = action.payload
		},
		[fetchNewFeedPlanData.pending.type]: state => {
			state.loading = true
			state.error = ''
		},
		[fetchNewFeedPlanData.rejected.type]: (
			state,
			action: PayloadAction<string>
		) => {
			state.loading = false
			state.error = action.payload
		},
	},
})

export const { setNewWeightValue } = feedNewPlanDataSlice.actions
export default feedNewPlanDataSlice.reducer
