import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import React from 'react'
import { COLORS } from '../../../styles/colors'

type PhoneEditFieldProps = {
	label: string
	key?: string
	id?: any
	sx?: any
	value?: any
	isPassword?: boolean
	helperText?: string
	isError?: boolean
	marginLeft?: string
	isErrorFocus?: boolean
	onChange?: (e?) => void
	setServerErrorText: any
	onBlur?: any
	onFocus?: any
	error: string
}

export const PhoneEditField = (props: PhoneEditFieldProps) => {
	const [inputValue, setInputValue] = React.useState(
		props.value ? props.value : ''
	)

	const [showPass, setShowPass] = React.useState(!props.isPassword)

	const [focused, setFocused] = React.useState(false)

	let sx = {
		width: '500px',
		minHeight: '72px',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: props.isErrorFocus ? COLORS.deviationFont : '#ced4da',
				borderWidth: props.isErrorFocus ? '2px' : '1px',
			},
			'&:hover fieldset': {
				borderColor: props.isErrorFocus ? COLORS.deviationFont : 'black',
			},
			'&.Mui-focused fieldset': {
				borderColor: props.isErrorFocus ? COLORS.deviationFont : COLORS.main,
			},
		},
		'&.MuiOutlinedInput-notchedOutline': {
			'&.Mui-focused fieldset': {
				borderColor: COLORS.main,
			},
		},
	}

	const inputChangeHandler = el => {
		let value = el.target.value.replace(/\D/g, '')
		let selectionStart = el.target.selectionStart
		let formattedInputValue = ''

		if (el.target.value.length != selectionStart) {
			// Editing in the middle of input, not last symbol
			if (el.data && /\D/g.test(el.data)) {
				// Attempt to input non-numeric symbol
				el.target.value = value
			}
			return
		}

		if (['7', '8', '9'].indexOf(value[0]) > -1) {
			if (value[0] == '9') value = '7' + value
			let firstSymbols = value[0] == '8' ? '+7' : '+7'
			formattedInputValue = el.target.value = firstSymbols + ' '
			if (value.length > 1) {
				formattedInputValue += '(' + value.substring(1, 4)
			}
			if (value.length >= 5) {
				formattedInputValue += ') ' + value.substring(4, 7)
			}
			if (value.length >= 8) {
				formattedInputValue += '-' + value.substring(7, 9)
			}
			if (value.length >= 10) {
				formattedInputValue += '-' + value.substring(9, 11)
			}
		} else {
			formattedInputValue = '+' + value.substring(0, 13)
		}
		setInputValue(formattedInputValue)
		props.onChange(formattedInputValue)
	}

	const keyDownHandler = e => {
		let value = e.target.value.replace(/\D/g, '')
		if (e.keyCode === 8 && value.length === 1) {
			setInputValue('')
		}
	}

	const handleBlur = () => {
		setFocused(false)
		props.onBlur()
	}

	const handleFocus = () => {
		props.setServerErrorText('')
		setFocused(true)
		props.onFocus()
	}

	return (
		<TextField
			helperText={
				props.isErrorFocus ? props.helperText : props.error ? props.error : ''
			}
			error={!!props.error}
			margin='dense'
			key={props.id}
			id={props.id}
			label={props.label}
			autoComplete={'off'}
			value={inputValue ? inputValue : props.value}
			onKeyDown={e => keyDownHandler(e)}
			onChange={inputChangeHandler}
			onBlur={handleBlur}
			onFocus={handleFocus}
			sx={props.sx ? props.sx : sx}
			variant='outlined'
			FormHelperTextProps={{
				style: {
					fontSize: '12px',
					lineHeight: '15px',
					fontWeight: 400,
					color:
						props.isErrorFocus || props.error
							? COLORS.deviationFont
							: COLORS.icon,
					marginLeft: '0px',
				},
			}}
			inputProps={{
				autoComplete: 'off',
			}}
			InputProps={{
				style: {
					fontSize: '14px',
					lineHeight: '17px',
					fontWeight: 400,
					color: props.isError ? COLORS.deviationFont : COLORS.black,
					borderRadius: '8px',
					borderColor: focused ? COLORS.main : COLORS.icon,
				},
				endAdornment: props.isPassword ? (
					<InputAdornment position='end'>
						<IconButton
							aria-label='toggle password visibility'
							onClick={() => setShowPass(!showPass)}
						>
							{showPass ? <Visibility /> : <VisibilityOff />}
						</IconButton>
					</InputAdornment>
				) : (
					<></>
				),
			}}
			InputLabelProps={{
				style: {
					fontSize: '14px',
					lineHeight: '17px',
					fontWeight: 400,
					color: props.isErrorFocus
						? COLORS.deviationFont
						: focused
						? COLORS.main
						: props.error
						? COLORS.deviationFont
						: COLORS.icon,
				},
			}}
		/>
	)
}
