import { Box } from '@mui/material'

import { ReactComponent as TimeIcon } from '../../assets/clock_group_logo.svg'
import { ReactComponent as FoodIcon } from '../../assets/food_icon.svg'
import { ReactComponent as CameraIcon } from '../../assets/translation_deviations_logo.svg'

import { EDeviationState } from 'models/EDeviation'
import moment from 'moment'
import React from 'react'
import { TypographyHeader14 } from '../../ui/typography/TypographyHeader14'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import { TypographySecondary14 } from '../../ui/typography/TypographySecondary14'

const DeviationItem = props => {
	const getMonthName = month => {
		const monthNames = [
			'января',
			'февраля',
			'марта',
			'апреля',
			'мая',
			'июня',
			'июля',
			'августа',
			'сентября',
			'октября',
			'ноября',
			'декабря',
		]
		return monthNames[month]
	}
	return (
		<React.Fragment>
			{props.showDate && (
				<TypographySecondary14 sx={{ marginBottom: '16px' }}>
					{`${moment(props.item.created_dt).format('D')} ${getMonthName(
						moment(props.item.created_dt).month()
					)}`}
				</TypographySecondary14>
			)}
			<Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '24px' }}>
				<Box sx={{ marginRight: '8px' }}>
					{props.item.type === EDeviationState.Ration && (
						<FoodIcon style={{ height: '16px', width: '16px' }} />
					)}
					{(props.item.type === EDeviationState.Feedplan ||
						props.item.type === EDeviationState.FeedplanWeight) && (
						<TimeIcon style={{ height: '16px', width: '16px' }} />
					)}
					{(props.item.type === EDeviationState.Camera ||
						props.item.type === EDeviationState.CameraIssue) && (
						<CameraIcon style={{ height: '16px', width: '16px' }} />
					)}
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						paddingRight: '12px',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: '4px',
						}}
					>
						<TypographyHeader14>{props.item.title}</TypographyHeader14>
						<TypographyPrimary>
							{moment(props.item.created_dt).format('HH:mm')}
						</TypographyPrimary>
					</Box>
					<TypographyPrimary sx={{ width: '90%' }}>
						{props.item.description}
					</TypographyPrimary>
				</Box>
			</Box>
		</React.Fragment>
	)
}

export default DeviationItem
