import { Box } from '@mui/material'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { ReactComponent as TagNumberIcon } from '../../../../assets/icons/tag-icon.svg'

const CowPhotoCardViewer = props => {
	const { images } = props
	const tagImage = images?.find(el => el.angle === 'tag')

	const renderImages = () => {
		const renderedImages = images?.slice(0, 3).map((el, index) => (
			<img
				key={index}
				style={{
					width: '30%',
					objectFit: 'cover',
					borderRadius: '4px',
					border: `1px solid ${COLORS.inputBorder}`,
				}}
				src={el.image}
				alt={`cow-preview${index}`}
			/>
		))

		const emptyPhotosCount = Math.max(0, 3 - images?.length)
		for (let i = 0; i < emptyPhotosCount; i++) {
			renderedImages.push(<EmptyPhoto key={`empty-photo-${i}`} noPhoto />)
		}

		return renderedImages
	}

	return (
		<CardStandart
			sx={{
				p: '24px',
				display: 'flex',
				flexDirection: 'column',
				gap: '16px',
				height: '100%',
			}}
		>
			{tagImage ? (
				<Box height={'70%'}>
					<img
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'cover',
							borderRadius: '4px',
							border: `1px solid ${COLORS.inputBorder}`,
						}}
						src={tagImage.image}
						alt={'tag-number'}
					/>
				</Box>
			) : (
				<EmptyPhoto noTag />
			)}
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					height: '30%',
					gap: '16px',
				}}
			>
				{renderImages()}
			</Box>
		</CardStandart>
	)
}

export default CowPhotoCardViewer

interface IEmptyPhotoProps {
	noTag?: boolean
	noPhoto?: boolean
}

const EmptyPhoto = (props: IEmptyPhotoProps) => {
	const { noTag, noPhoto } = props
	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				border: `1px solid ${COLORS.inputBorder}`,
				borderRadius: '4px',
				bgcolor: COLORS.background,
			}}
		>
			{noPhoto && (
				<TypographySecondary
					sx={{ fontSize: '10px', color: COLORS.inputBorder, textAlign: 'center' }}
				>
					Нет фото
				</TypographySecondary>
			)}
			{noTag && <TagNumberIcon />}
		</Box>
	)
}
