import axAPI from '../http'

export default class FoodtableApiService {
	static getFoodtableReachAndUnreachInfo = async () => {
		return axAPI.get('foodtable/graph')
	}
	static getDashboardStatistics = async () => {
		return axAPI.get('feedplan/statistic')
	}
}
