import Skeleton from 'react-loading-skeleton'
import { CardStandart } from 'ui/CardStandart'

interface IEmptyCardProps {
	height: string
	needTitle?: boolean
	heightTitle?: string
}
const SkeletonEmptyCard = (props: IEmptyCardProps) => {
	const { height, needTitle, heightTitle } = props
	return (
		<CardStandart
			sx={{
				width: '100%',
				padding: '24px',
			}}
		>
			{needTitle && (
				<span style={{ marginBottom: '16px' }}>
					<Skeleton height={heightTitle} />
				</span>
			)}
			<Skeleton height={height} />
		</CardStandart>
	)
}

export default SkeletonEmptyCard
