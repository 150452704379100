import React, { ReactElement, forwardRef, Dispatch, SetStateAction } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { COLORS } from 'styles/colors';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 12,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: '#000',
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.15)',
    '& .MuiMenu-list': {
      padding: '8px 0px',
    },
    '& .Mui-selected': {
      backgroundColor: 'transparent',
      color: COLORS.main,
    },
    '& .MuiMenuItem-root': {
      fontSize: '14px',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:hover': {
        backgroundColor: COLORS.mainActiveOpacity,
      },
      '&:active': {
        color: COLORS.main,
        backgroundColor: 'transparent',
      },
    },
  },
}));

const CustomButton = styled(Button)(({ open }: { open: boolean }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  borderRadius: '16px',
  fontSize: 16,
  padding: '8px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: 'transparent',
  borderColor: open ? COLORS.main : COLORS.inputBorder,
  '&:hover': {
    backgroundColor: 'transparent',
    borderColor: COLORS.secondary,
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderColor: COLORS.main,
  },
  '&:focus': {
    boxShadow: 'none',
    borderColor: COLORS.main,
  },
}));

type IMenuDesc = {
  description: string;
  value: string | number;
  icon?: ReactElement;
};

interface Props {
  menuDescription: IMenuDesc[];
  textTitle: ReactElement;
  isArrow?: boolean;
  onClick: (value: string | number) => void;
  selectedMenu: (string | number)[];
}

const DefaultSelectForm = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { menuDescription, isArrow, textTitle, onClick, selectedMenu, ...rest } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton
        ref={ref}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        disableRipple
        onClick={handleClick}
        open={open}
        endIcon={
          isArrow ? (
            <KeyboardArrowDownIcon
              style={{
                fill: open ? COLORS.main : COLORS.secondary,
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          ) : undefined
        }
        {...rest}
      >
        {textTitle}
      </CustomButton>
      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuDescription.map((el, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              onClick(el.value);
              setAnchorEl(null);
            }}
            disableRipple
            selected={selectedMenu.includes(el.value)}
          >
            {el.icon && el.icon}
            {el.description}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
});

export default DefaultSelectForm;
