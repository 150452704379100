import { getDatesArray, getDaysBetweenDates } from 'components/shared/processes'
import { ICowGraphResponse } from 'models/ICattlesData'
import { useEffect, useState } from 'react'
import CattlesApiService from 'services/cattlesApiServices'

const useMilkingsInfoForGraph = cowId => {
	const [milkingInfo, setMilkingInfo] = useState<ICowGraphResponse>()
	const weekDays = getDatesArray(6)
	const [dateRange, setDateRange] = useState({
		startDate: weekDays.startDate,
		endDate: weekDays.endDate,
	})
	const daysDifference = getDaysBetweenDates(dateRange)

	const onChangeCallback = value => {
		setDateRange({
			startDate: value[0],
			endDate: value[1],
		})
	}

	useEffect(() => {
		if (cowId) {
			CattlesApiService.getMilkingGraphByCowId(
				cowId,
				dateRange.startDate,
				dateRange.endDate
			).then(response => setMilkingInfo(response))
		}
	}, [dateRange, cowId])

	const categories =
		daysDifference !== 0
			? milkingInfo?.graph?.map(el => el.milk_sum)
			: milkingInfo?.graph[0].milkings.map(el => el.milk_amount)

	const timestamp =
		daysDifference !== 0
			? milkingInfo?.graph?.map(el => el.date)
			: milkingInfo?.graph[0].milkings.map(el => el.timestamp)

	return {
		dateRange,
		milkingInfo,
		categories,
		timestamp,
		onChangeCallback,
		daysDifference,
	}
}

export default useMilkingsInfoForGraph
