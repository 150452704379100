import { SubmitHandler, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useNavigate } from 'react-router-dom'

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { IconButton, InputAdornment } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { ThemeProvider } from '@mui/system'
import { useEffect, useState } from 'react'
import { ReactComponent as BlindPassLogo } from '../assets/blind_pass_logo.svg'
import { ReactComponent as DoneLogo } from '../assets/reset_password_done.svg'
import { ReactComponent as UnBlindPassLogo } from '../assets/unblind_pass_logo.svg'
import useAuth from '../hook/useAuth'
import axAPI from '../http'
import { clearPermissions, fetchPermissions } from '../store/authSlice'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { COLORS } from '../styles/colors'
import { fontTheme } from '../styles/font'
import { AlertText } from '../ui/AlertText'
import PasswordInput from '../ui/PasswordInput'
import PinCodeInput from '../ui/PinCodeInput'
import { ArrowButton, OutlinedButton } from '../ui/buttons/OutlinedButton'
import { TypographyHeader } from '../ui/typography/TypographyHeader'
import { TypographyHeaderExtra } from '../ui/typography/TypographyHeaderExtra'
import { TypographyPrimary } from '../ui/typography/TypographyPrimary'
import { TypographyPrimary500 } from '../ui/typography/TypographyPrimary500'

interface IFormInput {
	phone: string
	password: string
}

const theme = fontTheme

const LoginPage = props => {
	const authState = useAuth()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const { authRejected, loading, permissions } = useAppSelector(
		state => state.authReducer
	)

	const { register, handleSubmit, watch } = useForm<IFormInput>()

	const onSubmit: SubmitHandler<IFormInput> = (data, event) => {
		const phoneNumber = data.phone.replace(/[^\d+]/g, '')
		authState.signInAndContinue(phoneNumber, data.password)
	}

	useEffect(() => {
		setSubmitError(authRejected)
	}, [loading])

	const [submitError, setSubmitError] = useState(false)
	const watchPhone = watch('phone')
	const watchPass = watch('password')

	const [showPass, setShowPass] = useState(false)
	const handleClickShowPass = () => setShowPass(!showPass)

	const passwordRegex = /^[A-Za-z0-9!-@#$%.^,&*()_{}[\];:"/|^]+$/
	const [step, setStep] = useState(1)
	const [resetPage, setResetPage] = useState(false)
	const [phoneNumber, setPhoneNumber] = useState('')
	const [email, setEmail] = useState('')
	const [responseConfirmData, setResponseConfirmData] = useState({
		uid: '',
		token: '',
	})
	const [newPassword, setNewPassword] = useState('')
	const [confirmNewPassword, setConfirmNewPassword] = useState('')
	const samePassword =
		newPassword === confirmNewPassword &&
		passwordRegex.test(newPassword) &&
		passwordRegex.test(confirmNewPassword) &&
		newPassword !== '' &&
		confirmNewPassword !== ''

	if (authState.token) {
		dispatch(fetchPermissions())

		navigate('/cattles', { replace: true })
		if (permissions?.can_view_foodtable_main) {
			navigate('feed', { replace: true })
		} else if (permissions?.can_view_cattle_groups) {
			navigate('/feed/groups', { replace: true })
		} else if (permissions?.can_view_rations) {
			navigate('/feed/ration', { replace: true })
		} else if (permissions?.can_view_feedplans) {
			navigate('/feed/plan', { replace: true })
		} else if (permissions?.can_view_analytics) {
			navigate('/feed/analytics', { replace: true })
		} else if (permissions?.can_manage_users) {
			navigate('/settings', { replace: true })
		} else {
			navigate('/profile', { replace: true })
		}
	}

	const handleStepChange = () => {
		setStep(step + 1)
	}

	const handlePreviousStep = () => {
		setStep(step - 1)
		setSubmitError(false)
	}
	const handlePhoneNumberChange = e => {
		setPhoneNumber(e.target.value.replace(/[^\d+]/g, ''))
		setSubmitError(false)
	}

	const postResetPassword = () => {
		axAPI({
			method: 'POST',
			url: 'auth/password/reset',
			data: { mobile_phone: phoneNumber },
		})
			.then(response => {
				setEmail(response.data.email)
				if (step !== 3) {
					handleStepChange()
				}
			})
			.catch(error => {
				setSubmitError(true)
			})
	}

	const postResetConfirmPassword = () => {
		axAPI({
			method: 'POST',
			url: 'auth/users/reset_password_confirm/',
			data: {
				uid: responseConfirmData.uid,
				token: responseConfirmData.token,
				new_password: newPassword,
			},
		}).then(response => {
			setResetPage(true)
			handleStepChange()
		})
	}

	function hideEmail(email) {
		const [username, domain] = email.split('@')
		const firstTwoChars = username.substring(0, 2)
		const beforeAtSymbol = username.substring(username.length - 2)
		const hiddenEmail = `${firstTwoChars}*****${beforeAtSymbol}@${domain}`
		return hiddenEmail
	}

	const renderContent = () => {
		switch (step) {
			case 1:
				return (
					<>
						<TypographyHeaderExtra>Вход</TypographyHeaderExtra>
						<Box
							sx={{ marginTop: '20px' }}
							component='form'
							onSubmit={handleSubmit(onSubmit)}
						>
							<TextField
								sx={{
									mb: '20px',
									borderRadius: '12px',
									width: '100%',
									'& .MuiOutlinedInput-root': {
										'& fieldset': {
											borderColor: submitError
												? COLORS.error
												: COLORS.inputBorder,
										},
										'&:hover fieldset': {
											borderColor: COLORS.icon,
										},
										'&.Mui-focused fieldset': {
											border: `1px solid ${COLORS.main}`,
										},
									},
									'& label': {
										color: submitError ? COLORS.error : COLORS.secondaryFont,
									},
									'&:hover label': {
										color: COLORS.icon,
									},
									'& label.Mui-focused': {
										color: COLORS.main,
									},
								}}
								id='phone'
								label='Номер телефона'
								autoFocus
								autoComplete='tel'
								{...register('phone')}
								onClick={() => {
									setSubmitError(false)
								}}
								InputProps={{
									style: {
										fontSize: '14px',
										borderRadius: '8px',
										margin: '0px',
										padding: '0px',
									},
									inputComponent: InputMask as any,
									inputProps: {
										mask: '+7 999 999 99 99',
										maskChar: '',
									},
								}}
								InputLabelProps={{
									style: { fontSize: '14px' },
								}}
							/>
							<TextField
								sx={{
									mb: 5,
									borderRadius: '12px',
									width: '100%',
									'& .MuiOutlinedInput-root': {
										'& fieldset': {
											borderColor: submitError
												? COLORS.error
												: COLORS.inputBorder,
										},
										'&:hover fieldset': {
											borderColor: COLORS.icon,
										},
										'&.Mui-focused fieldset': {
											border: `1px solid ${COLORS.main}`,
										},
									},
									'& label': {
										color: submitError ? COLORS.error : COLORS.secondaryFont,
									},
									'&:hover label': {
										color: COLORS.icon,
									},
									'& label.Mui-focused': {
										color: COLORS.main,
									},
								}}
								margin='normal'
								id='password'
								type={showPass ? 'text' : 'password'}
								label='Пароль'
								autoComplete='password'
								{...register('password')}
								onClick={() => {
									dispatch(clearPermissions())
									setSubmitError(false)
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onMouseDown={handleClickShowPass}
												disableRipple
											>
												{showPass ? <UnBlindPassLogo /> : <BlindPassLogo />}
											</IconButton>
										</InputAdornment>
									),
									style: {
										fontSize: '14px',
										borderRadius: '8px',
										margin: '0px',
										paddingRight: '12px',
									},
								}}
								InputLabelProps={{
									style: { fontSize: '14px' },
								}}
							/>
							{submitError === true && (
								<Box
									sx={{
										backgroundColor: 'rgba(235, 77, 61, 0.2)',
										height: 'min-content',
										width: '100%',
										borderRadius: '12px',
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<AlertText>
										Вы ввели неверный номер телефона или пароль
									</AlertText>
								</Box>
							)}

							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<OutlinedButton
									disableRipple
									type='submit'
									sx={{
										width: '100%',
										textTransform: 'none',
										marginBottom: '16px',
									}}
									disabled={watchPhone && watchPass ? false : true}
								>
									Войти
								</OutlinedButton>
								<Typography
									onClick={() => {
										handleStepChange()
										setSubmitError(false)
									}}
									sx={{
										cursor: 'pointer',
										display: 'flex',
										justifyContent: 'center',
										mt: 1,
										color: 'rgb(82, 34, 208)',
										fontWeight: 400,
										fontSize: 14,
									}}
								>
									Забыли пароль?
								</Typography>
							</Box>
						</Box>
					</>
				)
			case 2:
				return (
					<>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							<ArrowBackIosNewOutlinedIcon
								onClick={handlePreviousStep}
								sx={{
									cursor: 'pointer',
									marginRight: '18px',
									color: '#7F7F84',
								}}
							/>
							<TypographyHeaderExtra>Забыли пароль?</TypographyHeaderExtra>
						</Box>
						<TypographyPrimary sx={{ marginTop: '32px' }}>
							Для смены пароля, введите номер телефона:
						</TypographyPrimary>
						<TextField
							sx={{
								marginTop: '32px',
								borderRadius: '12px',
								width: '100%',
								'& .MuiOutlinedInput-root': {
									'& fieldset': {
										borderColor: submitError
											? COLORS.error
											: COLORS.inputBorder,
									},
									'&:hover fieldset': {
										borderColor: COLORS.icon,
									},
									'&.Mui-focused fieldset': {
										border: `1px solid ${COLORS.main}`,
									},
								},
								'& label': {
									color: submitError ? COLORS.error : COLORS.secondaryFont,
								},
								'&:hover label': {
									color: COLORS.icon,
								},
								'& label.Mui-focused': {
									color: COLORS.main,
								},
							}}
							id='phone'
							label='Номер телефона'
							autoFocus
							onChange={e => handlePhoneNumberChange(e)}
							onClick={() => setSubmitError(false)}
							InputProps={{
								style: {
									fontSize: '14px',
									borderRadius: '8px',
									margin: '0px',
									padding: '0px',
								},
								inputComponent: InputMask as any,
								inputProps: {
									mask: '+7 999 999 99 99',
									maskChar: '',
								},
							}}
							InputLabelProps={{
								style: { fontSize: '14px' },
							}}
						/>
						{submitError === true && (
							<Box
								sx={{
									marginTop: '50px',
									backgroundColor: 'rgba(235, 77, 61, 0.2)',
									height: 'min-content',
									width: '100%',
									borderRadius: '12px',
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<AlertText>
									Данный номер телефона не найден. Убедитесь, что указан верный
									номер.
								</AlertText>
							</Box>
						)}
						<OutlinedButton
							disableRipple
							onClick={postResetPassword}
							type='submit'
							sx={{
								width: '100%',
								textTransform: 'none',
							}}
							disabled={phoneNumber.length === 12 ? false : true}
							endIcon={
								<ArrowButton>
									<ArrowForwardIcon sx={{ color: '#fff' }} />
								</ArrowButton>
							}
						>
							Продолжить
						</OutlinedButton>
					</>
				)
			case 3:
				return (
					<>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								marginBottom: '32px',
							}}
						>
							<ArrowBackIosNewOutlinedIcon
								onClick={handlePreviousStep}
								sx={{
									cursor: 'pointer',
									marginRight: '18px',
									color: '#7F7F84',
								}}
							/>
							<TypographyHeaderExtra>Смена пароля</TypographyHeaderExtra>
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								margin: '0 auto',
							}}
						>
							<TypographyPrimary>
								Код подтверждения был отправлен на почту:
							</TypographyPrimary>
							<TypographyPrimary500
								sx={{ marginTop: '16px', marginBottom: '32px' }}
							>
								{hideEmail(email)}
							</TypographyPrimary500>
						</Box>
						<Box sx={{ margin: '0 auto' }}>
							<PinCodeInput
								postResetPassword={postResetPassword}
								submitError={submitError}
								setSubmitError={setSubmitError}
								setResponseConfirmData={setResponseConfirmData}
								handleStepChange={handleStepChange}
							/>
						</Box>
					</>
				)
			case 4:
				return (
					<>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								marginBottom: '32px',
							}}
						>
							<ArrowBackIosNewOutlinedIcon
								onClick={handlePreviousStep}
								sx={{
									cursor: 'pointer',
									marginRight: '18px',
									color: '#7F7F84',
								}}
							/>
							<TypographyHeaderExtra>Смена пароля</TypographyHeaderExtra>
						</Box>
						<Box sx={{ marginBottom: '32px', width: '100%' }}>
							<PasswordInput
								id={'newPassword'}
								setNewPassword={setNewPassword}
								label={'Новый пароль'}
								needTooltip
							/>
						</Box>
						<PasswordInput
							id={'confirmNewPassword'}
							setConfirmNewPassword={setConfirmNewPassword}
							label={'Повторите новый пароль'}
							samePassword={samePassword}
							confirmNewPassword={confirmNewPassword}
						/>
						<OutlinedButton
							disableRipple
							type='submit'
							sx={{
								width: '100%',
								textTransform: 'none',
								marginBottom: '16px',
							}}
							onClick={postResetConfirmPassword}
							disabled={
								!samePassword ||
								newPassword.length <= 7 ||
								confirmNewPassword.length <= 7 ||
								!/(?=.*?[A-Z])(?=.*[a-z])/.test(newPassword) ||
								!/(?=.*?[A-Z])(?=.*[a-z])/.test(confirmNewPassword)
									? true
									: false
							}
						>
							Изменить пароль
						</OutlinedButton>
					</>
				)
			case 5:
				return (
					<>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								width: '100%',
								padding: '0px 20px',
							}}
						>
							<DoneLogo />
							<TypographyHeader sx={{ marginTop: '24px' }}>
								Пароль успешно изменён
							</TypographyHeader>

							<TypographyPrimary sx={{ marginTop: '16px' }}>
								Авторизуйтесь под новыми данными.
							</TypographyPrimary>
							<OutlinedButton
								disableRipple
								type='submit'
								sx={{
									width: '100%',
									textTransform: 'none',
									marginTop: '24px',
									backgroundColor: 'transparent',
								}}
								onClick={() => {
									setResetPage(false)
									setStep(1)
								}}
							>
								Войти
							</OutlinedButton>
						</Box>
					</>
				)
		}
	}

	return (
		<ThemeProvider theme={theme}>
			<Container
				component='main'
				sx={{ fontFamily: theme.typography.fontFamily, 
				width:'100%',
				height:'90vh',
				display:'flex',
				alignItems:'center',
				justifyContent: 'center',
				}}
			>
				<CssBaseline />
				<Box
					sx={{
						display: 'flex',
						padding: '32px 24px',
						flexDirection: 'column',
						backgroundColor: resetPage ? 'transparent' : 'white',
						maxWidth: '448px',
						height: 'auto',
						borderRadius: '12px',
						boxShadow: resetPage ? 'none' : '0px 4px 16px rgba(0, 0, 0, 0.08)',
					}}
				>
					{renderContent()}
				</Box>
			</Container>
		</ThemeProvider>
	)
}

export default LoginPage
