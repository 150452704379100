import {
	Box,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'

import { useEffect, useState } from 'react'
import { ReactComponent as FilterArray } from '../../assets/filter_array.svg'
import { ReactComponent as CopyLogo } from '../../assets/icons/copy-logo.svg'
import { ReactComponent as ManagementEditLogo } from '../../assets/management_edit_logo.svg'
import { ReactComponent as RestoreLogo } from '../../assets/restore_logo.svg'
import { ReactComponent as TrashLogo } from '../../assets/trash_logo.svg'

import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'

import { COLORS } from '../../styles/colors'
import ManagementPopup from './ManagementPopup'

import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { fetchUsersList, setUserActivityFlag } from '../../store/userSlice'
import { getFirstWordsFromName } from '../../utils'
import CustomTooltip from '../shared/CustomTooltip'
import { copyTextToClipboard } from '../shared/processes'

const ManagementTable = props => {
	const blankPopupProps = {
		open: false,
		userName: '',
		active: false,
		title: '',
		status: '',
	}

	const [managmentPopupProps, setShowManagmentPopup] = useState(blankPopupProps)

	const [shouldReverseList, setShouldReverseList] = useState(false)
	const [shouldRFilteredByRoleList, setShouldRFilteredByRoleList] =
		useState(false)
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(fetchUsersList())
	}, [])

	const { usersList, userData, userDataPending } = useAppSelector(
		store => store.userReducer
	)
	const { permissions } = useAppSelector(state => state.authReducer)
	const renderUsersListRows = (usersList, active) => {
		const displayedUsersList = shouldReverseList
			? [...usersList].reverse()
			: shouldRFilteredByRoleList
			? filterUsersByRoles(usersList)
			: usersList

		return displayedUsersList
			?.filter(el => el.is_active === active)
			.map(row => (
				<TableRow
					key={row.id}
					sx={{
						'&:hover': {
							background: COLORS.mainSecondaryOpacity,
						},
					}}
				>
					<TableCell>
						<Box
							sx={{
								minWidth: '200px',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								gap: '8px',
							}}
						>
							{row.avatar === null ? (
								<Box
									sx={{
										width: '40px',
										height: '40px',
										background: COLORS.background,
										borderRadius: '50%',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center',
										alignItems: 'center',
										color: COLORS.main,
										fontWeight: '600',
									}}
								>
									{getFirstWordsFromName(row.firstname, row.lastname)}
								</Box>
							) : (
								<img
									src={row.avatar}
									alt='Avatar'
									style={{ width: '40px', height: '40px', borderRadius: '50%' }}
								/>
							)}
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<TypographyPrimary>{`${row.lastname} ${row.firstname}`}</TypographyPrimary>
								<TypographyPrimary sx={{ color: COLORS.secondaryFont }}>
									{row.position}
								</TypographyPrimary>
							</Box>
						</Box>
					</TableCell>
					<TableCell>
						<Box
							sx={{
								width: '200px',
								display: 'flex',
								flexDirection: 'column',
								gap: '8px',
							}}
						>
							<Box
								sx={{
									width: 'max-content',
									display: 'flex',
									flexDirection: 'row',
									gap: '8px',
									alignItems: 'center',
								}}
							>
								<TypographyPrimary sx={{ wordWrap: 'break-word' }}>
									{row.email}
								</TypographyPrimary>
								{row.email && (
									<CopyLogo
										style={{ cursor: 'pointer' }}
										onClick={() => copyTextToClipboard(row.email, dispatch)}
									/>
								)}
							</Box>
							<Box
								sx={{
									width: 'max-content',
									display: 'flex',
									flexDirection: 'row',
									gap: '8px',
									alignItems: 'center',
								}}
							>
								<TypographyPrimary>{row.mobile_phone}</TypographyPrimary>
								{row.mobile_phone && (
									<CopyLogo
										style={{ cursor: 'pointer' }}
										onClick={() =>
											copyTextToClipboard(row.mobile_phone, dispatch)
										}
									/>
								)}
							</Box>
						</Box>
					</TableCell>
					<TableCell>
						<Box>
							<TypographyPrimary>Всего: {row.session_count}</TypographyPrimary>
							<TypographyPrimary sx={{ color: COLORS.secondaryFont }}>
								Последний сеанс:
							</TypographyPrimary>
							<TypographyPrimary sx={{ color: COLORS.secondaryFont }}>
								{row.last_login}
							</TypographyPrimary>
						</Box>
					</TableCell>
					<TableCell
						sx={{
							display: 'flex',
							flexDirection: 'row',
							gap: '8px',
							height: '100px',
						}}
					>
						<TypographyPrimary>
							{row.role_title ? row.role_title : 'Не указана'}
						</TypographyPrimary>
						<CustomTooltip
							placement={'top'}
							width={'16px'}
							height={'16px'}
							info={true}
							title={
								row.role_title === 'Администратор'
									? 'Доступ ко всем разделам и функциональным возможностям, в том числе к администрированию учетных записей.'
									: row.role_title === 'Руководитель'
									? 'Доступ к просмотру всех разделов, без возможности создания/редактирования.'
									: row.role_title === 'Зоотехник'
									? 'Доступ ко всем разделам, в том числе к возможности создания/редактирования.'
									: row.role_title === 'Механизатор'
									? 'Доступ к просмотру плана кормления.'
									: ''
							}
						/>
					</TableCell>
					<TableCell>
						{permissions.can_manage_permissions && (
							<Box display='flex' flexDirection='row' sx={{ pl: '8px' }}>
								{userData.id !== row.id ? (
									<>
										<Box
											onClick={() =>
												navigate(`/settings/management/${row.id}/edit`)
											}
										>
											<ManagementEditLogo style={{ cursor: 'pointer' }} />
										</Box>
										<Box
											onClick={() =>
												fillManagmentPopupProps(
													row.id,
													`${row.lastname} ${row.firstname}`,
													active
												)
											}
											sx={{ pl: '12px' }}
										>
											{active ? (
												<TrashLogo style={{ cursor: 'pointer' }} />
											) : (
												<RestoreLogo style={{ cursor: 'pointer' }} />
											)}
										</Box>
									</>
								) : (
									<></>
								)}
							</Box>
						)}
					</TableCell>
				</TableRow>
			))
	}

	const fillManagmentPopupProps = (userId, userName, active) => {
		const newManagmentPopupProps = {
			open: true,
			userId: userId,
			userName: userName,
			active: active,
			title: active ? 'Удаление' : 'Восстановление',
			status: active ? 'Удалить' : 'Восстановить',
			onClose: () => {
				setShowManagmentPopup(blankPopupProps)
			},
			handleClose: () => {
				dispatch(setUserActivityFlag({ userId: userId, active: !active }))
				setShowManagmentPopup(blankPopupProps)
			},
		}
		setShowManagmentPopup(newManagmentPopupProps)
	}
	const filterUsersByRoles = users => {
		const filteredUsers = {
			administrators: [],
			zookeepers: [],
			managers: [],
			mechanists: [],
		}

		for (const user of users) {
			if (user.role_title === 'Администратор') {
				filteredUsers.administrators.push(user)
			} else if (user.role_title === 'Зоотехник') {
				filteredUsers.zookeepers.push(user)
			} else if (user.role_title === 'Руководитель') {
				filteredUsers.managers.push(user)
			} else if (user.role_title === 'Механизатор') {
				filteredUsers.mechanists.push(user)
			}
		}

		const mergedFilteredUsers = [
			...filteredUsers.administrators,
			...filteredUsers.zookeepers,
			...filteredUsers.managers,
			...filteredUsers.mechanists,
		]

		return mergedFilteredUsers
	}

	const handleReverseList = () => {
		setShouldRFilteredByRoleList(false)
		setShouldReverseList(prevState => !prevState)
	}

	const handleFilteredByRoleList = () => {
		setShouldReverseList(false)
		setShouldRFilteredByRoleList(prevState => !prevState)
	}

	return (
		<Box sx={{ pt: '24px', pl: '20px', pr: '24px' }}>
			{userDataPending ? (
				<Box
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						marginTop: '20%',
					}}
				>
					<CircularProgress
						sx={{
							'&.MuiCircularProgress-colorPrimary': { color: COLORS.main },
						}}
					/>
				</Box>
			) : (
				<TableContainer
					sx={{
						boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
						backgroundColor: 'white',
						borderRadius: '12px',
					}}
				>
					<Table
						sx={{
							[`& .${tableCellClasses.root}`]: {
								borderBottom: 'none',
								padding: '24px',
							},
						}}
					>
						<TableHead>
							<TableRow>
								<TableCell
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										gap: '8px',
									}}
								>
									<Box>
										<TypographySecondary>Фамилия Имя</TypographySecondary>
										<TypographySecondary>Должность</TypographySecondary>
									</Box>
									<FilterArray
										onClick={handleReverseList}
										style={{ cursor: 'pointer' }}
									/>
								</TableCell>
								<TableCell>
									<TypographySecondary>Электронная почта</TypographySecondary>
									<TypographySecondary>и номер телефона</TypographySecondary>
								</TableCell>
								<TableCell>
									<TypographySecondary>Авторизация</TypographySecondary>
								</TableCell>
								<TableCell
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										gap: '8px',
										height: '100px',
									}}
								>
									<TypographySecondary>Роль</TypographySecondary>
									<FilterArray
										onClick={handleFilteredByRoleList}
										style={{ cursor: 'pointer' }}
									/>
								</TableCell>
								<TableCell>
									<TypographySecondary>Действия</TypographySecondary>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{usersList && renderUsersListRows(usersList, true)}
						</TableBody>
						{/* {(usersList && usersList.filter(el => el.is_active === false).length > 0) ?
          <><TableHead >
            <TableRow >
              <TableCell sx={{ pt: '24px' }}>
                <TypographyPrimary sx={{ color: COLORS.secondaryFont }}>Неактивные пользователи</TypographyPrimary>
              </TableCell>
            </TableRow>
          </TableHead>
            <TableBody>
              {renderUsersListRows(usersList, false)}
            </TableBody></>
          : <></>
        } */}
					</Table>
				</TableContainer>
			)}

			<ManagementPopup {...managmentPopupProps} />
		</Box>
	)
}

export default ManagementTable
