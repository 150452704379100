import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchGroupList } from 'store/groupsSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { ButtonUI } from 'ui/buttons/ButtonUI'
import {
	validateGroupArray,
	validateGroupsArray,
} from '../utils/validateGroupsArray'
import FarmFillGroupListItem from './FarmFillGroupListItem'

const FarmFillGroupList = () => {
	const [isOkay, setIsOkay] = useState<boolean | null>(null)
	useEffect(() => {
		if (groupList.length === 0) {
			dispatch(fetchGroupList())
		}
	}, [])
	const urlParams = useParams()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const { groupList } = useAppSelector(store => store.groupsReducer)
	useEffect(() => {
		setIsOkay(validateGroupsArray(groupList))
	}, [groupList])
	return (
		<>
			{groupList.map(group => {
				const doneLogo = validateGroupArray(group)
				return (
					<FarmFillGroupListItem
						key={group.id}
						barnTitle={group.barntitle}
						id={group.id}
						title={group.title}
						doneLogo={Boolean(doneLogo)}
					/>
				)
			})}

			<Box display='flex' justifyContent='flex-end'>
				<ButtonUI
					onClick={() => navigate(`/fill/${urlParams.id}/feedplan`)}
					sx={{
						width: '600px',
						height: '55px',
						mt: '48px',
						textTransform: 'none',
					}}
					disabled={!isOkay}
				>
					Сохранить и перейти к следующему шагу
				</ButtonUI>
			</Box>
		</>
	)
}

export default FarmFillGroupList
