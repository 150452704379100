import {
	Box,
	Collapse,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	tableCellClasses,
} from '@mui/material'
import { roundingNumber } from 'components/shared/processes'
import { ICowYieldsGraphInfo } from 'models/ICattlesData'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { TypographyHeader14 } from 'ui/typography/TypographyHeader14'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import { ReactComponent as ArrowOpenDone } from '../../../../assets/arrow_open_logo.svg'
import { ReactComponent as FilterArray } from '../../../../assets/filter_array.svg'

const YieldQtyHistory = props => {
	const { milkingInfo } = props
	const [visibleElement, setVisibleElement] = useState<ICowYieldsGraphInfo[]>(
		[]
	)
	const [openLegend, setOpenLegend] = useState(true)
	const [openDayYieldQty, setOpenDayYieldQty] = useState<string[]>([])

	useEffect(() => {
		const reverseArr = milkingInfo.reverse()
		setVisibleElement(reverseArr)
	}, [milkingInfo])

	const handleReverseArray = () => {
		const reversedArray = [...visibleElement].reverse()
		setVisibleElement(reversedArray)
	}

	const handleOpenDayYieldQty = (day: string) => {
		const findElement = openDayYieldQty.find(el => el === day)

		if (findElement) {
			setOpenDayYieldQty(prevState => {
				const newOpenDates = prevState.filter(el => el !== day)
				return newOpenDates
			})
		} else {
			setOpenDayYieldQty(prevState => [...prevState, day])
		}
	}

	return (
		<Box display={'flex'} flexDirection={'column'} gap={'16px'}>
			<Box
				display={'flex'}
				flexDirection={'row'}
				gap={'8px'}
				alignItems={'center'}
			>
				<TypographyHeader14>История изменений</TypographyHeader14>
				<ArrowOpenDone
					onClick={() => setOpenLegend(prevState => !prevState)}
					style={{
						cursor: 'pointer',
						width: '14px',
						transform: openLegend ? 'rotate(0deg)' : 'rotate(180deg)',
					}}
				/>
			</Box>
			<Collapse in={openLegend} timeout='auto'>
				<TableContainer
					sx={{
						overflowY: 'auto',
						overflowX: 'hidden',
						height: '350px',
						'&::-webkit-scrollbar': {
							width: 4,
						},
						'&::-webkit-scrollbar-track': {
							backgroundColor: '#DBDBDB',
							borderRadius: 12,
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#5222D0',
							borderRadius: 12,
						},
					}}
				>
					<Table stickyHeader>
						<TableHead
							sx={{
								[`& .${tableCellClasses.root}`]: {
									backgroundColor: '#fff',
								},
							}}
						>
							<TableRow sx={{ width: '100%', height: '40px' }}>
								<TableCell align={'left'} padding={'none'} width={'20%'}>
									<Box
										display={'flex'}
										flexDirection={'row'}
										alignItems={'center'}
										gap={'4px'}
									>
										<TypographySecondary>Дата и время</TypographySecondary>
										<FilterArray
											style={{ cursor: 'pointer' }}
											onClick={handleReverseArray}
										/>
									</Box>
								</TableCell>
								<TableCell align={'left'} padding={'none'} width={'10%'}>
									<TypographySecondary>№ доения</TypographySecondary>
								</TableCell>
								<TableCell align={'left'} padding={'none'} width={'70%'}>
									<TypographySecondary>Надои, литр</TypographySecondary>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{visibleElement.map((el, index) => (
								<React.Fragment key={index}>
									<TableRow
										sx={{
											height: '40px',
											[`& .${tableCellClasses.root}`]: {
												borderBottom: 'none',
											},
										}}
									>
										<TableCell align={'left'} padding={'none'} width={'20%'}>
											<Box
												display={'flex'}
												flexDirection={'row'}
												alignItems={'center'}
												gap={'4px'}
											>
												<TypographyPrimary>
													{moment(el.date).format('D.MM.YY')}
												</TypographyPrimary>
												{el.milkings.length > 0 && (
													<ArrowOpenDone
														onClick={() => handleOpenDayYieldQty(el.date)}
														style={{
															cursor: 'pointer',
															width: '14px',
															transform: openDayYieldQty.includes(el.date)
																? 'rotate(0deg)'
																: 'rotate(180deg)',
														}}
													/>
												)}
											</Box>
										</TableCell>
										<TableCell
											align={'left'}
											padding={'none'}
											width={'10%'}
										></TableCell>
										<TableCell align={'left'} padding={'none'} width={'70%'}>
											<TypographyPrimary>
												{roundingNumber(el.milk_sum, 1)} л
											</TypographyPrimary>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell
											sx={{
												borderBottom: 'none',
											}}
											colSpan={3}
											padding={'none'}
										>
											<Collapse
												in={openDayYieldQty.includes(el.date)}
												timeout='auto'
												unmountOnExit
											>
												<Table size='small' aria-label='purchases'>
													<TableBody>
														{el.milkings
															.slice()
															.reverse()
															.map((milk, index) => (
																<TableRow
																	key={index}
																	sx={{
																		[`& .${tableCellClasses.root}`]: {
																			borderBottom: 'none',
																			padding: '2px 0',
																		},
																	}}
																>
																	<TableCell
																		width={'20%'}
																		align={'left'}
																		padding={'none'}
																	>
																		<TypographySecondary14>
																			{moment(milk.timestamp).format(
																				'DD.MM.YY HH:mm:ss'
																			)}
																		</TypographySecondary14>
																	</TableCell>
																	<TableCell
																		width={'10%'}
																		align={'left'}
																		padding={'none'}
																	>
																		<TypographySecondary14>
																			{index + 1}
																		</TypographySecondary14>
																	</TableCell>
																	<TableCell
																		width={'70%'}
																		align={'left'}
																		padding={'none'}
																	>
																		<TypographySecondary14>
																			{roundingNumber(milk.milk_amount, 1) +
																				' л'}
																		</TypographySecondary14>
																	</TableCell>
																</TableRow>
															))}
													</TableBody>
												</Table>
											</Collapse>
										</TableCell>
									</TableRow>
								</React.Fragment>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<TypographySecondary>
					{'Всего: ' + `${visibleElement?.length}`}
				</TypographySecondary>
			</Collapse>
		</Box>
	)
}

export default YieldQtyHistory
