import { Box, IconButton } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import { ReactComponent as DoneLogo } from 'assets/done_logo.svg'
import { ReactComponent as FillBackArrow } from 'assets/fill_back_arrow.svg'

import { CardStandart } from 'ui/CardStandart'
import { OutlinedButton } from 'ui/buttons/OutlinedButton'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'

const FarmFillDonePage = () => {
	const navigate = useNavigate()
	const urlParams = useParams()

	return (
		<CardStandart
			sx={{
				mr: '150px',
				width: '50%',
				margin: '5% 0 0 20%',
				'&:hover': { boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)' },
			}}
		>
			<IconButton
				onClick={() => navigate(`/fill/${urlParams.id}/feedplan`)}
				sx={{ m: '8px' }}
			>
				<FillBackArrow stroke={'#7F7F84'} />
			</IconButton>
			<Box display='flex' justifyContent='center'>
				<DoneLogo />
			</Box>
			<TypographyHeader
				sx={{ display: 'flex', justifyContent: 'center', pb: '16px' }}
			>
				Поздравляем!
			</TypographyHeader>
			<Box sx={{ textAlign: 'center' }}>
				<TypographyPrimary sx={{ whiteSpace: 'pre-line' }}>
					Вы заполнили данные по рационам, группам и плану кормления. {'\n'}{' '}
					Теперь Вам доступны все функции системы “SMART FARM КОРМОВОЙ СТОЛ”.
				</TypographyPrimary>
				<TypographyPrimary sx={{ pt: '24px' }}>
					Хорошей работы!
				</TypographyPrimary>
				<OutlinedButton sx={{ mb: '32px' }} onClick={() => navigate('/feed')}>
					Начать работу
				</OutlinedButton>
			</Box>
		</CardStandart>
	)
}

export default FarmFillDonePage
