import Box from '@mui/material/Box/Box'
import { ReactComponent as ChatIcon } from '../../assets/icons/chat_icon.svg'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { TypographySecondary14 } from '../../ui/typography/TypographySecondary14'
import { ProfileItem } from './utils/ProfileItem'

const FeedbackBlock = () => {
	return (
		<Box sx={{ width: '100%', minHeight: 'auto', cursor: 'pointer' }}>
			<ProfileItem>
				<a
					href='https://smartfarm.vision/help'
					target='_blank'
					rel='noopener noreferrer'
					style={{
						display: 'block',
						width: '100%',
						textDecoration: 'none',
						color: 'inherit',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '8px',
						}}
					>
						<ChatIcon />
						<TypographyHeader>Обратная связь</TypographyHeader>
					</Box>
					<TypographySecondary14 sx={{ marginTop: '16px' }}>
						Задать вопрос, оставить предложение или сообщить об ошибке
					</TypographySecondary14>
				</a>
			</ProfileItem>
		</Box>
	)
}

export default FeedbackBlock
