import { Box } from '@mui/material'
import { IAvailableRolelist, IPermissionList } from 'models/IUserData'
import { useEffect, useState } from 'react'
import { TypographyHeader14 } from 'ui/typography/TypographyHeader14'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

import React from 'react'
import { ReactComponent as SettingLogo } from '../../assets/setting_logo.svg'
import { ReactComponent as AnalyticsIcon } from '../../assets/sidebar/analytics_icon.svg'
import { ReactComponent as EatingPlanIcon } from '../../assets/sidebar/eating_plan_icon.svg'
import { ReactComponent as FeedTableIcon } from '../../assets/sidebar/feed_table_icon.svg'
import { ReactComponent as GroupsIcon } from '../../assets/sidebar/groups_icon.svg'
import { ReactComponent as RationsIcon } from '../../assets/sidebar/rations_icon.svg'

type IRoleModelInfoProps = {
	choiceList: IAvailableRolelist[]
	currentValue: number | string
}

const RoleModelInfo = (props: IRoleModelInfoProps) => {
	const [currentRole, setCurrentRole] = useState<IPermissionList[]>([])
	useEffect(() => {
		const selectRole = props.choiceList.find(
			el =>
				(typeof props.currentValue === 'string' &&
					el.title === props.currentValue.toString()) ||
				(typeof props.currentValue === 'number' && el.id === props.currentValue)
		)
		setCurrentRole(selectRole?.perms_list)
	}, [props.currentValue, props.choiceList])
	return (
		<Box mt='24px'>
			<TypographyHeader14>Функционал роли</TypographyHeader14>
			{currentRole
				.filter(el => el.parent === null)
				.map((parentRole, index) => (
					<ul key={index} style={{ padding: '0px' }}>
						<Box
							display='flex'
							flexDirection='row'
							alignItems='center'
							gap='8px'
							mt='24px'
						>
							{(parentRole.name === 'can_view_cattle_groups' && (
								<GroupsIcon stroke='#7F7F84' />
							)) ||
								(parentRole.name === 'can_view_analytics' && (
									<AnalyticsIcon fill='#7F7F84' />
								)) ||
								(parentRole.name === 'can_view_rations' && (
									<RationsIcon stroke='#7F7F84' />
								)) ||
								(parentRole.name === 'can_view_foodtable_main' && (
									<FeedTableIcon stroke='#7F7F84' />
								)) ||
								(parentRole.name === 'can_view_feedplans' && (
									<EatingPlanIcon stroke='#7F7F84' />
								)) ||
								(parentRole.name === 'can_manage_users' && (
									<SettingLogo stroke='#7F7F84' />
								))}
							<TypographyHeader14>{parentRole.title}</TypographyHeader14>
						</Box>
						<TypographySecondary14 mt='16px'>
							{parentRole.description}
						</TypographySecondary14>

						{currentRole
							.filter(childRole => childRole.parent === parentRole.name)
							.map((childRole, childIndex) => (
								<React.Fragment key={childIndex}>
									{childIndex === 0 && (
										<TypographySecondary14 mt='24px'>
											Дополнительный функционал
										</TypographySecondary14>
									)}
									<li
										style={{
											marginTop: '8px',
											listStyleType: 'none',
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<div
											style={{
												width: '2px',
												height: '2px',
												borderRadius: '50%',
												background: 'black',
												marginRight: '8px',
											}}
										></div>
										<TypographyPrimary>{childRole.title}</TypographyPrimary>
									</li>
								</React.Fragment>
							))}
					</ul>
				))}
		</Box>
	)
}

export default RoleModelInfo
