import { Box } from '@mui/material'
import FatnessCard from 'components/cattles/fatnessPage/ui/FatnessCard'
import FatnessList from 'components/cattles/fatnessPage/ui/FatnessList'
import BreadCrumbsGroup from 'components/shared/breadCrumbs/BreadCrumbsGroup'
import { useEffect, useState } from 'react'
import { fetchLightGroupList } from 'store/groupsSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'

const FatnessPage = () => {
	const [activeFilter, setActiveFilter] = useState('all')

	const dispatch = useAppDispatch()
	useEffect(() => {
		!lightGroupList && dispatch(fetchLightGroupList())
	}, [activeFilter])
	const { lightGroupList } = useAppSelector(state => state.groupsReducer)

	const handleActiveFilter = name => {
		setActiveFilter(name)
	}

	return (
		<Box
			sx={{
				padding: '0 16px',
				display: 'flex',
				flexDirection: 'column',
				gap: '24px',
			}}
		>
			<BreadCrumbsGroup
				handleActiveFilter={handleActiveFilter}
				array={lightGroupList}
			/>
			<FatnessCard activeFilter={activeFilter} />
			<FatnessList activeFilter={activeFilter} />
		</Box>
	)
}

export default FatnessPage
