import Box from '@mui/material/Box/Box'
import { ReactComponent as PlusLogo } from '../../assets/plan_edit_plus_logo.svg'
import { TypographySecondaryBold16 } from '../typography/TypographySecondaryBold16'

export const AddButtonEvent = ({ title }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				width: '100%',
				height: '48px',
				backgroundColor: '#fff',
				borderRadius: '8px',
				border: '1px solid #7f7f84',
				padding: '12px 24px',
				cursor: 'pointer',
			}}
		>
			<Box></Box>
			<TypographySecondaryBold16>{title}</TypographySecondaryBold16>
			<PlusLogo style={{ width: '24px', height: '24px' }} />
		</Box>
	)
}
