import {
	Box,
	Checkbox,
	InputAdornment,
	OutlinedInput,
	ThemeProvider,
	useTheme,
} from '@mui/material'
import { ReactComponent as CheckBox } from 'assets/icons/checkBox.svg'
import CheckBoxActive from 'assets/icons/checkBoxActive'
import { roundingNumber } from 'components/shared/processes'
import React from 'react'
import { customTheme } from 'ui/buttons/ButtonStyle'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { lamenessStatuses } from '../processes'

const icon = <CheckBox />
const checkedIcon = <CheckBoxActive />

const LamenessContentBlock = props => {
	const { toggleFilter, lameness, handleDayChange } = props
	const outerTheme = useTheme()
	return (
		<>
			<ul>
				{lamenessStatuses.map(point => (
					<li
						onClick={() => toggleFilter('lameness', point.key)}
						key={point.id}
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							padding: '6px 0px',
						}}
					>
						<Checkbox
							icon={icon}
							disableRipple
							checkedIcon={checkedIcon}
							style={{ padding: '0px 8px 0px 0px', margin: '0px' }}
							checked={lameness.statuses.includes(point.key)}
						/>
						<TypographyPrimary sx={{ cursor: 'pointer' }}>
							{point.title}
						</TypographyPrimary>
					</li>
				))}
			</ul>
			<TypographyPrimary>Продолжительность, дни</TypographyPrimary>
			<ThemeProvider theme={customTheme(outerTheme)}>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'center'}
					gap={'12px'}
					marginTop={'16px'}
					flexWrap={'wrap'}
				>
					<OutlinedInput
						label='none'
						notched={false}
						type='number'
						error={
							lameness.startOf < 0 || lameness.startOf > lameness.endOf
								? true
								: false
						}
						placeholder='Дни'
						value={lameness.startOf ? roundingNumber(lameness.startOf, 0) : ''}
						endAdornment={
							<InputAdornment
								sx={{
									position: 'absolute',
									left:
										roundingNumber(lameness.startOf, 0)?.toString().length <= 3
											? '25px'
											: roundingNumber(lameness.startOf, 0)?.toString()
													.length <= 5
											? '15px'
											: '2px',
								}}
								position='end'
							>
								{lameness.startOf ? (
									<TypographySecondary>от</TypographySecondary>
								) : (
									''
								)}
							</InputAdornment>
						}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleDayChange(e, 'lameness', 'startOf')
						}
						sx={{
							position: 'relative',
							width: '120px',
							'& input': {
								textAlign: 'center',
							},
						}}
					/>

					<TypographySecondary>-</TypographySecondary>
					<OutlinedInput
						label='none'
						notched={false}
						type='number'
						error={
							lameness.endOf < 0 || lameness.endOf < lameness.startOf
								? true
								: false
						}
						placeholder='Дни'
						value={lameness.endOf ? roundingNumber(lameness.endOf, 0) : ''}
						endAdornment={
							<InputAdornment
								sx={{
									position: 'absolute',
									left:
										roundingNumber(lameness.endOf, 0)?.toString().length <= 3
											? '25px'
											: roundingNumber(lameness.endOf, 0)?.toString().length <=
											  5
											? '15px'
											: '2px',
								}}
								position='end'
							>
								{lameness.endOf ? (
									<TypographySecondary>до</TypographySecondary>
								) : (
									''
								)}
							</InputAdornment>
						}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleDayChange(e, 'lameness', 'endOf')
						}
						sx={{
							position: 'relative',
							width: '120px',
							'& input': {
								textAlign: 'center',
							},
						}}
					/>
				</Box>
			</ThemeProvider>
		</>
	)
}

export default LamenessContentBlock
