import { CssBaseline, ThemeProvider } from '@mui/material'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'
import store from './store'
import { fontTheme } from './styles/font'

import 'overlayscrollbars/css/OverlayScrollbars.min.css'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const theme = fontTheme

ReactDOM.render(
	<Provider store={store}>
		<SkeletonTheme baseColor='#F7F7F7' highlightColor='#fff'>
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<App />
				</ThemeProvider>
			</BrowserRouter>
		</SkeletonTheme>
	</Provider>,
	document.getElementById('root')
)
