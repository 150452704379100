import Drawer from '@mui/material/Drawer'
import { drawerWidth } from '../variables'

import { ReactComponent as SmartFarmLogo } from '../assets/smart_farm_logo.svg'

import { Box } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { fetchPermissions, selectPermissions } from '../store/authSlice'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { COLORS } from '../styles/colors'
import SidebarMainMenu from './SidebarMainMenu'
import SidebarSettings from './SidebarSettings'

const theme = createTheme({
	components: {
		MuiDrawer: {
			styleOverrides: {
				paper: {
					backgroundColor: '#5222D0',
					overflow: 'hidden',
					padding: '2rem 1rem',
					borderRadius: '0px 12px 12px 0px',
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				h4: {
					color: '#fff',
					fontWeight: 'bold',
				},
			},
		},
	},
})

const Sidebar = ({
	setOpenNotifications,
	countNotif,
	openNotifications,
	notificationButtonRef,
}) => {
	const dispatch = useAppDispatch()
	const location = useLocation()
	const permissions = useAppSelector(selectPermissions)

	useEffect(() => {
		if (!permissions) {
			dispatch(fetchPermissions())
		}
	}, [])
	return (
		<ThemeProvider theme={theme}>
			{location.pathname.includes('fill') ? (
				<Box sx={{ pt: '24px', pl: '40px' }}>
					<SmartFarmLogo fill={COLORS.main} />
				</Box>
			) : (
				<Drawer
					sx={{
						width: drawerWidth,

						flexShrink: 0,
						'& .MuiDrawer-paper': {
							width: drawerWidth,
							boxSizing: 'border-box',
							display: 'flex',
							justifyContent: 'space-between',
						},
					}}
					variant='permanent'
					anchor='left'
				>
					<Box display='flex' flexDirection='column'>
						<NavLink
							to={
								permissions && permissions.can_view_foodtable_main
									? '/feed'
									: '/feed/plan'
							}
						>
							<SmartFarmLogo width={drawerWidth === 70 ? '30px' : '100px'} fill='white' />
						</NavLink>
						<SidebarMainMenu />
					</Box>

					<Box sx={{ position: 'sticky', }}>
						<SidebarSettings
							countNotif={countNotif}
							setOpenNotifications={setOpenNotifications}
							openNotifications={openNotifications}
							notificationButtonRef={notificationButtonRef}
						/>
					</Box>
				</Drawer>
			)}
		</ThemeProvider>
	)
}

export default Sidebar
