import { StyleSheet, Text, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		marginTop: '16px',
	},
	row: {
		flexDirection: 'row',
		alignItems: 'center',
		border: '1px solid #7f7f84',
		borderTopLeftRadius: '16%',
		borderTopRightRadius: '16%',
	},
	descriptionLower: {
		height: '36px',
		padding: '8px 4px 0px 8px',
		width: '14%',
		borderRight: '1px solid #7f7f84',
		fontSize: '7px',
		lineHeight: 'normal',
		fontWeight: 400,
		color: '#7F7F84',
	},
	descriptionWidther: {
		height: '36px',
		padding: '8px 4px 0px 8px',
		width: '19%',
		borderRight: '1px solid #7f7f84',
		fontSize: '7px',
		lineHeight: 'normal',
		fontWeight: 400,
		color: '#7F7F84',
	},
})
const TableHeader = () => {
	return (
		<View style={styles.container}>
			<View style={styles.row}>
				<Text style={styles.descriptionLower}>Номер раздачи</Text>
				<Text style={styles.descriptionWidther}>Плановое время раздачи</Text>
				<Text style={styles.descriptionWidther}>Фактическое время раздачи</Text>
				<Text style={styles.descriptionWidther}>Вес раздачи</Text>
				<Text style={styles.descriptionWidther}>Фактический вес раздачи</Text>
				<Text style={styles.descriptionLower}>Остатки</Text>
				<Text style={styles.descriptionWidther}>Количество подталкиваний</Text>
				<Text
					style={{
						height: '36px',
						padding: '8px 4px 0px 8px',
						width: '14%',
						fontSize: '7px',
						lineHeight: 'normal',
						fontWeight: 400,
						color: '#7F7F84',
					}}
				>
					ПСВ
				</Text>
			</View>
		</View>
	)
}

export default TableHeader
