export const tableListAdapter = list => {
	const result = {}
	list.forEach(item => {
		const value = item.find(obj => obj.value)?.value

		if (value) {
			if (!result[value]) {
				result[value] = []
			}

			result[value].push(item)
		}
	})
	const groupedArrays = Object.values(result)
	groupedArrays.sort((a, b) => {
		const dateA = new Date(a[0].find(obj => obj.value)?.value)?.getTime() || 0
		const dateB = new Date(b[0].find(obj => obj.value)?.value)?.getTime() || 0
		return dateB + dateA
	})

	return groupedArrays
}
