import { TextField } from '@mui/material'
import { COLORS } from 'styles/colors'

type InputWithoutRestrictionsProps = {
	value: string
	onChange: (e) => void
	isMultiline: boolean
	label: string
	maxLength?: number
	rows?: number
	width?: string
	height?: string
	isDisable?: boolean
	isError?: boolean
	helperText?: string
}

const InputWithoutRestrictions = (props: InputWithoutRestrictionsProps) => {
	const {
		value,
		onChange,
		isMultiline,
		label,
		rows,
		maxLength,
		width,
		height,
		isDisable,
		isError,
		helperText,
	} = props
	return (
		<TextField
			error={isError}
			multiline={isMultiline}
			variant='outlined'
			label={label}
			helperText={helperText}
			disabled={isDisable}
			rows={rows}
			value={value}
			sx={{
				opacity: !isDisable ? 1 : 0.5,
				width: width ? width : '100%',
				height: height ? height : '54px',
				'.MuiFormHelperText-root': {
					color: isError ? COLORS.error : COLORS.icon,
					margin: '0px',
				},
				'& label': {
					fontSize: value ? '16px' : '14px',
				},
				'& label.Mui-focused': {
					color: `${COLORS.main}`,
					fontSize: '16px',
				},
				'& .MuiInputBase-input': {
					fontSize: '14px',
					paddingLeft: '20px',
				},
				'& .MuiOutlinedInput-root': {
					borderRadius: '8px',
					height: height ? height : '54px',
					'& fieldset': {
						border: `1px solid ${COLORS.inputBorder}`,
					},
					'&:hover fieldset': {
						opacity: !isDisable ? 1 : 0.5,
						border: `1px solid ${COLORS.hoverInputBorder}`,
					},
					'&.Mui-focused fieldset': {
						border: `1px solid ${COLORS.main}`,
					},
				},
			}}
			onChange={onChange}
			inputProps={{
				style: { fontSize: '14px', paddingLeft: '10px' },
				maxLength: maxLength,
			}}
		/>
	)
}

export default InputWithoutRestrictions
