import { styled } from '@mui/material';
import Button from '@mui/material/Button';

export const ButtonCancel = styled(Button)(( theme ) => ({
    marginTop: "32px",
    backgroundColor: 'transparent',
    color: 'rgba(235, 77, 61, 1)',
    height: '7vh', 
    width: '11vw', 
    borderRadius: '12px',
    "&:hover": {
        color: 'rgba(235, 77, 61, 0.7)',
        backgroundColor: 'transparent'
      },
    "&:disabled": {
      backgroundColor: 'rgba(219, 219, 219, 1)',
      color: 'rgba(255, 255, 255, 1)',
      pointerEvents: 'none', 
    },
}))
