import Box from '@mui/material/Box/Box'
import { COLORS } from '../../../styles/colors'
import { TypographyPrimary } from '../../../ui/typography/TypographyPrimary'

const ActiveComp = ({ is_archived }) => {
	return (
		<Box
			sx={{
				width: 'auto',
				borderRadius: '12px',
				height: '32px',
				backgroundColor: is_archived
					? COLORS.iconOpacity
					: COLORS.successOpacity,
			}}
		>
			<TypographyPrimary
				sx={{
					padding: '8px 16px',
					color: is_archived ? COLORS.secondary : COLORS.success,
				}}
			>
				{is_archived ? 'Архив' : 'Активный'}
			</TypographyPrimary>
		</Box>
	)
}

export default ActiveComp
