import { useEffect, useState } from 'react'
import CameraApiService from 'services/cameraApiServices'
import {
	cowChangeInFrame,
	fetchDailyStatistics,
	fetchDigitizatedCowInfo,
} from 'store/cattlesSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { notifyUser } from 'store/notificationsSlice'

const useDigitizationInfo = () => {
	const initialData = {
		tagNumber: undefined,
		tagImage: undefined,
		images: undefined,
	}
	const [currentInfoAboutCow, setCurrentInfoAboutCow] = useState(initialData)
	const [errorMessages, setErrorMessages] = useState<string>('')
	const dispatch = useAppDispatch()

	const handleFiles = ({ key, value }) => {
		setCurrentInfoAboutCow(prevState => ({
			...prevState,
			[key]: value,
		}))
	}

	const { infoFromDigitizatedCow, dailyStatistics } = useAppSelector(
		state => state.cattlesSlice
	)
	const messages = useAppSelector(
		state => state.websocketReducer.messageForCattleInfoDigitization
	)

	useEffect(() => {
		if (messages !== null) {
			dispatch(cowChangeInFrame(messages))
		}
	}, [messages])

	useEffect(() => {
		dispatch(fetchDailyStatistics('all'))
		const fetchData = async () => {
			try {
				const cameraList = await CameraApiService.getCameraList()
				const firstCameraId = cameraList[0].id
				dispatch(fetchDigitizatedCowInfo(firstCameraId))
			} catch (error) {
				dispatch(
					notifyUser({
						title: 'Ошибка по камере',
						description: error.message,
						isError: true,
					})
				)
			}
		}

		fetchData()
	}, [])

	useEffect(() => {
		if (
			infoFromDigitizatedCow !== undefined &&
			Object.keys(infoFromDigitizatedCow).length > 0
		) {
			handleFiles({ key: 'images', value: infoFromDigitizatedCow.images })
			const findTagImage = infoFromDigitizatedCow.cow.images.find(
				el => el.angle === 'tag'
			)?.image
			if (findTagImage) {
				handleFiles({
					key: 'tagImage',
					value: findTagImage,
				})
				handleFiles({
					key: 'tagNumber',
					value: infoFromDigitizatedCow.cow.tag_id,
				})
			}
		} else {
			setCurrentInfoAboutCow(initialData)
		}
	}, [infoFromDigitizatedCow])

	useEffect(() => {
		const isEmptyInput =
			// currentInfoAboutCow.images?.length !== 0 &&
			currentInfoAboutCow.tagNumber === ''
		isEmptyInput && setErrorMessages('Обязательное поле')
	}, [currentInfoAboutCow])

	return { currentInfoAboutCow, dailyStatistics, infoFromDigitizatedCow, errorMessages, setErrorMessages, handleFiles }
}

export default useDigitizationInfo
