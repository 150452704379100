import { Box } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField/TextField'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { COLORS } from '../../../styles/colors'
import ActiveComp from '../../rationSystem/utils/ActiveComp'

interface ICustomTextFieldProps {
	label: string
	value: number | string
	error?: boolean
	helperText?: string
	fontWeight?: number
	width: string
	adorment?: boolean
	is_archived?: boolean
	isIdentification?: boolean
	onChange?: (e: any) => void
	onClick?: (e: any) => void
	onBlur?: (e: any) => void
	type?: string
	withLabel?: boolean
}

const CustomTextField = (props: ICustomTextFieldProps) => {
	return (
		<TextField
			variant='outlined'
			label={props.label}
			value={props.value}
			type={props.type} // only type={'number}
			error={props.error}
			autoComplete='off'
			helperText={props.helperText}
			sx={{
				width: props.width,
				'& label': {
					fontSize: props.value ? '16px' : '14px',
				},
				'& label.Mui-focused': {
					color: `${COLORS.main}`,
					fontSize: props.value ? '16px' : '14px',
				},
				'& .MuiInputBase-input': {
					fontSize: '14px',
					fontWeight: props.fontWeight ? props.fontWeight : 400,
					paddingLeft: '20px',
				},
				'& .MuiOutlinedInput-root': {
					borderRadius: '8px',
					height: '54px',
					'& fieldset': {
						border: `1px solid ${COLORS.inputBorder}`,
					},
					'&:hover fieldset': {
						border: `1px solid ${COLORS.hoverInputBorder}`,
					},
					'&.Mui-focused fieldset': {
						border: `1px solid ${COLORS.main}`,
					},
				},
			}}
			onChange={props.onChange}
			onClick={props.onClick}
			onBlur={props.onBlur}
			InputLabelProps={{
				shrink: props.withLabel ? true : false,
				style: { paddingLeft: '5px' },
			}}
			InputProps={{
				endAdornment:
					props.adorment === true ? (
						<InputAdornment position='end'>
							<>
								{(props.is_archived === false ||
									props.is_archived === true) && (
									<ActiveComp is_archived={props.is_archived} />
								)}
								{(props.isIdentification === true ||
									props.isIdentification === false) && (
									<IdentificationLabel
										isIdentification={props.isIdentification}
									/>
								)}
							</>
						</InputAdornment>
					) : (
						<></>
					),
			}}
		/>
	)
}

export default CustomTextField

const IdentificationLabel = ({ isIdentification }) => {
	return (
		<Box
			sx={{
				width: 'auto',
				borderRadius: '12px',
				height: '32px',
				backgroundColor: isIdentification
					? COLORS.successOpacity
					: COLORS.deviationOpacity,
			}}
		>
			<TypographyPrimary
				sx={{
					padding: '8px 16px',
					color: isIdentification ? COLORS.success : COLORS.error,
				}}
			>
				{isIdentification ? 'Опознана' : 'Не опознана'}
			</TypographyPrimary>
		</Box>
	)
}
