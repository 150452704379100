import { LinearProgress, linearProgressClasses, styled } from '@mui/material'

interface IProgressbarProps {
	value: number
	total: number
	progressColor: string
	backgroundColor: string
	width: string
}
const ProgressOfDigitization = (props: IProgressbarProps) => {
	const { value, total, progressColor, backgroundColor, width } = props
	const percent = value && total ? (value / total) * 100 : 0
	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 6,
		width: width,
		borderRadius: 16,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: value === 0 ? '#F7F7F7' : backgroundColor,
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 16,
			backgroundColor: progressColor,
		},
	}))

	return <BorderLinearProgress variant='determinate' value={percent} />
}

export default ProgressOfDigitization
