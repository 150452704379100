import { Box, Checkbox } from '@mui/material'
import { ReactComponent as CheckBox } from 'assets/icons/checkBox.svg'
import CheckBoxActive from 'assets/icons/checkBoxActive'
import { morphForWords, roundingNumber } from 'components/shared/processes'
import moment from 'moment'
import { useEffect } from 'react'
import ReactApexChart from 'react-apexcharts'
import { COLORS } from 'styles/colors'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import ChartLegend from 'ui/widjets/ChartLegend'
import { localizationApexCharts } from 'utils'
import { LamenessTypeChart } from './models'
import {
	getColorByScoreForCrytical,
	getSeriesData,
	updateSeriesVisibilityBySelector,
	useVisibleSeries,
} from './processes'

const LamenessChart = ({ dotsInfo, daysBetweenDates, selectTypeOfChart }) => {
	const [visibleSeries, handleCheckboxChange] = useVisibleSeries(dotsInfo)

	useEffect(() => {
		if (selectTypeOfChart === LamenessTypeChart.PointType) {
			updateSeriesVisibilityBySelector(visibleSeries)
		}
	}, [visibleSeries, selectTypeOfChart])

	const series = getSeriesData(dotsInfo, visibleSeries, selectTypeOfChart)
	const lineColors = dotsInfo
		.filter((dots, index) => dots.score !== 1)
		.map(color => color.color)

	const timestamp = dotsInfo[0]?.timestamp
	const options: any = {
		mask: false,
		colors:
			selectTypeOfChart === LamenessTypeChart.PointType ? lineColors : ['#000'],
		chart: {
			animations: {
				enabled: true,
				easing: 'easeout',
				speed: 200,
				animateGradually: {
					enabled: true,
					delay: 100,
				},
				dynamicAnimation: {
					enabled: true,
					speed: 200,
				},
			},
			locales: localizationApexCharts(),
			defaultLocale: 'ru',
			zoom: {
				enabled: false,
			},
			height: 250,
			type: 'line',
			toolbar: {
				show: false,
			},
		},
		markers: {
			size: 0,
			colors: ['#fff'],
			strokeColors:
				selectTypeOfChart === LamenessTypeChart.PointType
					? lineColors
					: ['#000'],
			strokeWidth: 2,
			hover: {
				size: 5,
			},
		},
		stroke: {
			width: 2,
			curve: 'straight',
		},
		title: {
			style: {
				fontSize: '12px',
				fontFamily: 'Montserrat',
				fontStyle: 'normal',
				fontWeight: 400,
				lineHeight: '15px',
				color: '#7F7F84',
			},
			text: 'Количество голов',
		},

		dataLabels: {
			enabled: false,
		},
		grid: {
			strokeDashArray: [6, 3],
			show: true,
			xaxis: {
				lines: {
					show: true,
				},
			},
		},
		xaxis: {
			crosshairs: {
				show: true,
				opacity: 1,
				position: 'back',
				stroke: {
					width: 1,
					dashArray: 0,
				},
			},
			type: 'category',
			tooltip: {
				enabled: false,
			},

			labels: {
				formatter: function (value) {
					const momentValue = moment(value)

					if (momentValue.date() === 1 && daysBetweenDates <= 92) {
						return momentValue.format('DD.MM')
					} else {
						return daysBetweenDates <= 7
							? momentValue.format('DD.MM.YY')
							: daysBetweenDates <= 92
							? momentValue.format('D')
							: momentValue.format('MMMM')
					}
				},
				datetimeUTC: false,
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
			},
			categories: timestamp,
		},
		yaxis: {
			tickAmount: 4,
			min: 0,
			max: function (max) {
				const increasedValue = roundingNumber(max * 1.2, 0)
				return increasedValue
			},
			labels: {
				style: {
					fontSize: '12px',
					fontFamily: 'Montserrat',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: '15px',
					color: '#7F7F84',
				},
				formatter: function (value: number, index: number) {
					if (index % 2 !== 0) {
						return ''
					}
					return Math.round(value).toString()
				},
			},
		},
		tooltip: {
			fixed: true,
			custom: ({ series, seriesIndex, dataPointIndex, w }) => {
				const timeValue = moment(timestamp[dataPointIndex]).format('D.MM.YY')
				const totalCattle = dotsInfo.reduce(
					(acc, el) => acc + (el.category[dataPointIndex] || 0),
					0
				)
				return `<div
				style='width:max-content; height:auto; background-color: #fff; border-radius:12px; border: hidden; box-shadow: 0px 0px 16px 0px rgba(4, 6, 6, 0.16); position: relative'>
				<div style='padding:16px 8px; border: none;'>
					<div style='display: flex; flex-direction: column; gap:4px'>
						${dotsInfo
							.filter((dots, index) => dots.score !== 1 && visibleSeries[index])
							.map(el => {
								const percent =
									(el.category[dataPointIndex] / totalCattle) * 100
								const color = getColorByScoreForCrytical(el.score, percent)
								return `<div style='display:flex; flex-direction:row; align-items: center; gap:4px'>
								<span style='width:12px; height:12px; border-radius: 50%; background-color: ${
									el.color
								};'></span>
								<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>${
									el.label
								}</p>
								<p style='font-weight: 600; font-size: 16px; color: #000; margin: 0px'>${
									el.category[dataPointIndex]
								}</p>
								<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>${morphForWords(
									el.category[dataPointIndex],
									['голова', 'головы', 'голов']
								)}</p>
								<span style='font-weight: 600; font-size: 16px; margin: 0px'>/</span>
								<p style='font-weight: 400; font-size: 14px; color: ${color}; margin: 0px'>${
									percent ? roundingNumber(percent, 1) : ''
								}</p>
								<span style='font-weight: 400; font-size: 12px; margin: 0px; color: #7F7F84;'>${
									percent && '%'
								}</span>
							</div>`
							})
							.join('')}
						<div>
							<p style='font-weight: 400; font-size: 12px; color: #7F7F84; margin: 0px'>${timeValue}</p>
						</div>
					</div>
				</div>
			</div>`
			},
		},
		legend: {
			show: false,
		},
	}

	const descriptionForLegend = [
		{ color: COLORS.black, description: 'Количество хромых голов' },
	]
	const icon = <CheckBox />

	return (
		<Box>
			<ReactApexChart
				id={'lamenessChart'}
				options={options}
				series={series}
				type='line'
				height={250}
			/>
			{selectTypeOfChart === LamenessTypeChart.LamenessType && (
				<ChartLegend initialLegends={descriptionForLegend} />
			)}
			{selectTypeOfChart === LamenessTypeChart.PointType && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: '16px',
						alignItems: 'center',
					}}
				>
					{dotsInfo
						.filter(point => point.score !== 1)
						.map((el, index) => (
							<Box
								key={el.score}
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<Checkbox
									onChange={() => handleCheckboxChange(index + 1)}
									icon={icon}
									checkedIcon={
										<CheckBoxActive lineColor={el.color} bgColor={el.color} />
									}
									disableRipple
									style={{
										padding: '0px 8px 0px 0px',
										margin: '0px',
									}}
									checked={visibleSeries[index + 1]}
								/>
								<TypographySecondary14>{el.label}</TypographySecondary14>
							</Box>
						))}
				</Box>
			)}
		</Box>
	)
}

export default LamenessChart
