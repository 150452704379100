import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'

import { ReactComponent as ArrowCloseDone } from '../../assets/arrow_close_logo.svg'
import { ReactComponent as ArrowOpenDone } from '../../assets/arrow_open_logo.svg'

import { Box, IconButton } from '@mui/material'
import { useState } from 'react'
import RangeDate from '../../ui/datePicker/RangeDate'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { firstDayHandler, getDaysBetweenDates } from '../shared/processes'
import GroupApexChart from './GroupApexChart'
import { sxList } from './utils/headChartStyles'
import { COLORS } from 'styles/colors'

export default function GroupSpecificListOpen(props) {
	const [open, setOpen] = useState(true)

	const primaryDateState = firstDayHandler()
	const [dateRange, setDateRange] = useState({
		startDate: null,
		endDate: null,
	})
	const daysBetweenDates = getDaysBetweenDates(dateRange)
	const onChangeCallback = value => {
		setDateRange({
			startDate: value[0],
			endDate: value[1] + 86399000,
		})
	}
	const sx = sxList(open)
	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<List sx={sx}>
			<Box
				display='flex'
				flexDirection='row'
				alignItems='center'
				sx={{ pt: '12px', pb: '12px' }}
			>
				<ListItemButton
					disableRipple
					disableTouchRipple
					sx={{ '&:hover': { backgroundColor: 'transparent' } }}
					onClick={handleClick}
				>
					<TypographyHeader>{props.title}</TypographyHeader>
				</ListItemButton>
				<IconButton
					disableRipple
					disableTouchRipple
					onClick={handleClick}
					sx={{
						pr: '16px',
						pt: '8px',
						'&:hover': { backgroundColor: 'transparent' },
					}}
				>
					{open ? <ArrowOpenDone /> : <ArrowCloseDone stroke={COLORS.icon}/>}
				</IconButton>
			</Box>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					<Box
						sx={{
							paddingLeft: '16px',
							'&:hover': { backgroundColor: 'transparent' },
						}}
					>
						<Box
							sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
						>
							<Box
								sx={{
									marginBottom: '24px',
								}}
							>
								<RangeDate
									onChangeUpdateDate={onChangeCallback}
									startDate={primaryDateState[0]}
									endDate={primaryDateState[1]}
									todayFilter={true}
									weekFilter={true}
									monthFilter={true}
									currentRange={'Сегодня'}
								/>
							</Box>

							<Box sx={{ width: '99%' }}>
								<GroupApexChart
									id={props.id}
									startDate={dateRange.startDate}
									endDate={dateRange.endDate}
									daysBetweenDates={daysBetweenDates}
									group_title={props.group_title}
								/>
							</Box>
						</Box>
					</Box>
				</List>
			</Collapse>
		</List>
	)
}
