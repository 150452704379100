import { Box } from '@mui/material'
import { morphForWords, roundingNumber } from 'components/shared/processes'
import { TypographyHeader14 } from 'ui/typography/TypographyHeader14'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { getColorByScore, getColorByScoreForCrytical } from './processes'

const LinearChartLegend = props => {
	const { score, label, totalCattlesByCategory, totalCattles } = props
	const percent = (totalCattlesByCategory / totalCattles) * 100
	const color = getColorByScore(score)
	const cryticalColor = getColorByScoreForCrytical(score, percent)
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					gap: '8px',
					alignItems: 'center',
				}}
			>
				<span
					style={{
						width: '12px',
						height: '12px',
						backgroundColor: color,
						borderRadius: '50%',
					}}
				></span>
				<TypographySecondary>{label}</TypographySecondary>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					gap: '4px',
					alignItems: 'center',
				}}
			>
				<TypographyHeader14>
					{totalCattlesByCategory.toLocaleString()}
				</TypographyHeader14>
				<TypographySecondary>
					{morphForWords(totalCattlesByCategory, ['голова', 'головы', 'голов'])}
				</TypographySecondary>
				<span style={{ fontWeight: 400, fontSize: '14px' }}>/</span>
				<TypographyHeader14 sx={{ color: cryticalColor }}>
					{percent ? roundingNumber(percent, 1) : ''}
				</TypographyHeader14>
				<span
					style={{
						fontWeight: 400,
						fontSize: '12px',
						margin: '0px',
						color: '#7F7F84',
					}}
				>
					{'%'}
				</span>
			</Box>
		</Box>
	)
}

export default LinearChartLegend
