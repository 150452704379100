import { Box } from '@mui/material'
import { roundingNumber } from 'components/shared/processes'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'

type GroupCardProps = {
	changeValue: number
	bulk: string
	count: number
	ratio: string
}

const GroupSpecificIndicators = (props: GroupCardProps) => {
	return (
		<Box
			sx={{
				padding: '16px',
				display: ' flex',
				flexDirection: 'column',
				justifyContent: 'flex-end',
				boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.16)',
				borderRadius: '8px',
				gap: '16px',
			}}
		>
			<TypographyPrimary>{props.bulk}</TypographyPrimary>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-start',
					gap: '4px',
					flexWrap: 'wrap',
				}}
			>
				<TypographyPrimary16Bold>
					{roundingNumber(props.count, 1).toLocaleString()}
				</TypographyPrimary16Bold>
				<TypographySecondary>{props.ratio}</TypographySecondary>
			</Box>
		</Box>
	)
}

export default GroupSpecificIndicators
