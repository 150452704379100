import { COLORS } from 'styles/colors'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
interface ISmallButtonProps {
	setState: () => void
	label: string
	background?: string
}
const SmallButton = (props: ISmallButtonProps) => {
	const { setState, label, background } = props
	return (
		<button
			style={{
				border:'none',
				width: '89px',
				height: '32px',
				padding: '8px',
				backgroundColor: background ? background : COLORS.inputBorder,
				borderRadius: '8px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				cursor: 'pointer',
			}}
		>
			<TypographyPrimary sx={{ cursor: 'pointer' }} onClick={setState}>
			{label}
			</TypographyPrimary>
		</button>
	)
}

export default SmallButton
