import Box from '@mui/material/Box/Box'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import CssBaseline from '@mui/material/CssBaseline/CssBaseline'
import IconButton from '@mui/material/IconButton/IconButton'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ReactComponent as EditFeedLogo } from '../../assets/edit_feed_logo.svg'
import { ReactComponent as SettingLogo } from '../../assets/setting_logo.svg'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
	fetchComponentsById,
	fetchRationById,
} from '../../store/rationSystemSlice'
import { COLORS } from '../../styles/colors'
import { fontTheme } from '../../styles/font'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import RationCompositionComp from './RationCompositionComp'
import RationMainInfo from './RationMainInfo'

const theme = fontTheme

const RationViewPage = () => {
	const [open, setOpen] = useState(false)
	const urlParams = useParams()
	const dispatch = useAppDispatch()

	const { rationInfo, rationCompById, loading, error } = useAppSelector(
		state => state.rationSystemReducer
	)

	useEffect(() => {
		const fetchData = async () => {
			try {
				await dispatch(fetchComponentsById(urlParams.rationid))
				await dispatch(fetchRationById(urlParams.rationid))
			} catch (error) {}
		}

		fetchData()
	}, [])

	const { permissions } = useAppSelector(state => state.authReducer)
	const navigate = useNavigate()
	if (loading || !rationInfo || !rationCompById) {
		return (
			<Box
				sx={{
					marginTop: '25%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<CircularProgress
					sx={{
						'&.MuiCircularProgress-colorPrimary': { color: COLORS.main },
					}}
				/>
			</Box>
		)
	} else if (error) {
		;<>{error}</>
	}
	const GroupTooltip = () => {
		const [color, setColor] = useState(COLORS.icon)
		return (
			<Box
				display='flex'
				flexDirection='row'
				sx={{ padding: '16px 8px', cursor: 'pointer' }}
				alignItems='center'
				onMouseOver={() => setColor(COLORS.main)}
				onMouseLeave={() => setColor(COLORS.secondaryFont)}
				onClick={() => navigate(`/feed/ration/${rationInfo.id}/edit`)}
			>
				<EditFeedLogo
					fill={color}
					onMouseOver={() => setColor(COLORS.main)}
					onMouseLeave={() => setColor(COLORS.secondaryFont)}
				/>
				<TypographyPrimary
					sx={{ color: color, pl: '10px', '&:hover': { color: COLORS.main } }}
				>
					Редактировать рацион
				</TypographyPrimary>
			</Box>
		)
	}

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Box
				sx={{ fontFamily: theme.typography.fontFamily, pl: '16px', pr: '40px' }}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
					{permissions &&
						permissions.can_edit_rations &&
						!rationInfo.is_archived && (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'flex-end',
								}}
							>
								<ClickAwayListener onClickAway={() => setOpen(false)}>
									<Box>
										<Tooltip
											placement='bottom-start'
											componentsProps={{
												tooltip: {
													sx: {
														boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
														backgroundColor: 'white',
														borderRadius: '8px',
													},
												},
											}}
											PopperProps={{
												disablePortal: true,
											}}
											onClose={() => setOpen(false)}
											open={open}
											disableFocusListener
											disableHoverListener
											disableTouchListener
											title={<GroupTooltip />}
										>
											<IconButton onClick={() => setOpen(!open)}>
												<SettingLogo stroke={COLORS.secondaryFont} />
											</IconButton>
										</Tooltip>
									</Box>
								</ClickAwayListener>
							</Box>
						)}

					<RationMainInfo rationInfo={rationInfo} />
					<RationCompositionComp
						rationInfo={rationInfo}
						rationCompById={rationCompById}
					/>
				</Box>
			</Box>
		</ThemeProvider>
	)
}

export default RationViewPage
