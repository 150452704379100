import moment from 'moment'
import AnalyticApiServices from 'services/analyticApiService'

const formatDate = date => moment(date).format('DD.MM.YY')
interface IResponseAvailableAnalytics {
	group_id: number
	payload: IPayloadAvailableAnalytics[]
}

interface IPayloadAvailableAnalytics {
	date: number
	available: boolean
}
const createAnalytic = (
	type,
	dateStart,
	dateEnd,
	groupId,
	groupTitle,
	available
) => {
	return {
		date_col: `${formatDate(dateStart)} - ${formatDate(dateEnd)}`,
		filename:
			type === 'day'
				? 'Аналитика кормления за день'
				: type === 'month'
				? `Аналитика кормления за ${moment(dateStart)
						.format("MMMM'YY")
						.toLowerCase()}`
				: type === 'week'
				? 'Аналитика кормления за неделю'
				: `Аналитика кормления за ${moment(dateStart).format('YYYY')}`,
		group_title: groupTitle,
		group_id: groupId,
		type,
		startDate: dateStart.valueOf(),
		endDate: dateEnd.valueOf(),
		available,
	}
}
const isYearStart = date => date.format('MMDD') === '0101'
const isMonthStart = date => date.format('DD') === '01'
const isWeekStart = date => date.format('E') === '1'

const getFirstMondayBetween = (startDate, endDate) => {
	const start = moment(startDate)
	const end = moment(endDate)

	if (start.isoWeekday() <= 1) {
		return start.startOf('isoWeek').valueOf()
	} else {
		const nextMonday = start.add(1, 'week').startOf('isoWeek')
		return nextMonday.isBetween(start, end, null, '[]')
			? nextMonday.valueOf()
			: false
	}
}

const getFirstDayOfMonthBetween = (startDate, endDate) => {
	const start = moment(startDate)
	const end = moment(endDate)

	const startOfFirstDayOfMonth = moment(start).startOf('month')
	if (startOfFirstDayOfMonth.isBetween(start, end, null, '[]')) {
		return startOfFirstDayOfMonth.valueOf()
	}

	const endOfFirstDayOfMonth = moment(end).startOf('month')
	if (endOfFirstDayOfMonth.isBetween(start, end, null, '[]')) {
		return endOfFirstDayOfMonth.valueOf()
	}

	return false
}

const hasJanuaryFirstBetween = (startDate, endDate) => {
	const startYear = moment(endDate).year()
	const januaryFirst = moment([startYear, 0, 1])

	const start = moment(startDate)
	const end = moment(endDate)

	if (januaryFirst.isBetween(start, end, null, '[]')) {
		return januaryFirst.valueOf()
	}

	return false
}

const checkRangeDateFromType = (startDate, endDate) => {
	const januaryFirst = hasJanuaryFirstBetween(startDate, endDate)

	if (januaryFirst) {
		return moment(januaryFirst).subtract(1, 'year').valueOf()
	}

	const firstDayOfMonth = getFirstDayOfMonthBetween(startDate, endDate)
	if (firstDayOfMonth) {
		return moment(firstDayOfMonth).subtract(1, 'month').valueOf()
	}

	const monday = getFirstMondayBetween(startDate, endDate)
	if (monday) {
		return moment(monday).subtract(1, 'week').add(1, 'day').valueOf()
	}
	return startDate
}

const fetchAvailableData = async (
	activeFilterGroupButton,
	lightGroupList,
	startDate,
	endDate
) => {
	let availableAnalytics = []
	if (activeFilterGroupButton === -1) {
		availableAnalytics = []
		for (let i = 0; i < lightGroupList?.length; i++) {
			await AnalyticApiServices.getAvailableAnalitycs(
				lightGroupList[i].id,
				startDate,
				endDate
			).then(response => {
				availableAnalytics.push(response.data)
			})
		}
	} else {
		availableAnalytics = []
		await AnalyticApiServices.getAvailableAnalitycs(
			activeFilterGroupButton,
			startDate,
			endDate
		).then(response => {
			availableAnalytics.push(response.data)
		})
	}
	return availableAnalytics
}

export const createAnalyticTypeListByDate = async (
	dateRange,
	lightGroupList,
	activeFilterGroupButton,
	activeFilterTypeSelect
) => {
	const result = []
	const startDate = moment(dateRange.startDate)
	const endDate = moment(dateRange.endDate)

	const dateForAvailableAnalytics = checkRangeDateFromType(
		dateRange.startDate,
		dateRange.endDate
	)

	const availableAnalytics: IResponseAvailableAnalytics[] =
		await fetchAvailableData(
			activeFilterGroupButton,
			lightGroupList,
			dateForAvailableAnalytics,
			dateRange.endDate
		)
	const filteredGroup = lightGroupList.filter(el => {
		if (activeFilterGroupButton === -1) {
			return el
		} else {
			return activeFilterGroupButton === el.id
		}
	})

	for (const group of filteredGroup) {
		const availableDataForGroup = availableAnalytics.filter(
			el => el.group_id === group.id
		)

		let groupStartDate = moment(startDate)

		while (groupStartDate.isSameOrBefore(endDate, 'day')) {
			if (isYearStart(groupStartDate)) {
				const yearStartDate = moment(groupStartDate)
					.subtract(1, 'year')
					.startOf('year')
				const yearEndDate = moment(groupStartDate).startOf('year')
				const isAvailableData = availableDataForGroup[0]?.payload
					.filter(
						el =>
							yearStartDate.valueOf() <= el.date &&
							el.date < yearEndDate.valueOf()
					)
					.some(el => el.available === true)
				result.push(
					createAnalytic(
						'year',
						yearStartDate,
						yearEndDate,
						group.id,
						group.title,
						isAvailableData
					)
				)
			}
			if (isMonthStart(groupStartDate)) {
				const monthStartDate = moment(groupStartDate)
					.subtract(1, 'month')
					.startOf('month')
				const monthEndDate = moment(groupStartDate).startOf('month')
				const isAvailableData = availableDataForGroup[0]?.payload
					.filter(
						el =>
							monthStartDate.valueOf() <= el.date &&
							el.date < monthEndDate.valueOf()
					)
					.some(el => el.available === true)
				result.push(
					createAnalytic(
						'month',
						monthStartDate,
						monthEndDate,
						group.id,
						group.title,
						isAvailableData
					)
				)
			}
			if (isWeekStart(groupStartDate)) {
				const weekStartDate = moment(groupStartDate)
					.subtract(1, 'week')
					.add(1, 'day')
					.startOf('day')

				const weekEndDate = moment(groupStartDate).endOf('day')
				const isAvailableData = availableDataForGroup[0]?.payload
					.filter(
						el =>
							weekStartDate.valueOf() <= el.date &&
							el.date <= weekEndDate.valueOf()
					)
					.some(el => el.available === true)
				result.push(
					createAnalytic(
						'week',
						weekStartDate,
						weekEndDate,
						group.id,
						group.title,
						isAvailableData
					)
				)
			}

			const isAvailableData = availableDataForGroup[0]?.payload.find(
				el => groupStartDate.valueOf() === el.date
			).available
			result.push(
				createAnalytic(
					'day',
					moment(groupStartDate),
					moment(groupStartDate).endOf('day'),
					group.id,
					group.title,
					isAvailableData
				)
			)

			groupStartDate.add(1, 'day')
		}
	}

	if (activeFilterTypeSelect.length > 0) {
		const filteredResult = result.filter(item =>
			activeFilterTypeSelect.includes(item.type)
		)

		filteredResult.sort((a, b) => {
			const typeOrder = { year: 1, month: 2, week: 3, day: 4 }
			if (typeOrder[a.type] < typeOrder[b.type]) {
				return -1
			}
			if (typeOrder[a.type] > typeOrder[b.type]) {
				return 1
			}
			return b.startDate - a.startDate
		})

		return filteredResult
	}

	result.sort((a, b) => {
		const typeOrder = { year: 1, month: 2, week: 3, day: 4 }
		if (typeOrder[a.type] < typeOrder[b.type]) {
			return -1
		}
		if (typeOrder[a.type] > typeOrder[b.type]) {
			return 1
		}
		return b.startDate - a.startDate
	})

	return result
}
