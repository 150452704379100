import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { TypographySecondary } from '../../ui/typography/TypographySecondary'

import { ReactComponent as WaitingLogo } from '../../assets/waiting_logo.svg'

import { COLORS } from '../../styles/colors'

import { Box } from '@mui/material'
import moment from 'moment'
import { IFeed } from '../../models/IFeed'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'

interface IGroupSpecificPlanTableProps {
	groupId: number
	filteredData: IFeed[]
}

const GroupSpecificPlanTable = (props: IGroupSpecificPlanTableProps) => {
	const typoPrimaryCell = {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
	}
	return (
		<TableContainer>
			<Table
				sx={{
					[`& .${tableCellClasses.root}`]: {
						borderBottom: 'none',
					},
				}}
			>
				<TableHead>
					<TableRow
						sx={{ borderBottom: `1px solid ${COLORS.secondaryFontOpacity}` }}
					>
						<TableCell>
							<TypographySecondary>Действие</TypographySecondary>
						</TableCell>
						<TableCell align='center'>
							<TypographySecondary>Вес раздачи, кг</TypographySecondary>
						</TableCell>
						<TableCell align='center'>
							<TypographySecondary>Плановое время</TypographySecondary>
						</TableCell>
						<TableCell align='center'>
							<TypographySecondary>Допустимое отклонение</TypographySecondary>
						</TableCell>
						<TableCell align='center'>
							<TypographySecondary>Фактическое время</TypographySecondary>
						</TableCell>
						<TableCell align='center'>
							<TypographySecondary>Остатки, кг</TypographySecondary>
						</TableCell>
						<TableCell align='center'>
							<TypographySecondary>
								Фактический вес раздачи, кг
							</TypographySecondary>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.filteredData.map(data => {
						return (
							<TableRow key={data.id}>
								<TableCell>
									{data.type === 'distribution'
										? 'Раздача'
										: data.type === 'nudge'
										? 'Подталкивание'
										: 'Уборка'}
								</TableCell>
								<TableCell align='right'>
									{data.type === 'distribution' && (
										<Box sx={typoPrimaryCell}>
											<TypographyPrimary>{data.weight}</TypographyPrimary>
											<TypographySecondary sx={{ pl: '4px' }}>
												кг
											</TypographySecondary>
										</Box>
									)}
								</TableCell>
								<TableCell align='center'>{data.time}</TableCell>
								<TableCell>
									<Box sx={typoPrimaryCell}>
										<TypographyPrimary>
											&plusmn; {data.deviation}
										</TypographyPrimary>
										<TypographySecondary sx={{ pl: '4px' }}>
											мин
										</TypographySecondary>
									</Box>
								</TableCell>

								<TableCell align='center'>
									{data.feed_facts
										.filter(el => el.group.id === props.groupId)
										.map(el =>
											el.fact_dt === null ? (
												<WaitingLogo key={el.group.id} />
											) : (
												<Box key={el.group.id}>
													{moment(el.fact_dt).format('HH:mm')}
												</Box>
											)
										)}
								</TableCell>

								<TableCell>
									{data.type === 'nudge' || data.type === 'distribution' ? (
										<></>
									) : (
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												flexDirection: 'column',
											}}
										>
											{data.feed_facts
												.filter(el => el.group.id === props.groupId)
												.map(el => (
													<Box
														key={el.group.id}
														sx={{
															display: 'flex',
															flexDirection: 'row',
															alignItems: 'center',
														}}
													>
														{el.weight ? (
															<>
																{el.weight.toFixed(0)}
																<TypographySecondary sx={{ pl: '4px' }}>
																	{' кг'}
																</TypographySecondary>
															</>
														) : (
															<>
																<TypographySecondary>{'-'}</TypographySecondary>
																<TypographySecondary sx={{ pl: '4px' }}>
																	кг
																</TypographySecondary>
															</>
														)}
													</Box>
												))}
										</Box>
									)}
								</TableCell>

								<TableCell>
									{data.type === 'nudge' || data.type === 'cleaning' ? (
										<></>
									) : (
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												flexDirection: 'column',
											}}
										>
											{data.feed_facts
												.filter(el => el.group.id === props.groupId)
												.map(el => (
													<Box
														key={el.group.id}
														sx={{
															display: 'flex',
															flexDirection: 'row',
															alignItems: 'center',
														}}
													>
														{el.weight ? (
															<>
																{el.weight.toFixed(0)}
																<TypographySecondary sx={{ pl: '4px' }}>
																	{' кг'}
																</TypographySecondary>
															</>
														) : (
															<TypographySecondary sx={{ pl: '4px' }}>
																Данные не введены
															</TypographySecondary>
														)}
													</Box>
												))}
										</Box>
									)}
								</TableCell>
							</TableRow>
						)
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default GroupSpecificPlanTable
