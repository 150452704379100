import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { COLORS } from '../styles/colors'
import { TypographySecondaryBold16 } from '../ui/typography/TypographySecondaryBold16'
import { TypographySecondary } from '../ui/typography/TypographySecondary'

const Timer = props => {
	const [counter, setCounter] = useState(4 * 60)
	const [showMessage, setShowMessage] = useState(false)

	useEffect(() => {
		const timer = setInterval(() => {
			setCounter(prevCounter => prevCounter - 1)
		}, 1000)

		if (counter === 0) {
			clearInterval(timer)
			setShowMessage(true)
		}

		return () => clearInterval(timer)
	}, [counter])

	const formatTime = time => {
		const minutes = Math.floor(time / 60)
		const seconds = time % 60
		return `${minutes.toString().padStart(2, '0')}:${seconds
			.toString()
			.padStart(2, '0')}`
	}

	return (
		<Box sx={{ margin: '0 auto' }}>
			{showMessage || props.submitError === true ? (
				<TypographySecondary
					sx={{
						color: COLORS.main,
						textDecoration: 'underline',
						textUnderlineOffset: '3px',
						cursor: 'pointer',
					}}
					onClick={() => {
						props.postResetPassword()
						props.setSubmitError(false)
						setCounter(4 * 60)
						props.setValues(Array(4).fill(''))
						setShowMessage(false)
					}}
				>
					Отправить код повторно
				</TypographySecondary>
			) : (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '16px',
					}}
				>
					<TypographySecondary>
						Отправить код повторно через
					</TypographySecondary>
					<TypographySecondaryBold16>
						{formatTime(counter)}
					</TypographySecondaryBold16>
				</Box>
			)}
		</Box>
	)
}

export default Timer
