import { Box } from '@mui/material'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

interface IChartLegendItem {
	color: string
	description: string
}

interface IChartLegendProps {
	initialLegends: IChartLegendItem[]
}

const ChartLegend = (props: IChartLegendProps) => {
	const { initialLegends } = props
	return (
		<Box display={'flex'} flexDirection={'row'} gap={'16px'}>
			{initialLegends.map((el, index) => (
				<Box
					key={index}
					display={'flex'}
					flexDirection={'row'}
					gap={'8px'}
					alignItems={'center'}
				>
					<span
						style={{
							backgroundColor: el.color,
							width: '16px',
							height: '16px',
							borderRadius: '50%',
						}}
					></span>
					<TypographySecondary14>{el.description}</TypographySecondary14>
				</Box>
			))}
		</Box>
	)
}

export default ChartLegend
