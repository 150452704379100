import { useLocation, Navigate } from 'react-router-dom';
import useAuth from '../hook/useAuth'

const RequireAuth = ({children}) => {
    
    const location = useLocation();
    const authState = useAuth();
    
    if (!authState.token || !authState.permissions) {
        return <Navigate to='/login' state={{ from: location }} />
    }

    return children;
}

export { RequireAuth };