import Box from '@mui/material/Box/Box'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import Typography from '@mui/material/Typography/Typography'
import { useEffect } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import axAPI from '../../http'
import { useAppSelector } from '../../store/hooks'
import { COLORS } from '../../styles/colors'
import DeleteButton from '../../ui/buttons/DeleteButton'
import NotificationItem from './NotificationItem'
import './styles/notif.css'

const NotificationsCurrent = ({
	currentNotif,
	remove,
	setCurrentNotifications,
	setCountNotif,
	fetching,
}) => {
	const messages = useAppSelector(
		state => state.websocketReducer.messageForNotifications
	)

	useEffect(() => {
		if (messages !== null) {
			setCurrentNotifications(prevState => [messages, ...prevState])
		}
	}, [messages])

	const removeAllCurrentNotifications = () => {
		setCurrentNotifications([])
		axAPI({
			method: 'PATCH',
			url: `notifications/bulk`,
			data: { id: [], close_today: true, close_old: false },
		}).then(response => {
			if (response.status === 201)
				axAPI({
					method: 'GET',
					url: 'notifications/count',
				}).then(response => {
					setCountNotif(response.data.new_count)
				})
		})
	}
	return (
		<Box sx={{ marginBottom: '16px' }}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: '8px',
				}}
			>
				<Typography
					sx={{
						fontSize: '14px',
						fontWeight: '400',
						lineHeight: '16.8px',
						fontFamily: 'Montserrat',
						color: '#fff',
					}}
				>
					Сегодня
				</Typography>
				<Box onClick={removeAllCurrentNotifications}>
					<DeleteButton children={'Очистить'} />
				</Box>
			</Box>
			<TransitionGroup>
				{currentNotif.map(el => (
					<CSSTransition key={el.id} timeout={500} classNames={'notif'}>
						<NotificationItem
							remove={remove}
							id={el.id}
							title={el.title}
							text={el.text}
							groups={el.groups}
							created_dt={el.created_dt}
						/>
					</CSSTransition>
				))}
			</TransitionGroup>
			{fetching && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress
						sx={{
							'&.MuiCircularProgress-colorPrimary': { color: COLORS.main },
						}}
					/>
				</Box>
			)}
		</Box>
	)
}

export default NotificationsCurrent
