import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axAPI from '../http'
import { IAvailableRolelist, IUserData, IUserSlice } from '../models/IUserData'

export const fetchUserData = createAsyncThunk(
	'user/getdata',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axAPI({
				method: 'GET',
				url: 'auth/users/me/',
			})

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data
		} catch (error) {
			let errorMessage = 'Failed to retrieve user data!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)

export const fetchUsersList = createAsyncThunk(
	'user/getuserslist',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axAPI({
				method: 'GET',
				url: 'auth/users/',
			})

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data
		} catch (error) {
			let errorMessage = 'Failed to retrieve users list!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)

export const fetchAvailableRoles = createAsyncThunk(
	'auth/roles',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axAPI({
				method: 'GET',
				url: 'auth/roles',
			})

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data
		} catch (error) {
			let errorMessage = 'Failed to retrieve users list!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)

const initialState: IUserSlice = {
	availableRoles: [],
	availableRolesPending: false,
	userDataPending: false,
	userData: {
		username: '',
		email: '',
		firstname: '',
		lastname: '',
		superuser: false,
		is_active: false,
		company: {
			id: null,
			title: 'Smart Farm',
		},
	},
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserActivityFlag(state, action) {
			state.usersList.forEach(el => {
				if (el.id === action.payload.userId)
					el.is_active = action.payload.active
			})
		},
		changeAvatar(state, action) {
			state.userData.avatar = action.payload
		},
		changeUserData(state, action) {
			state.userData = action.payload
		},
		addNewRole(state, action) {
			return {
				...state,
				availableRoles: [...state.availableRoles, action.payload],
			}
		},
	},
	extraReducers: {
		[fetchUserData.fulfilled.type]: (
			state,
			action: PayloadAction<IUserData>
		) => {
			state.userDataPending = false
			state.userData = action.payload
		},
		[fetchUserData.pending.type]: state => {
			state.userDataPending = true
			state.userDataError = ''

			state.userData.username = ''
			state.userData.email = ''
			state.userData.firstname = ''
			state.userData.lastname = ''
			state.userData.superuser = false
		},
		[fetchUserData.rejected.type]: (
			state,
			action: PayloadAction<IUserSlice>
		) => {
			state.userDataPending = false
			state.userDataError = action.payload.detail
		},
		[fetchUsersList.fulfilled.type]: (
			state,
			action: PayloadAction<IUserData[]>
		) => {
			state.userDataPending = false
			state.usersList = action.payload
		},
		[fetchUsersList.pending.type]: state => {
			state.userDataPending = true
			state.userDataError = ''
		},
		[fetchUsersList.rejected.type]: (
			state,
			action: PayloadAction<IUserSlice>
		) => {
			state.userDataPending = false
			state.userDataError = action.payload.detail
		},
		[fetchAvailableRoles.fulfilled.type]: (
			state,
			action: PayloadAction<IAvailableRolelist[]>
		) => {
			state.availableRolesPending = false
			state.availableRoles = action.payload.sort((a, b) => a.id - b.id)
		},
		[fetchAvailableRoles.pending.type]: state => {
			state.availableRolesPending = true
			state.rolesError = ''
		},
		[fetchAvailableRoles.rejected.type]: (
			state,
			action: PayloadAction<IUserSlice>
		) => {
			state.availableRolesPending = false
			state.rolesError = action.payload.detail
		},
	},
})

export default userSlice.reducer
export const { setUserActivityFlag, changeAvatar, changeUserData, addNewRole } =
	userSlice.actions
