import { Box } from '@mui/material'
import {
	getDaysBetweenDates,
	getYearsOld,
	morphForWords,
} from 'components/shared/processes'
import { ICowsList } from 'models/ICattlesData'
import moment from 'moment'
import { COLORS } from 'styles/colors'
import { TypographyHeaderSecondary } from 'ui/typography/TypographyHeaderSecondary'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

type MainInfoProps = {
	cowInfo: ICowsList
}

const MainInfoBlock = (props: MainInfoProps) => {
	const { cowInfo } = props
	const dateRangeForStartTreatment = {
		startDate: cowInfo.first_examination_date,
		endDate: moment().valueOf(),
	}
	const daysBetweenDatesInStartTreatment = getDaysBetweenDates(
		dateRangeForStartTreatment
	)
	const dateRangeForStartLameness = {
		startDate: cowInfo.limpness_start_dt,
		endDate: moment().valueOf(),
	}
	const daysBetweenDatesInStartLameness = getDaysBetweenDates(
		dateRangeForStartLameness
	)
	return (
		<Box
			display={'flex'}
			flexDirection={'row'}
			width={'100%'}
			alignItems={'flex-start'}
			gap={'40px'}
		>
			<Box width={'150px'}>
				<TypographyHeaderSecondary>
					Основная
					<br />
					информация
				</TypographyHeaderSecondary>
			</Box>
			<Box display={'flex'} flexDirection={'column'} width={'40%'} gap={'16px'}>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'flex-end'}
					gap={'8px'}
				>
					<TypographySecondary14>Ушная бирка: </TypographySecondary14>
					<TypographyPrimary>{cowInfo.tag_id}</TypographyPrimary>
				</Box>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'flex-end'}
					gap={'8px'}
				>
					<TypographySecondary14>Группа: </TypographySecondary14>
					<TypographyPrimary>{cowInfo.group.title}</TypographyPrimary>
				</Box>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'flex-end'}
					gap={'8px'}
				>
					<TypographySecondary14>Возраст: </TypographySecondary14>
					<TypographyPrimary>
						{getYearsOld(cowInfo.birth_date)}
					</TypographyPrimary>
				</Box>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'flex-end'}
					gap={'8px'}
				>
					<TypographySecondary14>День лактации: </TypographySecondary14>
					<TypographyPrimary>{cowInfo.lactation_length}</TypographyPrimary>
				</Box>
			</Box>

			<Box display={'flex'} flexDirection={'column'} width={'40%'} gap={'16px'}>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'flex-end'}
					gap={'8px'}
				>
					<TypographySecondary14>Балл хромоты: </TypographySecondary14>
					<TypographyPrimary>
						{cowInfo.limpness}{' '}
						<span style={{ color: COLORS.secondary, fontSize: '12px' }}>
							{morphForWords(cowInfo.limpness, ['балл', 'балла', 'баллов'])}
						</span>
					</TypographyPrimary>
				</Box>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'flex-end'}
					gap={'8px'}
				>
					<TypographySecondary14>Определение хромоты: </TypographySecondary14>
					{cowInfo.limpness_start_dt && (
						<TypographyPrimary>
							{moment(cowInfo.limpness_start_dt).format('DD.MM.YY')}
							<span style={{ color: COLORS.secondary, margin: '0 4px' }}>
								/
							</span>
							<span>{daysBetweenDatesInStartLameness}</span>
							<span style={{ color: COLORS.secondary, margin: '0 4px' }}>
								{morphForWords(daysBetweenDatesInStartLameness, [
									'день',
									'дня',
									'дней',
								])}
							</span>
						</TypographyPrimary>
					)}
				</Box>
				<Box
					display={'flex'}
					flexDirection={'row'}
					alignItems={'flex-end'}
					gap={'8px'}
				>
					<TypographySecondary14>Начало лечения: </TypographySecondary14>
					{cowInfo.first_examination_date && (
						<TypographyPrimary>
							{moment(cowInfo.first_examination_date).format('DD.MM.YY')}
							<span style={{ color: COLORS.secondary, margin: '0 4px' }}>
								/
							</span>
							<span>{daysBetweenDatesInStartTreatment}</span>
							<span style={{ color: COLORS.secondary, margin: '0 4px' }}>
								{morphForWords(daysBetweenDatesInStartTreatment, [
									'день',
									'дня',
									'дней',
								])}
							</span>
						</TypographyPrimary>
					)}
				</Box>
			</Box>
		</Box>
	)
}

export default MainInfoBlock
