import {
	Divider,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	tableCellClasses,
} from '@mui/material'
import { useState } from 'react'
import { COLORS } from 'styles/colors'
import CustomPagination from 'ui/CustomPagination'
import TableSkeleton from 'ui/skeleton/TableSkeleton'
import { TypographySecondaryBold16 } from 'ui/typography/TypographySecondaryBold16'
import { CardStandart } from '../../ui/CardStandart'
import { TypographyPrimary } from '../../ui/typography/TypographyPrimary'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'

const HistoryTable = props => {
	const { historyData, fetchindData } = props
	const [visibleElements, setVisibleElements] = useState([])

	return (
		<CardStandart
			sx={{
				mt: '24px',
				p: '24px',
				display: ' flex',
				flexDirection: 'column',
				gap: '16px',
			}}
		>
			<TableContainer
				sx={{
					overflowY: 'auto',
					overflowX: 'hidden',
					height: 'calc(100vh - 370px)',
					'&::-webkit-scrollbar': {
						width: 4,
					},
					'&::-webkit-scrollbar-track': {
						backgroundColor: '#DBDBDB',
						borderRadius: 12,
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#5222D0',
						borderRadius: 12,
					},
				}}
			>
				<Table stickyHeader>
					<TableHead
						sx={{
							[`& .${tableCellClasses.root}`]: {
								backgroundColor: '#fff',
							},
						}}
					>
						<TableRow sx={{ width: '100%', height: '50px' }}>
							<TableCell align={'left'} padding={'none'} width={'20%'}>
								<TypographySecondary>Дата изменения</TypographySecondary>
							</TableCell>
							<TableCell align={'left'} padding={'none'} width={'20%'}>
								<TypographySecondary>Группы</TypographySecondary>
							</TableCell>
							<TableCell align={'left'} padding={'none'} width={'60%'}>
								<TypographySecondary>Изменение</TypographySecondary>
							</TableCell>
						</TableRow>
					</TableHead>
					{fetchindData ? (
						<TableBody>
							<TableCell colSpan={3} align={'center'} padding={'none'}>
								<TableSkeleton
									widthForBody={'100%'}
									heightForBody={'34px'}
									rowCount={36}
									totalHeight={'calc(100vh - 350px)'}
								/>
							</TableCell>
						</TableBody>
					) : visibleElements.length === 0 ? (
						<TableBody>
							<TableRow
								sx={{
									width: '100%',
									[`& .${tableCellClasses.root}`]: {
										borderBottom: 'none',
										height: '400px',
									},
								}}
							>
								<TableCell colSpan={3} align={'center'} padding={'none'}>
									<TypographySecondaryBold16>
										Нет данных
									</TypographySecondaryBold16>
								</TableCell>
							</TableRow>
						</TableBody>
					) : (
						<TableBody>
							{visibleElements.map((data, index) => (
								<TableRow
									key={index}
									sx={{
										height: '34px',
										'&:hover': {
											background: COLORS.mainSecondaryOpacity,
										},
										[`& .${tableCellClasses.root}`]: {
											borderBottom: 'none',
										},
									}}
								>
									<TableCell align={'left'} padding={'none'}>
										{data.date}
									</TableCell>
									<TableCell align={'left'} padding={'none'}>
										{data.groups.map((el, index) => (
											<TypographyPrimary key={index}>
												{el.group_title}
											</TypographyPrimary>
										))}
									</TableCell>
									<TableCell align={'left'} padding={'none'}>
										{data.description.map((el, index) => (
											<TypographyPrimary key={index}>{el}</TypographyPrimary>
										))}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					)}
				</Table>
			</TableContainer>
			<Divider />
			<CustomPagination
				initialArray={historyData}
				setVisibleState={setVisibleElements}
				count={36}
			/>
		</CardStandart>
	)
}

export default HistoryTable
