import { Box } from '@mui/material'
import { COLORS } from '../../../styles/colors'
import { ChipItem } from '../../../ui/ChipItem'

const GroupList = props => {
	const chipItemStyle = { mr: '16px', margin: '5px' }
	return (
		<Box sx={{ marginBottom: '24px' }}>
			<ChipItem
				key='0'
				label='Все'
				onClick={e => props.setCurrentGroupId('0')}
				sx={
					props.currentGroupId === '0'
						? {
								...chipItemStyle,
								backgroundColor: `${COLORS.main}`,
								color: '#fff',
						  }
						: chipItemStyle
				}
			/>
			{props.groupList.map(group => (
				<ChipItem
					key={group.key}
					label={group.title}
					onClick={() => props.setCurrentGroupId(group.id)}
					sx={
						group.id === props.currentGroupId
							? {
									...chipItemStyle,
									backgroundColor: `${COLORS.main}`,
									color: '#fff',
							  }
							: chipItemStyle
					}
				/>
			))}
		</Box>
	)
}

export default GroupList
