import { Checkbox, IconButton } from '@mui/material'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import CheckBoxActive from 'assets/icons/checkBoxActive'
import { useState } from 'react'
import { COLORS } from 'styles/colors'
import TooltipHover from 'ui/TooltipHover'
import { ReactComponent as CheckBox } from '../../../../../assets/icons/checkBox.svg'
import { ReactComponent as AddColumnIcon } from '../../../../../assets/plus_popup_icon.svg'
import { ColumnTypes } from '../models'
const icon = <CheckBox />
const checkedIcon = <CheckBoxActive />
const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 8,
		marginTop: theme.spacing(1),
		minWidth: 210,
		color:
			theme.palette.mode === 'light'
				? 'rgb(55, 65, 81)'
				: theme.palette.grey[300],
		boxShadow: '0px 0px 16px 0px #04060629',
		'& .MuiMenu-list': {
			padding: '8px',
		},
		'& .MuiMenuItem-root': {
			margin: '4px',
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: 'transparent',
			},
		},
	},
}))
type ColumnsMenuProps = {
	activeColumns: ColumnTypes[]
	onClick: (title: string) => void
}
export default function AddColumnMenu(props: ColumnsMenuProps) {
	const { activeColumns, onClick } = props
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<div>
			<TooltipHover title={'Вид таблицы'} placement='top-start'>
				<IconButton
					onClick={handleClick}
					size='small'
					aria-controls={open ? 'account-menu' : undefined}
					aria-haspopup='true'
					aria-expanded={open ? 'true' : undefined}
				>
					<AddColumnIcon />
				</IconButton>
			</TooltipHover>
			<StyledMenu
				id='lameness-column-customized-menu'
				MenuListProps={{
					'aria-labelledby': 'demo-customized-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{activeColumns.map((el, index) => (
					<MenuItem
						key={index}
						onClick={() => onClick(el.title)}
						disableRipple
						sx={{
							color: COLORS.secondaryFont,
							fontSize: '14px',
							padding: '0px',
							'&:hover': {
								backgroundColor: '#fff',
							},
							'&.Mui-selected': {
								backgroundColor: '#fff',
								color: '#fff',
							},
						}}
					>
						<Checkbox
							icon={icon}
							disableRipple
							checkedIcon={checkedIcon}
							style={{ padding: '0px 10px 0px 0px', margin: '0px' }}
							checked={el.isShowColumn}
						/>
						{el.title}
					</MenuItem>
				))}
			</StyledMenu>
		</div>
	)
}
