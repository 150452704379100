import { Box, Grid } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import CardWidjet from 'ui/widjets/CardWidjet'
import { textForHeaderWidjets } from '../../utils'
import DailyYieldCard from '../shared/DailyYieldCard'
import DonutChart from '../shared/DonutChart'
import DonutDescription from '../shared/DonutDescription'
import { generateColorsAndValuesArray } from '../utils'

const WidjetsHeader = props => {
	const { dailyStatistics, loadingStatistics } = props
	const lamenessGroups = generateColorsAndValuesArray(
		dailyStatistics?.limpness_data
	)
	const fatnessGroups = generateColorsAndValuesArray(
		dailyStatistics?.fatness_data
	)
	return (
		<Grid
			container
			spacing={{ xs: 2, md: 3 }}
			columns={{ xs: 4, sm: 8, md: 12 }}
		>
			{textForHeaderWidjets.map((el, index) => (
				<Grid item xs={2} sm={4} md={4} key={index}>
					<CardWidjet
						title={loadingStatistics ? <Skeleton width={150} /> : el.text}
						tooltipContent={null}
						pathNavigate={
							(index === 1 && '/cattles/fatness') ||
							(index === 2 && '/cattles/lameness')
						}
						content={
							loadingStatistics ? (
								<Skeleton height={125} />
							) : (
								(index === 0 && (
									<DailyYieldCard
										milking_data={dailyStatistics?.milking_data}
									/>
								)) ||
								(index === 1 && (
									<Box
										sx={{
											display: 'flex',
											height: '100%',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'space-between',
											width: '100%',
											gap: '16px',
										}}
									>
										<DonutDescription
											description={dailyStatistics?.fatness_data}
										/>
										<DonutChart
											categories={fatnessGroups.categories}
											colors={fatnessGroups.colors}
										/>
									</Box>
								)) ||
								(index === 2 && (
									<Box
										sx={{
											display: 'flex',
											height: '100%',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'space-between',
											width: '100%',
											gap: '16px',
										}}
									>
										<DonutDescription
											description={dailyStatistics?.limpness_data}
										/>
										<DonutChart
											categories={lamenessGroups.categories}
											colors={lamenessGroups.colors}
										/>
									</Box>
								))
							)
						}
					/>
				</Grid>
			))}
		</Grid>
	)
}

export default WidjetsHeader
