import { ThemeProvider } from '@mui/material/styles'
import SettingUser from 'components/settings/SettingUser'
import { Navigate, Route, Routes } from 'react-router-dom'
import './App.css'
import AnalyticsPage from './components/analytics/AnalyticsPage'
import FeedingPaginationList from './components/analytics/FeedingPaginationList'
import DeviationPage from './components/deviations/DeviationPage'
import FarmFillDonePage from './components/fill/ui/FarmFillDonePage'
import FarmFillFeedPlan from './components/fill/ui/FarmFillFeedPlan'
import FarmFillGroups from './components/fill/ui/FarmFillGroups'
import FarmFillPage from './components/fill/ui/FarmFillPage'
import FarmFillRation from './components/fill/ui/FarmFillRation'
import GroupSpecificEdit from './components/group/GroupSpecificEdit'
import HistoryPage from './components/history/HistoryPage'
// import EditPlanPage from './components/plan/EditPlanPage'
import FeedGroupsPage from './components/foodTable/FeedGroupsPage'
import AddNewRation from './components/rationSystem/AddNewRation'
import RationViewPage from './components/rationSystem/RationViewPage'
import ManagementPage from './components/settings/ManagementPage'
import SettingPage from './components/settings/SettingPage'
import { AuthProvider } from './hoc/AuthProvider'
import { RequireAuth } from './hoc/RequireAuth'
import ErrorPerms from './layouts/ErrorPerms'
// import FillPage from './layouts/FillPage'
import GroupPage from './layouts/GroupPage'
// import { GroupsPage } from './layouts/GroupsPage'
import { Suspense, lazy } from 'react'
import Preloader from 'ui/Preloader'
import Layout from './layouts/Layout'
import LoginPage from './layouts/LoginPage'
import ProfilePage from './layouts/ProfilePage'
import RationEditPage from './layouts/RationEditPage'
import { RationPageEntry } from './layouts/RationPageEntry'
// import ViewPlanPage from './layouts/ViewPlanPage'
import CattlesPage from 'components/cattles/cattlesPage/CattlesPage'
import DigitizationPage from 'components/cattles/digitizationPage/DigitizationPage'
import FatnessPage from 'components/cattles/fatnessPage/FatnessPage'
import LamenessPage from 'components/cattles/lamenessPage/LamenessPage'
import CowPasportPage from 'components/cattles/passportPage/CowPasportPage'
import { useAppSelector } from './store/hooks'
import { fontTheme } from './styles/font'
import './styles/font.css'
import { hasPermission } from './utils'
const theme = fontTheme
const GroupsPage = lazy(() => import('./layouts/GroupsPage'))
const ViewPlanPage = lazy(() => import('./layouts/ViewPlanPage'))
const EditPlanPage = lazy(() => import('./components/plan/EditPlanPage'))
const FillPage = lazy(() => import('./components/fill/FillPage'))
function App() {
	const userPermissions = useAppSelector(state => state.authReducer.permissions)
	const mainPage = userPermissions?.can_view_foodtable_main
		? '/feed'
		: '/cattles'
	return (
		<AuthProvider>
			<ThemeProvider theme={theme}>
				<Routes>
					<Route path='/' element={<Layout />}>
						<Route path='/login' element={<LoginPage />} />
						<Route index element={<Navigate to={mainPage} replace />} />

						<Route
							path='profile'
							element={
								<RequireAuth>
									<ProfilePage />
								</RequireAuth>
							}
						/>
						<Route
							path='fill'
							element={
								<RequireAuth>
									<Suspense fallback={<Preloader mt={50} />}>
										<FillPage />
									</Suspense>
								</RequireAuth>
							}
						/>
						<Route
							path='fill/:id'
							element={
								<RequireAuth>
									<FarmFillPage />
								</RequireAuth>
							}
						/>
						<Route
							path='fill/:id/ration'
							element={
								<RequireAuth>
									<FarmFillRation />
								</RequireAuth>
							}
						/>
						<Route
							path='fill/:id/ration/:rationkey'
							element={
								<RequireAuth>
									<FarmFillRation />
								</RequireAuth>
							}
						/>
						<Route
							path='fill/:id/groups'
							element={
								<RequireAuth>
									<FarmFillGroups />
								</RequireAuth>
							}
						/>
						<Route
							path='fill/:id/feedplan'
							element={
								<RequireAuth>
									<FarmFillFeedPlan />
								</RequireAuth>
							}
						/>
						<Route
							path='fill/:id/done'
							element={
								<RequireAuth>
									<FarmFillDonePage />
								</RequireAuth>
							}
						/>
						<Route
							path='settings'
							element={
								<RequireAuth>
									{hasPermission(userPermissions, ['can_manage_users']) ? (
										<SettingPage />
									) : (
										<Navigate to='/error' replace />
									)}
								</RequireAuth>
							}
						/>
						<Route
							path='settings/management/'
							element={
								<RequireAuth>
									{hasPermission(userPermissions, ['can_manage_users']) ? (
										<ManagementPage />
									) : (
										<Navigate to='/error' replace />
									)}
								</RequireAuth>
							}
						/>
						<Route
							path='settings/management/:userId/edit'
							element={
								<RequireAuth>
									{hasPermission(userPermissions, [
										'can_manage_permissions',
									]) ? (
										<SettingUser />
									) : (
										<Navigate to='/error' replace />
									)}
								</RequireAuth>
							}
						/>
						<Route
							path='settings/management/create-user'
							element={
								<RequireAuth>
									{hasPermission(userPermissions, [
										'can_manage_permissions',
									]) ? (
										<SettingUser />
									) : (
										<Navigate to='/error' replace />
									)}
								</RequireAuth>
							}
						/>
						<Route
							path='feed'
							element={
								<RequireAuth>
									{hasPermission(userPermissions, [
										'can_view_foodtable_main',
									]) ? (
										<FeedGroupsPage />
									) : (
										<Navigate to='/error' replace />
									)}
								</RequireAuth>
							}
						/>
						<Route
							path='feed/deviations'
							element={
								<RequireAuth>
									<DeviationPage />
								</RequireAuth>
							}
						/>
						<Route
							path='feed/analytics'
							element={
								<RequireAuth>
									<AnalyticsPage />
								</RequireAuth>
							}
						/>
						<Route
							path='feed/analytics/feeding'
							element={
								<RequireAuth>
									<FeedingPaginationList />
								</RequireAuth>
							}
						/>
						<Route
							path='feed/groups/'
							element={
								<RequireAuth>
									{hasPermission(userPermissions, [
										'can_view_cattle_groups',
									]) ? (
										<Suspense fallback={<Preloader mt={50} />}>
											<GroupsPage />
										</Suspense>
									) : (
										<Navigate to='/error' replace />
									)}
								</RequireAuth>
							}
						/>
						<Route
							path='feed/groups/:groupkey'
							element={
								<RequireAuth>
									{hasPermission(userPermissions, [
										'can_view_cattle_groups',
									]) ? (
										<GroupPage />
									) : (
										<Navigate to='/error' replace />
									)}
								</RequireAuth>
							}
						/>
						<Route
							path='feed/groups/:groupkey/edit'
							element={
								<RequireAuth>
									{hasPermission(userPermissions, [
										'can_edit_cattle_groups',
									]) ? (
										<GroupSpecificEdit />
									) : (
										<Navigate to='/error' replace />
									)}
								</RequireAuth>
							}
						/>
						<Route
							path='feed/ration'
							element={
								<RequireAuth>
									{hasPermission(userPermissions, ['can_view_rations']) ? (
										<RationPageEntry />
									) : (
										<Navigate to='/error' replace />
									)}
								</RequireAuth>
							}
						/>
						<Route
							path='feed/ration/create'
							element={
								<RequireAuth>
									{hasPermission(userPermissions, ['can_edit_rations']) ? (
										<AddNewRation />
									) : (
										<Navigate to='/error' replace />
									)}
								</RequireAuth>
							}
						/>
						<Route
							path='feed/ration/:rationid'
							element={
								<RequireAuth>
									{hasPermission(userPermissions, ['can_view_rations']) ? (
										<RationViewPage />
									) : (
										<Navigate to='/error' replace />
									)}
								</RequireAuth>
							}
						/>
						<Route
							path='feed/ration/:rationid/edit'
							element={
								<RequireAuth>
									{hasPermission(userPermissions, ['can_edit_rations']) ? (
										<RationEditPage />
									) : (
										<Navigate to='/error' replace />
									)}
								</RequireAuth>
							}
						/>
						<Route
							path='feed/plan'
							element={
								<RequireAuth>
									{hasPermission(userPermissions, ['can_view_feedplans']) ? (
										<Suspense fallback={<Preloader />}>
											<ViewPlanPage />
										</Suspense>
									) : (
										<Navigate to='/error' replace />
									)}
								</RequireAuth>
							}
						/>

						<Route
							path='feed/plan/edit'
							element={
								<RequireAuth>
									{hasPermission(userPermissions, ['can_edit_feedplans']) ? (
										<Suspense fallback={<Preloader />}>
											<EditPlanPage />
										</Suspense>
									) : (
										<Navigate to='/error' replace />
									)}
								</RequireAuth>
							}
						/>
						<Route
							path='feed/plan/history'
							element={
								<RequireAuth>
									<HistoryPage />
								</RequireAuth>
							}
						/>
						<Route
							path='feed/plan/history/:groupkey'
							element={
								<RequireAuth>
									<HistoryPage />
								</RequireAuth>
							}
						/>
						<Route
							path='cattles'
							element={
								<RequireAuth>
									<CattlesPage />
								</RequireAuth>
							}
						/>
						<Route
							path='cattles/lameness'
							element={
								<RequireAuth>
									<LamenessPage />
								</RequireAuth>
							}
						/>
						<Route
							path='cattles/fatness'
							element={
								<RequireAuth>
									<FatnessPage />
								</RequireAuth>
							}
						/>
						<Route
							path='cattles/digitization'
							element={
								<RequireAuth>
									<DigitizationPage />
								</RequireAuth>
							}
						/>
						<Route
							path='cattles/cow/:tagNumber'
							element={
								<RequireAuth>
									<CowPasportPage />
								</RequireAuth>
							}
						/>
						<Route
							path='*'
							element={
								<Navigate
									to={
										userPermissions?.can_view_foodtable_main
											? '/feed'
											: '/feed/plan'
									}
									replace
								/>
							}
						/>
					</Route>
					<Route path='/error' element={<ErrorPerms />} />
				</Routes>
			</ThemeProvider>
		</AuthProvider>
	)
}

export default App
