import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { roundingNumber } from 'components/shared/processes'
import moment from 'moment'
import axAPI from '../http'
import {
	IRationArray,
	IRationInitial,
	IRationTableComponents,
} from '../models/IRationSystem'

const handleAsyncThunkError = (error: Error | string) => {
	let errorMessage = 'Failed to retrieve data!'
	if (error instanceof Error) {
		errorMessage = error.message
	}
	return errorMessage
}

export const fetchRations = createAsyncThunk(
	'ration/',
	async function (_, { rejectWithValue }) {
		try {
			const response = await axAPI({
				method: 'GET',
				url: 'ration/',
			})

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data
		} catch (error) {
			return rejectWithValue(handleAsyncThunkError(error))
		}
	}
)

export const fetchRationById = createAsyncThunk(
	'ration/id',
	async function (ration_id: string, { rejectWithValue }) {
		try {
			const response = await axAPI({
				method: 'GET',
				url: `ration/${ration_id}`,
			})

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data
		} catch (error) {
			return rejectWithValue(handleAsyncThunkError(error))
		}
	}
)

export const fetchRationComponents = createAsyncThunk(
	'ration/components',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axAPI({
				method: 'GET',
				url: 'ration/components',
			})

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data.reverse()
		} catch (error) {
			return rejectWithValue(handleAsyncThunkError(error))
		}
	}
)

export const fetchComponentsById = createAsyncThunk(
	'ration/id/components',
	async (ration_id: string, { rejectWithValue }) => {
		try {
			const response = await axAPI({
				method: 'GET',
				url: `ration/${ration_id}/components`,
			})

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data
		} catch (error) {
			return rejectWithValue(handleAsyncThunkError(error))
		}
	}
)

const initialState: IRationInitial = {
	loading: false,
	error: '',
}

export const rationSlice = createSlice({
	name: 'ration',
	initialState,
	reducers: {
		returnInitialStateForRation(state, action) {
			const { initialState, index } = action.payload
			const newRationArray = [...state.rationArray]
			newRationArray[index] = initialState
			return {
				...state,
				rationArray: newRationArray,
			}
		},
		addNewRation(state) {
			const newRationData: IRationArray = {
				title: '',
				dry_matter_total: null,
				food_humidity: 0,
				components: [],
				open: true,
			}
			state.rationArray.push(newRationData)
		},
		removeRation(state, action) {
			const { index } = action.payload
			const newRationArray = [
				...state.rationArray.slice(0, index),
				...state.rationArray.slice(index + 1),
			]
			return { ...state, rationArray: newRationArray }
		},

		changeComponentInfo(state, action) {
			const { title, dry_matter_percent } = action.payload
			let componentId = null
			const updatedComponents = state.rationComponents.map(component => {
				if (component.id === action.payload.id) {
					componentId = component.id
					return {
						...component,
						title: title,
						dry_matter_percent: dry_matter_percent,
					}
				}
				return component
			})

			let updatedRationInfo = state.rationInfo
			if (dry_matter_percent && state.rationInfo && componentId) {
				const components = state.rationInfo.components
				const updatedComponents = components.map(el => {
					if (el.component_id === componentId) {
						return {
							...el,
							head_weight: roundingNumber(
								(+el.dry_per_head * 100) / dry_matter_percent,
								1
							),
						}
					} else {
						return el
					}
				})

				const finallyDryMatterKg = updatedComponents.reduce(
					(sum, component) => sum + +component.dry_per_head,
					0
				)
				const finallyWeightMatterKg = updatedComponents.reduce(
					(sum, component) => sum + +component.head_weight,
					0
				)
				const finallyCV = (+finallyDryMatterKg / +finallyWeightMatterKg) * 100

				updatedRationInfo = {
					...state.rationInfo,
					components: updatedComponents,
					finallyCV: finallyCV,
					finallyDryMatterKg: finallyDryMatterKg,
					finallyWeightMatterKg: finallyWeightMatterKg,
					food_humidity: 100 - finallyCV,
				}
			}

			const updateCompForOneRation = state.rationCompById.map(el => {
				if (el.id === action.payload.id) {
					return {
						...el,
						title: title,
						dry_matter_percent: dry_matter_percent,
					}
				} else {
					return el
				}
			})

			return {
				...state,
				rationComponents: updatedComponents,
				rationInfo: updatedRationInfo,
				rationCompById: updateCompForOneRation,
			}
		},
		changeTitleRation(state, action) {
			const { value, index } = action.payload
			if (index !== undefined) {
				const foundComponent = state.rationArray[index]
				foundComponent.title = value
			} else {
				state.rationInfo.title = value
			}
		},
		changeArchiveTitleRation(state, action) {
			state.rationInfo.archive_title = action.payload
		},
		changeHumidityRations(state, action) {
			const { value } = action.payload
			state.rationInfo = {
				...state.rationInfo,
				food_humidity: value,
				finallyCV: 100 - value,
			}
		},
		editDryMatterById(state, action) {
			const { componentId, calculatedValue, index } = action.payload
			const findCompProcent = state.rationComponents?.find(
				el => el.id === componentId
			)

			const updateComponents = components => {
				return components.map(component => {
					if (component.component_id === componentId) {
						const headWeight = roundingNumber(
							(+calculatedValue * 100) / findCompProcent.dry_matter_percent,
							1
						)
						return {
							...component,
							dry_per_head: calculatedValue || '',
							head_weight: calculatedValue ? headWeight : '',
						}
					}
					return component
				})
			}

			const updateRationInfo = ration => {
				const finallyDryMatterKg = ration.components.reduce(
					(sum, component) => sum + +component.dry_per_head,
					0
				)
				const finallyWeightMatterKg = ration.components.reduce(
					(sum, component) => sum + +component.head_weight,
					0
				)
				const finallyCV =
					(+finallyDryMatterKg / +finallyWeightMatterKg) * 100 || null

				ration.finallyDryMatterKg = finallyDryMatterKg
				ration.finallyCV = finallyCV
				ration.food_humidity = finallyCV ? 100 - finallyCV : null
				ration.finallyWeightMatterKg = finallyWeightMatterKg
			}

			if (state.rationInfo) {
				state.rationInfo.components = updateComponents(
					state.rationInfo.components
				)
				updateRationInfo(state.rationInfo)
			} else if (state.rationArray[index]) {
				const updatedRationFromArray = state.rationArray[index]
				updatedRationFromArray.components = updateComponents(
					updatedRationFromArray.components
				)
				updateRationInfo(updatedRationFromArray)
				state.rationArray[index] = updatedRationFromArray
			}
		},
		editWaterWeight(state, action) {
			const { waterWeight, index } = action.payload
			if (state.rationInfo && waterWeight) {
				const finallyCV =
					(+state.rationInfo.finallyDryMatterKg /
						(+state.rationInfo.finallyWeightMatterKg + +waterWeight)) *
					100
				const humidity = 100 - finallyCV
				state.rationInfo.food_humidity = humidity
				state.rationInfo.finallyCV = finallyCV
			}
			if (state.rationArray) {
				const updateRation = state.rationArray[index]
				const finallyCV =
					(+updateRation.finallyDryMatterKg /
						(+updateRation.finallyWeightMatterKg + +waterWeight)) *
					100
				const humidity = 100 - finallyCV
				updateRation.food_humidity = humidity
				updateRation.finallyCV = finallyCV
				state.rationArray[index] = updateRation
			}
		},
		editWeightPerHeadById(state, action) {
			const { componentId, newWeightPerHead, index } = action.payload
			const findCompProcent = state.rationComponents?.find(
				el => el.id === componentId
			)

			const updateComponent = component => {
				const dryWeight = roundingNumber(
					(+findCompProcent.dry_matter_percent * +newWeightPerHead) / 100,
					1
				)

				return {
					...component,
					dry_per_head: newWeightPerHead ? dryWeight : '',
					head_weight: newWeightPerHead || '',
				}
			}

			const updatedRationInfo = ration => {
				const updatedComponents = ration.components.map(component =>
					component.component_id === componentId
						? updateComponent(component)
						: component
				)

				const finallyDryMatterKg = updatedComponents.reduce(
					(sum, component) => sum + +component.dry_per_head,
					0
				)
				const finallyWeightMatterKg = updatedComponents.reduce(
					(sum, component) => sum + +component.head_weight,
					0
				)
				const finallyCV =
					(+finallyDryMatterKg / +finallyWeightMatterKg) * 100 || null

				ration.components = updatedComponents
				ration.finallyDryMatterKg = finallyDryMatterKg
				ration.finallyCV = finallyCV
				ration.food_humidity = 100 - +finallyCV
				ration.finallyWeightMatterKg = +finallyWeightMatterKg
			}

			if (state.rationInfo) {
				updatedRationInfo(state.rationInfo)
			}

			if (state.rationArray) {
				updatedRationInfo(state.rationArray[index])
			}
		},
		removeComponentFromRation(state, action) {
			const { componentId, index } = action.payload

			if (state.rationInfo) {
				const updatedComponents = state.rationInfo.components
					.filter(component => component.component_id !== componentId)
					.map(component => ({
						...component,
					}))

				const finallyDryMatterKg = updatedComponents.reduce(
					(sum, component) => sum + +component.dry_per_head,
					0
				)
				const finallyWeightMatterKg = updatedComponents.reduce(
					(sum, component) => sum + +component.head_weight,
					0
				)
				const finallyCV = (+finallyDryMatterKg / +finallyWeightMatterKg) * 100
				state.rationInfo.components = updatedComponents
				state.rationInfo.finallyCV = finallyCV
				state.rationInfo.food_humidity = 100 - finallyCV
				state.rationInfo.finallyDryMatterKg = finallyDryMatterKg
				state.rationInfo.finallyWeightMatterKg = finallyWeightMatterKg
			} else if (state.rationArray[index]) {
				const updatedComponents = state.rationArray[index].components.filter(
					component => component.component_id !== componentId
				)
				const finallyDryMatterKg = updatedComponents.reduce(
					(sum, component) => sum + +component.dry_per_head,
					0
				)
				const finallyWeightMatterKg = updatedComponents.reduce(
					(sum, component) => sum + +component.head_weight,
					0
				)
				const finallyCV = (+finallyDryMatterKg / +finallyWeightMatterKg) * 100
				state.rationArray[index].components = updatedComponents
				state.rationArray[index].finallyCV = finallyCV
				state.rationArray[index].food_humidity = 100 - finallyCV
				state.rationArray[index].finallyDryMatterKg = finallyDryMatterKg
				state.rationArray[index].finallyWeightMatterKg = finallyWeightMatterKg
			}
			return state
		},
		addComponentToRation(state, action) {
			const { id, index } = action.payload

			const updateRation = ration => {
				const isComponentSelected = ration.components.some(
					component => component.component_id === id
				)

				if (isComponentSelected) {
					ration.components = ration.components.filter(
						component => component.component_id !== id
					)
				} else {
					const selectedComponent = state.rationComponents.find(
						component => component.id === id
					)

					if (selectedComponent) {
						const newComponent = {
							component_id: selectedComponent.id,
							dry_per_head: 0,
							head_weight: 0,
						}
						ration.components.push(newComponent)
					}
				}
			}

			if (state.rationInfo && state.rationComponents) {
				updateRation(state.rationInfo)
			}

			if (state.rationArray && state.rationArray[index]) {
				updateRation(state.rationArray[index])
			}
		},
	},

	extraReducers: {
		[fetchRations.fulfilled.type]: (
			state,
			action: PayloadAction<IRationArray[]>
		) => {
			state.loading = false
			state.error = ''
			state.rationArray = action.payload

			if (
				state.rationArray &&
				state.rationComponents &&
				state.rationArray.length > 0
			) {
				const newAdapterRationsArray = state.rationArray.map(ration => {
					let finallyDryMatter = 0
					let finallyWeightMatter = 0

					const updatedComponents = ration.components.map(comp => {
						const findComponent = state.rationComponents.find(
							el => el.id === comp.component_id
						)
						const updateRationComp = findComponent
							? roundingNumber(
									(+comp.dry_per_head * 100) / findComponent.dry_matter_percent,
									1
							  )
							: 0

						finallyDryMatter += +comp.dry_per_head
						finallyWeightMatter += +updateRationComp

						return {
							...comp,
							head_weight: +updateRationComp,
						}
					})

					ration.components = updatedComponents

					ration.finallyCV = 100 - ration.food_humidity
					ration.finallyDryMatterKg = finallyDryMatter
					ration.finallyWeightMatterKg = finallyWeightMatter

					return ration // Возвращаем обновлённый объект ration
				})

				state.rationArray = newAdapterRationsArray
			}
		},
		[fetchRations.pending.type]: state => {
			state.loading = true
			state.error = ''
			state.rationArray = []
		},
		[fetchRations.rejected.type]: (state, action: PayloadAction<string>) => {
			state.loading = false
			state.error = action.payload
			state.rationArray = []
		},
		[fetchRationById.fulfilled.type]: (
			state,
			action: PayloadAction<IRationArray>
		) => {
			state.loading = false
			state.error = ''
			state.rationInfo = action.payload

			if (state.rationInfo && state.rationCompById) {
				const maxTitleLength =
					45 - ` арх. от ${moment().format('DD.MM.YYYY, HH:mm')}`.length
				state.rationInfo = {
					...state.rationInfo,
					archive_title:
						state.rationInfo.title.length > maxTitleLength
							? state.rationInfo.title.substring(0, maxTitleLength) +
							  '...' +
							  ` арх. от ${moment().format('DD.MM.YYYY, HH:mm')}`
							: state.rationInfo.title +
							  ` арх. от ${moment().format('DD.MM.YYYY, HH:mm')}`,
				}

				let finallyDryMatter = 0
				let finallyWeightMatter = 0

				const updatedComponents = state.rationInfo.components?.map(comp => {
					const findComponent = state.rationCompById?.find(
						el => el.id === comp.component_id
					)
					const updateRationComp = findComponent
						? roundingNumber(
								(+comp.dry_per_head * 100) / findComponent.dry_matter_percent,
								1
						  )
						: 0

					finallyDryMatter += +comp.dry_per_head
					finallyWeightMatter += +updateRationComp

					return {
						...comp,
						head_weight: +updateRationComp,
					}
				})

				state.rationInfo.components = updatedComponents

				state.rationInfo.finallyCV =
					// (+finallyDryMatter / +finallyWeightMatter) * 100;
					100 - state.rationInfo.food_humidity

				state.rationInfo.finallyDryMatterKg = finallyDryMatter
				state.rationInfo.finallyWeightMatterKg = finallyWeightMatter
			}
		},
		[fetchRationById.pending.type]: state => {
			state.loading = true
			state.error = ''
			state.rationInfo = undefined
		},
		[fetchRationById.rejected.type]: (state, action: PayloadAction<string>) => {
			state.loading = false
			state.error = action.payload
			state.rationInfo = undefined
		},
		[fetchComponentsById.fulfilled.type]: (
			state,
			action: PayloadAction<IRationTableComponents[]>
		) => {
			state.loading = false
			state.error = ''
			state.rationCompById = action.payload
		},
		[fetchComponentsById.pending.type]: state => {
			state.loading = true
			state.error = ''
			state.rationCompById = undefined
		},
		[fetchComponentsById.rejected.type]: (
			state,
			action: PayloadAction<string>
		) => {
			state.loading = false
			state.error = action.payload
			state.rationCompById = undefined
		},
		[fetchRationComponents.fulfilled.type]: (
			state,
			action: PayloadAction<IRationTableComponents[]>
		) => {
			state.loading = false
			state.error = ''
			state.rationComponents = action.payload
		},
		[fetchRationComponents.pending.type]: state => {
			state.loading = true
			state.error = ''
			state.rationComponents = []
		},
		[fetchRationComponents.rejected.type]: (
			state,
			action: PayloadAction<string>
		) => {
			state.loading = false
			state.error = action.payload
			state.rationComponents = []
		},
	},
})

export const {
	addNewRation,
	removeRation,
	returnInitialStateForRation,
	changeComponentInfo,
	changeTitleRation,
	editDryMatterById,
	editWeightPerHeadById,
	removeComponentFromRation,
	addComponentToRation,
	changeArchiveTitleRation,
	changeHumidityRations,
	editWaterWeight,
} = rationSlice.actions
export default rationSlice.reducer
