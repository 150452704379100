import Box from '@mui/material/Box/Box'
import IconButton from '@mui/material/IconButton/IconButton'
import InputAdornment from '@mui/material/InputAdornment/InputAdornment'
import TextField from '@mui/material/TextField/TextField'
import { useState } from 'react'
import { ReactComponent as BlindPassLogo } from '../assets/blind_pass_logo.svg'
import { ReactComponent as UnBlindPassLogo } from '../assets/unblind_pass_logo.svg'
import { COLORS } from '../styles/colors'
import { TypographySecondary } from './typography/TypographySecondary'

const PasswordInput = props => {
	const [passError, setPassError] = useState(false)
	const [showPass, setShowPass] = useState(false)
	const [lengthError, setLengthError] = useState(true)
	const [uppercaseError, setUppercaseError] = useState(true)

	const handleClickShowPass = () => setShowPass(!showPass)

	const handlePasswordChange = event => {
		const password = event.target.value
		const passwordRegex = /^[A-Za-z0-9!-@#$%.^,&*()_{}[\];:"/|^]+$/

		setPassError(!passwordRegex.test(password))
		setLengthError(password.length < 8)
		setUppercaseError(!/(?=.*?[A-Z])(?=.*[a-z])/.test(password))
		if (password === '') {
			setPassError(false)
		}
		if (props.id === 'newPassword') {
			props.setNewPassword(password)
		}
		if (props.id === 'confirmNewPassword') {
			props.setConfirmNewPassword(password)
		}
	}

	if (props.setControlButtonError && props.newPassword !== '') {
		props.setControlButtonError(passError || lengthError || uppercaseError)
	} else if (props.setControlButtonError && props.newPassword === '') {
		props.setControlButtonError(false)
	}

	return (
		<>
			<TextField
				sx={{
					borderRadius: '12px',
					width: '100%',
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							borderColor: passError ? COLORS.error : COLORS.inputBorder,
						},
						'&:hover fieldset': {
							borderColor: COLORS.icon,
						},
						'&.Mui-focused fieldset': {
							border: `1px solid ${passError ? COLORS.error : COLORS.main}`,
						},
					},
					'& label': {
						color: passError ? COLORS.error : COLORS.secondaryFont,
					},
					'&:hover label': {
						color: COLORS.icon,
					},
					'& label.Mui-focused': {
						color: passError ? COLORS.error : COLORS.main,
					},
				}}
				margin='normal'
				id={`password${props.key}`}
				type={showPass ? 'text' : 'password'}
				error={props.error}
				autoComplete={'off'}
				label={props.label}
				onChange={handlePasswordChange}
				onClick={props.onClick}
				helperText={
					passError
						? 'Пароль может состоять только из букв латинского алфавита (A-z), арабских цифр (0-9) и ‘!-@#$%.^,&*()_{}[];:”/|^'
						: props.id === 'confirmNewPassword' &&
						  !props.samePassword &&
						  props.confirmNewPassword !== ''
						? 'Пароли не совпадают'
						: props.error
						? 'Неправильный пароль'
						: ''
				}
				FormHelperTextProps={{
					style: { color: COLORS.error },
				}}
				inputProps={{
					autoComplete: 'off',
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							<IconButton
								aria-label='toggle password visibility'
								onMouseDown={handleClickShowPass}
								disableRipple
							>
								{showPass ? <UnBlindPassLogo /> : <BlindPassLogo />}
							</IconButton>
						</InputAdornment>
					),
					style: {
						fontSize: '14px',
						borderRadius: '8px',
						margin: '0px',
						paddingRight: '12px',
					},
				}}
				InputLabelProps={{
					style: { fontSize: '14px' },
				}}
			/>
			{props.needTooltip && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '8px',
						marginTop: '12px',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '8px',
						}}
					>
						<Box
							sx={{
								backgroundColor:
									lengthError || passError
										? COLORS.inputBorder
										: COLORS.success,
								borderRadius: '50%',
								width: '12px',
								height: '12px',
							}}
						></Box>
						<TypographySecondary>Не менее 8 символов</TypographySecondary>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '8px',
						}}
					>
						<Box
							sx={{
								backgroundColor:
									uppercaseError || passError
										? COLORS.inputBorder
										: COLORS.success,
								borderRadius: '50%',
								width: '12px',
								height: '12px',
							}}
						></Box>
						<TypographySecondary>
							Минимум одна заглавная и одна строчная буква
						</TypographySecondary>
					</Box>
				</Box>
			)}
		</>
	)
}

export default PasswordInput
