import { Box } from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import { COLORS } from 'styles/colors'
import SwitchiOS from 'ui/SwitchiOS'
import RangeDate from 'ui/datePicker/RangeDate'
import { TypographyHeaderSecondary } from 'ui/typography/TypographyHeaderSecondary'
import { ICheckupList } from '../models'
type DefectiveMilkBlockProps = {
	startOfReject: number
	endOfReject: number
	setCheckupInfo: Dispatch<SetStateAction<ICheckupList>>
}
const DefectiveMilkBlock = (props: DefectiveMilkBlockProps) => {
	const { startOfReject, endOfReject, setCheckupInfo } = props
	const [activeSwitch, setActiveSwitch] = useState<boolean>(false)
	const [dateRange, setDateRange] = useState({
		startDate: startOfReject,
		endDate: endOfReject,
	})
	const onChangeCallback = value => {
		setDateRange({
			startDate: value[0],
			endDate: value[1],
		})
	}

	const toggleSwitch = () => {
		setActiveSwitch(prev => !prev)
	}
	return (
		<Box
			display={'flex'}
			flexDirection={'row'}
			width={'100%'}
			alignItems={'flex-start'}
			gap={'40px'}
		>
			<Box width={'150px'}>
				<TypographyHeaderSecondary>
					Забраковать
					<br />
					молоко
					<br />
					на период
				</TypographyHeaderSecondary>
			</Box>
			<Box display={'flex'} flexDirection={'column'} gap={'16px'}>
				<SwitchiOS
					checked={activeSwitch}
					background={COLORS.health}
					onClick={toggleSwitch}
				/>
				<RangeDate
					disabled={!activeSwitch}
					onChangeUpdateDate={onChangeCallback}
					startDate={dateRange.startDate}
					endDate={dateRange.endDate}
					disabledDates={null}
				/>
			</Box>
		</Box>
	)
}

export default DefectiveMilkBlock
