import { Box, Typography } from '@mui/material'
import { EDeviationState } from 'models/EDeviation'
import moment from 'moment'
import 'moment/locale/ru'
import React from 'react'
import { COLORS } from 'styles/colors'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import DeviationItem from '../shared/DeviationItem'

moment.locale('ru')

const DeviationsList = ({
	deviationsList,
	select,
	activeCard,
	controlValue,
}) => {
	const filteredArr = deviationsList.map(group =>
		group.filter(el => {
			if (select === EDeviationState.Ration) {
				return el.type === select || el.type === EDeviationState.FeedplanWeight
			} else if (select === EDeviationState.Camera) {
				return el.type === select || el.type === EDeviationState.CameraIssue
			} else {
				return el.type === select
			}
		})
	)

	const isEmpty = deviationsList
		.map(el => el.length === 0)
		.every(el => el === true)

	const getMonthName = month => {
		const monthNames = [
			'января',
			'февраля',
			'марта',
			'апреля',
			'мая',
			'июня',
			'июля',
			'августа',
			'сентября',
			'октября',
			'ноября',
			'декабря',
		]
		return monthNames[month]
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				background: '#fff',
				borderRadius: '12px',
				boxShadow: `${COLORS.shadowSecondary}`,
			}}
		>
			{!isEmpty ? (
				<Box sx={{ padding: '24px 24px' }}>
					{activeCard === false ? (
						deviationsList?.map(group =>
							group.map((item, itemIndex) => (
								<React.Fragment key={item.id}>
									{itemIndex === 0 && (
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'column',
												marginBottom: '24px',
												borderBottom: '1px solid #DBDBDB',
											}}
										>
											<Typography
												sx={{
													fontSize: '14px',
													fontWeight: '400',
													lineHeight: '16.8px',
													color: '#7f7f84',
													paddingBottom: '8px',
												}}
											>
												{`${moment(item.created_dt).format(
													'DD'
												)} ${getMonthName(moment(item.created_dt).month())}`}
											</Typography>
										</Box>
									)}
									<DeviationItem item={item} key={item.id} />
								</React.Fragment>
							))
						)
					) : filteredArr
							.map(el => el.length === 0)
							.every(el => el === true) ? (
						<TypographyPrimary
							sx={{
								color: COLORS.secondaryFont,
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
							}}
						>
							Отклонений не обнаружено
						</TypographyPrimary>
					) : (
						filteredArr?.map(group =>
							group.map((item, itemIndex) => (
								<React.Fragment key={item.id}>
									{itemIndex === 0 && (
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'column',
												marginBottom: '24px',
												borderBottom: '1px solid #DBDBDB',
											}}
										>
											<Typography
												sx={{
													fontSize: '14px',
													fontWeight: '400',
													lineHeight: '16.8px',
													color: '#7f7f84',
													paddingBottom: '8px',
												}}
											>
												{`${moment(item.created_dt).format(
													'DD'
												)} ${getMonthName(moment(item.created_dt).month())}`}
											</Typography>
										</Box>
									)}
									<DeviationItem item={item} key={item.id} />
								</React.Fragment>
							))
						)
					)}
				</Box>
			) : (
				<Box
					sx={{
						padding: '24px 24px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}
				>
					<TypographyPrimary style={{ color: COLORS.secondaryFont }}>
						{controlValue
							? 'По данному запросу ничего не найдено'
							: 'Отклонений не обнаружено'}
					</TypographyPrimary>
				</Box>
			)}
		</Box>
	)
}

export default DeviationsList
