import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import { useState, useRef } from 'react'
import { COLORS } from '../../styles/colors'



type TypeRationTextFieldProps = {
  label: string;
  key?: string;
  id?:any;
  sx?: any;
  value?: any;
  isPassword?: boolean;
  helperText?: string;
  isError?: boolean;
  marginLeft?: string;
  isErrorFocus?: boolean;
  onChange?: (e?) => void;
  onBlur?: any;
  onFocus?: any;
  type?: any;
  width?: string
  maxLength?: number
}

export const UserEditField = (props: TypeRationTextFieldProps) => {
  
  const [showPass, setShowPass] = useState(!props.isPassword)

  const [focused, setFocused] = useState(false)

  const inputRef: any = useRef<HTMLInputElement>();

  let sx = {width: props.width ? props.width : '500px', minHeight: '72px', '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: props.isErrorFocus ? COLORS.deviationFont : '#ced4da',
      borderWidth: props.isErrorFocus ? '2px' : '1px',
    },
    '&:hover fieldset': {
      borderColor:  props.isErrorFocus ? COLORS.deviationFont : 'black'
    },
    '&.Mui-focused fieldset': {
      borderColor: props.isErrorFocus ? COLORS.deviationFont : COLORS.main,
    },
  },
  '&.MuiOutlinedInput-notchedOutline': {
    '&.Mui-focused fieldset': {
      borderColor: COLORS.main,
    },
  },
}

  const handleBlur = () => {
    setFocused(false)
    props.onBlur()
  }

  const handleFocus = () => {
    setFocused(true)
    props.onFocus()
  }

  return <TextField 
    helperText={props.isErrorFocus ? props.helperText : ''}
    margin="dense"
    key={props.id}
    id={props.id}
    label={props.label}
    value={props.value}
    inputRef={inputRef}
    autoComplete="off"
    onChange={props.onChange}
    onBlur={handleBlur}
    onFocus={handleFocus}
    type={showPass ? "text" : "password"}
    sx={props.sx ? props.sx : sx}
    variant="outlined"
    FormHelperTextProps={{
      style: { fontSize: '12px', lineHeight: '15px', fontWeight: 400, color: props.isErrorFocus ? COLORS.deviationFont : COLORS.icon, marginLeft: '0px' }
    }}
    inputProps={{ maxLength: props.maxLength && props.maxLength }}
    InputProps={{
      style: { fontSize: '14px', lineHeight: '17px', fontWeight: 400, color: props.isError ? COLORS.deviationFont : COLORS.black, borderRadius: '8px', borderColor: focused ? COLORS.main : COLORS.icon},
      endAdornment: props.isPassword ? (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShowPass(!showPass)}
          >
            {showPass ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment> 
      ) : <></>
    }}
    InputLabelProps={{
      style: { fontSize: '14px', lineHeight: '17px', fontWeight: 400, color: props.isErrorFocus ? COLORS.deviationFont : (focused ? COLORS.main : COLORS.icon), }
    }}
  />
}

type TypeRationSelectFieldProps = {
  label: string
  sx?: any
  key: string
  value: string | number
  onChange?: any
  choiceList?: any
  isErrorFocus?: boolean
}



export const RationSelectField = (props: TypeRationSelectFieldProps) => {
  const sx = {
    width: '64.3vw', '& .MuiOutlinedInput-root': {
      borderColor: props.isErrorFocus ? COLORS.deviationFont : COLORS.secondaryFontOpacity,
      '&.Mui-focused fieldset': {
        borderColor: props.isErrorFocus ? COLORS.deviationFont : COLORS.secondaryFontOpacity,
      },
    },
    '&.MuiOutlinedInput-notchedOutline': {
      borderColor: props.isErrorFocus ? COLORS.deviationFont : COLORS.secondaryFontOpacity,
    },
    "& .MuiOutlinedInput-notchedOutline legend": { display: "none", }
  }
  return <TextField
    error={props.isErrorFocus}
    select
    margin="dense"
    key={props.key}
    label={props.label}
    value={props.value}
    onChange={props.onChange}
    type="text"
    sx={props.sx ? props.sx : sx}
    variant="outlined"
    InputProps={{
      style: { fontSize: '14px', lineHeight: '17px', fontWeight: 400, color: props.isErrorFocus ? COLORS.deviationFont : COLORS.black, borderRadius: '8px' }
    }}
    InputLabelProps={{
      style: { fontSize: '14px', lineHeight: '17px', fontWeight: 400, color: props.isErrorFocus ? COLORS.deviationFont : COLORS.icon, }
    }}
  >
    {
      props.choiceList.map((option) => {
        return <MenuItem key={option.key} value={option.title}>
          {option.title}
        </MenuItem>
      })
    }
  </TextField>
}
