import { Box, Card, CardContent } from '@mui/material'
import { Suspense, lazy, useState } from 'react'

import { ReactComponent as AnalyticsDownloadLogo } from '../../assets/analytics_download_logo.svg'
import { COLORS } from '../../styles/colors'

import moment from 'moment'
import axAPI from '../../http'
import { OutlinedButton } from '../../ui/buttons/OutlinedButton'
import { TypographyHeader } from '../../ui/typography/TypographyHeader'
import { TypographySecondary } from '../../ui/typography/TypographySecondary'

import fileSaver from 'file-saver'
import RangeDate from '../../ui/datePicker/RangeDate'
import { firstDayHandler } from '../shared/processes'
// import FeedingAnalyticsCard from './FeedingAnalyticsCard'
import Preloader from 'ui/Preloader'
const FeedingAnalyticsCard = lazy(() => import('./FeedingAnalyticsCard'))
const AnalyticsPage = () => {
	const [color, setColor] = useState(COLORS.secondaryFont)
	const primaryDateState = firstDayHandler()

	const [dateRange, setDateRange] = useState({
		startDate: primaryDateState[0],
		endDate: primaryDateState[1],
	})

	const onChangeCallback = value => {
		setDateRange({
			startDate: value[0],
			endDate: value[1],
		})
	}

	const handleExportClick = () => {
		axAPI
			.get(
				`analytics/food-intake/export?start=${dateRange.startDate}&end=${dateRange.endDate}`,
				{ responseType: 'arraybuffer' }
			)
			.then(response => {
				const blob = new Blob([response.data], {
					type: 'application/vnd.ms-excel',
				})
				fileSaver.saveAs(
					blob,
					`Сводный отчет ${moment(dateRange.startDate).format(
						'DD.MM.YYYY'
					)} - ${moment(dateRange.endDate).format('DD.MM.YYYY')}.xlsx`
				)
			})
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '24px',
				pt: '16px',
				mr: '24px',
			}}
		>
			<Card
				sx={{
					ml: '16px',
					mr: '32px',
					boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
					backgroundColor: 'white',
					borderRadius: '12px',
				}}
			>
				<CardContent sx={{ p: '24px' }}>
					<Box
						display='flex'
						flexDirection='row'
						justifyContent='space-between'
						alignItems='center'
					>
						<TypographyHeader>Сводный отчет</TypographyHeader>
						<Box display='flex' flexDirection='row' gap='24px'>
							<OutlinedButton
								onClick={handleExportClick}
								onMouseOver={() => setColor(COLORS.main)}
								onMouseLeave={() => setColor(COLORS.secondaryFont)}
								sx={{
									width: '128px',
									height: '40px',
									margin: '0px',
									color: color,
									border: `1px solid ${COLORS.secondaryFontOpacity}`,
									textTransform: 'none',
									fontWeight: 600,
									'&:hover': {
										backgroundColor: 'transparent',
										color: color,
										borderColor: COLORS.main,
										boxShadow: 'none',
									},
								}}
								startIcon={
									<AnalyticsDownloadLogo
										onMouseOver={() => setColor(COLORS.main)}
										onMouseLeave={() => setColor(COLORS.secondaryFont)}
										stroke={color}
									/>
								}
							>
								Экспорт
							</OutlinedButton>
							<RangeDate
								onChangeUpdateDate={onChangeCallback}
								startDate={dateRange.startDate}
								endDate={dateRange.endDate}
								currentRange={'Сегодня'}
							/>
						</Box>
					</Box>
					<Box sx={{ pt: '32px', pl: '2px' }}>
						<TypographySecondary>
							Включает в себя информацию по всем группам по следующим
							показателям: количество голов, расход корма и остатки, сухое
							вещество, потребление сухого вещества.
						</TypographySecondary>
					</Box>
				</CardContent>
			</Card>
			<Suspense fallback={<Preloader />}>
				<FeedingAnalyticsCard />
			</Suspense>
		</Box>
	)
}

export default AnalyticsPage
