import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { COLORS } from '../../../styles/colors'
const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		width: '100%',
		marginTop: '32px',
	},
	row: {
		flexDirection: 'row',
		width: '100%',
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		gap: '12px',
	},
	typographyBold: {
		color: '#000',
		fontSize: '10px',
		fontWeight: 600,
	},
	number: {
		fontSize: '10px',
		color: COLORS.main,
	},
	outcome: {
		paddingLeft: '12px',
		width: '370pt',
		fontSize: '8px',
		color: '#000',
	},
	advice: {
		borderRadius: '8px',
		marginLeft: '12px',
		width: '370pt',
		fontSize: '8px',
		padding: '8px 16px',
		color: COLORS.main,
		backgroundColor: COLORS.background,
	},
})
const Observations = ({ observations }) => {
	let observationList = []
	let outcomeCount = 0

	for (let i = 0; i < observations.length; i++) {
		const observation = observations[i]
		if (observation.type === 'outcome') {
			outcomeCount++
		}
		const num = observation.type === 'outcome' ? outcomeCount : null
		const newObj = { ...observation, num }
		observationList.push(newObj)
	}
	if (observations.length === 0) return <></>
	return (
		<View style={styles.container}>
			<View style={{ width: '96px', marginRight: '36px' }}>
				<Text style={styles.typographyBold}>Наши наблюдения</Text>
			</View>
			<View style={styles.column}>
				{observationList.map((el, index) => (
					<View style={styles.row} key={index}>
						<Text style={styles.number}>{el.num && el.num}</Text>
						{el.type === 'outcome' ? (
							<Text style={styles.outcome}>{el.text}</Text>
						) : (
							<Text style={styles.advice}>{el.text}</Text>
						)}
					</View>
				))}
			</View>
		</View>
	)
}

export default Observations
