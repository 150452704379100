export const textForHeaderWidjets = [
	{
		text: 'Суточный надой',
		tooltipContent: 'Что-то написано',
	},
	{ text: 'Упитанность', tooltipContent: 'Что-то написано' },
	{ text: 'Хромота', tooltipContent: 'Что-то написано' },
]


